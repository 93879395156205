export const catigories = {
    "graduation-flower-bouquets": {
        "title": "Graduation Flowers",
        "description": "Celebrate graduation in Malaysia with Flowers Malaysia's affordable graduation flower bouquets. Enjoy same-day delivery and make their day unforgettable."
    },
    "grand-opening-flowers": {
        "title": "Grand Opening Flower Stands",
        "description": "Celebrate your grand opening with stunning flower stands that make a lasting impression."
    },
    "condolence": {
        "title": "Condolence Flower Stands",
        "description": "Explore our collection of condolence flower stands, thoughtfully designed to convey your sympathy and support during difficult times."
    },
    "wedding-flower-bouquets": {
        "title": "Wedding Flowers",
        "description": "Transform your special day with exquisite wedding flowers, designed to perfectly complement your celebration and create lasting memories."
    },
    "anniversary-flower-bouquets": {
        "title": "Anniversary Flowers",
        "description": "Celebrate your love with anniversary flowers that speak volumes and create lasting memories."
    },
    "birthday-flower-bouquets": {
        "title": "Birthday Flowers & Bouquets",
        "description": "Brighten their special day with vibrant birthday flowers and bouquets that capture the joy of celebration."
    },
    "congratulation-flower-bouquets": {
        "title": "Congratulations Flowers",
        "description": "Send your heartfelt congratulations with a bouquet that celebrates their success and joy."
    },
    "get-well-soon-flower-bouquets": {
        "title": "Get Well Soon Flowers & Bouquets",
        "description": "\u00a0Send a touch of comfort and cheer with our get well soon flowers and bouquets, perfect for lifting spirits and aiding recovery."
    },
    "newborn-flower-bouquets": {
        "title": "Newborn Flowers & Bouquets",
        "description": "Celebrate the arrival of a new baby with our exquisite collection of newborn flowers and bouquets, designed to welcome the little one with love and joy."
    },
    "romantic": {
        "title": "Romantic Flowers & Bouquets",
        "description": "Express your deepest emotions with our exquisite range of romantic flowers and bouquets, perfect for any special occasion."
    },
    "sorry-flower-bouquets": {
        "title": "Sorry Flowers & Bouquets",
        "description": "Apologise with heartfelt sorry flowers and bouquets, perfect for expressing your sincere apologies and mending relationships."
    },
    "engagement-flower-bouquets": {
        "title": "Engagement Flowers & Bouquets",
        "description": "Celebrate your engagement with our exquisite selection of flowers and bouquets, perfect for marking this special moment."
    },
    "baby-hampers": {
        "title": "Baby Hampers",
        "description": "Baby Hampers"
    },
    "fruit-hampers": {
        "title": "Fruit Hampers",
        "description": "Fruit Hampers"
    },
    "gourmet-hampers": {
        "title": "Gourmet Food Hampers",
        "description": "Gourmet Food Hampers"
    },
    "tea-set-hampers": {
        "title": "Tea Hampers",
        "description": "Tea Hampers"
    },
    "wellness-hampers": {
        "title": "Get Well Soon Hampers",
        "description": "Get Well Soon Hampers"
    },
    "organic-hampers": {
        "title": "Organic Hampers",
        "description": "Organic Hampers"
    },
    "chocolate-hampers": {
        "title": "Chocolate Hampers",
        "description": "Chocolate Hampers"
    },
    "corporate-hampers": {
        "title": "Corporate Hampers",
        "description": "Corporate Hampers"
    },
    "halal-food-hampers": {
        "title": "Halal Food Hampers",
        "description": "Halal Food Hampers"
    },
    "wine-gifts": {
        "title": "Wine Gift Hampers",
        "description": "Explore our exquisite selection of Wine Gift Hampers, perfect for celebrating any occasion. Each hamper is thoughtfully curated with premium wines and gourmet treats, ensuring a memorable gift."
    },
    "baby-breath-bouquet-malaysia": {
        "title": "Baby's Breath Flowers & Bouquets",
        "description": "Explore our delicate Baby's Breath flowers and bouquets, perfect for adding a touch of elegance and charm to any occasion."
    },
    "calla-lily-flower-bouquets": {
        "title": "Calla Lilies & Bouquets",
        "description": "Explore our collection of Calla Lily flowers and bouquets, perfect for adding elegance to any occasion."
    },
    "carnation-bouquet-flowers": {
        "title": "Carnation Flowers & Bouquets",
        "description": "Explore our exquisite collection of carnation flowers and bouquets, perfect for every occasion and crafted to delight."
    },
    "eustoma-flower-bouquets": {
        "title": "Eustoma Flowers & Bouquets",
        "description": "Explore our collection of elegant Eustoma flowers and bouquets, perfect for any special occasion."
    },
    "hydrangea-bouquet-flowers": {
        "title": "Hydrangea Flowers & Bouquets",
        "description": "Explore our elegant hydrangea flowers and bouquets, perfect for adding a touch of charm and beauty to any occasion."
    },
    "lilies": {
        "title": "Lilies Bouquets",
        "description": "Explore our stunning collection of lilies bouquets, perfect for any occasion and designed to impress."
    },
    "rose-bouquets-malaysia": {
        "title": "Roses Flowers & Bouquets",
        "description": "Explore our collection of exquisite rose flower bouquets for any special occasion."
    },
    "sunflower-bouquets-malaysia": {
        "title": "Sunflower Bouquets",
        "description": "Brighten up any occasion with our vibrant sunflower bouquets, radiating joy and warmth."
    },
    "tulip-flower-bouquets": {
        "title": "Tulip Flowers & Bouquets",
        "description": "Explore our collection of tulip flowers and bouquets, perfect for every occasion."
    },
    "newborn-fnb": {
        "title": "Newborn Balloons Bouquet",
        "description": "Newborn Balloons Bouquet"
    },
    "get-well-soon-fnb": {
        "title": "Get Well Soon Balloons Bouquet",
        "description": "Get Well Soon Balloons Bouquet"
    },
    "happy-birthday-fnb": {
        "title": "Happy Birthday Balloons\u00a0Bouquet",
        "description": "Happy Birthday Balloons\u00a0"
    },
    "congratulations": {
        "title": "Congratulations Balloons & Flowers\u00a0",
        "description": "Celebrate life's special moments with our beautiful selection of Congratulations Balloons and Flowers, delivered right to your door."
    },
    "anniversary-fnb": {
        "title": "Anniversary Balloons & Flowers",
        "description": "Celebrate your anniversary with stunning balloons and flowers from Flowers Malaysia, perfect for any special moment."
    },
    "graduation-fnb": {
        "title": "Graduation Balloons Bouquet",
        "description": "Graduation Balloons Bouquet"
    }
}

export const products = [
    {
        "id": 0,
        "name": "Mulberry",
        "handle": "mulberry",
        "description": "This stunning bouquet features vibrant pink roses, perfectly complemented by lush green foliage and delicate white accents. Wrapped in elegant grey paper, this arrangement exudes grace and beauty, making it an ideal gift for any special occasion or celebration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Mulberry-2a.jpg",
            "/images/products/Mulberry-1a.jpg"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Purple Roses . Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1,
        "name": "Crystal",
        "handle": "crystal",
        "description": "This striking bouquet showcases a unique and captivating arrangement of blue roses, surrounded by delicate white baby's breath and lush greenery. The vivid blue roses serve as the centerpiece, creating a bold and memorable display. ",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Crystal-2a.jpg",
            "/images/products/Crystal-1a.jpg"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 2,
        "name": "Breezy",
        "handle": "breezy",
        "description": "This vibrant bouquet features a cheerful arrangement of sunflowers accented by delicate baby's breath. Wrapped in a stylish kraft paper with a soft white ribbon, this bouquet is a perfect gift for brightening up any special occasions.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Breezy-2a.jpg?v=1721182381",
            "/images/products/Breezy-1a.jpg?v=1721182381"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers  .  Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 30cm*36cm"
    },
    {
        "id": 3,
        "name": "Divine",
        "handle": "divine",
        "description": "A symphony of floral beauty, this bouquet blends vibrant hot pink rose with delicate carnations and lush white blooms, all tied together with a graceful pink ribbon. It's an exquisite gift that captures the essence of grace and femininity.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Divine-2a-171538.jpg?v=1720875692",
            "/images/products/Divine-1a.jpg?v=1720840956"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Spray Roses .  Mum . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 4,
        "name": "Burgundy",
        "handle": "burgundy",
        "description": "This bouquet is a passionate display of deep pink and pristine white roses, artfully arranged with delicate greenery. It's beautifully enveloped in soft pink paper and secured with a large, silky white bow, creating a stunning gift that speaks volumes of love and admiration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Burgundy-2a-358164.jpg?v=1720875616",
            "/images/products/Burgundy-1a.jpg?v=1720840795"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 5,
        "name": "Princess",
        "handle": "princess",
        "description": "This bouquet is a vision of soft elegance, featuring a lush dome of pastel pink hydrangeas, symbolizing heartfelt emotion. The blooms are nestled atop a bed of delicate white baby's breath, creating a cloud-like base.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Princess-2b.jpg?v=1720796844",
            "/images/products/Princess-1b.jpg?v=1720796844"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 6,
        "name": "Fleur",
        "handle": "fleur",
        "description": "A charming ensemble of pastel florals, this bouquet brings together lavender, pink, and creamy roses with complementary seasonal blooms. Each flower is thoughtfully arranged and presented in a graceful blush wrap, offering a timeless expression of elegance and beauty.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Fleur-2a.jpg?v=1720889998",
            "/images/products/Fleur-1a_3eeaf10a-547b-474d-bf4b-54d10af8b5d4-358790.jpg?v=1720962298"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Tulip . Carnations . Eustoma . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 7,
        "name": "Passion",
        "handle": "passion",
        "description": "Elevate your moments with our enchanting bouquet featuring 10 red and pink tip roses adorned with delicate caspia. A vibrant symphony of colors creates a stunning visual display, while the intricate arrangement exudes elegance. Perfect for expressing love and celebrating special occasions, this bouquet is a timeless expression of affection.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Passion-2a.jpg?v=1721188571",
            "/images/products/Passion-1a.jpg?v=1721188571"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 8,
        "name": "Euphoria",
        "handle": "euphoria",
        "description": "This delightful cone bouquet from Flowers Malaysia features a pastel medley of lavender roses, pink carnations, and lemon-yellow blooms, accented with variegated pink-white carnations. Tucked in a chic cone and finished with a cream ribbon, it's a whimsical touch for any joyous occasion.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Euphoria-2f-988112.jpg?v=1724775599",
            "/images/products/Euphoria-1f.jpg?v=1724724608"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnations . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 18cm*30cm"
    },
    {
        "id": 9,
        "name": "Lollipop",
        "handle": "lollipop",
        "description": "A stunning bouquet featuring a vibrant mix of roses and flowers, elegantly wrapped in sleek black paper. The rich red, soft pink, and pastel hues create a striking contrast, perfect for any occasion. Ideal for expressing love, appreciation, or celebration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/lollipop-2b.jpg?v=1721211108",
            "/images/products/lollipop-1b.jpg?v=1721211108"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses .  Matthiola  .  Eustomas  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 10,
        "name": "Bebe",
        "handle": "baby",
        "description": "This elegant bouquet features a stunning mix of red and pink blooms, creating a harmonious and romantic arrangement. The vibrant red roses are complemented by delicate pink carnations, small white flowers, and lush greenery, adding depth and texture to the bouquet. Accents of red berries bring an additional pop of color and a festive touch.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Bebe-2a-696216.jpg?v=1721135616",
            "/images/products/Bebe-1a.jpg?v=1721100824"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Tulip  .  Carnations .  Spray Roses  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 11,
        "name": "Foreva",
        "handle": "foreva",
        "description": "Experience the romance of our delightful bouquet featuring five rich red roses and pink-tipped roses, gracefully embraced by the delicate whispers of baby's breath. This enchanting ensemble exudes love and tenderness, creating a perfect expression of affection for your special moments. Embrace the beauty of love with this captivating floral arrangement.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Foreva-2b.jpg?v=1720707386",
            "/images/products/Foreva-1b.jpg?v=1720707386"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "9 Roses  .  Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 12,
        "name": "Condolence (I)",
        "handle": "condolence-i",
        "description": "Express your deepest condolences with our Flower Stands adorned in pristine white chrysanthemums. This elegant arrangement offers solace and a serene tribute, symbolizing purity and compassion during moments of grief. May these blooms provide comfort and honor the departed with grace.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Condolence-_I_-1d.jpg?v=1720145826",
            "/images/products/Condolence-Card-2024_1_9a50d3a6-e056-44b7-9c5b-7b7f03b315d5.jpg?v=1720145831",
            "/images/products/Condolence-_I_-2d.jpg?v=1720145826"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 13,
        "name": "Dovey",
        "handle": "dovey",
        "description": "\"The best love is the kind that awakens the soul; that makes us reach for more, that plants the fire in our hearts and brings peace to our minds. That’s what I hope to give you forever.\"  - The Notebook",
        "type": "Flower Baskets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/30fd2f07-5fce-453b-954d-a0dcf0e09e37.jpg?v=1650767314",
            "/images/products/WhatsAppImage2022-04-23at12.10.49PM_1.jpg?v=1650767314"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 25cm*30cm"
    },
    {
        "id": 14,
        "name": "Mystique",
        "handle": "mystique",
        "description": "This bouquet exudes serene elegance with its soft pink roses and delicate blossoms, nestled amidst lush greenery. Wrapped in pale blue paper and accented with a billowing light blue ribbon, it makes for a tranquil and sophisticated offering.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Mystique-1b.jpg?v=1721350189",
            "/images/products/Mystique-2a.jpg?v=1720749015"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 15,
        "name": "Prosperity (I)",
        "handle": "prosperity-i",
        "description": "Unveil Orchid Splendor in Gold  Purple at all grand business openings. Luxurious orchids, adorned in golden hues, create a visually opulent display, promising success and prosperity for your flourishing venture.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Prosperity-_I_-1d.jpg?v=1720492483",
            "/images/products/Grand-Opening-Card-2024_b4dff7fc-72d5-4ad1-87ad-9da4b88caf33.jpg?v=1720492488",
            "/images/products/Prosperity-_I_-2d.jpg?v=1720492482"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Orchids . Lilies . Heliconia . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 16,
        "name": "Venice",
        "handle": "venice",
        "description": "A charming bouquet of delicate pink and white roses, accented with sprigs of lavender and eucalyptus. Wrapped in soft pink paper and adorned with a matching ribbon, this arrangement exudes elegance and grace, perfect for expressing your heartfelt sentiments.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Venice-2a.jpg?v=1720920608",
            "/images/products/Venice-1a.jpg?v=1720920608"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 17,
        "name": "Condolence (II)",
        "handle": "condolence-ii",
        "description": "In sincere condolence, our flower stands present a serene blend of white-themed chrysanthemums, pure white gerberas, and delicate orchids, adorned in respectful black dressing. This elegant arrangement offers a dignified tribute, symbolizing compassion and remembrance, providing solace during moments of deep grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Condolence-_II_-1d.jpg?v=1720181584",
            "/images/products/Condolence-Card-2024_1_0c060985-030b-49d9-ae02-9c61ddb4b885.jpg?v=1720181595",
            "/images/products/Condolence-_II_-2d.jpg?v=1720181584"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Mum . Eustoma . Orchid . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 18,
        "name": "Dreams",
        "handle": "dreams",
        "description": "An exquisite bouquet of white calla lilies, their trumpet-shaped blooms exuding sophistication, complemented by fresh green foliage. Each flower is elegantly encased in crisp, white wrapping paper, finished with a graceful white satin ribbon, creating a luxurious and refined floral arrangement.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Calla Lilies & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Dreams-2a.jpg?v=1721189107",
            "/images/products/Dreams-1a.jpg?v=1721189107"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Calla Lilies . Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 19,
        "name": "Everlast",
        "handle": "everlast",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Everlast1A.jpg?v=1667886455",
            "/images/products/Everlast2A.jpg?v=1667886455"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gossypium . Dried Lavender"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 20,
        "name": "Grace",
        "handle": "grace",
        "description": "This bouquet features a mix of pastel roses, white blooms, and delicate baby's breath, accented by lush greenery. Wrapped in soft peach paper, it's tied with a simple ribbon, creating an elegant and charming arrangement perfect for any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Carnation Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Grace-2a.jpg?v=1721101071",
            "/images/products/Grace-1a.jpg?v=1721101071"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnation . Matthiola . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 21,
        "name": "Cottage",
        "handle": "cottage",
        "description": "This bouquet features a blend of white, pink, and blush menta roses, complemented by delicate yellow blossoms and lush greenery. Wrapped in natural brown paper, it is tied with a simple ribbon, making it a charming and elegant choice for any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Cottage-2a.jpg?v=1721101303",
            "/images/products/Cottage-1a.jpg?v=1721101303"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Carnation  .  Rose Spray  .  Daisy  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 22,
        "name": "Remi",
        "handle": "queen",
        "description": "Embrace the delicate beauty of our bouquet featuring 6 charming pink roses adorned with the soft elegance of dusty miller, all tastefully presented in a lovely pink wrapper. This enchanting ensemble is a celebration of grace and subtlety, making it an ideal gift for birthdays, celebrations, or expressions of admiration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Congratulations Flowers",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Remi-2a.jpg?v=1720882958",
            "/images/products/Remi-1a.jpg?v=1720882958"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*36cm"
    },
    {
        "id": 23,
        "name": "Condolence (III)",
        "handle": "condolence-iii",
        "description": "In times of sorrow, our Condolence Flower Stands, featuring pristine white chrysanthemums, soft champagne roses, and gentle gerberas, offer solace and a graceful tribute. This elegant arrangement symbolizes compassion and remembrance, extending comfort and support during moments of grief.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Condolence-_III_-1e.jpg?v=1720145596",
            "/images/products/Condolence-Card-2024_1_6ce56357-6a12-4f2d-a178-1534097f303b.jpg?v=1720145603",
            "/images/products/Condolence-_III_-2e.jpg?v=1720145597"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Roses . Mum . Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 24,
        "name": "Hippy",
        "handle": "hippy",
        "description": "Experience a burst of joy with our Jumbo Rainbow-Colored Baby's Breath Bouquet. Bursting with a vibrant spectrum of hues, this extravagant arrangement exudes happiness and celebration. Perfect for adding a pop of color to any event or space, it promises to uplift spirits and create a kaleidoscope of unforgettable moments filled with warmth and cheer.",
        "type": "Bouquets",
        "tags": [
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Hippy-3.jpg?v=1625094566"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rainbow Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 45cm*55cm"
    },
    {
        "id": 25,
        "name": "Charm",
        "handle": "charm",
        "description": "This stunning bouquet features a large, lush blue hydrangea, beautifully accented with delicate greenery. Wrapped in elegant grey paper and tied with a matching ribbon, it’s a perfect gift for any occasion, radiating elegance and charm.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Charm-2a.jpg?v=1720745286",
            "/images/products/Charm-1a.jpg?v=1720745286"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 26,
        "name": "Success (I)",
        "handle": "success-i",
        "description": "Ignite opulence at all grand business openings with the Opulent Purple Wave Jumbo Floral Stand. Towering purple Brassicas, cheerful gerberas, delicate eustomas, and vibrant purple orchids are accented by the exotic red ginger flowers. The entire arrangement is encased in the richness of wavy red velvet skirting, creating a grand and sophisticated centerpiece.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "661.55",
        "inclTax": "661.55",
        "img": [
            "/images/products/Success-_I_-1c.jpg?v=1720524540",
            "/images/products/Grand-Opening-Card-2024_513cdb93-6f4a-4ac6-8149-2b9000996b2f.jpg?v=1721487989",
            "/images/products/Success-_I_-2c-560247.jpg?v=1720554458"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Brassica . Gerbera . Eustoma . Orchids . Red Ginger . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 120cm*180cm"
    },
    {
        "id": 27,
        "name": "Condolence (VIII)",
        "handle": "condolence-viii",
        "description": "Expressing sympathy with elegance, our condolence flower stands showcase serene white chrysanthemums and pure white gerberas, adorned in a tasteful array of white and blue-themed skirtings. This graceful arrangement offers solace and a dignified tribute during moments of grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Condolence-_VIII_-1d.jpg?v=1720157370",
            "/images/products/Condolence-Card-2024_1_7f8ca9d3-61a0-4607-b3ff-5be3477b4291.jpg?v=1720157383",
            "/images/products/Condolence-_VIII_-2d.jpg?v=1720157370"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Mum . Matthiola . Artificial Delphinium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 28,
        "name": "Graduation I",
        "handle": "graduation-i",
        "description": "This delightful bouquet features bright sunflowers and white blooms, accompanied by a cute teddy bear in graduation attire. Wrapped in rustic brown paper and adorned with a ribbon, it’s an ideal gift to celebrate a loved one's academic achievement.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Graduation-_I_-2a.jpg?v=1720665693",
            "/images/products/Graduation-_I_-1a.jpg?v=1720665692"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Eustoma . Fillers & Foliage "
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 29,
        "name": "Rainbow",
        "handle": "rainbow",
        "description": "\"Sometimes two people have to fall apart to realize how much they need to fall back together.\" - Anonymous",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2021-09-09at1.48.51PM_1.jpg?v=1641311182",
            "/images/products/WhatsAppImage2021-09-09at12.59.22PM_1.jpg?v=1641311175"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Roses  .  Eustomas  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 45cm*45cm"
    },
    {
        "id": 30,
        "name": "Kisses",
        "handle": "kisses",
        "description": "This vibrant bouquet features a stunning array of deep red roses and carnations, accented with tulips and hypericum berries. Wrapped in elegant cream paper and adorned with a gold Flowers Malaysia label, it exudes sophistication and passion, making it a perfect gift for expressing deep emotions and admiration.",
        "type": "Bouquets",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Kisses-2a-891668.jpg?v=1721570879",
            "/images/products/Kisses-1a.jpg?v=1721485646"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnations . Tulip"
            ]
        },
        "dimention": "Width*Height: 15cm*28cm"
    },
    {
        "id": 31,
        "name": "Smooch",
        "handle": "smooch",
        "description": "This elegant bouquet features a captivating blend of purple and white blooms, including delicate tulips, roses, and lisianthus, all beautifully wrapped in soft, neutral-toned paper. The harmonious mix of purples and whites creates a sophisticated and graceful arrangement, perfect for expressing admiration or celebrating special moments. This bouquet brings a touch of elegance and serenity to any setting, making it an ideal choice for any occasion.",
        "type": "Bouquets",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Smooch-2a.jpg?v=1724817132",
            "/images/products/Smooch-1a.jpg?v=1724817132"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Astromeria . Eustoma. Tulip . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 32,
        "name": "Graduation II",
        "handle": "graduation-ii",
        "description": "“All of our dreams can come true if we have the courage to pursue them.” -  Walt Disney",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/WhatsAppImage2022-08-12at1.49.21PM_2.jpg?v=1660283463",
            "/images/products/WhatsAppImage2022-08-12at1.49.21PM_1_1.jpg?v=1660283463"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Dried Flowers "
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 33,
        "name": "Graduation III",
        "handle": "graduation-iii",
        "description": "“Only those who dare to fail greatly can ever achieve greatly.” -  Bobby Kennedy",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Graduation-III---frontc-min.jpg?v=1641329755",
            "/images/products/Graduation-III---downb-min-619303.jpg?v=1671685312",
            "/images/products/Graduation-III---ZoomB-min.jpg?v=1641329660"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Dried Flowers "
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 34,
        "name": "Cheerful",
        "handle": "cheerful",
        "description": "Bask in the radiant warmth of our enchanting 3-Stalk Sunflowers Bouquet. Three vibrant sunflowers, each a symbol of joy and positivity, stand tall in this delightful arrangement. Surrounded by lush greenery, the bouquet exudes a natural charm, perfect for brightening any space or conveying heartfelt wishes. Embrace the sun's energy with this cheerful ensemble.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Newborn Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Cheerful-1aa-656132.jpg?v=1705328636",
            "/images/products/Cheerful-2aa.jpg?v=1705247868"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Thlaspi . Wheat Flower . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 35,
        "name": "Hippo",
        "handle": "hippo",
        "description": "This enchanting bouquet features a mesmerizing array of rainbow-colored baby's breath, delicately arranged and wrapped in elegant paper. The soft, pastel hues create a dreamy and whimsical effect, making this bouquet a perfect choice for adding a touch of magic to any occasion. Whether as a unique gift or a vibrant decoration, this bouquet radiates joy and creativity, bringing a smile to anyone’s face.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Hippo-2a.jpg?v=1724809619",
            "/images/products/Hippo-1a.jpg?v=1724809619"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rainbow Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 18cm*30cm"
    },
    {
        "id": 36,
        "name": "Graduation IV",
        "handle": "graduation-iv",
        "description": "“Some men see things as they are and ask why. Others dream things that never were and ask why not.” -  George Bernard Shaw",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Graduation-IV---FrontnewB-min.jpg?v=1641329652",
            "/images/products/Graduation-IV---down-min.jpg?v=1641329647",
            "/images/products/Graduation-IV---side-min.jpg?v=1641329639"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 37,
        "name": "Bridal I",
        "handle": "bridal-i",
        "description": "This beautiful bridal arrangement will come together with a Vase =)",
        "type": "Bouquets",
        "tags": [
            "Wedding Flowers",
            "Engagement Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Bridal-_I_-1.jpg?v=1625094574",
            "/images/products/Bridal-_I_-2-299560.jpg?v=1671685020",
            "/images/products/Bridal-_I_-3.jpg?v=1625094574"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "3 Garden Roses  .  Eustomas  .  Spray Roses  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 38,
        "name": "Daisy",
        "handle": "daisy",
        "description": "This charming bouquet features a lush arrangement of delicate chamomile and baby's breath flowers. Wrapped in elegant beige paper and tied with a ribbon, it exudes a simple yet sophisticated beauty, making it a perfect gift for any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Daisy-2a.jpg?v=1720790378",
            "/images/products/Daisy-1a.jpg?v=1720790377"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Daisy . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 18cm*30cm"
    },
    {
        "id": 39,
        "name": "Starry",
        "handle": "starry",
        "description": "This bouquet features elegant pink lilies surrounded by lush greenery and delicate white filler flowers. Wrapped in soft pink paper and tied with a ribbon, it exudes a sense of grace and charm, perfect for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Lilies Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Starry-2a.jpg?v=1721101921",
            "/images/products/Starry-1a.jpg?v=1721101921"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Lilies . Fillers & Foliage "
            ]
        },
        "dimention": "Width*Height:30cm*36cm"
    },
    {
        "id": 40,
        "name": "Promise",
        "handle": "promise",
        "description": "Express your passion with our striking red tulip bouquet. This captivating arrangement features vibrant red tulips elegantly wrapped in sleek black paper, creating a dramatic contrast that highlights the beauty of the blooms. ",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Newborn Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Promise-2a.jpg?v=1721214644",
            "/images/products/Promise-1a.jpg?v=1721214644"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips"
            ]
        },
        "dimention": "Width*Height: 30cm*36cm"
    },
    {
        "id": 41,
        "name": "Graduation V",
        "handle": "graduation-v",
        "description": "“The horizon leans forward, offering you space to place new steps of change.”  - Maya Angelou",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Graduation-V---frontnewC-min.jpg?v=1641329533",
            "/images/products/Graduation-V---downB-min.jpg?v=1641329528",
            "/images/products/Graduation-V---zoomB-min.jpg?v=1641329521"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rainbow Baby's Breath "
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 42,
        "name": "Graduation VI",
        "handle": "graduation-vi",
        "description": "“Nobody likes to fail, but failure is an essential part of life and of learning. If your uniform isn’t dirty, you haven’t been in the game.” -  Ben Bernanke",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Graduation-VI---FrontB-min.jpg?v=1641329516",
            "/images/products/Graduation-VI---downB-min.jpg?v=1641329508",
            "/images/products/Graduation-VI---side2B-min.jpg?v=1641329420"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Spray Roses . Spray Carnations . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 43,
        "name": "Graduation VII",
        "handle": "graduation-vii",
        "description": "The semantics of this bouquet makeup for some aesthetic combination of sunflowers, roses and a combination of unique components to create a harmonious creation..",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Graduation-VII---front2-min.jpg?v=1641329414",
            "/images/products/Graduation-VII---Down-min.jpg?v=1641329409",
            "/images/products/Graduation-VII---side3-min.jpg?v=1641329403",
            "/images/products/Graduation-VII---Side-min.jpg?v=1641329395",
            "/images/products/Graduation-VII---Side2-min.jpg?v=1641329390"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rainbow Baby's Breath "
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 44,
        "name": "Graduation VIII",
        "handle": "graduation-viii",
        "description": "Celebrate a special graduation with this charming bouquet, featuring a jubilant sunflower at its heart, surrounded by delicate pink roses, purple carnations, and assorted blooms. A plush teddy bear, donning a graduation cap, adds a touch of whimsy to the ensemble, making it a perfect commemorative gift.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Graduation-VIII-2a.jpg?v=1720670767",
            "/images/products/Graduation-VIII-1a.jpg?v=1720670767"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Carnation . Eustoma . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 45,
        "name": "Graduation IX",
        "handle": "graduation-ix",
        "description": "A graduation bouquet that captures the spirit of achievement, with a prominent, cheerful sunflower surrounded by soft pink roses and blush-toned blooms. A keepsake plush bear in a graduate's cap nestles within, making it an ideal gift to honor the graduate's milestone.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Graduation-IX-2b.jpg?v=1720670421",
            "/images/products/Graduation-IX-1b.jpg?v=1720670421"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Spray Roses . Rose . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 46,
        "name": "Graduation X",
        "handle": "graduation-x",
        "description": "This whimsical bouquet features a mix of pink gerbera daisies, purple roses, and lush greenery, topped with a playful graduation-themed plush toy. Wrapped in elegant grey paper and tied with a ribbon, it’s a fun and celebratory gift for graduates.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Graduation-X-2a.jpg?v=1720669523",
            "/images/products/Graduation-X-1a.jpg?v=1720669524"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerberra . Rose .Carnation . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 47,
        "name": "Flamingo",
        "handle": "flamingo",
        "description": "This elegant bouquet boasts a romantic assortment of soft pink roses and alstroemeria, complemented by delicate white baby's breath and lush foliage. Nestled in a chic beige round box tied with a satin ribbon, it’s a sophisticated gift for any special occasion.",
        "type": "Bouquets",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Flamingo-2c-393000.jpg?v=1724775599",
            "/images/products/Flamingo-1c.jpg?v=1724723434"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Tulip . Spray Roses . Carnations . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 18cm*30cm"
    },
    {
        "id": 48,
        "name": "Fondness",
        "handle": "fondness",
        "description": "Experience the gentle allure of romance with our charming bouquet featuring six delicate pink roses, elegantly surrounded by the ethereal whispers of baby's breath. This enchanting ensemble exudes tenderness and sweetness, creating a perfect expression of affection for your special moments. Embrace the beauty of love with this captivating floral arrangement.",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fondness-1aa.jpg?v=1704982249",
            "/images/products/Fondness-2aa.jpg?v=1704982249"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 49,
        "name": "Summer",
        "handle": "summer",
        "description": "Brighten someone's day with our radiant sunflower bouquet, featuring three stunning sunflowers surrounded by delicate baby's breath. Elegantly wrapped in earthy brown paper, this cheerful arrangement is perfect for bringing warmth and joy to any occasion.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Summer-1b.jpg?v=1720661167",
            "/images/products/Summer-1a.jpg?v=1720532462"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 50,
        "name": "Solitude",
        "handle": "solitude",
        "description": "A stunning bouquet featuring soft blush and cuppucino roses, complemented by delicate sprigs of lavender and lush greenery. Wrapped in rustic brown paper and finished with a stylish ribbon, this arrangement embodies timeless beauty and charm, perfect for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Solitude-2a.jpg?v=1720966021",
            "/images/products/Solitude-1a.jpg?v=1720966021"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Spray Roses  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 51,
        "name": "Cinderella",
        "handle": "cinderella",
        "description": "\"The best love is the kind that awakens the soul; that makes us reach for more, that plants the fire in our hearts and brings peace to our minds. That’s what I hope to give you forever.\" - The Notebook",
        "type": "Flower Baskets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-10-01at10.31.01AM_2.jpg?v=1641343328",
            "/images/products/WhatsAppImage2021-10-01at10.31.01AM_1_1.jpg?v=1641343320"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Carnations  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*30cm"
    },
    {
        "id": 52,
        "name": "Lolita",
        "handle": "lolita",
        "description": "“You know it's love when all you want is that person to be happy, even if you're not part of their happiness.”- Julia Roberts",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/WhatsAppImage2022-03-18at2.47.42PM_2.jpg?v=1647586515",
            "/images/products/WhatsAppImage2022-03-18at2.47.42PM_1_1.jpg?v=1647586517"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Spray Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 40cm*50cm"
    },
    {
        "id": 53,
        "name": "Hazel FBS",
        "handle": "hazel",
        "description": "This delightful arrangement features a rainbow of delicate baby's breath flowers, artfully presented in a stylish tan handbag. Perfect for any occasion, it brings a burst of color and joy to your loved ones' day.",
        "type": "Bag Series",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets"
        ],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Hazel-1a-301832.jpg?v=1720030582",
            "/images/products/Hazel-1b-949460.jpg?v=1720030582"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Rainbow Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 54,
        "name": "Sunset FBS",
        "handle": "sunset",
        "description": "This sunny arrangement features a vibrant sunflower surrounded by delicate baby's breath, creating a bright and joyful display. Presented in a stylish tan handbag with a soft satin ribbon, it's the perfect gift to bring happiness and warmth to any occasion.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Sunset-FBS-1a-980965.jpg?v=1720118501",
            "/images/products/Sunset-FBS-2a-418692.jpg?v=1720118501"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 55,
        "name": "Ferro FBS",
        "handle": "ferror",
        "description": "\"Love comforteth like sunshine after rain.\" – William Shakespeare",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-09-01at9.45.49AM_2.jpg?v=1661996808",
            "/images/products/WhatsAppImage2022-09-01at9.45.49AM_1_1.jpg?v=1661996814"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 56,
        "name": "Lowy",
        "handle": "lowy",
        "description": "A celebration of spring, this Flowers Malaysia bouquet bursts with cheerful tulips in shades of sunrise—pinks, oranges, and yellows—accented by frothy baby's breath and unique silvery foliage. Presented in a soft beige wrapper tied with a silky ribbon, it brings a smile to any day.",
        "type": "Bouquets",
        "tags": [
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Lowy-2c-310984.jpg?v=1724775811",
            "/images/products/Lowy-1c.jpg?v=1724724116"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 18cm*32cm"
    },
    {
        "id": 57,
        "name": "Nana",
        "handle": "nana",
        "description": "\"Nobody has ever measured—not even poets—how much love the human heart can hold.\" - Zelda Fitzgerald",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Newborn Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Nana-Front1B.jpg?v=1625094590",
            "/images/products/Nana-Front3B.jpg?v=1641312847",
            "/images/products/Nana-Front2B.jpg?v=1625094590"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "16 Tulips"
            ]
        },
        "dimention": "Width*Height: 28cm*36cm"
    },
    {
        "id": 58,
        "name": "Beauty",
        "handle": "beauty",
        "description": "Embrace the beauty of our exquisite pastel bouquet, featuring soothing blue hydrangeas, delicate pink roses, and charming baby's breath. Accented with a whimsical pom-pom detail and wrapped in elegant pink paper, this arrangement is perfect for any occasion, offering a delightful blend of softness and grace.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Beauty-2a.jpg?v=1721188709",
            "/images/products/Beauty-1a.jpg?v=1721188708"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Rose  .  Mum  .  Cotton  .  Baby Breath"
            ]
        },
        "dimention": "Width*Height: 30cm*36cm"
    },
    {
        "id": 59,
        "name": "Rosy",
        "handle": "rosy",
        "description": "This bouquet is a delightful arrangement featuring a variety of pink and white blooms, including roses and other delicate flowers, accented with silver-dusted foliage. The bouquet is elegantly wrapped in light purple paper and tied with a soft ribbon, creating a charming and romantic presentation.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Rosy-2a.jpg?v=1721102133",
            "/images/products/Rosy-1a-104147.jpg?v=1721136466"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Eustomas . Mum . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 60,
        "name": "Fondness III",
        "handle": "fondness-iii",
        "description": "Immerse yourself in the enchanting allure of our bouquet featuring six regal purple roses, gracefully encircled by the delicate elegance of baby's breath. This captivating arrangement exudes sophistication and charm, making it the perfect gift to express admiration and sentiment on any special occasion. Revel in the beauty of these blooms as they bring a touch of luxury to your cherished moments.",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fondness-III-1aa.jpg?v=1704983900",
            "/images/products/Fondness-III-2aa.jpg?v=1704983900"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 61,
        "name": "Fondness IV",
        "handle": "fondness-iv",
        "description": "Bask in the radiance of joy and friendship with our delightful bouquet featuring six sunny yellow roses, tenderly encircled by the soft, airy beauty of baby's breath. This enchanting ensemble exudes warmth and positivity, making it an ideal expression of appreciation and affection for your special moments. Embrace the vibrancy of these blooms and share the happiness with this charming floral arrangement.",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fondness-IV-1aa.jpg?v=1704984774",
            "/images/products/Fondness-IV-2aa.jpg?v=1704984773"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 62,
        "name": "Fondness II",
        "handle": "fondness-ii",
        "description": "Ignite the flame of passion with our stunning bouquet, showcasing six radiant red roses enveloped in the soft, ethereal embrace of baby's breath. This classic and romantic arrangement is a timeless expression of love and desire, perfect for conveying heartfelt sentiments on any special occasion. Revel in the beauty and elegance of this exquisite floral ensemble.",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fondness-II-1aa-379104.jpg?v=1705069242",
            "/images/products/Fondness-II-2aa.jpg?v=1704982846"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 63,
        "name": "Iglet FBS",
        "handle": "iglet",
        "description": "Presenting an exquisite floral arrangement, this piece features delicate pink roses and lush baby's breath, nestled in a chic kraft paper box with an elegant satin ribbon. Two charming, fluffy bunny figures add a whimsical touch, perfect for a luxurious gift.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-01-18at11.55.36AM_2.jpg?v=1642478314",
            "/images/products/Iglet---Delivery_1.jpg?v=1715783953",
            "/images/products/Iglet-M-Size---Delivery_1.jpg?v=1716520102",
            "/images/products/WhatsAppImage2022-01-18at11.55.36AM_1_1.jpg?v=1716520102"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Rose . Spray Roses . Baby's Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 64,
        "name": "Vibrant FBS",
        "handle": "vibrant",
        "description": "This is an elegant floral arrangement featuring a harmonious blend of peach and orange pink roses, accentuated with delicate white caspia and lush greenery. Presented in a chic, minimalist kraft paper bag with a graceful double hoop handle, it’s a modern twist on classic beauty.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Vibrant-FBS-1a.jpg?v=1696949004",
            "/images/products/Vibrant-FBS-2a.jpg?v=1696949004"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Spray Carnations  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 65,
        "name": "Castle",
        "handle": "castle",
        "description": "Elevate any occasion with our exquisite flower bouquet featuring delicate pink tip spray roses and elegant champagne eustoma. Perfectly curated to convey warmth and sophistication, this arrangement exudes timeless beauty and charm. Whether as a heartfelt gift or a stunning centerpiece, it adds a touch of luxury to any moment.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Castle-2a.jpg?v=1720966320",
            "/images/products/Castle-1a.jpg?v=1720966320"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Spray Roses  . Eustoma  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 66,
        "name": "Grinny",
        "handle": "grinny",
        "description": "This bouquet exudes a bright, cheerful vibe with its prominent sunflower, surrounded by crisp white carnations, soft pink roses, and lively green alstroemeria. Baby's breath adds a touch of whimsy, all elegantly wrapped in kraft paper and adorned with a flowing ribbon.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Newborn Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Grinny-1aa.jpg?v=1704817731",
            "/images/products/Grinny-2aa.jpg?v=1704817731"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Roses . Spray Roses . Eustomas . Baby's Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 67,
        "name": "Happiness",
        "handle": "happiness-1",
        "description": "This bouquet is a stunning arrangement featuring white roses surrounded by multicolored baby's breath in shades of blue, pink, purple, and orange. The flowers are elegantly wrapped in natural brown paper and tied with a soft ribbon, creating a beautiful and harmonious presentation that exudes charm and sophistication.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Happiness-2a.jpg?v=1721102429",
            "/images/products/Happiness-1a.jpg?v=1721102429"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Rainbow Baby Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 68,
        "name": "Lovely",
        "handle": "lovely",
        "description": "Add a touch of whimsy to your gift with our delightful pink hydrangea bouquet. This enchanting arrangement features lush pink hydrangeas, vibrant roses, and playful accents of baby's breath, all wrapped in soft pink paper. Topped with an adorable floral bunny, it's perfect for bringing joy and charm to any celebration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Lovely-2a.jpg?v=1721188453",
            "/images/products/Lovely-1a.jpg?v=1721188453"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Rose . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 69,
        "name": "Memories FBS",
        "handle": "memories",
        "description": "This beautiful arrangement features soft cream Eustoma and a delicate pink gerbera daisy, accented with lush greenery. Presented in an elegant tan handbag with a satin ribbon, it’s the perfect gift to bring a touch of grace and joy to any occasion.",
        "type": "Bag Series",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets"
        ],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Memories-FBS-1a-954185.jpg?v=1720117360",
            "/images/products/Memories-FBS-2a-904830.jpg?v=1720117360"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Gerbera . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 70,
        "name": "Swiss",
        "handle": "swiss",
        "description": "This elegant bouquet features a stunning combination of pink and purple flowers, including roses and lisianthus, enhanced with lush greenery and delicate white accents. The arrangement is beautifully wrapped in soft beige paper and tied with a matching ribbon, making it a sophisticated choice for any occasion.",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Swiss-2a.jpg?v=1721175302",
            "/images/products/Swiss-1a.jpg?v=1721175302"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Spray Roses  .  Eustomas  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 71,
        "name": "Czarina",
        "handle": "czarina",
        "description": "This elegant bouquet features a harmonious blend of red and cuppucino roses, meticulously arranged to create a stunning visual impact. Wrapped in delicate ivory paper and accented with a deep red ribbon, it is the perfect gift for expressing love and admiration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Czarina-2d-843817.jpg?v=1721049037",
            "/images/products/Czarina-1d.jpg?v=1720966559"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "6 Cappuccino Roses . 6 Red Roses"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 72,
        "name": "Florero",
        "handle": "florero",
        "description": "This elegant bouquet features a harmonious blend of soft pink roses, delicate lisianthus, and lush ornamental cabbage, creating a unique and sophisticated arrangement. Accented with fresh greenery and sprigs of lavender, this bouquet is a stunning centerpiece for any setting. Arranged in a clear glass vase and tied with a classic ribbon, it’s perfect for adding a touch of luxury and grace to any occasion.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Florero-2a.jpg?v=1724125221",
            "/images/products/Florero-1a-253820.jpg?v=1724158461"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Brassica . Eustoma . Spray Roses . Matthiola . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 73,
        "name": "Florero II",
        "handle": "florero-ii",
        "description": "This vase arrangement radiates warmth and joy with its combination of soft white lilies, pastel roses, and delicate baby's breath. Enhanced by lush greenery, the arrangement exudes a fresh and inviting ambiance. Presented in a charming glass vase adorned with a satin ribbon, this bouquet is perfect for brightening up any room or as a thoughtful gift for a special occasion.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Florero-II-2a-239661.jpg?v=1724158463",
            "/images/products/Florero-II-1a-975220.jpg?v=1724158463"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Lilies . Eustoma . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 74,
        "name": "Sincere",
        "handle": "sincere",
        "description": "This elegant bouquet features pristine white lilies complemented by lush green foliage and delicate white blossoms. Expertly wrapped in soft mint green paper, it's perfect for any occasion, adding a touch of sophistication and natural beauty to your space.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Sincere-2a.jpg?v=1721178422",
            "/images/products/Sincere-1a.jpg?v=1721178422"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Lilies  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 75,
        "name": "Luvu",
        "handle": "luvu",
        "description": "Ignite passion with our romantic bouquet – 9 red roses elegantly presented in a captivating heart-shaped willow arrangement. Wrapped in a soft pink wrapper, this enchanting ensemble is a symbol of love and affection, making it an ideal gift for anniversaries, Valentine's Day, or any heartfelt occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Luvu-2a.jpg?v=1720886407",
            "/images/products/Luvu-1a.jpg?v=1720886407"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 76,
        "name": "Mushy",
        "handle": "mushy",
        "description": "Celebrate elegance with our enchanting tulip and baby's breath bouquet. Featuring delicate pink tulips nestled among airy baby's breath, this arrangement is wrapped in soft pink paper and tied with a satin ribbon. Perfect for any occasion, it brings a touch of grace and beauty to your loved one's day.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Mushy-2a.jpg?v=1721188102",
            "/images/products/Mushy-1a.jpg?v=1721188102"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "9 Tulips . Baby Breath"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 77,
        "name": "Vogue",
        "handle": "vogue",
        "description": "A sophisticated bouquet featuring soft pastel Menta roses, white calla lilies, and lush greenery, elegantly wrapped in rustic brown paper. This exquisite arrangement is perfect for expressing heartfelt sentiments and adding a touch of elegance to any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Sorry Flowers & Bouquets",
            "Calla Lilies & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Vogue-2a.jpg?v=1721011849",
            "/images/products/Vogue-1a.jpg?v=1721011850"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Calla Lilies . Roses . Mum  .  Alstroemeria  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 78,
        "name": "Clue",
        "handle": "clue",
        "description": "Capture the serenity and tranquility of a clear blue sky with our Blue Baby's Breath Bouquet. Handcrafted with precision and care, this enchanting bouquet features delicate blue baby's breath flowers arranged to perfection. Each stem is meticulously chosen to create a stunning composition that radiates beauty and elegance.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Newborn Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Clue-1a.jpg?v=1720527841",
            "/images/products/Clue-2a.jpg?v=1720527841"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 79,
        "name": "Wink",
        "handle": "wink",
        "description": "Embrace the tender beauty of our Pink Baby's Breath Bouquet, a delightful ensemble that embodies sweetness and elegance. Each stem of baby's breath blooms in soft shades of pink, creating a vision of delicate charm and grace.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Wink-1a.jpg?v=1720527613",
            "/images/products/Wink-2a.jpg?v=1720662935"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 80,
        "name": "Burp",
        "handle": "burp",
        "description": "Celebrate life's moments with our Purple Baby's Breath Bouquet, a symbol of beauty, grace, and sophistication. Let its exquisite charm captivate your senses and elevate your surroundings with its enchanting presence.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Burp-1a.jpg?v=1720527408",
            "/images/products/Burp-2a.jpg?v=1720527409"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 81,
        "name": "Otter",
        "handle": "otter",
        "description": "This charming bouquet features a lovely mix of pink carnations and tulips, accented with a single white lisianthus and lush greenery. Wrapped in elegant kraft paper and tied with a soft pink ribbon, it makes a delightful gift for any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Otter-2a.jpg?v=1720966865",
            "/images/products/Otter-1a.jpg?v=1720966865"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Tulip  .  Mum . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 82,
        "name": "Blink",
        "handle": "blink",
        "description": "An enchanting bouquet brimming with vibrant roses, delicate carnations, and lush greenery, elegantly wrapped in pink paper. This charming arrangement is perfect for celebrating love, joy, and special moments, bringing a touch of romance and beauty to any occasion.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Blink-2a.jpg?v=1721012042",
            "/images/products/Blink-1a.jpg?v=1721012042"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Mum . Carnations . Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 83,
        "name": "Babylon",
        "handle": "babylon",
        "description": "This delicate bouquet features charming pink tulips, white roses, and fluffy poms, accented with baby's breath and lush greenery. Wrapped in soft pink paper, this arrangement exudes elegance and grace, making it the perfect gift for expressing love and appreciation.",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Babylon-1a_3.jpg?v=1721012295",
            "/images/products/Babylon-1a_1.jpg?v=1721012295"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "RosesMums  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 84,
        "name": "Whisper FBS",
        "handle": "whisper",
        "description": "“We loved with a love that was more than love.” -  Edgar Allen Poe",
        "type": "Bag Series",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets"
        ],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Whisper-New1a-527537.jpg?v=1686169096",
            "/images/products/Whisper-New2a-733999.jpg?v=1686169096"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Mum  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 85,
        "name": "Vienna",
        "handle": "vienna",
        "description": "This elegant bouquet features a harmonious blend of pink roses, carnations, and chrysanthemums, beautifully arranged with delicate statice and lush greenery. Wrapped in soft white paper and tied with a matching ribbon, this arrangement is perfect for expressing love and appreciation.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Vienna-2a.jpg?v=1720967054",
            "/images/products/Vienna-1a.jpg?v=1720967054"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Eustomas . Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 86,
        "name": "Mable",
        "handle": "mable",
        "description": "This enchanting bouquet features a delightful mix of soft peach roses and vibrant orange blooms, creating a harmonious blend of colors. Accented with delicate greenery, this arrangement brings a fresh and lively atmosphere to any setting. Presented in a stylish glass vase tied with a satin ribbon, it’s an ideal gift to celebrate special moments or to simply brighten someone's day.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Mable-2a-510015.jpg?v=1724158918",
            "/images/products/Mable-1a-773544.jpg?v=1724158918"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Spray Roses . Carnation . Eustoma . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 16cm*28cm"
    },
    {
        "id": 87,
        "name": "Snowie",
        "handle": "snowie",
        "description": "Immerse yourself in a dreamy display of purity and elegance with our Jumbo White Baby's Breath Bouquet. Overflowing with delicate blooms, this grand arrangement captivates with its ethereal beauty. Perfect for weddings, grand celebrations, or as a breathtaking centerpiece, it promises to enchant and delight, creating unforgettable moments of grace and serenity.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Congratulations Flowers",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Snowie-frontnew1.jpg?v=1625094604",
            "/images/products/Snowie-sidenew1.jpg?v=1641316681"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 50cm*60cm"
    },
    {
        "id": 88,
        "name": "Victoria",
        "handle": "victoria",
        "description": "This charming bouquet features a soft and romantic mix of blush pink tulips, delicate white roses, and fragrant stock, all beautifully wrapped in elegant paper. Accented with sprigs of greenery and filler flowers, this arrangement exudes a gentle and graceful charm. Perfect for expressing love and admiration, it’s an ideal choice for special occasions or simply to brighten someone’s day.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Victoria-2b-980272.jpg?v=1724865614",
            "/images/products/Victoria-1a.jpg?v=1724808524"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Tulips . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 89,
        "name": "Riviere",
        "handle": "riviere",
        "description": "This exquisite bouquet features lush champagne eustoma and vibrant yellow tulips, artfully arranged among delicate sprays of daisies. Tied with a soft yellow ribbon, it's presented in a stylish blush-toned Flowers Malaysia-branded wrapper, perfect for elegant gifting and special occasions.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Riverie-2h-761732.jpg?v=1724775989",
            "/images/products/Riverie-1g.jpg?v=1724723092"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips  .  Eustoma  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 18cm*28cm"
    },
    {
        "id": 90,
        "name": "Jovell",
        "handle": "jovell",
        "description": "This elegant bouquet features a harmonious blend of deep purple tulips, delicate white roses, and sprigs of lush greenery, all wrapped in a refined beige paper with Flowers Malaysia's signature branding. Perfect for any occasion, this arrangement exudes sophistication and grace.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Jovell-2b.jpg?v=1724891419",
            "/images/products/Jovell-1b.jpg?v=1724891419"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 18cm*28cm"
    },
    {
        "id": 91,
        "name": "Cuscaden FBS",
        "handle": "cuscaden",
        "description": "A radiant floral ensemble, featuring a bold sunflower centerpiece amidst blushing pink roses and alabaster white blooms, presented in an elegant kraft paper box with a luxurious satin bow, creating a timeless gift of natural beauty and sophistication.",
        "type": "Bag Series",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets"
        ],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-01-18at11.55.45AM_2.jpg?v=1642478334",
            "/images/products/Cuscaden---Delivery_1-956595.jpg?v=1716500892",
            "/images/products/Cuscaden-M-Size---Delivery_1.jpg?v=1716520034",
            "/images/products/WhatsAppImage2022-01-18at11.55.45AM_1_1.jpg?v=1716520034"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Eustomas . Matthiola . Orchid . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*35cm"
    },
    {
        "id": 92,
        "name": "Berry",
        "handle": "berry",
        "description": "This whimsical bouquet features a delightful mix of colorful baby's breath and fluffy cotton blooms, creating a playful and charming arrangement. Wrapped in elegant white paper and secured with a matching ribbon, it's a perfect gift for any occasion to brighten someone's day.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Berry-2a.jpg?v=1721005579",
            "/images/products/Berry-1a.jpg?v=1721005579"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Cotton Flowers . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 93,
        "name": "Hilton",
        "handle": "hilton",
        "description": "This stunning bouquet combines pristine white tulips with delicate blue thistles, surrounded by lush greenery. Wrapped in a sophisticated beige paper and adorned with Flowers Malaysia's signature branding, this arrangement exudes elegance and charm, perfect for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Hilton-2c1.jpg?v=1724891883",
            "/images/products/Hilton-1c.jpg?v=1724891774"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Eustoma . Matthiola . Silver Brunia . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 18cm*30cm"
    },
    {
        "id": 94,
        "name": "Faith",
        "handle": "faith",
        "description": "A sophisticated bouquet arranged in a cream box, brimming with vibrant red roses, pink tulips, and a spray of white daisies, complemented by red carnations and assorted greenery, offering a classic and romantic floral gift.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Faith-2a.jpg?v=1721527918",
            "/images/products/Faith-1a.jpg?v=1721527918"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Tulips . Carnation . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 18cm*30cm"
    },
    {
        "id": 95,
        "name": "Merry",
        "handle": "merry",
        "description": "Embrace the vibrant allure with our bouquet featuring 6 stunning hot pink roses, adorned with the delicate beauty of caspia and the textured elegance of parvifolia. The bold and lively hues of the roses create a striking visual impact, while the complementary caspia and parvifolia add an extra layer of charm. ",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Merry-1a.jpg?v=1720777622",
            "/images/products/Merry-2a.jpg?v=1720777622"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hot Pink Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 96,
        "name": "Olaf FBS",
        "handle": "olaf",
        "description": "\"Maybe you don't need the whole world to love you. Maybe you just need one person.\" - Kermit the Frog",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Olaf-Front-min.jpg?v=1641309468",
            "/images/products/Olaf-Side2-min.jpg?v=1641309379"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Gossypium . Carnation . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*32cm"
    },
    {
        "id": 97,
        "name": "Jupiter",
        "handle": "jupiter",
        "description": "This vibrant bouquet features radiant sunflowers paired with creamy white roses, enhanced by lush green foliage and delicate baby's breath. Wrapped in natural brown paper, the arrangement exudes a rustic charm and warmth. Ideal for bringing a touch of sunshine and elegance to any occasion, making it a perfect gift for brightening someone's day.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Jupiter-2a.jpg?v=1722296967",
            "/images/products/Jupiter-1a.jpg?v=1722296968"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 45cm*55cm"
    },
    {
        "id": 98,
        "name": "Estuary",
        "handle": "estuary",
        "description": "This elegant arrangement features pristine white lilies paired with delicate daisies and sprigs of eucalyptus, creating a fresh and serene bouquet. Accented with touches of blue thistle and wrapped in a stylish ribbon, this bouquet is presented in a clear glass vase. Perfect for bringing a touch of nature indoors, it’s an ideal choice for a peaceful and calming atmosphere.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Estuary-2a-511713.jpg?v=1724337584",
            "/images/products/Estuary-1a-964814.jpg?v=1724337584"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Lilies . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 99,
        "name": "Frosty",
        "handle": "frosty",
        "description": "\"Better to have lost and loved than never to have loved at all.\" - Ernest Hemingway",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Frosty1A_c673726f-5a50-4f8c-9b69-2bee637c4647-374894.jpg?v=1674238788",
            "/images/products/Frosty2.jpg?v=1674181862"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gossypium . Baby's Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 100,
        "name": "Petite",
        "handle": "petite",
        "description": "This bouquet is a whimsical blend of nature's joy, featuring a standout sunflower framed by pale pink roses and creamy blossoms. Feathery pink spikes add a playful contrast, while a backdrop of lush greenery and gypsophila provides a full-bodied look.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Petite-1a.jpg?v=1720749189",
            "/images/products/Petite-2a.jpg?v=1720749189"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*32cm"
    },
    {
        "id": 101,
        "name": "Agnes",
        "handle": "valentine",
        "description": "Celebrate Love with Our Enchanting Red Rose BouquetFeaturing 3 radiant red roses artfully arranged with heart-shaped willow accents, this bouquet is adorned with a whimsical touch of rainbow baby's breath. A symbol of joy and affection, it is presented in a stylish wrapper, making it the perfect gift for anniversaries, romantic occasions, or simply to brighten someone's day.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Agnes-2a.jpg?v=1720778303",
            "/images/products/Agnes-1a.jpg?v=1720778303"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Rainbow Baby Breath"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 102,
        "name": "Regis",
        "handle": "regis",
        "description": "Elevate the romance with our bouquet of 12 stunning red and pink roses, beautifully bundled in a soft pink wrapper. This enchanting arrangement is a symphony of passionate reds and delicate pinks, creating a visual masterpiece perfect for expressing love on anniversaries or any heartfelt occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Regis-1a.jpg?v=1720658543",
            "/images/products/Regis-2a.jpg?v=1720658543"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 103,
        "name": "Honey",
        "handle": "honey",
        "description": "This charming bouquet features delicate pink and white blooms nestled in lush greenery, all beautifully arranged with a rustic double heart frame. Wrapped in soft pink paper and adorned with a vibrant ribbon, this arrangement makes a perfect romantic gesture or heartfelt gift.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Honey-1b.jpg?v=1721012587",
            "/images/products/Honey-1a-635826.jpg?v=1721049343"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Eustoma  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 104,
        "name": "Flame",
        "handle": "flame",
        "description": "Discover the elegance of our signature bouquet, featuring a delicate blend of creamy white, soft pink, and pastel yellow roses, accented with lush greenery and baby's breath. Perfect for any occasion, this arrangement brings a touch of sophistication and charm to your special moments.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Flame-2a.jpg?v=1721184971",
            "/images/products/Flame-1a.jpg?v=1721184971"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 105,
        "name": "Darling",
        "handle": "darling",
        "description": "Immerse yourself in the language of love with our exquisite bouquet featuring 12 luxurious red roses, artfully arranged and accentuated by a heart-shaped willow frame. This enchanting ensemble captures the essence of romance, making it a perfect gesture for anniversaries, engagements, or expressions of deep affection. Elevate the moment with this symbol of enduring love and passion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Darling-2a-265479.jpg?v=1721396491",
            "/images/products/Darling-1a-256951.jpg?v=1721396491"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "12 Roses"
            ]
        },
        "dimention": "Width*Height: 30cm*36cm"
    },
    {
        "id": 106,
        "name": "Smitten",
        "handle": "smitten",
        "description": "Embark on a journey of vibrant beauty with our Rainbow Heart-Shaped Baby's Breath Bouquet, a stunning testament to love, joy, and diversity. Crafted with meticulous care and artistic flair, this bouquet features an array of baby's breath flowers in a spectrum of radiant colors, intricately arranged into a heart shape.",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Smitten-2a_ee7ae15b-51a4-45ec-94dc-6ab163ca24a1.jpg?v=1720797264",
            "/images/products/Smitten-1a_9e39444f-b53d-4647-b010-f56e54c67d66-615399.jpg?v=1720877664"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rainbow Baby's Breath "
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 107,
        "name": "Lovie",
        "handle": "lovie",
        "description": "Immerse yourself in the timeless elegance of love with our Red Heart-Shaped Baby's Breath Bouquet, a striking expression of passion, devotion, and romance. Carefully curated and expertly arranged, this bouquet features delicate baby's breath flowers in rich shades of red, intricately formed into a captivating heart shape.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Lovie-2a-393633.jpg?v=1720876225",
            "/images/products/Lovie-1a-240787.jpg?v=1720876225"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 108,
        "name": "Inspire",
        "handle": "inspire",
        "description": "Brighten someone's day with our vibrant sunflower and rose bouquet. Featuring cheerful sunflowers paired with striking red roses and lush eucalyptus, this arrangement is wrapped in warm orange paper. Perfect for conveying joy, appreciation, or love, it brings a burst of sunshine and happiness to any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Inspire-2a.jpg?v=1721189238",
            "/images/products/Inspire-1a.jpg?v=1721189238"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 109,
        "name": "DarDar",
        "handle": "dardar",
        "description": "Immerse yourself in a sea of passion with our grand bouquet featuring 36 radiant red roses intricately arranged around a heart-shaped willow frame. This opulent ensemble is a testament to love's grandeur, making it an extraordinary gift for anniversaries, grand celebrations, or to express profound affection. Capture the essence of romance with this stunning floral masterpiece.",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/DarDar--2a-252742.jpg?v=1727261656",
            "/images/products/DarDar-1-985348.jpg?v=1727261656"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "36 Roses"
            ]
        },
        "dimention": "Width*Height: 32cm*40cm"
    },
    {
        "id": 110,
        "name": "Collyer",
        "handle": "collyer",
        "description": "A delicate bouquet of pastel roses in hues of cream, soft pink, and white, intermingled with charming sprigs of baby’s breath. This ethereal arrangement is adorned with playful pink ribbons and wrapped in a dreamy white paper, radiating gentle grace and sophistication.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/WhatsAppImage2022-04-13at12.25.40PM_2_1.jpg?v=1650419590",
            "/images/products/WhatsAppImage2022-04-13at12.25.40PM_1.jpg?v=1650419590"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Rainbow Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 32cm*40cm"
    },
    {
        "id": 111,
        "name": "Granduer",
        "handle": "granduer",
        "description": "\"Sometimes, someone comes into your life, so unexpectedly, takes your heart by surprise, and changes your life forever.\" - Unknown",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Granduer-Insta2.jpg?v=1625094617"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 112,
        "name": "Valle",
        "handle": "valle",
        "description": "Radiate joy with our Sunflower and Hot Pink Roses Arrangement. Three vibrant sunflowers and hot pink roses, embraced by delicate caspia, create a cheerful and love-filled display. Artfully arranged within a heart-shaped willow frame, this arrangement is a delightful expression of affection, perfect for celebrating moments of happiness and love.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Valle-1aa.jpg?v=1705981530",
            "/images/products/Valle-2aa.jpg?v=1705981530"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers  .  Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*36cm"
    },
    {
        "id": 113,
        "name": "Leonie",
        "handle": "leonie",
        "description": "This vibrant bouquet features a bright sunflower, pink roses, and red spray roses, accented with delicate baby's breath. Wrapped in soft blue paper and tied with a white ribbon, it’s a cheerful and charming gift for any occasion",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Leonie-2a-857195.jpg?v=1720789290",
            "/images/products/Leonie-1a-121422.jpg?v=1720789290"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Roses . Spray Roses . Eustoma . Baby Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 114,
        "name": "Grove",
        "handle": "grove",
        "description": "Captivate with the allure of this bouquet featuring a captivating cluster of vibrant lavender roses. Wrapped in ethereal white and lilac tulle with a graceful satin bow, this arrangement from Flowers Malaysia radiates elegance, making it a perfect gift for cherished moments.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Grove-2f.jpg?v=1721006259",
            "/images/products/Grove-1f.jpg?v=1721006259"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "12 Stalks Purple Roses"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 115,
        "name": "Dalvey",
        "handle": "dalvey",
        "description": "Discover the striking allure of our blue-themed floral bouquet. Featuring vivid blue roses and delicate baby's breath, accented with soft cotton blooms, this arrangement is a perfect blend of elegance and uniqueness. Wrapped in a bold blue paper, it creates a captivating visual display, making it an ideal choice for special celebrations or to brighten someone's day with a touch of sophistication.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Dalvey-2a-404649.jpg?v=1721396487",
            "/images/products/Dalvey-1a.jpg?v=1721354048"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses . Gossypium . Baby Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 116,
        "name": "Gentle",
        "handle": "gentle",
        "description": "This elegant bouquet features soft pink roses, delicate blooms, and lush greenery, beautifully wrapped in pastel pink paper. Perfect for any occasion, it exudes a sense of grace and charm, making it an ideal gift for expressing your heartfelt sentiments.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Gentle-2a.jpg?v=1721012778",
            "/images/products/Gentle-1a.jpg?v=1721012778"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses . Spray Roses . Calla Lily . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 117,
        "name": "Pretty",
        "handle": "pretty",
        "description": "\"Love does not consist in gazing at each other, but in looking outward together in the same direction.\" - Antoine de Saint-Exupéry",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Pretty-Insta.jpg?v=1641312354",
            "/images/products/Pretty-Front-815875.jpg?v=1671685636",
            "/images/products/Pretty-side.jpg?v=1641312259"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 118,
        "name": "Watten",
        "handle": "watten",
        "description": "This enchanting bouquet features soft purple roses, fluffy white chrysanthemums, and delicate blue accents, beautifully arranged with lush greenery. Wrapped in elegant blue paper and tied with a matching ribbon, it’s a stunning gift for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Watten-2b.jpg?v=1720882480",
            "/images/products/Watten-1b.jpg?v=1720882480"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Mum  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 119,
        "name": "Fancy",
        "handle": "fancy",
        "description": "This beautiful bouquet features a delightful mix of soft peach and cream spray roses, accented with delicate white fillers and lush greenery. Wrapped in elegant pink and grey paper, it exudes charm and sophistication. Perfect for weddings, anniversaries, or any special occasion to convey heartfelt sentiments.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Fancy-2a.jpg?v=1721190563",
            "/images/products/Fancy-1a.jpg?v=1721190563"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Spray Roses  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 120,
        "name": "Olton",
        "handle": "olton",
        "description": "Delight in the subtle beauty of our bouquet featuring 6 charming pink-tipped roses, adorned with the delicate blooms of caspia and the intricate texture of parvifolia. The soft pink hues on the rose tips add a touch of elegance, while the complementary caspia and parvifolia create a harmonious and visually appealing arrangement.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Olton-1a.jpg?v=1720777520",
            "/images/products/Olton-2a.jpg?v=1720777521"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Pink Tip Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*36cm"
    },
    {
        "id": 121,
        "name": "Rennell",
        "handle": "rennell",
        "description": "This charming bouquet features a lush cluster of white baby's breath, elegantly arranged in a rustic heart-shaped willow. Wrapped in earthy brown paper and tied with a ribbon, it's a perfect gift for any occasion, radiating simplicity and grace.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Rennell-1c.jpg?v=1720744948",
            "/images/products/Rennell-1b.jpg?v=1720663420"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 30cm*36cm"
    },
    {
        "id": 122,
        "name": "Morris",
        "handle": "morris",
        "description": "\"Happily ever after is not a fairy tale—it's a choice.\" - Fawn Weaver",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/56588767-bbed-44e8-af70-74ffadbeb9c3.jpg?v=1641313093",
            "/images/products/02f93627-1a8c-4052-adfe-56e2a410f37d.jpg?v=1641313087"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea . Tulips  .  Eustomas . Carnation . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 123,
        "name": "Success (II)",
        "handle": "success-ii",
        "description": "Elevate all business launches with our Red Rose  Gerbera Majesty Floral Stand. Towering red roses and lively gerberas create a regal display, signifying love, energy, and prosperity for your flourishing business venture.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Success-_II_-1d.jpg?v=1720407410",
            "/images/products/Grand-Opening-Card-2024_f19d390f-c35d-4483-9fdc-6e91f75608ab.jpg?v=1720407415",
            "/images/products/Success-_II_-2d.jpg?v=1720407410"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Gerberra . Mum .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 124,
        "name": "Success (III)",
        "handle": "success-iii",
        "description": "Step into a realm of regal elegance with our Royal Crimson Blossom Grand Opening Stand, a stunning showcase of red gerbera, pink lilies and green mum. This majestic floral arrangement effortlessly combines the fiery passion of red gerbera daisies with the royal sophistication of color, creating a visual masterpiece that symbolizes prestige and success. ",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Success-_III_-1d.jpg?v=1720492623",
            "/images/products/Grand-Opening-Card-2024_d6b860f5-60b8-4251-ac1e-07a581448957.jpg?v=1720492630",
            "/images/products/Success-_III_-2d.jpg?v=1720492624"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Lily . Mum . Carnations . Red Ginger . Purple Statice . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 100cm*185cm"
    },
    {
        "id": 125,
        "name": "Success (IV)",
        "handle": "success-iv",
        "description": "Elevate your grand business opening with our Pink  Red-themed gerbera  purple orchids flower stands. A symphony of vibrant hues and lush greenery creates a visually stunning display, symbolizing passion, energy, and a flourishing start for your venture.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Success-_IV_-1e.jpg?v=1720492969",
            "/images/products/Grand-Opening-Card-2024_db8e3928-2067-41d8-b95a-6032646214dc.jpg?v=1720492975",
            "/images/products/Success-_IV_-2e.jpg?v=1720492969"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerberra . Pink Lilies . Orchids . Heliconia . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 126,
        "name": "Vue",
        "handle": "vue",
        "description": "\"Love grows more tremendously full, swift, poignant, as the years multiply.\" - Zane Grey",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Vue1_3cc9d33c-cfaf-46e1-84e4-34870c492226-890295.jpg?v=1671775974",
            "/images/products/Vue2.jpg?v=1667270849"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 40cm*50cm"
    },
    {
        "id": 127,
        "name": "Eternity",
        "handle": "eternity",
        "description": "Unveil the Allure of Romance with Our Red Rose BouquetUnveil the allure of romance with this bouquet featuring nine luscious red roses accompanied by the delicate charm of pink caspia. Tastefully wrapped in nude-colored packaging, this arrangement balances passion and subtlety, making it an ideal gift for expressing love and appreciation.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Eternity-2b-446438.jpg?v=1720875853",
            "/images/products/Eternity-1b.jpg?v=1720839905"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "9 Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 128,
        "name": "Eternity II",
        "handle": "eternity-ii",
        "description": "Indulge in the Tender Charm of Our Pink Rose BouquetIndulge in the tender charm of our bouquet, showcasing 9 lovely pink roses adorned with delicate caspia. The soft hues and intricate arrangement create an elegant ensemble, perfect for expressing affection on birthdays, celebrations, or to brighten someone's day. Elevate the moment with this beautiful floral expression of grace and admiration. Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Eternity-II-2a.jpg?v=1720839805",
            "/images/products/Eternity-II-1a.jpg?v=1720839805"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Pink Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 129,
        "name": "Geneve",
        "handle": "geneve",
        "description": "Bask in the timeless allure of romance with our exquisite bouquet featuring 12 radiant red roses, each one surrounded by the delicate elegance of baby's breath. This classic arrangement captures the essence of love, making it a perfect gift to convey passion and affection for your cherished moments.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Geneve-2c.jpg?v=1720880292",
            "/images/products/Geneve-1c.jpg?v=1720880292"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "12 Roses  .  Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 130,
        "name": "Tulipwood",
        "handle": "tulipwood",
        "description": "This elegant bouquet features delicate 9 pink tulips beautifully arranged with lush green stems and wrapped in brown and white paper. Tied with a soft pink ribbon, it is a perfect gift to convey love and admiration on any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Tulipwood-2c.jpg?v=1720692824",
            "/images/products/Tulipwood-1c.jpg?v=1720692823"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 131,
        "name": "Princess II",
        "handle": "princess-ii",
        "description": "This bouquet captivates with a serene expanse of sky blue hydrangeas, a symbol of heartfelt sincerity and abundance. The rich, voluminous blooms are complemented by a frothy sea of baby's breath, adding a touch of innocence.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Princess-II-1a.jpg?v=1720745964",
            "/images/products/Princess-II-2a.jpg?v=1720745964"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 132,
        "name": "Carey",
        "handle": "carey",
        "description": "This romantic bouquet features a charming mix of red and pink roses, accented by delicate baby’s breath and a playful pom-pom bloom. Wrapped in elegant pink paper and adorned with a heart-shaped twig accent, it's the perfect gift to express love and affection.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Carey-1a.jpg?v=1721007091",
            "/images/products/Carey-2a.jpg?v=1721007091"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "= Roses  .  Mum . Spray Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 133,
        "name": "Ice",
        "handle": "ice",
        "description": "This bouquet features a striking combination of blue and white flowers. A single vibrant blue rose stands out among white roses, delicate blue delphiniums, and sprigs of baby's breath. The bouquet is accented with lush eucalyptus leaves, adding greenery and texture. ",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Newborn Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Ice-2a.jpg?v=1721102686",
            "/images/products/Ice-1a.jpg?v=1721102686"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Matthiola . Delphinium . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 134,
        "name": "Bridal II",
        "handle": "bridal-ii",
        "description": "This beautiful bridal arrangement will come together with a Vase =)",
        "type": "Bouquets",
        "tags": [
            "Wedding Flowers",
            "Engagement Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Bridal-II---1.jpg?v=1625094629",
            "/images/products/Bridal-II---3.jpg?v=1641337579",
            "/images/products/Bridal-II---2.jpg?v=1641337573",
            "/images/products/Bridal-II---4.jpg?v=1625094629"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Eustomas . Mattiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 135,
        "name": "Lovers",
        "handle": "lovers",
        "description": "Celebrate elegance with our charming pink rose bouquet. Featuring three delicate pink roses surrounded by lush baby's breath and soft pink accents, this arrangement is beautifully wrapped in pastel pink paper. Perfect for expressing love and admiration on any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Lovers-2a.jpg?v=1720532747",
            "/images/products/Lovers-1a.jpg?v=1720532747"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath . Fillers"
            ]
        },
        "dimention": "Width*Height: 22cm*30cm"
    },
    {
        "id": 136,
        "name": "Bridal III",
        "handle": "bridal-iii",
        "description": "This beautiful bridal arrangement will come together with a Vase =)",
        "type": "Bouquets",
        "tags": [
            "Wedding Flowers",
            "Engagement Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Bridal-III---1.jpg?v=1625094630",
            "/images/products/Bridal-III---3.jpg?v=1641337555",
            "/images/products/Bridal-III---2.jpg?v=1641337548"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Spray Roses . Eustomas . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 137,
        "name": "Bridal IV",
        "handle": "bridal-iv",
        "description": "This beautiful bridal arrangement will come together with a Vase =)",
        "type": "Bouquets",
        "tags": [
            "Wedding Flowers",
            "Engagement Flowers & Bouquets"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Bridal-IV---1.jpg?v=1625094631",
            "/images/products/Bridal-IV---2.jpg?v=1641337452",
            "/images/products/Bridal-IV---3.jpg?v=1641337446"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Eustomas . Mattiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 138,
        "name": "Purity",
        "handle": "purity",
        "description": "This elegant bouquet features a stunning white hydrangea as its centerpiece, surrounded by soft pink roses and delicate lisianthus blooms. Accentuated with sprigs of greenery and wrapped in a blush-toned ribbon, this arrangement exudes grace and charm. Perfect for expressing love or celebrating special moments, it brings a touch of sophistication and warmth to any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Purity-2a.jpg?v=1726622968",
            "/images/products/Purity-1D.jpg?v=1625094634",
            "/images/products/Purity-2.jpg?v=1641311659",
            "/images/products/Purity-3A.jpg?v=1625094634",
            "/images/products/Purity-1a.jpg?v=1726622968"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Roses  .  Eustoma  .  Spray Carnations  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 139,
        "name": "Enchant",
        "handle": "enchant",
        "description": "\"When love is at its best, one loves so much that he cannot forget.\" – Helen Hunt Jackson",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Newborn Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Enchant.jpg?v=1625094635",
            "/images/products/Enchant3.jpg?v=1625094635"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 140,
        "name": "Wonders",
        "handle": "wonders",
        "description": "Celebrate the elegance of our pastel rose bouquet. This stunning arrangement features a delightful mix of soft pink and creamy yellow roses, accented with baby's breath and lush greenery. Wrapped in rustic brown paper, it exudes timeless charm and is perfect for any occasion, from anniversaries to heartfelt thank yous.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Wonders-2a.jpg?v=1721190860",
            "/images/products/Wonders-1a.jpg?v=1721190860"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Matthiola . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 141,
        "name": "Stellar",
        "handle": "stellar",
        "description": "“Love takes off masks that we fear we cannot live without and know we cannot live within.” - James Baldwin",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Stellar-1C.jpg?v=1625094636",
            "/images/products/Stellar-3.jpg?v=1641316447",
            "/images/products/Stellar-2.jpg?v=1625094636"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 142,
        "name": "Paradise",
        "handle": "paradise",
        "description": "\"Love looks not with the eyes, but with the mind / And therefore is winged Cupid painted blind.\" - William Shakespeare",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Paradise1-665667.jpg?v=1696490755",
            "/images/products/Paradise1A-431014.jpg?v=1696490755"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Bird of Paradise  .  Eustomas  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 32cm*38cm"
    },
    {
        "id": 143,
        "name": "Atasi",
        "handle": "atasi",
        "description": "This striking bouquet features vibrant blue tulips, elegantly paired with delicate white fillers and lush green foliage. Wrapped in sophisticated white paper, it is finished with a stylish ribbon, making it a perfect gift to convey your heartfelt sentiments.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Atasi-2a.jpg?v=1721007616",
            "/images/products/Atasi-1a.jpg?v=1721007617"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Fillers & Foliages"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 144,
        "name": "Chardonnay",
        "handle": "chardonnay",
        "description": "This bouquet is a romantic composition of roses in blushing shades of cappuccino and champagne, complemented by hints of greenery. Elegantly wrapped in an ivory paper and tied with a soft satin ribbon, it exudes a classic charm perfect for heartfelt occasions.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Chardonnay-2a.jpg?v=1720884927",
            "/images/products/Chardonnay-1a.jpg?v=1720884927"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 145,
        "name": "Lanore",
        "handle": "lanore",
        "description": "Indulge in luxury with our delightful bouquet, a perfect blend of romance and sweetness. Featuring 6 velvety red roses, 6 Ferrero Rocher chocolates, and delicate baby's breath, all elegantly bundled in a pristine white wrapper. This exquisite ensemble is a harmonious balance of passion and decadence, making it an ideal gift for special occasions or expressions of love. Elevate your gestures with this captivating floral and chocolate arrangement.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2022-01-09at9.00.33AM_2_1.jpg?v=1641690079",
            "/images/products/WhatsAppImage2022-01-09at9.00.33AM_1_1.jpg?v=1641690109"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath . Ferrero Rocher"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 146,
        "name": "Condolence (V)",
        "handle": "condolence-v",
        "description": "In a heartfelt expression of sympathy, our condolence flower stands present a serene display of pure white, gentle yellow, and soothing green chrysanthemums. Adorned with a tasteful white skirting, this elegant arrangement offers solace and a dignified tribute during moments of condolence and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Condolence-_V_-1d.jpg?v=1720158806",
            "/images/products/Condolence-Card-2024_1_4550e8c6-5f33-4f7b-8da9-ba1157c65306.jpg?v=1720158822",
            "/images/products/Condolence-_V_-2d.jpg?v=1720158807"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Birds of Paradise . Cyperus Stick . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 100cm*180cm"
    },
    {
        "id": 147,
        "name": "Condolence (VI)",
        "handle": "condolence-vi",
        "description": "In solemn remembrance, our condolence flower stands elegantly feature pure white chrysanthemums paired with warm brown eustomas. This arrangement offers a serene tribute, symbolizing purity and grace, providing solace and support during moments of condolence and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Condolence-_VI_-1f.jpg?v=1720145357",
            "/images/products/Condolence-Card-2024_1_c1ca1966-ebe2-4de9-9934-357a346f4d9a.jpg?v=1720145363",
            "/images/products/Condolence-_VI_-2f.jpg?v=1720145356"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Eustoma . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 100cm*180cm"
    },
    {
        "id": 148,
        "name": "Blenda",
        "handle": "blenda",
        "description": "This elegant bouquet features soft pink tulips and delicate white fillers, beautifully arranged with lush greenery. Wrapped in stylish grey paper and tied with a pink ribbon, it’s a perfect gift for any occasion, expressing grace and sophistication.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Blenda-1a.jpg?v=1720785089",
            "/images/products/Blenda-2a.jpg?v=1720785089"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 149,
        "name": "Lonna",
        "handle": "lonna",
        "description": "“Your task is not to seek for love, but merely to seek and find all the barriers within yourself that you have built against it.” - Rumi",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Lonna-1B.jpg?v=1625094643",
            "/images/products/Lonna-3.jpg?v=1641322668",
            "/images/products/Lonna-2.jpg?v=1641322579"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 150,
        "name": "Lenita",
        "handle": "lenita",
        "description": "“The only thing we never get enough of is love; and the only thing we never give enough of is love.” - Henry Miller",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/WhatsAppImage2022-05-17at7.40.54PM_2.jpg?v=1652837835",
            "/images/products/WhatsAppImage2022-05-17at7.40.54PM_1_1.jpg?v=1652837837"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*38cm"
    },
    {
        "id": 151,
        "name": "Chelsa",
        "handle": "juliette",
        "description": "Celebrate lasting beauty with our bouquet of three preserved pastel pink roses and hydrangea. Each bloom, meticulously preserved, radiates elegance and grace. This enchanting arrangement is a heartfelt expression of love that will stand the test of time.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Chelsa-1e_42679d8b-a42a-43d1-9a4d-f8389801e30f-609135.jpg?v=1704809553",
            "/images/products/Chelsa-2e_86f9bd85-05a5-4763-a2e6-0a4b4b1898de-934554.jpg?v=1704809553"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Roses . Preserved Hydrangea . Fillers"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 152,
        "name": "Julia",
        "handle": "julia",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Julia-1B.jpg?v=1641315228",
            "/images/products/Julia-2B.jpg?v=1625094647",
            "/images/products/Julia-3B.jpg?v=1625094647"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Carnation . Gossypium . Fillers"
            ]
        },
        "dimention": "Width*Height: 10cm*15cm"
    },
    {
        "id": 153,
        "name": "Lydia",
        "handle": "lydia",
        "description": "\"Absence is to love as wind is to fire; it extinguishes the small and kindles the great.\" – Roger de Bussy-Rabutin",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Lydia-1B.jpg?v=1641321714",
            "/images/products/Lydia-2B.jpg?v=1641321708",
            "/images/products/Lydia-3B.jpg?v=1641321619"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Preserved Hydrangea"
            ]
        },
        "dimention": "Width*Height: 8cm*12cm"
    },
    {
        "id": 154,
        "name": "Autumn",
        "handle": "autumn",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Baskets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Autumn-1c.jpg?v=1625094648",
            "/images/products/Autumn-2c.jpg?v=1625094649"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Preserved Carnation . Preserved Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 155,
        "name": "Eva",
        "handle": "eva",
        "description": "Delicate pink carnations bloom amidst a frothy bed of gypsophila, presented in a handwoven basket, adorned with a dainty ribbon. This Flowers Malaysia creation exudes a quaint charm and gentle grace, perfect for moments of pure affection.",
        "type": "Flower Baskets",
        "tags": [
            "Carnation Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Eva-1c.jpg?v=1727312359",
            "/images/products/Eva-2c.jpg?v=1727312359"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 18cm*26cm"
    },
    {
        "id": 156,
        "name": "Isla",
        "handle": "isla",
        "description": "Bold red carnations take center stage in this Flowers Malaysia bouquet, nestled within a delicate cloud of gypsophila, all elegantly wrapped in a blush-hued paper with a soft ribbon, embodying a vibrant declaration of love.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Isla-1b.jpg?v=1720662192",
            "/images/products/Isla-2a.jpg?v=1720581187"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Carnations  . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 157,
        "name": "Everly",
        "handle": "everly",
        "description": "This vibrant bouquet features red and pink carnations, complemented by delicate greenery and silver accents. Wrapped in soft pink paper and tied with a bold red ribbon, it’s a striking and elegant gift for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Everly-2a.jpg?v=1720690330",
            "/images/products/Everly-1a.jpg?v=1720690330"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 158,
        "name": "Condolence (VII)",
        "handle": "condolence-vii",
        "description": "Express heartfelt condolences with our White-Themed Flower Stand. Thoughtfully arranged with serene Chrysanthemums, silk Lilies, and Hydrangea, along with fresh White Orchids, this elegant tribute exudes comfort and sympathy. A tasteful way to convey solace and support during difficult times, offering a serene and comforting presence.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Condolence-_VII_-1e.jpg?v=1720146629",
            "/images/products/Condolence-Card-2024_1_08105af7-677c-4b6d-b9b5-a53be6e4c2bc.jpg?v=1720146654",
            "/images/products/Condolence-_VII_-2e.jpg?v=1720146630"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Orchids . Eustoma . Faux Lilies . Faux Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 159,
        "name": "Charlotte FBS",
        "handle": "charlotte",
        "description": "\"I love how she makes me feel like anything is possible, or like life is worth it.\" - Tom Hansen",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2021-10-07at2.12.22PM_2.jpg?v=1641336228",
            "/images/products/WhatsAppImage2021-10-07at2.12.22PM_1_1.jpg?v=1641336139"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Carnations . Spray Carnations . Mum . Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 160,
        "name": "Clara FBS",
        "handle": "clara",
        "description": "Presenting a charming arrangement of delicate pink carnations nestled in a stylish handbag-style container. Accented with lush green foliage and a decorative pink ribbon, this floral creation is perfect for gifting on special occasions or adding a touch of elegance to any space. Enjoy the fresh beauty and timeless appeal of this lovely floral display.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Clara-FBS-2a-654944.jpg?v=1721831483",
            "/images/products/Clara-FBS-1a-504700.jpg?v=1721831483"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Spray Carnations . Spray Roses . Matthiola . Fillers & Foliage "
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 161,
        "name": "Avery FBS",
        "handle": "avery",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2021-08-30at11.58.23AM_2.jpg?v=1641354828",
            "/images/products/WhatsAppImage2021-08-30at11.58.23AM_1_1.jpg?v=1641354739"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Cotton Flower . Carnations . Roses . Carnation Spray . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 162,
        "name": "Eliza",
        "handle": "eliza",
        "description": "A dreamy Flowers Malaysia bouquet, where soft pink carnations bloom among a sea of gypsophila, wrapped in delicate pink paper with a graceful ribbon, invoking the tender whisper of first light.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Eliza-2a.jpg?v=1721180969",
            "/images/products/Eliza-1a.jpg?v=1721180970"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 163,
        "name": "Heather",
        "handle": "heather",
        "description": "“And in the end, the love you take, is equal to the love you make.” - Paul McCartney",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Heather-1.jpg?v=1625094653",
            "/images/products/Heather-3.jpg?v=1625094653",
            "/images/products/Heather-2.jpg?v=1625094654"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Spray Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 164,
        "name": "Vera",
        "handle": "vera",
        "description": "This Flowers Malaysia arrangement is a poetic blend of rich fuchsia and bold crimson carnations, set amongst delicate foliage and wrapped in a lavender embrace, finished with a gentle ribbon—a celebration of love's vivid hues.",
        "type": "Bouquets",
        "tags": [
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Vera-2a.jpg?v=1720797662",
            "/images/products/Vera-1a.jpg?v=1720797661"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 165,
        "name": "Celeste",
        "handle": "celeste",
        "description": "Discover the delicate beauty of our tulip bouquet, featuring a lovely array of pink tulips. Wrapped in a rich, deep red paper, this bouquet embodies elegance and sophistication. Ideal for expressing love, admiration, or simply brightening someone's day, this arrangement is a timeless gift that speaks volumes without saying a word. The vibrant tulips combined with the luxurious wrapping create a stunning presentation that is perfect for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Celeste-1.jpg?v=1641336366",
            "/images/products/Celeste-3.jpg?v=1641336362",
            "/images/products/Celeste-2.jpg?v=1641336354"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 166,
        "name": "Clarissa",
        "handle": "clarissa",
        "description": "Bold and passionate, this Flowers Malaysia bouquet features lush clusters of deep red carnations cradled within a heart-shaped vine, wrapped in radiant fuchsia paper with a cascading ribbon, symbolizing heartfelt emotions and enduring love.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Clarissa-2a.jpg?v=1721102906",
            "/images/products/Clarissa-1a-720147.jpg?v=1721135818"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 167,
        "name": "Gianna",
        "handle": "gianna",
        "description": "Captured in a striking display, variegated pink carnations are elegantly framed by intertwined heart-shaped willows, all wrapped in a soft lilac paper with a satin ribbon, creating a Flowers Malaysia masterpiece that symbolizes love and admiration.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Gianna-2b.jpg?v=1720662077",
            "/images/products/Gianna-1b.jpg?v=1720662077"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Fillers"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 168,
        "name": "Elena",
        "handle": "elena",
        "description": "Bask in the warmth of sunshine with our radiant bouquet, showcasing three vibrant sunflowers embraced by delicate baby's breath and cheerful daisies. Evoking feelings of joy and optimism, this arrangement brings the spirit of summer indoors, making it a delightful gift to brighten anyone's day with its natural charm.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Elena-1a.jpg?v=1720839208",
            "/images/products/Elena-2a.jpg?v=1720839208"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Daisy . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 169,
        "name": "Ember",
        "handle": "ember",
        "description": "“Love isn't a feeling or emotion that changes timely, it's everlasting...” -  D'vijay",
        "type": "Bouquets",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Ember---1a-133111.jpg?v=1671685200",
            "/images/products/Ember---2a.jpg?v=1625094659"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Dried Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 22cm*28cm"
    },
    {
        "id": 170,
        "name": "Eleanor",
        "handle": "eleanor",
        "description": "“Some people believe holding on and hanging in there are signs of great strength. However, there are times when it takes much more strength to know when to let go and then do it.” -  Ann Landers",
        "type": "Bouquets",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/WhatsAppImage2022-04-12at10.48.30AM_2.jpg?v=1649732269",
            "/images/products/WhatsAppImage2022-04-12at10.48.30AM_1_1.jpg?v=1649732269"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Dried Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 22cm*28cm"
    },
    {
        "id": 171,
        "name": "Ashley",
        "handle": "ashley",
        "description": "“Love isn't a feeling or emotion that changes timely, it's everlasting...”―  D'vijay",
        "type": "Bouquets",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Ashley---1a-938602.jpg?v=1671684750",
            "/images/products/Ashley---2a.jpg?v=1625094660"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Dried Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 172,
        "name": "Ella",
        "handle": "ella",
        "description": "This elegant bouquet features delicate lavender roses and white calla lilies, accentuated by lush greenery and wrapped in soft lavender and white paper. Perfect for any occasion, this arrangement adds a touch of sophistication and charm to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Calla Lilies & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Ella-2a.jpg?v=1720840136",
            "/images/products/Ella-1a.jpg?v=1720840136"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Calla Lilies . Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 173,
        "name": "Disney",
        "handle": "disney",
        "description": "This vibrant bouquet features an array of colorful roses, delicate blooms, and lush greenery, beautifully wrapped in rustic brown paper. Perfect for any occasion, it brings a cheerful and lively touch to any setting, making it an ideal gift to brighten someone's day.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Disney-2a-688729.jpg?v=1721049041",
            "/images/products/Disney-1a-833621.jpg?v=1721049041"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnation . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 174,
        "name": "Hannah",
        "handle": "hannah",
        "description": "\"Life is from the inside out. When you shift on the inside, life shifts on the outside.\" - Kamal Ravikant",
        "type": "Bouquets",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Hannah1.jpg?v=1667947833",
            "/images/products/Hannah2.jpg?v=1667947833"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Dried Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 175,
        "name": "Keller",
        "handle": "keller",
        "description": "Embrace the passion of your special day with our enchanting bouquet of 12 hot pink roses elegantly encased in a sleek chrome wrapper. Each velvety bloom radiates love and desire, creating a striking and contemporary expression of affection. Gift this bouquet to convey your deepest emotions and celebrate the romance of the occasion in style.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Keller-1dd_2f843ff9-49e0-4d08-ae46-5d5e7adc076c.jpg?v=1704822134",
            "/images/products/Keller-2dd_5609bb14-8e37-4206-ab32-cf44fc0eb18a-501471.jpg?v=1704896327"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "12 Hot Pink Roses"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 176,
        "name": "Beatrice",
        "handle": "beatrice",
        "description": "This bouquet showcases a delightful collection of petite white blossoms daisies cradled in a stylish beige wrap, secured with a soft satin ribbon. The bouquet exudes a serene elegance, making it an ideal gift to convey purity, warmth, and thoughtfulness.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Beatrice-2c-423174.jpg?v=1724863709",
            "/images/products/Beatrice-1c.jpg?v=1724809093"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Daisies"
            ]
        },
        "dimention": "Width*Height: 18cm*30cm"
    },
    {
        "id": 177,
        "name": "Louise",
        "handle": "louise",
        "description": "\"Life is not a matter of holding good cards, but sometimes, playing a poor hand well.\" - Jack London",
        "type": "Bouquets",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Louise---1b-797303.jpg?v=1671685424",
            "/images/products/Louise---2b.jpg?v=1625094665"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Dried Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 178,
        "name": "Nellie",
        "handle": "nellie",
        "description": "\"Life is like a box of chocolates. You never know what you’re going to get.\" - Forrest Gump",
        "type": "Bouquets",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/WhatsAppImage2022-04-13at2.27.35PM_3_1.jpg?v=1649831316",
            "/images/products/WhatsAppImage2022-04-13at2.27.35PM_2_1.jpg?v=1649831316"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Dried Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 179,
        "name": "Yvette",
        "handle": "yvette",
        "description": "Brighten up any occasion with our charming bouquet featuring 6 radiant yellow roses nestled among delicate caspia blooms. The sunny hue of the roses, complemented by the airy elegance of caspia, creates a captivating ensemble. ",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Yvette-1a.jpg?v=1720777893",
            "/images/products/Yvette-2a.jpg?v=1720777893"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Yellow Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 180,
        "name": "Poppy",
        "handle": "poppy",
        "description": "Celebrate birthdays or anniversaries with our Sunshine Bliss Bouquet, a delightful arrangement of six roses and spray roses in a cheerful fusion of yellow and pink. The vibrant yellow roses symbolize joy, while the blush pink tip spray roses add a touch of romance.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Poppy-1j-388229.jpg?v=1720789706",
            "/images/products/Poppy_2i-296413.jpg?v=1720789706"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 181,
        "name": "Ophelia",
        "handle": "ophelia",
        "description": "This vibrant bouquet features a stunning mix of orange and yellow roses, elegantly arranged with lush greenery and delicate white fillers. Accentuated with charming heart-shaped wicker handles and wrapped in rustic brown paper, it's a perfect gift for expressing heartfelt emotions.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Ophelia-2a-327229.jpg?v=1721049443",
            "/images/products/Ophelia-1a-807616.jpg?v=1721049443"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Spray Roses . Fillers"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 182,
        "name": "Viola",
        "handle": "viola",
        "description": "This bouquet presents a joyful arrangement, featuring a large, vibrant sunflower taking center stage. Encircling the sunflower are lush yellow roses, adding a rich texture and depth to the bouquet. Delicate white filler flowers sprinkle throughout, providing a lovely contrast and softness.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Viola-1a_54504301-9a36-4257-af78-824eb970d62e-720448.jpg?v=1720791509",
            "/images/products/Viola-2a_5e40e2e7-5377-44d3-9f27-19808f306e8c.jpg?v=1720748606"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Spray Roses . Ammi Maju . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 183,
        "name": "Colleen",
        "handle": "colleen",
        "description": "A bouquet of elegant peach roses paired with delicate white accents and lush greenery, beautifully wrapped in rustic brown paper. This arrangement exudes timeless charm and sophistication, making it the perfect gift to express love and admiration for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Colleen-2a-733446.jpg?v=1721048924",
            "/images/products/Colleen-1a-758566.jpg?v=1721048924"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 184,
        "name": "Paislee",
        "handle": "paislee",
        "description": "Embrace the serenity of sunshine with this bouquet featuring seven yellow roses gracefully enveloped in a lace wrapping of yellow and white hues. The harmonious blend of colors creates a sense of tranquility, making this arrangement an ideal gift for conveying well-wishes, gratitude, or celebrating special moments with a touch of refined beauty.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Paislee-1aa-104360.jpg?v=1706109372",
            "/images/products/Paislee-2aa-416881.jpg?v=1706109372"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 185,
        "name": "Bexley",
        "handle": "bexley",
        "description": "Embrace purity and elegance with our enchanting bouquet featuring 6 pristine white gossypium (cotton) blossoms delicately adorned with the whimsical beauty of rainbow-colored baby's breath.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Bexley-2b.jpg?v=1720793862",
            "/images/products/Bexley-1b.jpg?v=1720793862"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gossypium . Unicorn Colored Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 186,
        "name": "Merritt",
        "handle": "merritt",
        "description": "Embrace simplicity with our elegant white tulip bouquet. This arrangement features pristine white tulips surrounded by lush eucalyptus and delicate ammi maju, all wrapped in soft blue paper. Perfect for adding a touch of sophistication to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Merritt-1b-233208.jpg?v=1720789521",
            "/images/products/Merritt-1a-549408.jpg?v=1720878358"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Fillers & Foliages"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 187,
        "name": "Oriana",
        "handle": "oriana",
        "description": "This bouquet is a celebration of color, featuring a cheerful sunflower, ivory roses, and a vivid pink bloom at its heart. The arrangement is accented with delicate baby's breath and silvery foliage, wrapped in a clear layer topped with bold blue paper and a large blue satin ribbon for a striking finish.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Oriana-1a.jpg?v=1720749966",
            "/images/products/Oriana-2a.jpg?v=1720749966"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 188,
        "name": "Ladonna",
        "handle": "ladonna",
        "description": "Celebrate elegance with our graceful calla lily bouquet. Featuring stunning white calla lilies surrounded by soft pink roses and lush greenery, this arrangement is wrapped in delicate pink paper. Perfect for weddings, anniversaries, or any special occasion, it brings a touch of sophistication and charm to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Calla Lilies & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Ladonna-2a.jpg?v=1721225224",
            "/images/products/Ladonna-1a.jpg?v=1721225225"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Calla Lilies . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 189,
        "name": "Tinley",
        "handle": "tinley",
        "description": "Embrace the charm of our Mixed Rose Bouquet – a delightful fusion of 4 hot pink, 4 white, and 4 pink roses, artfully enveloped in a soft pink wrapping. Complemented by delicate baby's breath, this bouquet exudes romance and elegance, making it the perfect gift for any occasion that calls for a touch of sophistication.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Tinley-2d-127638.jpg?v=1720963729",
            "/images/products/Tinley-1d.jpg?v=1720880515"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 190,
        "name": "Zinnia",
        "handle": "zinnia",
        "description": "A sophisticated bouquet featuring elegant cappuccino roses, artistically arranged and wrapped in pristine white paper with a delicate white lace ribbon. This timeless arrangement is perfect for expressing grace and refinement, making it an ideal gift for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Zinnia-2a.jpg?v=1721009303",
            "/images/products/Zinnia-1a.jpg?v=1721009303"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Cappuccino Roses"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 191,
        "name": "Scarlet FBS",
        "handle": "scarlet",
        "description": "\"Never let the fear of striking out keep you from playing the game.\" - Babe Ruth",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-03-23at10.42.59AM_2.jpg?v=1648003440",
            "/images/products/WhatsAppImage2022-03-23at10.42.59AM_1_1.jpg?v=1648003441"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 192,
        "name": "Ivana",
        "handle": "ivana",
        "description": "An enchanting bouquet featuring a harmonious blend of creamy white and soft lavender roses, complemented by delicate fillers and lush eucalyptus leaves. Elegantly wrapped in beige paper with a white ribbon, this arrangement exudes elegance and charm, perfect for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Ivana-2a_5a4dc3b8-352c-46ba-82f8-70e1cb05bd5d.jpg?v=1721010062",
            "/images/products/Ivana-1a.jpg?v=1721010062"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Meta Roses. White Roses . Wax Flowers . Statice Flowers . Eucalyptus"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 193,
        "name": "Hadley FBS",
        "handle": "hadley",
        "description": "\"No one can compete with you on being you. Most of life is a search for who and what needs you the most.\" - Naval Ravikant",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Hadley---1.jpg?v=1641328927",
            "/images/products/Hadley---2.jpg?v=1641328921"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 194,
        "name": "Daphne",
        "handle": "daphne",
        "description": "This bouquet features peach tulips with hints of yellow at the petal bases, accompanied by delicate white gypsophila and lush greenery. Wrapped in a beige and white paper with a creamy satin bow, it offers a warm, gentle, and elegant presentation.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Daphne-1d-653977.jpg?v=1720788754",
            "/images/products/Daphne-1c-424509.jpg?v=1720842430"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 195,
        "name": "Braelynn",
        "handle": "braelynn",
        "description": "This elegant bouquet features a delicate mix of white roses, soft blue blooms, and yellow lilies, accented with lush greenery. Wrapped in light yellow paper and tied with a white ribbon, it’s a perfect gift for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Braelynn-2a.jpg?v=1720742120",
            "/images/products/Braelynn-1a.jpg?v=1720742120"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 196,
        "name": "Darby",
        "handle": "darby",
        "description": "Elevate any occasion with our sophisticated lily and orchid bouquet. Featuring elegant white lilies, delicate roses, and striking red orchids, this arrangement is beautifully accented with lush greenery. Wrapped in pristine white paper and tied with a satin ribbon, it is perfect for celebrating milestones, expressing gratitude, or adding a touch of refinement to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Lilies Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Darby-2a.jpg?v=1721216491",
            "/images/products/Darby-1a.jpg?v=1721216491"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Lilies  .  Eustoma  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 32cm*46cm"
    },
    {
        "id": 197,
        "name": "Callie FBS",
        "handle": "callie",
        "description": "\"All our dreams can come true if we have the courage to pursue them.\" Walt Disney",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2021-10-07at12.03.08PM_2.jpg?v=1641337087",
            "/images/products/WhatsAppImage2021-10-07at12.03.08PM_1_1.jpg?v=1641337081"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Baby's Breath . Graduation Bear . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 198,
        "name": "Faye",
        "handle": "faye",
        "description": "A dramatic and passionate bouquet of striking red roses, meticulously arranged and wrapped in sleek black paper. The bold contrast enhances the roses' vibrant color, making this bouquet a perfect expression of love and admiration. Ideal for romantic gestures and special occasions.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Faye-2b.jpg?v=1721010443",
            "/images/products/Faye-1b.jpg?v=1721010443"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "9 Roses . Spray Roses"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 199,
        "name": "Madison",
        "handle": "madison",
        "description": "This vibrant bouquet features three bright sunflowers, complemented by lush greenery and red berries. Wrapped in rustic brown paper and tied with an elegant ribbon, it radiates warmth and cheer, making it a perfect gift for any joyful occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Madison-2a.jpg?v=1720790665",
            "/images/products/Madison-1a.jpg?v=1720790665"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Hypericum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 200,
        "name": "Ellie",
        "handle": "ellie",
        "description": "Celebrate love with our exquisite bouquet of 6 passionate red roses, beautifully accompanied by delicate caspia, all encased within a heart-shaped willow frame in enchanting pink hues. ",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Ellie-2a.jpg?v=1720693306",
            "/images/products/Ellie-1a.jpg?v=1720693306"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 201,
        "name": "Leah",
        "handle": "leah",
        "description": "This enchanting bouquet features soft pink tulips, delicate chamomile, and fragrant lavender nestled among airy baby's breath. Wrapped in pastel pink paper and adorned with a matching ribbon, this arrangement exudes elegance and charm, perfect for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Leah-2a.jpg?v=1720918864",
            "/images/products/Leah-1a.jpg?v=1720918864"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath . Daisy . Tulips . Fillers"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 202,
        "name": "Claire",
        "handle": "claire",
        "description": "Embrace the purity and elegance of our stunning white bouquet. Featuring a harmonious blend of white roses, hydrangeas, and delicate blooms, accented with lush greenery and a whimsical pom-pom detail, this arrangement is wrapped in pristine white paper. Perfect for weddings, anniversaries, or any special occasion, it exudes timeless grace and sophistication.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Claire-1a.jpg?v=1721214340",
            "/images/products/Claire-2a.jpg?v=1721214340"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Carnations . Mum . Eustoma . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 203,
        "name": "Janelle",
        "handle": "janelle",
        "description": "Elegance meets romance in our stunning bouquet featuring a harmonious blend of white and pink roses, accentuated by the soft charm of dusty miller. This enchanting ensemble is delicately arranged and presented in a lovely pink wrapper, creating a timeless expression of sophistication. Ideal for weddings, anniversaries, or to convey sentiments of admiration, this bouquet radiates grace and beauty in every bloom.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Janelle-2a.jpg?v=1721272314",
            "/images/products/Janelle-1a.jpg?v=1721272313"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Dusty Millers"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 204,
        "name": "Keily FBS",
        "handle": "keily",
        "description": "Featuring vibrant red roses, delicate white daisies, and lush greenery, all beautifully arranged in a chic tan handbag. Perfect for expressing love and admiration, this stunning bouquet brings timeless beauty to any occasion.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Keily-FBS-1a-548275.jpg?v=1720030669",
            "/images/products/Keily-FBS-2a-164301.jpg?v=1720030669"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 205,
        "name": "Lindsey",
        "handle": "lindsey",
        "description": "This charming bouquet features a delightful mix of preserved purple and pink flowers, accented with a teddy bear in graduation attire. Wrapped in elegant lavender paper and tied with a matching ribbon, it’s a perfect gift to celebrate a graduation.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Lindsey-2a.jpg?v=1720675478",
            "/images/products/Lindsey-1a.jpg?v=1720675478"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Dried Flowers . Graduation Bear"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 206,
        "name": "Sariah",
        "handle": "sariah",
        "description": "This lovely bouquet features a mix of preserved pink and white flowers, complemented by a cute teddy bear in graduation attire. Wrapped in soft pink paper and tied with a matching ribbon, it’s an ideal gift to celebrate graduation.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Sariah-2a.jpg?v=1720674582",
            "/images/products/Sariah-1a.jpg?v=1720674582"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Dried Flowers . Graduation Bear"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 207,
        "name": "Diana",
        "handle": "diana",
        "description": "Unveil the striking allure of our Black Tinge White Rose Bouquet. Twelve pristine white roses, each adorned with a subtle black tinge, are impeccably arranged and presented in a chic black wrapping. This unique blend of purity and sophistication makes for an exquisite gift, perfect for any stylish and memorable celebration.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Diana-2d.jpg?v=1721268933",
            "/images/products/Diana-1d.jpg?v=1721268933"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 208,
        "name": "Journee",
        "handle": "journee",
        "description": "Celebrate with the vibrant elegance of our rainbow baby's breath and rose bouquet. Featuring classic red roses surrounded by a stunning array of multicolored baby's breath, this arrangement is wrapped in rustic brown paper and tied with a satin ribbon. Perfect for expressing love, joy, or marking any special occasion, it adds a touch of colorful charm and beauty to any setting.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Journee-2a.jpg?v=1721351699",
            "/images/products/Journee-1a.jpg?v=1721351699"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Rainbow Baby Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 209,
        "name": "Paige",
        "handle": "paige",
        "description": "This elegant bouquet features deep blue preserved flowers and delicate white blooms, topped with a charming teddy bear in graduation attire. Wrapped in soft white and blue paper and tied with a matching ribbon, it’s a perfect gift for graduates.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Paige-2a-246249.jpg?v=1720789705",
            "/images/products/Paige-1a.jpg?v=1720672333"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Dried Flowers . Graduation Bear"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 210,
        "name": "Noelle FBS",
        "handle": "noelle",
        "description": "Featuring a cheerful mix of orange and yellow gerbera daisies, accented with lush eucalyptus and delicate fillers. Presented in a stylish tan handbag, this bouquet radiates warmth and joy, making it a perfect gift for any occasion.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Noelle-FBS-1a.jpg?v=1719975994",
            "/images/products/Noelle-FBS-2a-847198.jpg?v=1720030794"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 211,
        "name": "Novah",
        "handle": "novah",
        "description": "This vibrant arrangement features cheerful sunflowers, orange roses, and yellow blooms, complemented by lush greenery. Wrapped in striking black paper and tied with a matching ribbon, it makes a bold and stylish statement, perfect for brightening up any room or occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Novah-1a.jpg?v=1720839426",
            "/images/products/Novah-2a-549396.jpg?v=1720876241"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Gerbera . Mum . Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 212,
        "name": "Kimora",
        "handle": "kimora",
        "description": "This bouquet is a delightful and vibrant arrangement featuring multicolored pompom chrysanthemums. Each pompom is a different color of the rainbow, including red, orange, yellow, green, blue, purple, and pink. ",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Kimora-2a.jpg?v=1721103101",
            "/images/products/Kimora-1a.jpg?v=1721103101"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 213,
        "name": "Azaria",
        "handle": "azaria",
        "description": "Indulge in the warmth of love with our bouquet featuring nine charming cappuccino roses paired with delicate orange spray roses. Wrapped in a sophisticated nude wrapper, this arrangement exudes elegance and subtle passion. Share the richness of your feelings with this unique and stylish expression of affection.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Azaria-1d-984264.jpg?v=1721135613",
            "/images/products/Azaria-1c.jpg?v=1721103536"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Cappuccino Roses . Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 214,
        "name": "Ingrid",
        "handle": "ingrid",
        "description": "Elevate your floral experience with our exquisite bouquet featuring 6 champagne roses delicately complemented by the timeless charm of caspia. The soft, muted tones of the roses, reminiscent of effervescent champagne, blend seamlessly with the delicate caspia, creating a refined and sophisticated arrangement. ",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Ingrid-1a.jpg?v=1720841171",
            "/images/products/Ingrid-2a.jpg?v=1720841171"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Champagne Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 215,
        "name": "Kimber",
        "handle": "kimber",
        "description": "Make a bold statement with our luxurious red rose bouquet. Featuring a dozen radiant red roses complemented by eucalyptus accents and wrapped in elegant beige paper, this arrangement exudes timeless romance and sophistication. Perfect for expressing deep love and passion, it is an ideal gift for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Kimber-2a-165026.jpg?v=1721222879",
            "/images/products/Kimber-1a-626001.jpg?v=1721222879"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "12 Roses . Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 216,
        "name": "Elliot",
        "handle": "elliot",
        "description": "This elegant bouquet presents a serene palette of pastel blue roses, complemented by clouds of baby's breath. The blossoms are arranged with care and wrapped in a translucent pink paper, adding a whisper of grace and femininity to the presentation.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Elliot-2f.jpg?v=1720710563",
            "/images/products/Elliot-1f.jpg?v=1720710563"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "9 Roses . Baby's Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 217,
        "name": "Tiana",
        "handle": "tiana",
        "description": "Captivating in its pure elegance, this bouquet features pristine white roses, each bloom a symbol of innocence and loyalty, cradled by a delicate embrace of baby's breath. Gracefully presented in a transparent wrap with satin ribbons, it's a classic choice for weddings, anniversaries, or a heartfelt gesture.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Newborn Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Tiana-2d.jpg?v=1720710299",
            "/images/products/Tiana-1d.jpg?v=1720710299"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "White Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 218,
        "name": "Katalina",
        "handle": "katalina",
        "description": "A captivating bouquet featuring elegant white roses, vibrant purple lisianthus, and delicate lavender accents. This stunning arrangement is beautifully wrapped in soft pastel paper and finished with a matching ribbon, making it an exquisite choice for celebrating life's special moments.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Newborn Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Katalina-1a.jpg?v=1720919130",
            "/images/products/Katalina-2a.jpg?v=1720919131"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "White Roses . Purple Tip Eustoma . Purple Matthiola . Wax Flowers . Caspia . Eucalyptus"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 219,
        "name": "Elyse",
        "handle": "elyse",
        "description": "\"Life is a game and true love is a trophy.\" - Rufus Wainwright",
        "type": "Bouquets",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/WhatsAppImage2022-04-12at11.15.01AM_1.jpg?v=1649733341",
            "/images/products/WhatsAppImage2022-04-12at10.48.24AM_1_1.jpg?v=1649733341"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gossypium . Dried Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 22cm*28cm"
    },
    {
        "id": 220,
        "name": "Merlot",
        "handle": "merlot",
        "description": "This elegant bouquet features a blend of red roses, peach lisianthus, and vibrant orange alstroemeria, accented with lush greenery. Wrapped in rich red paper and tied with a matching ribbon, it’s a striking gift for expressing love and admiration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Merlot-2b.jpg?v=1720841604",
            "/images/products/Merlot-1a.jpg?v=1720703652"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustomas . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 221,
        "name": "Savannah",
        "handle": "savannah",
        "description": "This stunning arrangement boasts vibrant fuchsia roses, nestled amongst a sea of gypsophila, creating a striking contrast. The bouquet, exquisitely wrapped in transparent white paper and adorned with sheer ribbons, makes a bold statement of deep affection and is perfect for any memorable celebration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Savannah-2b.jpg?v=1720710008",
            "/images/products/Savannah-1b.jpg?v=1720710008"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Purple Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 222,
        "name": "Caroline",
        "handle": "caroline",
        "description": "This elegant bouquet features a dozen delicate pink roses, each one symbolizing admiration and gratitude. Surrounded by a cloud of baby's breath, the roses are wrapped in a graceful white and soft pink paper, accented with a vibrant pink ribbon. Perfect for expressing heartfelt emotions and making any occasion special.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Caroline-2g.jpg?v=1720708845",
            "/images/products/Caroline-1g.jpg?v=1720708845"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Pink Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 223,
        "name": "Presley FBS",
        "handle": "presley",
        "description": "This elegant arrangement features soft pink and mauve roses, complemented by delicate fillers and lush greenery. Presented in a chic tan handbag with a lavender ribbon, it's the perfect gift to convey grace and sophistication for any special occasion.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Presley-FBS-2b.jpg?v=1721692957",
            "/images/products/Presley-FBS-1b.jpg?v=1721692957"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 224,
        "name": "Haisley",
        "handle": "haisley",
        "description": "A single bold sunflower bursts forth, its golden petals radiating warmth and happiness. Complementary shades of violet and lavender roses and blooms add a regal touch, while hints of soft pink hydrangea bring a gentle romance to the arrangement. Wisps of greenery and delicate filler flowers create a lush backdrop, all wrapped in a craft paper with a natural texture.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Newborn Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Haisley-2f-802918.jpg?v=1720789072",
            "/images/products/Haisley-1f.jpg?v=1720744192"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Roses . Eustomas . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 225,
        "name": "Ensley",
        "handle": "ensley",
        "description": "Brighten Your Day with Our Sunflower BouquetOur enchanting mixed floral bouquet features a vibrant sunflower surrounded by delicate pink roses, carnations, and lush greenery, all elegantly wrapped in a rustic brown paper ensemble. Perfect for any occasion, this bouquet brings joy and warmth, making it a wonderful gift.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Ensley-2a.jpg?v=1720780177",
            "/images/products/Ensley-1a.jpg?v=1720780177"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Rose . Carnation . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 226,
        "name": "Valentina",
        "handle": "valentina",
        "description": "Indulge in the pure beauty of our White Rose Bouquet, featuring six pristine white roses elegantly encircled by delicate baby's breath, all embraced in a charming pink wrapping. This timeless arrangement exudes grace and simplicity, making it a perfect expression of purity and heartfelt sentiments for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Valentina-2d-588477.jpg?v=1721050596",
            "/images/products/Valentina-1d-221804.jpg?v=1721050596"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 227,
        "name": "Nevaeh",
        "handle": "nevaeh",
        "description": "This bouquet offers a striking mix of textures and colors, centered around a vibrant sunflower. Crimson roses and a rich red carnation lend a touch of classic romance, while yellow alstroemeria and assorted greenery provide a lively backdrop.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Newborn Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Naveah-2a.jpg?v=1720746439",
            "/images/products/Naveah-1a.jpg?v=1720746439"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Rose . Eustoma . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 228,
        "name": "Quinn",
        "handle": "quinn",
        "description": "A symphony of seven pink roses unfolds in a breathtaking display, embraced by a wrapping of pink and white lace. The combination of romantic pink hues and the intricate lace pattern creates a bouquet that is both dreamy and sophisticated. This arrangement is an exquisite choice for expressing sentiments of love and tenderness on special occasions.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Quinn-1aa_1-461826.jpg?v=1706197543",
            "/images/products/Quinn-2aa_1-782550.jpg?v=1706197543"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 229,
        "name": "Eliana",
        "handle": "eliana",
        "description": "This captivating bouquet bursts with life, centering around a radiant sunflower. It's complemented by roses in shades of soft cream, blush pink, and deep crimson, creating a dynamic palette. Baby's breath and green foliage add freshness, all elegantly wrapped in a tan paper with a cascading ribbon, embodying vibrant elegance.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Eliana-1aa.jpg?v=1704817085",
            "/images/products/Eliana---Delivery-_1_1.jpg?v=1716209974",
            "/images/products/Eliana-2aa.jpg?v=1716209974"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Rose . Eustomas . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 230,
        "name": "Brooklyn",
        "handle": "brooklyn",
        "description": "This vibrant bouquet features a striking sunflower at its center, surrounded by elegant white roses and lisianthus, accented with lush greenery and delicate fillers. Wrapped in rustic brown paper and tied with a ribbon, it's a perfect gift to brighten any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Brooklyn-2a-663383.jpg?v=1720788698",
            "/images/products/Brooklyn-1a-810184.jpg?v=1720788698"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Rose . Eustomas . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 231,
        "name": "Natalie FBS",
        "handle": "natalie",
        "description": "This charming arrangement features soft, fluffy cotton blooms paired with colorful baby's breath, creating a whimsical and serene display. Nestled in a chic tan handbag, it's the perfect gift to add a touch of elegance and tranquility to any space.",
        "type": "Bag Series",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets"
        ],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Natalie-FBS-1a.jpg?v=1719975621",
            "/images/products/Natalie-FBS-2a.jpg?v=1719975621"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Gossypium . Rainbow Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 232,
        "name": "Xandra",
        "handle": "xandra",
        "description": "This bouquet is a symphony of bold romance, featuring hot pink roses enveloped in a dramatic black wrapping, highlighted by an abundance of baby's breath. A duo of contrasting pink and black ribbons add a modern twist to the classic rose, making it an unforgettable gift for any lover of beauty.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Xandra-2c.jpg?v=1720708218",
            "/images/products/Xandra-1c.jpg?v=1720708218"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 233,
        "name": "Talia",
        "handle": "talia",
        "description": "\"The best and most beautiful things in this world cannot be seen or even heard, but must be felt with the heart.\" - Helen Keller",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Talia-1.jpg?v=1628486564",
            "/images/products/Talia-2-664311.jpg?v=1671775915"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 234,
        "name": "Sabrina",
        "handle": "sabrina",
        "description": "\"Life without love is like a tree without blossoms or fruit.\" - Khalil Gibran",
        "type": "Bouquets",
        "tags": [
            "Sunflower Bouquets"
        ],
        "price": "342.07",
        "inclTax": "342.07",
        "img": [
            "/images/products/Sabrina-1.jpg?v=1628486564",
            "/images/products/Sabrina-2.jpg?v=1641310319"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Spray Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 50cm*60cm"
    },
    {
        "id": 235,
        "name": "Tabby FBS",
        "handle": "tabby",
        "description": "\"Never let the fear of striking out keep you from playing the game.\" - Babe Ruth",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-03-23at10.37.39AM.jpg?v=1648003197",
            "/images/products/WhatsAppImage2022-03-23at10.37.39AM_1.jpg?v=1648003200"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 236,
        "name": "Ebele FBS",
        "handle": "ebele",
        "description": "This charming arrangement features a striking blue rose as the centerpiece, complemented by soft cotton blooms and delicate greenery. Arranged in a unique and stylish bag, tied with a light blue ribbon, this bouquet is perfect for those who appreciate both elegance and innovation. Ideal for gifting or as a decorative piece, it brings a touch of modern flair to any setting.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Ebele-2a.jpg?v=1724291587",
            "/images/products/Ebele-1a.jpg?v=1724291588"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 237,
        "name": "Edwina FBS",
        "handle": "edwina",
        "description": "\"Never let the fear of striking out keep you from playing the game.\" - Babe Ruth",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-03-23at10.35.11AM_2_1.jpg?v=1648003591",
            "/images/products/WhatsAppImage2022-03-23at10.35.11AM_1_1.jpg?v=1648003591"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 238,
        "name": "Elmira FBS",
        "handle": "elmira",
        "description": "\"Never let the fear of striking out keep you from playing the game.\" - Babe Ruth",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-04-01at11.02.09AM.jpg?v=1648782495",
            "/images/products/WhatsAppImage2022-04-01at11.02.09AM_1.jpg?v=1648782497"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Carnation . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 239,
        "name": "Aubrey",
        "handle": "aubrey",
        "description": "“If you want to live a happy life, tie it to a goal, not to people or things.” – Albert Einstein",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Aubrey-1-393592.jpg?v=1671684751",
            "/images/products/Aubrey-2-561472.jpg?v=1671684751"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Roses . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 240,
        "name": "Tasty & Fresh FHP",
        "handle": "matilda-fruit-hamper",
        "description": "Tasty  FreshDelight anyone with this gorgeous crate of fresh tropical fruits! The Tasty  Fresh hamper is filled to the brim with scrumptious and inviting fruits, and the beautiful flowers make the hamper the most perfect looking gift.",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers"
        ],
        "price": "241.66",
        "inclTax": "241.66",
        "img": [
            "/images/products/Matilda1-674503.jpg?v=1671775916",
            "/images/products/Matilda2-207885.jpg?v=1671775916"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Apple",
                "2. Dragon Fruits",
                "3. Orange",
                "4. Green Grape",
                "5. Nam Shui Pear",
                "6. Kiwi",
                "7. Avocado",
                "8. Forelle Pear",
                "9. Green Apple",
                "10. Mango",
                "11. Comes with flowers and packaging"
            ]
        },
        "dimention": "Size of Hamper: 40cm x25cm x 28cm"
    },
    {
        "id": 241,
        "name": "Crispy Bites WHP",
        "handle": "millie-wellness-hamper",
        "description": "Crispy BitesCheers to good health! The Crispy Bites Get Well Soon Hamper includes all the healthy but delicious snacks that nature has to offer! Designed and created to help a loved one get back on their feet, this assortment of delightful and wholesome snacks is sure to perk up their day!",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Millie-WHP2a-511914.jpg?v=1671685198",
            "/images/products/Millie-WHP1a-117864.jpg?v=1671685198"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tKellogg’s Cornflakes 275g",
                "2.\tCowhead Chocolate Chip Oat Cookies 178g",
                "3.\tFarmhouse UHT Fresh Milk 1L",
                "4.\tShoon Fatt Cream Crackers Special 400g",
                "5.\tYogood Wildberry Muesli Bars 138g",
                "6.\tTaste Asia Vegetarian Porridge 400g",
                "7.\tSt. Dalfour Fruit Spread Minis 3x 28g",
                "8.\tCoco Life Coconut Packet Water 2 x 330ml",
                "9.\tNutrione Baked Cocktail Nuts 85g",
                "10.\tFlower Arrangement",
                "11.\tExquisite Gift Box",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 242,
        "name": "The Health Essentials WHP",
        "handle": "desiree-wellness-hamper",
        "description": "The Health EssentialsThe Health Essentials is just what it is designed to be – a healthy assortment of popular and wholesome snacks! This exquisite gift basket is filled with goodies that are essential for a healthy snacker on the mend! Say get well soon with this care package of snacking essential today! Free same day delivery in Malaysia is available to orders placed before 3pm.",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers",
            "Halal Food Hampers"
        ],
        "price": "200.59",
        "inclTax": "200.59",
        "img": [
            "/images/products/The-Health-Essentials1-604425.jpg?v=1690594335",
            "/images/products/Desiree-WHP2a-747660.jpg?v=1690504787"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tAmazin’ Graze Anti-Oxidant Mixed Nuts Trail 130g",
                "2.\tGranny’s Recipe – Cranberries Oatmeal Cookies 130g",
                "3.\tCowhead Original Plain Cracker 180g (8 sachets)",
                "4.\tMilo Tin 400g",
                "5.\tAmazin’ Graze Chocolate Banana Goodness Bowl Instant Oatmeal 6 x 40g",
                "6.\tFomec ‘s Berry Essence 2 x 42ml",
                "7.\tExquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 243,
        "name": "Colourful Freshness FHP",
        "handle": "jean-fruit-hamper",
        "description": "Colourful FreshnessThis fruit basket gives off the air of soft, gentle generosity thanks found in delightful shades in both the fruit and the bunch of lovely flowers it comes with.",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers"
        ],
        "price": "241.66",
        "inclTax": "241.66",
        "img": [
            "/images/products/Colourful-Freshness-FHP1.jpg?v=1708663700",
            "/images/products/Colourful-Freshness-FHP2.jpg?v=1708663700"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Dragon Fruits",
                "2.  Purple Grape",
                "3.  Nam Shui Pear",
                "4.  Kiwi",
                "5.  Fuji Apple",
                "6.  Green Apple",
                "7.  Orange",
                "8.  Mango",
                "9.  Gerbera",
                "10.  Roses",
                "11.  Eustoma",
                "12.  Chrysanthemum Marble",
                "13.  Coin Leaf",
                "14.  White basket without handle"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 244,
        "name": "Demetria",
        "handle": "demetria",
        "description": "“We accept the love we think we deserve.” – Stephen Chbosky",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Demetria-1a.jpg?v=1700179652",
            "/images/products/Demetria-2a-813222.jpg?v=1700216902"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Garden Roses . Eustoma . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 245,
        "name": "Dionne",
        "handle": "dionne",
        "description": "Introducing our Graceful Hydrangea and Rose Bouquet, a luxurious ensemble of soft pink hydrangeas, elegant roses, and delicate seasonal blooms. This bouquet is thoughtfully arranged with lush greenery and wrapped in pastel pink paper, creating a harmonious blend of textures and colors. Perfect for expressing heartfelt sentiments, it makes a stunning gift for birthdays, anniversaries, or any celebration that calls for a touch of sophistication.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "342.07",
        "inclTax": "342.07",
        "img": [
            "/images/products/Dionne-2a.jpg?v=1724460140",
            "/images/products/Dionne-1a.jpg?v=1724460140"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangeas  .  Spray Roses  .  Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 40cm*40cm"
    },
    {
        "id": 246,
        "name": "Delila FBS",
        "handle": "delila",
        "description": "\"Life is like a coin. You can spend it any way you wish, but you only spend it once.” - Lillian Dickson",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-04-06at2.42.20PM_2.jpg?v=1649227386",
            "/images/products/WhatsAppImage2022-04-06at2.42.20PM_1_1.jpg?v=1649227387"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 247,
        "name": "Pink Of Health WHP",
        "handle": "hadleigh-wellness-hamper",
        "description": "Pink Of HealthTo be in the Pink of Health all the time is what we wish for. Send over our get well soon hamper that is loaded with snacks and food with nutrients.",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "259.92",
        "inclTax": "259.92",
        "img": [
            "/images/products/HadleighWHP1_55b2272c-7755-4580-a7eb-f4399cdd85de-873060.jpg?v=1671685636",
            "/images/products/HadleighWHP2_4df01900-7a08-41f8-a8cc-235726adf2fc-433537.jpg?v=1671685636"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tSpafood Super Cacao Premium Hot Chocolate lavender with Lutein 300g",
                "2.\tPost Great Grains- Cranberry Almond Crunch 396g",
                "3.\tDJ&A Fruity Crisps Blueberry & Raseberry Mix 20g",
                "4.\tHarvest Box Energy Mix Nuts 45g",
                "5.\tHarvest Box Super Mix Nuts and Chocolates 45g",
                "6.\tAssorted Flower Packaging",
                "7.\tExquisite Gift Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 248,
        "name": "Bloom My Health with Flowers WHP",
        "handle": "genesis-wellness-hamper",
        "description": "Bloom My Health with FlowerFilled to the brim with bottles of Bird’s Nest tonic and flowers, the Bloom My Health with Flower Get Well Soon Hamper is a warm and cheerful gift that will bring the glow back to someone who is under the weather. Help a loved one speed up their recovery with our hampers that are packed with mood and health-boosting products!  ",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "200.59",
        "inclTax": "200.59",
        "img": [
            "/images/products/Genesis-WHP1-194955.jpg?v=1671684899",
            "/images/products/Genesis-WHP2_4e3db062-65b8-43b2-acec-d1c00fb06f9a-478775.jpg?v=1671684899"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tBird’s Nest with Ginseng, White Fungus & Rock Sugar 6 X 70 ml",
                "2.\tNew Moon Essence of Chicken 3 x 70ml",
                "3.\tAssorted Flowers Packaging",
                "4.\tExquisite Gift Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm*35cm"
    },
    {
        "id": 249,
        "name": "Rylee",
        "handle": "rylee",
        "description": "\"Love is the delightful interval between meeting a beautiful girl and discovering that she looks like a haddock.\" - John Barrymore",
        "type": "Flower Baskets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Rylee-1.jpg?v=1628486581",
            "/images/products/Rylee-2.jpg?v=1641310328"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Preserved Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 250,
        "name": "Margaret",
        "handle": "margaret",
        "description": "\"It is best to love wisely, no doubt; but to love foolishly is better than not to be able to love at all.\" - William Makepeace Thackeray",
        "type": "Flower Baskets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Margaret-1a.jpg?v=1641321481",
            "/images/products/Margaret-2.jpg?v=1641321474"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Preserved Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 251,
        "name": "Naomi",
        "handle": "naomi",
        "description": "“To the world you may be one person, but to one person you are the world.” – Bill Wilson",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Naomi-2a-880366.jpg?v=1721396913",
            "/images/products/Naomi-1a-781509.jpg?v=1721396913"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Garden Rose . Eustoma . Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 252,
        "name": "Elizabeth",
        "handle": "elizabeth",
        "description": "\"The best love is the kind that awakens the soul; that makes us reach for more, that plants the fire in our hearts and brings peace to our minds. That’s what I hope to give you forever.\" - The Notebook",
        "type": "Flower Baskets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-05-07at1.13.37PM_2.jpg?v=1651900539",
            "/images/products/WhatsAppImage2022-05-07at1.13.37PM_1_1.jpg?v=1651900540"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Carnations  Eustoma .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*30cm"
    },
    {
        "id": 253,
        "name": "Beryl",
        "handle": "beryl",
        "description": "Celebrate love with our enchanting mixed rose bouquet. Featuring vibrant fuchsia and soft pink roses complemented by lush greenery and delicate white berries, this arrangement is wrapped in elegant pink paper. Perfect for expressing admiration and affection on any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Beryl-1c-219506.jpg?v=1721657474",
            "/images/products/Beryl-1a.jpg?v=1720534484"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Hypericum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 254,
        "name": "Bethany",
        "handle": "bethany",
        "description": "This vibrant vase arrangement features a stunning combination of radiant sunflowers and soft pink roses, beautifully accented with lush eucalyptus leaves. The bright yellow sunflowers bring warmth and cheer, while the delicate roses add a touch of elegance. Perfect for brightening any room, this bouquet is an ideal choice for celebrating joyful moments and adding a burst of sunshine to any setting.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Bethany-2a-513524.jpg?v=1724687451",
            "/images/products/Bethany-1a-666634.jpg?v=1724687451"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Spray Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 40cm*50cm"
    },
    {
        "id": 255,
        "name": "Condolence (IV)",
        "handle": "condolence-iv-1",
        "description": "In sincere condolence, our flower stands tenderly feature pure white chrysanthemums artfully paired with soothing green hues and delicate orchids. This serene ensemble offers a gentle tribute, symbolizing compassion and remembrance, providing solace and comfort during moments of deep grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Condolence-_IV_-1d.jpg?v=1720187925",
            "/images/products/Condolence-Card-2024_1_cc0ee89c-dc7e-4ae2-928d-9ec8e66df2d1.jpg?v=1720187930",
            "/images/products/Condolence-_IV_-2d.jpg?v=1720187925"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Orchids . Artificial Flowers . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 256,
        "name": "Empathy (IV)",
        "handle": "empathy-iv-1",
        "description": "In a grand expression of sympathy, our jumbo condolence flower stands showcase a lavish arrangement of pure white chrysanthemums and delicate lilies. This opulent ensemble provides a dignified and comforting tribute, symbolizing compassion and remembrance during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "844.11",
        "inclTax": "844.11",
        "img": [
            "/images/products/Empathy-_IV_-2c.jpg?v=1727184810",
            "/images/products/Condolence-Card-2024_1_fd8dff27-c725-4a2b-b1e5-a7602d3537c3.jpg?v=1720191144",
            "/images/products/Empathy-_IV_-1c.jpg?v=1727184805"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Gerbera . Lily. Artificial Flowers . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 160cm*180cm"
    },
    {
        "id": 257,
        "name": "Sympathy (I)",
        "handle": "sympathy-i",
        "description": "In a tender expression of sympathy, our condolence flower stands feature pristine white chrysanthemums complemented by a gentle color palette in champagne-themed eustomas and orchids. This elegant arrangement offers solace and a serene tribute, symbolizing compassion and remembrance during moments of grief.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Sympathy-_I_-1d.jpg?v=1720157523",
            "/images/products/Condolence-Card-2024_1_94846050-b7df-4f53-8a6d-719257cc03c2.jpg?v=1720157529",
            "/images/products/Sympathy-_I_-2d.jpg?v=1720157523"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Eustoma . Orchids . Matthiola . Artificial Lilies . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 258,
        "name": "Sympathy (II)",
        "handle": "sympathy-ii",
        "description": "Expressing condolences with grace, our flower stands showcase pure white chrysanthemums amidst a soothing palette of pink and purple blooms. This elegant arrangement offers a tender tribute, symbolizing compassion and remembrance, bringing solace and comfort during moments of grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Sympathy-_II_-1d.jpg?v=1720157700",
            "/images/products/Condolence-Card-2024_1_b2d46ee9-42b0-4e92-813d-1387b086b924.jpg?v=1720157706",
            "/images/products/Sympathy-_II_-2d.jpg?v=1720157700"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Mum . Rose . Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 259,
        "name": "Melody",
        "handle": "melody",
        "description": "Bask in the beauty of romance with our captivating bouquet featuring 9 deep red roses gracefully adorned with delicate pink and white baby's breath. This enchanting ensemble is a symphony of passion and tenderness, presented in a perfect balance of colors. Ideal for expressing love on anniversaries, Valentine's Day, or any occasion that calls for a touch of heartfelt elegance. Revel in the timeless allure of this stunning floral arrangement.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/WhatsAppImage2022-01-07at2.12.32PM_2.jpg?v=1641536042",
            "/images/products/WhatsAppImage2022-01-07at2.12.32PM_1_1.jpg?v=1641536047"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 260,
        "name": "Healthy Vibes with Flower WHP",
        "handle": "vivienne-wellness-hamper",
        "description": "Healthy Vibes with FlowerThis wellness gift will help that beloved one who is on the path to recovery have his/her full health back in good time. From the essential oil to the organic tea, organic juice, oatmeal and so on, the basket delivers some basic needs to an ailing one.",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Vivienne2-592822.jpg?v=1671685348",
            "/images/products/Vivienne1-320909.jpg?v=1671685348"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Spa Foods- Blue Pea Flower Tea with Manuka Honey Flavour 150g",
                "2.  Post Great Grains Cereal Cranberry Almond Crunch 396g",
                "3.  DJ&A Fruity Crisps 25g",
                "4.  Danisa Butter Cookies 200g",
                "5.  Assorted Flower Packaging",
                "6.  Exquisite Wooden Crate (M)"
            ]
        },
        "dimention": "Width*Height: 35cm*35cm"
    },
    {
        "id": 261,
        "name": "Jade",
        "handle": "jade",
        "description": "\"To know when to go away and when to come closer is the key to any lasting relationship.\" - Doménico Cieri Estrada",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "1597.17",
        "inclTax": "1597.17",
        "img": [
            "/images/products/Jade-3a-185553.jpg?v=1671685417",
            "/images/products/Jade-1-252223.jpg?v=1671685417",
            "/images/products/Jade-2-246908.jpg?v=1671685417"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "99 Stalks of Tulips"
            ]
        },
        "dimention": "Width*Height: 45cm*55cm"
    },
    {
        "id": 262,
        "name": "Taylor",
        "handle": "taylor",
        "description": "Make a bold statement with our stunning tulip bouquet. Featuring a captivating mix of vibrant red and soft pink tulips, this arrangement is accented with delicate baby's breath and lush greenery. Wrapped in sleek black paper, it exudes elegance and sophistication, perfect for expressing love, admiration, or celebrating any special occasion with flair.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Newborn Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Taylor-2a.jpg?v=1721215630",
            "/images/products/Taylor-1a.jpg?v=1721215630"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*36cm"
    },
    {
        "id": 263,
        "name": "Destiny",
        "handle": "destiny",
        "description": "Celebrate elegance with our stunning autumn-inspired bouquet. Featuring a beautiful blend of peach and white roses, accented with vibrant orange blooms, delicate daisies, and lush eucalyptus, this arrangement is wrapped in elegant white and beige paper. Perfect for any occasion, it brings a touch of warmth and sophistication to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Destiny-1a.jpg?v=1721272054",
            "/images/products/Destiny-2a.jpg?v=1721272053"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 264,
        "name": "Daleyza",
        "handle": "daleyza",
        "description": "Indulge in the allure of mystery and enchantment with our bouquet of 6 mesmerizing purple roses gracefully nestled amid the delicate clusters of caspia. The rich, regal hues of the roses paired with the subtle charm of caspia create a harmonious and sophisticated floral arrangement.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Daleyza-1a.jpg?v=1720777780",
            "/images/products/Daleyza-2a.jpg?v=1720777780"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Purple Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 265,
        "name": "Paris",
        "handle": "paris",
        "description": "This beautiful bouquet features pastel menta roses, delicate carnations, and lovely tulips, accented with lush greenery and wrapped in elegant brown paper. Perfect for any special occasion, it brings a touch of sophistication and charm to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Paris-2a.jpg?v=1721179579",
            "/images/products/Paris-1a.jpg?v=1721179579"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnation . Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 266,
        "name": "Rebecca",
        "handle": "rebecca",
        "description": "Make any occasion memorable with our exquisite mixed rose bouquet. Featuring a harmonious blend of red, pink, and yellow roses, accented with delicate pink alstroemeria, wax flowers, and lush greenery, this arrangement is wrapped in elegant pink and white paper. Perfect for expressing love, appreciation, or celebrating life's special moments, it adds a touch of beauty and charm to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Rebecca-2a.jpg?v=1721271571",
            "/images/products/Rebecca-1a.jpg?v=1721271571"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses .  Eustomas  . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 267,
        "name": "Miracle",
        "handle": "miracle",
        "description": "This elegant bouquet showcases a lovely mix of lavender, pink, and white tulips, symbolizing grace and serenity. Wrapped in soft pink paper and tied with a matching ribbon, the arrangement exudes a delicate charm. Perfect for expressing admiration, gratitude, or simply bringing a touch of springtime beauty into any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Newborn Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Miracle-2a-794283.jpg?v=1722358166",
            "/images/products/Miracle-1a.jpg?v=1722297796"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "30 Tulips (15 Purple,10 Pink, 5 White) "
            ]
        },
        "dimention": "Width*Height: 36cm*42cm"
    },
    {
        "id": 268,
        "name": "Nayeli",
        "handle": "nayeli",
        "description": "This bouquet is an exquisite array of creamy white roses and carnations interspersed with lush greenery and sprightly blooms, all enveloped in a soft, blush-toned paper. It's a display of natural grace and delicate charm, perfect for any occasion that calls for a touch of gentleness and elegance.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Nayeli-2b.jpg?v=1720890553",
            "/images/products/Nayeli-1b.jpg?v=1720890552"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 269,
        "name": "Janessa FBS",
        "handle": "janessa",
        "description": "This elegant arrangement features vibrant carnation, delicate mini roses, and charming chrysanthemum, complemented by lush greenery and fillers. Presented in a chic tan handbag with a violet ribbon, it's the perfect blend of beauty and sophistication for any occasion.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Janessa-FBS-1a-599880.jpg?v=1720117283",
            "/images/products/Janessa-FBS-2a-814473.jpg?v=1720117283"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Spray Rose . Carnations . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 270,
        "name": "Emmarie",
        "handle": "emmarie",
        "description": "“You come to love not by finding the perfect person, but by seeing an imperfect person perfectly.” – Sam Keen",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Emmarie-1.jpg?v=1628486590",
            "/images/products/Emmarie-2.jpg?v=1641324000"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Garden Roses . Eustoma . Matthiola . Alstroemeria"
            ]
        },
        "dimention": "Width*Height: 28cm*36cm"
    },
    {
        "id": 271,
        "name": "Giavanna",
        "handle": "giavanna",
        "description": "This beautiful bouquet features a harmonious blend of white roses, soft blue tulips, delicate white pom-poms, and small blue blooms. The arrangement is elegantly wrapped in light grey paper and tied with a matching ribbon, creating a sophisticated and calming aesthetic perfect for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Giavanna-2a.jpg?v=1721177193",
            "/images/products/Giavanna-1a.jpg?v=1721177193"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Mum . Eustomas . Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 272,
        "name": "Valery",
        "handle": "valery",
        "description": "Evoke tender sentiments with our \"Pastel Serenity\" bouquet. Three light pink roses, paired with eustoma and chrysanthemum, embody grace and appreciation. Delicately presented in a pastel green wrapper, this arrangement exudes tranquility and elegance. Perfect for conveying heartfelt emotions on any occasion, it's a gesture of beauty and sincerity.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Valery-1e.jpg?v=1720662664",
            "/images/products/Valery-1d.jpg?v=1720525481"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Mum . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 273,
        "name": "Esperanza",
        "handle": "esperanza",
        "description": "This delightful vase arrangement features a lovely cluster of dainty daisies, surrounded by lush greenery and delicate foliage. The cheerful white and yellow blooms radiate a fresh and vibrant charm, making this bouquet perfect for bringing a touch of nature indoors. Whether as a thoughtful gift or a charming decoration, it adds a breath of fresh air and simplicity to any setting.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Esperanza-2a-534762.jpg?v=1724687723",
            "/images/products/Esperanza-aa-435668.jpg?v=1724687723"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Daisy . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 274,
        "name": "Christine",
        "handle": "christine",
        "description": "Delight in the elegance of our tulip bouquet. Featuring a mix of pink and white tulips complemented by delicate daisies and lush greenery, this arrangement is wrapped in soft pink paper. Perfect for adding a touch of spring to any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Christine-1b.jpg?v=1720661274",
            "/images/products/Christine-1a.jpg?v=1720533067"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 275,
        "name": "Nataly",
        "handle": "nataly",
        "description": "This elegant bouquet blends cappuccino roses with crisp white blossoms and a touch of greenery, all beautifully encased in a gentle pink wrap. A luxurious gesture, perfect for conveying warmth and affection on special occasions or simply to brighten someone's day.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Nataly-2c-265793.jpg?v=1720962612",
            "/images/products/Nataly-1c-138531.jpg?v=1720962612"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Astilbe . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 276,
        "name": "Brynleigh",
        "handle": "brynleigh",
        "description": "A charming bouquet of pastel-colored baby's breath flowers, delicately arranged and adorned with a cute bunny decoration. Wrapped in elegant white paper with pink ribbons, this delightful arrangement is perfect for adding a whimsical touch to any special occasion or celebration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/lollipop-2a_38f7090c-5b8b-4a03-8d86-cde3e57a9a06.jpg?v=1721011638",
            "/images/products/Brynleigh-1a_8aa848f5-5a04-4e27-8f86-2daaeb793585-571953.jpg?v=1721048922"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath . Mum"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 277,
        "name": "Gabriella",
        "handle": "gabriella",
        "description": "\"Missing you is my hobby, caring for you is my job, making you happy is my duty and loving you is my life.\" Anonymous",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Gabriella-1.jpg?v=1641319572",
            "/images/products/Gabriella-2.jpg?v=1641319566"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "20 Stalks of Kenya Roses"
            ]
        },
        "dimention": "Width*Height: 35cm*35cm"
    },
    {
        "id": 278,
        "name": "Melanie FBS",
        "handle": "melanie",
        "description": "\"Love is more than a noun- it is a verb; it is more than a feeling- it is caring, sharing, helping, sacrificing.\" - William Arthur Ward",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-01-23at8.41.41AM_2.jpg?v=1642908775",
            "/images/products/WhatsAppImage2022-01-23at8.41.41AM_1_1.jpg?v=1642908775"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 279,
        "name": "Vivian FBS",
        "handle": "vivian",
        "description": "Introducing our exquisite bouquet featuring a delicate tiffany blue preserved rose, surrounded by a lush arrangement of white hydrangeas, baby's breath, and unique accents of dried flowers. Elegantly presented in a chic box with dual black hoops, this arrangement is perfect for any special occasion, adding a touch of elegance and charm to your celebration.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Vivian-FBS---2c.jpg?v=1721660848",
            "/images/products/Vivian-FBS---1b.jpg?v=1721660847"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 280,
        "name": "Iris FBS",
        "handle": "iris",
        "description": "\"If love is universal, no one can be left out.\" - Deepak Chopra",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Iglet-1.1.jpg?v=1628486594",
            "/images/products/iglet-2-min_2.jpg?v=1641318616"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 281,
        "name": "Kimberly FBS",
        "handle": "kimberly",
        "description": "\"Love doesn't make the world go 'round, but it sure makes the ride worthwhile.\" - Mae West",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Kimberly-1-860518.jpg?v=1671685468",
            "/images/products/Kimberly-2.jpg?v=1641314508"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerberas . Ferrero Rochers . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 282,
        "name": "Jocelyn",
        "handle": "jocelyn",
        "description": "\"True love is not a hide-and-seek game; in true love, both lovers seek each other.\" - Michael Bassey Johnson",
        "type": "Flower Boxes",
        "tags": [
            "Anniversary Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Jocelyn-1.jpg?v=1641315732",
            "/images/products/Jocelyn-2.jpg?v=1628486595"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Preserved Hydrangea . Gossypium . Fillers"
            ]
        },
        "dimention": "Width*Height: 12cm*12cm"
    },
    {
        "id": 283,
        "name": "Cecilia",
        "handle": "cecilia",
        "description": "This charming bouquet showcases vibrant pink tulips and lush greenery, wrapped in elegant peach paper. Perfect for expressing love and appreciation, it adds a touch of beauty and grace to any celebration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Cecilia-2a.jpg?v=1721179939",
            "/images/products/Cecilia-1a.jpg?v=1721179939"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Fillers & Foliages"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 284,
        "name": "Fiona",
        "handle": "fiona",
        "description": "This bouquet features a delicate combination of pink roses, white tulips, and baby's breath, arranged beautifully with lush green foliage. The flowers are elegantly wrapped in a translucent white paper and tied with a matching white ribbon, creating a sophisticated and serene presentation.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Fiona-2a.jpg?v=1721140221",
            "/images/products/Fiona-1a.jpg?v=1721140221"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Spray Roses . Baby's Breath . Fillers & Foliages"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 285,
        "name": "Sympathy (III)",
        "handle": "sympathy-iii",
        "description": "In sincere condolence, our flower stands tenderly feature pure white chrysanthemums adorned in a soothing green-themed arrangement. This serene ensemble offers a gentle tribute, symbolizing compassion and remembrance, providing solace and comfort during moments of deep grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Sympathy-_III_-1d.jpg?v=1720187503",
            "/images/products/Condolence-Card-2024_1_c12781ec-2b59-48ae-b732-b76a38bbb2b8.jpg?v=1720187532",
            "/images/products/Sympathy-_III_-2d.jpg?v=1720187503"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 286,
        "name": "Sympathy (IV)",
        "handle": "sympathy-iv",
        "description": "Extend sincere condolences with our elegant Condolence Flower Stands, adorned with faux pink lilies, pure white chrysanthemums, and regal purple orchids. This heartfelt arrangement offers a gentle expression of sympathy, honoring the departed with grace and compassion during moments of grief.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Sympathy-_IV_-1d.jpg?v=1720145052",
            "/images/products/Condolence-Card-2024_1_ccaa1b84-57d9-4a39-a17f-0c981fddd296.jpg?v=1720145058",
            "/images/products/Sympathy-_IV_-2d.jpg?v=1720145052"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Orchids . Artificial Lilies . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 287,
        "name": "Ruth",
        "handle": "ruth",
        "description": "\"What we have once enjoyed we can never lose. All that we love deeply becomes a part of us.\" - Helen Keller",
        "type": "Bouquets",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Ruth-1.jpg?v=1641310428",
            "/images/products/Ruth-2.jpg?v=1641310338"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Dried Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 288,
        "name": "Elsie",
        "handle": "elsie",
        "description": "\"Spread love everywhere you go. Let no one ever come to you without leaving happier.\" - Mother Teresa",
        "type": "Bouquets",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Elsie-1.jpg?v=1641333960",
            "/images/products/Elsie-2.jpg?v=1641333954"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Dried Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 289,
        "name": "Prosperity (II)",
        "handle": "prosperity-ii",
        "description": "Embrace success with our Crimson Elegance Grand Opening Stand. Vibrant red gerbera daisies and deep purple chrysanthemums unite in a stunning arrangement, radiating energy and sophistication. A floral masterpiece that mirrors the grandeur of your special day in every petal.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Prosperity-_II_-1g.jpg?v=1720406333",
            "/images/products/Grand-Opening-Card-2024_f872b97b-2e74-445b-996b-41cf76b3ae8c.jpg?v=1720406345",
            "/images/products/Prosperity-_II_-2g.jpg?v=1720406333"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Mum . Willows . Cyperus Stick . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 290,
        "name": "Prosperity (III)",
        "handle": "prosperity-iii",
        "description": "A living masterpiece that tells a story of celebration and success. Whether adorning an entrance or gracing the center stage, our grand opening flower stand promises to enchant all who witness its beauty, leaving a lasting impression that mirrors the grandeur of the occasion.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Prosperity-_III_-1e.jpg?v=1720405319",
            "/images/products/Grand-Opening-Card-2024_58646203-b46f-470f-bba8-e444b11266a3.jpg?v=1720405326",
            "/images/products/Prosperity-_III_-2e.jpg?v=1720405319"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Faux Sunflowers . Carnations . Cyperus Stick . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 291,
        "name": "Gracelynn",
        "handle": "gracelynn",
        "description": "Presenting \"Gracelynn,\" a beautifully crafted floral arrangement by Flowers Malaysia. This charming basket features a vibrant mix of red, pink, and peach blooms, including roses and orchids, complemented by lush greenery. Perfect for brightening any space, Gracelynn is designed to bring joy and elegance to your day.",
        "type": "Flower Baskets",
        "tags": [
            "Carnation Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Gracelynn-1-328879.jpg?v=1671685313",
            "/images/products/Graelyn-1b_2-347103.jpg?v=1715806130",
            "/images/products/Gracelynn-2-518422.jpg?v=1715783664"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnations . Eustoma . Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 292,
        "name": "Veronica",
        "handle": "veronica",
        "description": "\"The beauty of a woman must be seen from in her eyes, because that is the doorway to her heart, the place where love resides.\" - Audrey Hepburn",
        "type": "Flower Baskets",
        "tags": [
            "Sunflower Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-03-31at9.49.48AM_2.jpg?v=1648693762",
            "/images/products/WhatsAppImage2022-03-31at9.49.48AM_1_1.jpg?v=1648693762"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnations . Eustoma . Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 293,
        "name": "Keira",
        "handle": "keira",
        "description": "Indulge in the timeless beauty of our exquisite bouquet, blending delicate blue hydrangeas, soft pink roses, pristine white eustomas, and airy baby's breath. Radiating elegance and charm, this arrangement is perfect for weddings, anniversaries, or any special occasion, adding a touch of romance and sophistication to any setting.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Keira-2a.jpg?v=1721211439",
            "/images/products/Keira-1a.jpg?v=1721211439"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Eustoma . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 294,
        "name": "Ciara",
        "handle": "ciara",
        "description": "This charming vase arrangement features a delicate mix of soft white roses, pastel blooms, and unique accents like cotton and thistles. Complemented by lush greenery and silvery foliage, this bouquet brings a touch of whimsy and elegance to any setting. Perfect for celebrating special moments, it offers a serene and graceful ambiance that will enchant anyone who receives it.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Ciara-2a-833777.jpg?v=1724687593",
            "/images/products/Ciara-1a-575790.jpg?v=1724687593"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Spray Roses . Gossypium . Eustomas . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 16cm*28cm"
    },
    {
        "id": 295,
        "name": "Megan",
        "handle": "megan",
        "description": "Experience the charm of our delightful bouquet featuring 6 lovely pink roses artfully arranged in a rustic rattan basket. The soft, rosy hues of the roses complement the natural texture of the basket, creating a sweet and picturesque ensemble. Ideal for expressing affection on birthdays, celebrations, or as a thoughtful gesture, this bouquet radiates warmth and simplicity. Embrace the beauty of nature with this enchanting floral arrangement.",
        "type": "Flower Baskets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Megan-1-849258.jpg?v=1671685491",
            "/images/products/Megan-2-382088.jpg?v=1671685491"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 296,
        "name": "Lauren",
        "handle": "lauren",
        "description": "This elegant bouquet features creamy white roses and vibrant orange spray roses, accented with delicate baby's breath and lush greenery. Wrapped in soft peach paper and tied with a matching ribbon, it’s a perfect gift for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Lauren-2b.jpg?v=1720709100",
            "/images/products/Lauren-1b.jpg?v=1720709101"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  . Spray Roses . Baby's Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 297,
        "name": "Nancy",
        "handle": "nancy",
        "description": "Elevate your special day with our enchanting bouquet, a delicate symphony of pink and white gossypium, and the soft allure of pink baby's breath. This artfully crafted arrangement exudes romance and purity, creating a perfect expression of love. Share the beauty of the season with this ethereal and tender floral ensemble.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Nancy-1cc_24a35d00-1c69-449e-b717-a8e05a558e07-677728.jpg?v=1704983144",
            "/images/products/Nancy-2cc_1f9277bc-df3a-4afe-b2df-309735bdbe66-536699.jpg?v=1704983144"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Cotton Flowers . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 298,
        "name": "Aubrielle",
        "handle": "aubrielle",
        "description": "Elevate your gifting experience with this harmonious fusion of preserved florals and indulgent champagne in a captivating blend of hot pink and purple hues. This exquisite arrangement, meticulously crafted to withstand the test of time, exudes eternal beauty. Accompanied by a 20cl bottle of MOET champagne, it's a perfect gift of sophistication, celebrating moments that last forever in both memory and bloom. ",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Aubrielle-1cc-498046.jpg?v=1705588160",
            "/images/products/Aubrielle-2cc-615059.jpg?v=1705588160"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved & Dried Floral Arrangement in Box"
            ]
        },
        "dimention": "Width*Height: 15cm*20cm"
    },
    {
        "id": 299,
        "name": "Joyce",
        "handle": "joyce",
        "description": "Embrace Romance with Our Enchanting 12 Pink Roses BouquetDelicately arranged in a cone-wrapped ensemble, our bouquet of 12 pink roses is perfect for anniversaries, birthdays, and special occasions. This elegant floral expression conveys love and tenderness.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Joyce-1h-672379.jpg?v=1720789288",
            "/images/products/Joyce-1g-300698.jpg?v=1720843130"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses"
            ]
        },
        "dimention": "Width*Height: 18cm*30cm"
    },
    {
        "id": 300,
        "name": "Luella",
        "handle": "luella",
        "description": "\"I love you” begins by I, but it ends up by you. ― Charles de Leusse",
        "type": "Flower Baskets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Luella-1-133715.jpg?v=1671685471",
            "/images/products/Luella-2-687327.jpg?v=1671685471"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Lilies . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 301,
        "name": "Prosperity (IV)",
        "handle": "prosperity-iv",
        "description": "Illuminate all business launches with our purple-themed Elegant Bloom Floral Stand. The tower of purple Brassicas is accompanied by vibrant pink gerberas, delicate eustomas, exotic purple orchids, and the bold presence of red carnations  pink lilies, creating an opulent and elegant centerpiece.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Prosperity-_IV_-1h.jpg?v=1720493386",
            "/images/products/Grand-Opening-Card-2024_16ba4503-b04a-4039-a69e-569bd34aecd9.jpg?v=1720495255",
            "/images/products/Prosperity-_IV_-2h.jpg?v=1720495394"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Brassica . Gerbera . Eustoma . Carnation . Orchids . Anthurium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 302,
        "name": "Raquel",
        "handle": "raquel",
        "description": "Indulge in the warm and comforting beauty of our bouquet featuring 9 exquisite cappuccino roses accented by the aromatic touch of eucalyptus. The subtle, earthy tones of the roses evoke the richness of a cup of cappuccino, while the eucalyptus adds a refreshing and elegant touch. Perfect for expressing admiration or bringing a cozy ambiance to any occasion, this bouquet is a sophisticated blend of beauty and fragrance.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Raquel-2a.jpg?v=1721268694",
            "/images/products/Raquel-1a.jpg?v=1721268694"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Cappuccino Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 303,
        "name": "Deborah",
        "handle": "deborah",
        "description": "\"Whatever you're fighting for, you go with full passion and love.\" - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Tulip Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Deborah-1.jpg?v=1641335397",
            "/images/products/Deborah-2-887787.jpg?v=1671685198"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 304,
        "name": "Delicious Detox FHP",
        "handle": "casey-fruit-hamper",
        "description": "Delicious Detox",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Casey1-899608.jpg?v=1671685078",
            "/images/products/Casey2-620543.jpg?v=1671685078"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Dragon Fruits",
                "2. Green Apple",
                "3. Green Grape",
                "4. Mango",
                "5. Forelle Pear",
                "6. Nam Shui Pear",
                "7. Kiwi",
                "8. Fuji Apple",
                "9. Orange",
                "10. Flower plant"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 305,
        "name": "Emani",
        "handle": "emani",
        "description": "Embrace the delicate beauty of our pastel tulip bouquet. Featuring soft pink tulips accented with charming daisies and lush eucalyptus, this arrangement is wrapped in iridescent pink paper and tied with a satin ribbon. Perfect for expressing love, gratitude, or celebrating any special occasion, it brings a touch of elegance and tranquility to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Emani-2a.jpg?v=1721314236",
            "/images/products/Emani-1a.jpg?v=1721314236"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips  .  Daisy . Foliage "
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 306,
        "name": "Lory FBS",
        "handle": "lory",
        "description": "“Life isn't about finding yourself. Life is about creating yourself.”  - George Bernard Shaw",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2021-09-14at3.33.06PM_2.jpg?v=1641322550",
            "/images/products/WhatsAppImage2021-09-15at10.23.37AM_1.jpg?v=1641322458"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 307,
        "name": "Lainey FBS",
        "handle": "lainey",
        "description": "\"The greatest happiness of life is the conviction that we are loved; loved for ourselves, or rather, loved in spite of ourselves.\" - Victor Hugo",
        "type": "Bag Series",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets"
        ],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2021-09-16at11.00.12AM_2.jpg?v=1641314274",
            "/images/products/WhatsAppImage2021-09-16at11.00.12AM_1_1.jpg?v=1641314268"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 308,
        "name": "Colette",
        "handle": "colette",
        "description": "Evoke the purity and innocence of a newborn with our White Baby's Breath Bouquet. Delicately crafted from a selection of pristine white baby's breath flowers, this charming bouquet embodies grace and tenderness. Each stem of baby's breath is carefully arranged to create a vision of ethereal beauty, perfect for celebrating life's precious moments.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Colette-1b.jpg?v=1720528153",
            "/images/products/Colette-2a.jpg?v=1720528086"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 309,
        "name": "Josie",
        "handle": "josie",
        "description": "A luxurious bouquet of soft pink roses, meticulously arranged amidst a delicate halo of baby's breath, presented in an elegant wrapping with cascading pink ribbons that whisper sweet sentiments of love and affection. Perfect for a heartfelt gift or a romantic occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Josie-1b-896010.jpg?v=1713490244",
            "/images/products/Josie-2b-987124.jpg?v=1713490244"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "40 Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 45cm*45cm"
    },
    {
        "id": 310,
        "name": "Sympathy (V)",
        "handle": "sympathy-v",
        "description": "Express heartfelt condolences with our Flower Stands featuring serene white chrysanthemums and deep red roses intricately arranged in a heart shape. This elegant tribute is a symbol of love and remembrance, offering solace and compassion during times of grief and loss.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Sympathy-_V_-1d.jpg?v=1720155834",
            "/images/products/Condolence-Card-2024_1_26283ad4-0690-450f-a50d-63bc45993d24.jpg?v=1720155848",
            "/images/products/Sympathy-_V_-2d.jpg?v=1720155835"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Mums . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 100cm*180cm"
    },
    {
        "id": 311,
        "name": "Bailey",
        "handle": "bailey",
        "description": "This exquisite bouquet features elegant pink tip roses accented with sprigs of lavender and lush, silvery foliage. Wrapped in soft beige paper and tied with a matching ribbon, it's the perfect gift to convey sophistication and grace.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Bailey-2a.jpg?v=1721180451",
            "/images/products/Bailey-1a.jpg?v=1721180451"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 312,
        "name": "Prosperity (V)",
        "handle": "prosperity-v",
        "description": "Immerse your grand business opening in Purple Orchid Radiance with our purple-themed Floral Stand. Towering purple Brassicas, cheerful gerberas, delicate violet eustomas, and vibrant purple orchids are accentuated by the exotic presence of red gerbera flowers. The entire arrangement is elegantly wrapped in wavy red velvet skirting, creating a visually stunning and unique display.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Prosperity-_V_-1d.jpg?v=1720408133",
            "/images/products/Grand-Opening-Card-2024_7e15ba60-3334-4e2a-aed4-cb39846fb5c5.jpg?v=1720408143",
            "/images/products/Prosperity-_V_-2d.jpg?v=1720408132"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Brassica . Gerbera . Eustoma . Artificial Flowers . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 313,
        "name": "Natala FBS",
        "handle": "natala",
        "description": "“You only live once, but if you do it right, once is enough.” - Mae West",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-01-09at10.16.18AM_1.jpg?v=1641694675",
            "/images/products/WhatsAppImage2022-01-09at10.16.44AM_1.jpg?v=1641694669"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 314,
        "name": "Bethlehem",
        "handle": "bethlehem",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Baskets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Bethlelem-1.jpg?v=1641354001",
            "/images/products/Bethlelem-2.jpg?v=1628486613"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 315,
        "name": "Susan",
        "handle": "susan",
        "description": "\"I love you” begins by I, but it ends up by you. ― Charles de Leusse",
        "type": "Flower Baskets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Susan-1.jpg?v=1628486615",
            "/images/products/Susan-2.jpg?v=1628486615"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Mum . Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 316,
        "name": "Sympathy (VI)",
        "handle": "sympathy-vi",
        "description": "In a serene tribute of condolence, our flower stands showcase pure white and green-themed chrysanthemums, accompanied by delicate roses and eustomas. This elegant arrangement offers solace and comfort, symbolizing compassion and remembrance during moments of grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Sympathy-_VI_-1c.jpg?v=1720181846",
            "/images/products/Condolence-Card-2024_1_550ab6d1-579b-4b0b-9e54-1bdd9a68d216.jpg?v=1720181863",
            "/images/products/Sympathy-_VI_-2c.jpg?v=1720181847"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Lilies . Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 317,
        "name": "Comfort (I)",
        "handle": "comfort-i",
        "description": "In times of sympathy, our condolence flower stands feature radiant orange orchids, delicate white lilies, and a harmonious blend of white and green chrysanthemums. This elegant arrangement offers solace and a vibrant tribute, symbolizing compassion and remembrance during moments of grief.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Comfort-_I_-1d.jpg?v=1720156766",
            "/images/products/Condolence-Card-2024_1_731dd51e-30a9-42a3-a0fb-157ab84ec6ad.jpg?v=1720156774",
            "/images/products/Comfort-_I_-2d.jpg?v=1720156766"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Orchids . Lilies . Eustomas . Mum . Faux Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 318,
        "name": "Wealth (I)",
        "handle": "wealth-i",
        "description": "Elevate all grand opening with our Colorful Blossom Ensemble. Bursting with a vibrant array of hues, this stunning flower stand symbolizes joy, diversity, and a flourishing start for all business ventures. Luxurious orchids, adorned in golden hues, create a visually opulent display, promising success and prosperity for your flourishing venture.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "456.17",
        "inclTax": "456.17",
        "img": [
            "/images/products/Wealth-_I_-1d.jpg?v=1720524036",
            "/images/products/Grand-Opening-Card-2024_5ed8612e-db82-4f3e-8054-a62564372369.jpg?v=1720524041",
            "/images/products/Wealth-_I_-2d.jpg?v=1720524036"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnations . Gerbera . Anthurium . Ginger . Lilies . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 319,
        "name": "Bridal V",
        "handle": "bridal-v",
        "description": "This beautiful bridal arrangement will come together with a Vase =)",
        "type": "Bouquets",
        "tags": [
            "Wedding Flowers",
            "Engagement Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Bridal-_V_-1.jpg?v=1641337442",
            "/images/products/Bridal-_V_-2.jpg?v=1641337434"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Calla Lilies . Astromeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 320,
        "name": "Phileo",
        "handle": "phileo",
        "description": "Immerse yourself in a sea of passion with our breathtaking bouquet of 22 radiant red roses. Each bloom is a testament to love's intensity and beauty, carefully arranged to create a stunning visual impact. Perfect for expressing deep emotions on anniversaries, romantic occasions, or as a grand gesture, this bouquet captures the essence of timeless love in every velvety petal.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Phileo-2a-901209.jpg?v=1721310185",
            "/images/products/Phileo-1a-170950.jpg?v=1721310185"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "22 Roses"
            ]
        },
        "dimention": "Width*Height: 30cm*36cm"
    },
    {
        "id": 321,
        "name": "Jill",
        "handle": "jill-1",
        "description": "Elevate the essence of love with our enchanting bouquet featuring nine passionate red roses, tenderly encircled by the delicate grace of baby's breath. This arrangement is a symphony of romance, perfect for expressing deep affection and admiration. Let the beauty of these blooms convey your heartfelt emotions on any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Jill-2b.jpg?v=1721360496",
            "/images/products/Jill-1b.jpg?v=1721360496"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "9 Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 322,
        "name": "Lorelei",
        "handle": "lorelei",
        "description": "Elevate the romance with our enchanting bouquet featuring 18 deep red roses delicately accented by the ethereal beauty of baby's breath. Each velvety rose exudes passion, while the delicate white blooms add a touch of purity and grace. Perfect for expressing love and admiration on anniversaries, special occasions, or as a heartfelt gesture, this bouquet is a captivating symbol of enduring affection.",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2022-01-07at2.37.35PM_2.jpg?v=1641537606",
            "/images/products/WhatsAppImage2022-01-07at2.37.35PM_1_1.jpg?v=1641537601"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 32cm*40cm"
    },
    {
        "id": 323,
        "name": "Highness",
        "handle": "highness",
        "description": "Immerse yourself in the soft hues of romance with our delightful bouquet featuring nine charming pink roses, gracefully encircled by the delicate clusters of caspia. This enchanting arrangement captures the essence of love and tenderness, making it a perfect gift for expressing admiration and affection. Revel in the beauty of these blooms as they bring a touch of elegance to your special moments.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Highness-1aa.jpg?v=1704954711",
            "/images/products/Highness---Delivery-_1_1-393673.jpg?v=1715632102",
            "/images/products/Highness-2aa.jpg?v=1715577642"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 324,
        "name": "Camila",
        "handle": "camila",
        "description": "Immerse yourself in the captivating allure of our bouquet featuring 17 radiant red roses. Each bloom is a declaration of love, carefully arranged to create a visually stunning ensemble. Perfect for expressing passion on anniversaries, romantic occasions, or as a timeless gesture, this bouquet speaks volumes with its rich red hues and elegant simplicity.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Camila-2a-744736.jpg?v=1721396416",
            "/images/products/Camila-1a-102491.jpg?v=1721396416"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "17 Roses"
            ]
        },
        "dimention": "Width*Height: 30cm*36cm"
    },
    {
        "id": 325,
        "name": "Desire",
        "handle": "desire",
        "description": "This stunning bouquet of red roses, accented with delicate greenery and wrapped in soft pink paper, exudes elegance and romance. Perfect for expressing love and admiration, it makes a timeless gift for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Desire-2a.jpg?v=1721182774",
            "/images/products/Desire-1a.jpg?v=1721182774"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "12 Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 326,
        "name": "Zoe",
        "handle": "zoe",
        "description": "“In vain have I struggled. It will not do. My feelings will not be repressed. You must allow me to tell you how ardently I admire and love you.” -  Jane Austen",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Zoe-1a-304939.jpg?v=1671776017",
            "/images/products/Zoe-2a-237516.jpg?v=1671776017"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "20 Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 32cm*40cm"
    },
    {
        "id": 327,
        "name": "Luxe",
        "handle": "luxe",
        "description": "Immerse yourself in the charm of our delightful arrangement, featuring a curated ensemble of red roses, delicate baby's breath, and intricate parvifolia, all elegantly presented in a rustic rattan basket. The passionate red roses, airy baby's breath, and textured parvifolia create a harmonious blend of romance and natural beauty. Perfect for expressing love on anniversaries, celebrations, or as a heartwarming gift, this arrangement exudes elegance and thoughtful charm. Embrace the timeless beauty of nature with this captivating floral ensemble.",
        "type": "Flower Baskets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Luxe-New_cf05fe6e-a6ef-445b-9d2b-f3a0a028e748.jpg?v=1641321979",
            "/images/products/Luxe-New-2_85c98870-22f4-4116-9161-12e9988bc4da.jpg?v=1641321972"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "22 Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 328,
        "name": "Priscilla",
        "handle": "priscilla",
        "description": "“In vain have I struggled. It will not do. My feelings will not be repressed. You must allow me to tell you how ardently I admire and love you.” -  Jane Austen",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Priscilla-1b.jpg?v=1641312114",
            "/images/products/Priscilla-2b.jpg?v=1611625560"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 329,
        "name": "Nicole",
        "handle": "nicole",
        "description": "Introducing our Love's Spectrum Bouquet, a truly enchanting creation featuring three stunning rainbow preserved roses, symbolizing an array of emotions. Nestled within a heart-shaped pink willow basket, this bouquet is a unique and timeless expression of love. The vibrant colors and exquisite presentation make it a perfect gift for conveying affection, joy, and lasting admiration.",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Nicole-1bb-126886.jpg?v=1705415697",
            "/images/products/Nicole-2bb-119734.jpg?v=1705415697"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 330,
        "name": "Biondi",
        "handle": "biondi",
        "description": "Celebrate Spring with Our Vibrant Tulip BouquetThis vibrant bouquet is a kaleidoscope of color, featuring an array of brightly hued tulips wrapped in chic white paper and tied with a delicate Flowers Malaysia ribbon. A cheerful gift perfect for any occasion, it celebrates the beauty of spring in every petal. Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Biondi-2b.jpg?v=1721013755",
            "/images/products/Biondi-1b.jpg?v=1721013755"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Holland Tulips"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 331,
        "name": "Nadia",
        "handle": "nadia",
        "description": "Experience the epitome of romance with our charming arrangement featuring 6 deep red roses artfully entwined with the subtle allure of purple caspia. This delightful ensemble is presented in a rustic rattan basket, adding a touch of natural elegance. Ideal for expressing love and admiration on various occasions, this arrangement combines the passionate charm of red roses with the whimsical beauty of purple caspia in a rustic yet sophisticated display.",
        "type": "Flower Baskets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Nadia-1.jpg?v=1641312960",
            "/images/products/Nadia-2.jpg?v=1641312954"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 332,
        "name": "Jaselyn",
        "handle": "jaselyn",
        "description": "This charming basket arrangement features vibrant sunflowers, symbolizing happiness and vitality, paired with orange roses that bring a warm, uplifting touch. The blooms are elegantly nestled among lush greenery and delicate filler flowers, creating a delightful composition. Accented with a rustic woven basket and a thoughtful \"I Love You\" message, it's a perfect gift for any joyous occasion.",
        "type": "Flower Baskets",
        "tags": [
            "Sunflower Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Jaselyn-2a-913637.jpg?v=1726743727",
            "/images/products/Jaselyn-1a.jpg?v=1726621424"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 333,
        "name": "Everett",
        "handle": "everett",
        "description": "“In vain have I struggled. It will not do. My feelings will not be repressed. You must allow me to tell you how ardently I admire and love you.” -  Jane Austen",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Everett-1a_e2a3344e-1523-4737-8938-1f0d2fee02bf.jpg?v=1641321126",
            "/images/products/Everett-2a_e9e9472d-e1dc-4751-b91d-622c02c5c388.jpg?v=1641321120"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 334,
        "name": "Sheila",
        "handle": "sheila",
        "description": "“In vain have I struggled. It will not do. My feelings will not be repressed. You must allow me to tell you how ardently I admire and love you.” -  Jane Austen",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Sheila-1a.jpg?v=1641316926",
            "/images/products/Sheila-2a.jpg?v=1641316920"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 335,
        "name": "Tiffany",
        "handle": "tiffany",
        "description": "“In vain have I struggled. It will not do. My feelings will not be repressed. You must allow me to tell you how ardently I admire and love you.” -  Jane Austen",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/tiffany-1.jpg?v=1641303961",
            "/images/products/tiffany-2.jpg?v=1641303955"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 336,
        "name": "Wealth (II)",
        "handle": "wealth-ii",
        "description": "Illuminate all grand openings with the Golden Radiance Tripod Stand. Bright yellow Gerbera, Roses, and Chrysanthemums intertwine, adorned with golden leaves, creating a floral masterpiece that symbolizes prosperity and elegance. A radiant focal point for a joyous celebration.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Wealth-_II_-1b.jpg?v=1720094601",
            "/images/products/Grand-Opening-Card-2024_ed0d6bad-c442-4914-9e4d-cfa78849fe55.jpg?v=1720094610",
            "/images/products/Wealth-_II_-2b.jpg?v=1720094601"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Gerbera . Eustoma . Matthiola . Carnations . Willows . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 337,
        "name": "Wealth (III)",
        "handle": "wealth-iii",
        "description": "Embrace a timeless aesthetic at all grand business openings with our Timeless White Elegance Tripod Stand. Beautiful flowers cascade from beneath delicate white mesh skirtings, creating a stunning and classic centerpiece that radiates sophistication.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Wealth-_III_-1a.jpg?v=1720406076",
            "/images/products/Grand-Opening-Card-2024_7c612a2f-7424-4900-bd7d-5edfe00fce18.jpg?v=1720406101",
            "/images/products/Wealth-_III_-2a.jpg?v=1720406077"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Gerbera . Carnations . Eustoma . Anthurium . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 338,
        "name": "Donna",
        "handle": "donna",
        "description": "Elevate any occasion with our refined pastel bouquet. This sophisticated arrangement features delicate blush garden roses, creamy white blooms, and lush eucalyptus, elegantly wrapped in soft beige paper. Perfect for weddings, anniversaries, or simply to show someone you care, it adds a touch of grace and beauty to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Donna-2a.jpg?v=1721215385",
            "/images/products/Donna-1a.jpg?v=1721215385"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Garden Rose . Spray Roses . Carnation . Eustoma . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 339,
        "name": "Yolanda",
        "handle": "yolanda",
        "description": "Delight in romance with our Pink Heart-Shaped Baby's Breath Bouquet. Soft hues of pink intertwine into a captivating heart formation, evoking love and tenderness. Meticulously crafted, this bouquet embodies elegance and charm, perfect for celebrating love on anniversaries, Valentine's Day, or any heartfelt occasion.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Yolanda-2b.jpg?v=1720796211",
            "/images/products/Yolanda-1a.jpg?v=1720794931"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 340,
        "name": "Helen",
        "handle": "helen",
        "description": "Elevate romance with our Tiffany Blue Heart-Shaped Baby's Breath Bouquet. Intricately arranged baby's breath blooms cascade in a captivating heart design, exuding sophistication and charm. Ideal for weddings, engagements, or expressing affection, this bouquet radiates elegance, offering a timeless symbol of love in a unique Tiffany Blue hue.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Helen-2a-685333.jpg?v=1720875987",
            "/images/products/Helen-1a.jpg?v=1720795917"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 341,
        "name": "Dawn",
        "handle": "dawn",
        "description": "\"The best and most beautiful things in this world cannot be seen or even heard, but must be felt with the heart.\" -  Helen Keller",
        "type": "Flower Baskets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Dawn-1b.jpg?v=1641335414",
            "/images/products/Dawn-2b.jpg?v=1641335408"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Cotton Flower . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 342,
        "name": "Comfort (II)",
        "handle": "comfort-ii",
        "description": "Offering condolences, our flower stands elegantly blend blue roses and faux white lilies with vibrant green chrysanthemums. This serene arrangement symbolizes tranquility and compassion, providing a gentle tribute to the departed and comfort during moments of grief and remembrance.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Comfort-_II_-1d.jpg?v=1720156595",
            "/images/products/Condolence-Card-2024_1_ccadc7aa-9403-4e55-a5a6-f3e366cf41e7.jpg?v=1720156601",
            "/images/products/Comfort-_II_-2d.jpg?v=1720156595"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Orchids . Roses . Matthiola . Mum . Faux Hydrangea & Lilies . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 343,
        "name": "Jolene",
        "handle": "jolene",
        "description": "ndulge in the purity of our White Baby's Breath Bouquet elegantly wrapped in checkered wrapper. Each delicate stem exudes grace and charm, harmonizing beautifully with the vibrant packaging. Ideal for celebrations, weddings, or as a heartfelt gesture, this bouquet adds a touch of sophistication and joy to any occasion.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Jolene-2a.jpg?v=1721366444",
            "/images/products/Jolene-1a.jpg?v=1721366444"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 344,
        "name": "Cynthia",
        "handle": "cynthia",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/cynthia-1.jpg?v=1641336020",
            "/images/products/cynthia-2.jpg?v=1641336012"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Cotton Flowers . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 345,
        "name": "Jacquelyn",
        "handle": "jacquelyn",
        "description": "This elegant bouquet features soft pastel roses, white chrysanthemums, and delicate lisianthus, accented with lush greenery. Wrapped in peach-colored paper and tied with a white ribbon, it’s a perfect gift for any special occasion, radiating beauty and grace.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Jacquelyn-2b.jpg?v=1720841951",
            "/images/products/Jacquelyn-1b.jpg?v=1720841951"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Meta Roses . Eustoma . Matthiola . Baby's Breath . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 346,
        "name": "Wealth (V)",
        "handle": "wealth-v",
        "description": "Make a statement at all grand business openings with our Pink Elegance Tripod Stand. Adorned with pastel pink flower arrangements wrapped in beautiful pink skirtings, it's a graceful and sophisticated centerpiece that signifies success and a flourishing new venture.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Wealth-_V_-1b.jpg?v=1720094782",
            "/images/products/Grand-Opening-Card-2024_a602e631-d8c2-4992-87b9-ee355d61151a.jpg?v=1720094788",
            "/images/products/Wealth-_V_-2b.jpg?v=1720094783"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Eustomas . Matthiola . Faux Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 347,
        "name": "Kathy",
        "handle": "kathy",
        "description": "This romantic bouquet features soft pastel roses and lisianthus, accented with delicate chamomile flowers and lush greenery. Two heart-shaped wicker accents add a charming touch. Wrapped in elegant beige paper and tied with a ribbon, it's perfect for expressing love and affection.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Kathy-2b.jpg?v=1720709703",
            "/images/products/Kathy-1b.jpg?v=1720709703"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Eustoma  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 348,
        "name": "Janice",
        "handle": "janice",
        "description": "This stunning bouquet features vibrant red roses and orange spray roses, accented with eucalyptus and delicate greenery. Wrapped in elegant beige paper and adorned with a ribbon, it's a perfect gift for expressing deep love and admiration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Janice-2a.jpg?v=1720706501",
            "/images/products/Janice-1a.jpg?v=1720706501"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 349,
        "name": "Comfort (III)",
        "handle": "comfort-iii",
        "description": "In heartfelt condolence, our flower tripod stands feature a regal purple theme, adorned with exquisite brassica, elegant orchids, chrysanthemums, and delicate eustomas. This arrangement provides a dignified tribute, symbolizing compassion and remembrance, offering solace during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Comfort-_III_-1b.jpg?v=1720144036",
            "/images/products/Condolence-Card-2024_1_d85f303c-37ee-4dc8-a204-5aeca42b6ab3.jpg?v=1720144041",
            "/images/products/Comfort-_III_-2b.jpg?v=1720144036"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Eustoma . Orchids . Faux Hydrangea & Lilies . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 350,
        "name": "Jesse",
        "handle": "jesse",
        "description": "This cheerful bouquet features three vibrant sunflowers surrounded by delicate baby's breath, creating a beautiful contrast. Wrapped in elegant white and grey paper and tied with a white ribbon, it's a perfect gift to brighten any occasion with warmth and happiness.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Jesse-2b-886908.jpg?v=1726743728",
            "/images/products/Jesse-1b-184656.jpg?v=1726743728"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 351,
        "name": "Estelle",
        "handle": "estelle",
        "description": "Radiate serenity with a bouquet of seven white roses elegantly presented in a wrapping adorned with white lace. The calming beauty of the white roses is enhanced by the intricate lace detailing, creating a sense of tranquility and grace. This arrangement is perfect for conveying peaceful sentiments on occasions that call for understated beauty.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Estelle-1aa-286857.jpg?v=1706196885",
            "/images/products/Estelle-2aa-359413.jpg?v=1706196885"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 352,
        "name": "Verlynn",
        "handle": "verlynn",
        "description": "A timeless and romantic bouquet composed of 18 stems of pure pink roses. The uniformity in color creates a sense of serenity and grace, while the lush blooms convey sentiments of love and admiration. This classic arrangement is perfect for expressing heartfelt emotions on special occasions.",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Verlynn-1bb-559543.jpg?v=1706110522",
            "/images/products/Verlynn-2bb-527971.jpg?v=1706110522"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "18 Roses"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 353,
        "name": "Linda",
        "handle": "linda",
        "description": "This vibrant vase arrangement features a joyful mix of radiant red roses, cheerful sunflowers, and delicate white blooms, highlighted by soft pink accents and lush greenery. The addition of charming gerberas and playful hypericum berries adds a delightful touch, making this bouquet perfect for brightening any space. It radiates warmth and happiness, bringing a lively and elegant atmosphere to any occasion.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Linda-2a-791842.jpg?v=1724688022",
            "/images/products/Linda-1a.jpg?v=1724633520"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Sunflower . Matthiola . Carnation Spray . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*32cm"
    },
    {
        "id": 354,
        "name": "Wealth (VI)",
        "handle": "wealth-vi",
        "description": "Bathed in the golden glow of sunflowers, our stand exudes warmth and positivity, embodying the spirit of new beginnings. Each sunflower, with its vibrant petals and towering stature, symbolizes the joy and abundance that the grand opening festivities bring.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Wealth-_VI_-1d.jpg?v=1720094961",
            "/images/products/Grand-Opening-Card-2024_3bb00e35-459e-425c-8619-bb36a8506ed0.jpg?v=1720094968",
            "/images/products/Wealth-_VI_-2d.jpg?v=1720094961"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Roses Spray . Eustomas . Orchids . Mum . Willows . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 355,
        "name": "Comfort (VI)",
        "handle": "comfort-vi",
        "description": "In a gesture of profound sympathy, our condolence flower stands feature rich purple-themed chrysanthemums and delicate white lilies. This graceful arrangement expresses a tender tribute, symbolizing compassion and remembrance, offering solace and comfort during moments of grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Comfort-_VI_-1d.jpg?v=1720158969",
            "/images/products/Condolence-Card-2024_1_e95f98df-86d0-4b70-ae64-0d041df569dc.jpg?v=1720158985",
            "/images/products/Comfort-_VI_-2d.jpg?v=1720158969"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Spray Carnations . Faux Flowers . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 356,
        "name": "Comfort (VII)",
        "handle": "comfort-vii",
        "description": "In a respectful tribute, our condolence flower tripod stands feature serene white chrysanthemums and delicate blue gerberas, elegantly adorned in white dressing. This arrangement provides a dignified and soothing tribute, symbolizing compassion and remembrance during moments of deep grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Comfort-_VII_-1b.jpg?v=1720143058",
            "/images/products/Condolence-Card-2024_1_4965929c-d85f-407d-b5ec-2973d525d48f.jpg?v=1720143063",
            "/images/products/Comfort-_VII_-2b.jpg?v=1720143058"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerberra . Mum . Eustoma . Matthiola . Rose Sprays . Faux Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 357,
        "name": "Quinnlyn",
        "handle": "quinnlyn",
        "description": "Delight in the timeless elegance of our bouquet featuring 7 deep red roses, each embraced by the delicate charm of lace. This enchanting ensemble is a perfect balance of passion and sophistication, creating a visually stunning arrangement. Ideal for expressing love on special occasions, anniversaries, or as a romantic gesture, this bouquet exudes grace and refinement. Embrace the beauty of love with this captivating floral presentation in lace.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Quinnlyn-1a.jpg?v=1721312409",
            "/images/products/Quinnlyn-2a.jpg?v=1721312409"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 358,
        "name": "Khloe",
        "handle": "khloe",
        "description": "Delight in the elegance of our creamy rose bouquet. Featuring soft, ivory roses paired with charming daisies and lush eucalyptus, this arrangement exudes timeless beauty. Wrapped in delicate white paper and adorned with a satin ribbon, it is perfect for weddings, anniversaries, or any occasion where you want to convey grace and sophistication.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Khloe-2a-965416.jpg?v=1721222878",
            "/images/products/Khloe-1a.jpg?v=1721216088"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 359,
        "name": "Spring Showers FNB",
        "handle": "spring-showers-fnb",
        "description": "Spring ShowersA crisp and sophisticated flower and balloon arrangement, the Spring Showers is designed by our florists to be classically elegant. Featuring roses, carnations and preserved hydrangea, this bouquet of flowers and balloons comes in an exquisite hat box that is just waiting to be personalised with a customisable message on our balloon!",
        "type": "Balloons & Flowers",
        "tags": [
            "Happy Birthday Balloons Bouquet"
        ],
        "price": "221.13",
        "inclTax": "221.13",
        "img": [
            "/images/products/Felicity1-534032.jpg?v=1671775907",
            "/images/products/Felicity2a-829340.jpg?v=1671775907"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.   Roses",
                "2.  Eustoma",
                "3.  Carnation",
                "4.  Preserved Hydrangea",
                "5.  Caspia",
                "6.  Eucalyptus",
                "7.  Personalized Text On Balloon with 4 Mini Balloons",
                "8.  Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 360,
        "name": "Frida",
        "handle": "frida-1",
        "description": "This exquisite bouquet features cuppuccino roses paired with fluffy white cotton stems, creating a unique and elegant arrangement. Accented with lush greenery and delicate fillers, it's wrapped in a charming peach-toned paper.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Frida-2b.jpg?v=1720778642",
            "/images/products/Frida-1b.jpg?v=1720778642"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Cappuccino Roses . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 361,
        "name": "Daniella",
        "handle": "daniella",
        "description": "Sunshine personified, this radiant bouquet of sunny yellow roses, symbolizing friendship and joy, is complemented by a delicate halo of baby's breath. Wrapped in soft yellow paper with a gentle white ribbon, it's like a warm embrace on a bright day, perfect for lifting spirits and celebrating life's cheerful moments.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Daniella-2d-616279.jpg?v=1720788754",
            "/images/products/Daniella-1d.jpg?v=1720708515"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Yellow Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 362,
        "name": "Empathy (I)",
        "handle": "empathy-i",
        "description": "In times of loss, our Condolence Flower Stand extends heartfelt sympathy. Featuring a serene blend of violet chrysanthemums, pink gerbera, purple orchids, and white roses, this arrangement is a comforting tribute, symbolizing compassion and remembrance during moments of grief.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Empathy-_I_-1d.jpg?v=1720147024",
            "/images/products/Condolence-Card-2024_1_c356a1bc-8f21-44bf-ac62-6a8989e37ffa.jpg?v=1720147029",
            "/images/products/Empathy-_I_-2d.jpg?v=1720147024"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Orchids . Roses . Mum . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 363,
        "name": "Empathy (II)",
        "handle": "empathy-ii",
        "description": "In moments of condolence, our flower stands, adorned with pristine white chrysanthemums, delicate champagne gerberas, and soft roses, offer solace and a gentle tribute. This elegant arrangement symbolizes compassion and remembrance, extending comfort and support during times of grief.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Empathy-_II_-1d.jpg?v=1720156418",
            "/images/products/Condolence-Card-2024_1_2929c2c8-4e95-4e9f-ab3c-2fb14b51a0cb.jpg?v=1720156425",
            "/images/products/Empathy-_II_-2d.jpg?v=1720156418"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Roses. Eustoma .Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 364,
        "name": "Success (V)",
        "handle": "success-v",
        "description": "Unveil the grandeur of all grand openings with our Sunlit Splendor Tripod Stand. Golden touches of leaves accentuate the brilliance of sunflowers and yellow Gerbera, creating a radiant spectacle that symbolizes prosperity and joy. This floral ensemble stands tall, infusing your special occasion with a golden touch of elegance and success.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Success-_V_-1b.jpg?v=1720094414",
            "/images/products/Grand-Opening-Card-2024_91780e97-abbe-4a92-8493-cdbff9c4f0c0.jpg?v=1720094426",
            "/images/products/Success-_V_-2b.jpg?v=1720094414"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Gerbera . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 365,
        "name": "Candice",
        "handle": "candice",
        "description": "This beautiful bouquet features a stunning sunflower, surrounded by peach roses, orange spray roses, and lush greenery. Wrapped in soft beige paper and tied with an elegant ribbon, it’s a perfect gift for any occasion, bringing warmth and joy.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Candice-2a.jpg?v=1720701225",
            "/images/products/Candice-1a.jpg?v=1720701225"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses .  Sunflower  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 366,
        "name": "Prudence",
        "handle": "prudence",
        "description": "This delightful bouquet features a vibrant sunflower, soft pink roses, and delicate baby's breath, topped with an adorable teddy bear in graduation attire. Wrapped in iridescent paper and tied with a ribbon, it's a perfect gift to celebrate graduation.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers",
            "Sunflower Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Prudence-2a.jpg?v=1720668336",
            "/images/products/Prudence-1b.jpg?v=1720668468"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Roses . Spray Roses . Eustoma . Baby Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 367,
        "name": "Empathy (III)",
        "handle": "empathy-iii",
        "description": "In a gentle expression of sympathy, our condolence flower tripod stands showcase tender pink gerberas and soft champagne roses, accented with delicate baby's breath. This elegant arrangement offers a comforting tribute, symbolizing compassion and remembrance, providing solace during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Empathy-_III_-1b.jpg?v=1720143890",
            "/images/products/Condolence-Card-2024_1_c93a6b99-e6d6-4bcf-b008-cc4973e6b692.jpg?v=1720143901",
            "/images/products/Empathy-_III_-2b.jpg?v=1720143890"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Roses . Bird of Paradise . Baby Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 368,
        "name": "Luby",
        "handle": "luby-1",
        "description": "Elevate emotions with our exquisite bouquet featuring 6 red roses and 6 pink tip roses, elegantly arranged with delicate pink caspia. Wrapped in a vibrant hot pink wrapper, this enchanting floral ensemble is a perfect expression of love and admiration. Bring joy with the beauty of these meticulously selected and artfully presented roses.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Luby-2g-179479.jpg?v=1721396910",
            "/images/products/Luby-1g-809302.jpg?v=1721396910"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "12 Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 369,
        "name": "Ferlyn",
        "handle": "ferlyn",
        "description": "This sunlit bouquet boasts bright yellow sunflowers and roses, interspersed with delicate white blooms and verdant foliage, crowned by a cheerful teddy bear in a graduation cap, symbolizing joyous accomplishment and new beginnings.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers",
            "Congratulations Flowers",
            "Sunflower Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Ferlyn-2b-615424.jpg?v=1720788920",
            "/images/products/Ferlyn-1b-838416.jpg?v=1720788920"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Daisy . Eustoma . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 370,
        "name": "Harley",
        "handle": "harley",
        "description": "Celebrate with the cheerful charm of our gingham bouquet. Featuring a radiant mix of champange mini roses, delicate daisies, and lush greenery, this arrangement is wrapped in delightful orange gingham paper and tied with a matching bow. Perfect for birthdays, anniversaries, or any joyful occasion, it brings a burst of sunshine and happiness to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Harley-2a.jpg?v=1721313919",
            "/images/products/Harley-1a.jpg?v=1721313919"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Spray Roses . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 371,
        "name": "Zina",
        "handle": "zina",
        "description": "This elegant bouquet features deep red roses and soft pink carnations nestled in a cloud of delicate baby's breath. Wrapped in soft pink paper, it exudes romance and charm, making it a perfect gift for expressing love and admiration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Zina-2a.jpg?v=1721361112",
            "/images/products/Zina-1a.jpg?v=1721361112"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Rose . Carnations . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 372,
        "name": "Noura",
        "handle": "noura",
        "description": "This elegant bouquet showcases a harmonious blend of soft pink and white flowers, including roses and carnations, with delicate eucalyptus accents. Wrapped in light pink paper and adorned with a matching ribbon, it exudes a timeless charm, perfect for expressing love and appreciation.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Noura-2a.jpg?v=1721367603",
            "/images/products/Noura-1a.jpg?v=1721367602"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 373,
        "name": "Kylie",
        "handle": "kylie",
        "description": "A stunning Flowers Malaysia bouquet, where vivacious pink carnations and creamy roses mingle with silvery leaves, all wrapped in a blush paper with cascading ribbons, evoking a sense of timeless elegance and joyful celebration.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Eustoma Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Kylie-2b-861833.jpg?v=1721222878",
            "/images/products/Kylie-1b.jpg?v=1721180765"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Carnations . Eustoma . Dusty Millers"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 374,
        "name": "Success (VI)",
        "handle": "success-vi",
        "description": "Let the Sunflower Cascade Celebration be the focal point of all grand openings. Cascading sunflowers paired with the delicate allure of carnations create a stunning visual spectacle, setting the stage for a day filled with success and jubilation.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Success-_VI_-1da.jpg?v=1720408475",
            "/images/products/Grand-Opening-Card-2024_0de2a1c4-1e3d-437b-a498-d4bb9126eb4b.jpg?v=1720408493",
            "/images/products/Prosperity-_VI_-2d.jpg?v=1720408393"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Carnations . Gerberra . Eustomas . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 375,
        "name": "Luxury (I)",
        "handle": "luxury-i",
        "description": "Make a colossal statement at all grand business openings with our Sunlit Spectacle Jumbo Floral Stand. Towering sunflowers, spray roses, and Anthurium flowers create a breathtaking display symbolizing vibrancy and success.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "547.45",
        "inclTax": "547.45",
        "img": [
            "/images/products/Luxury-_I_-1c.jpg?v=1720524189",
            "/images/products/Grand-Opening-Card-2024_44a83f0c-71af-4a3a-8458-9a67b45cc2b0.jpg?v=1720524194",
            "/images/products/Luxury-_I_-2c.jpg?v=1720524189"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Orchids . Anthurium . Roses . Matthiola . Mum . Birds of Paradise . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 120cm*180cm"
    },
    {
        "id": 376,
        "name": "Pearlyn FBS",
        "handle": "pearlyn",
        "description": "This lovely arrangement features vibrant red carnations surrounded by delicate baby's breath, creating a classic and elegant display. Presented in a chic tan handbag with a red ribbon, it’s the perfect gift to convey your heartfelt emotions and brighten any occasion.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Pearlyn-FBS-2c.jpg?v=1721788441",
            "/images/products/Pearlyn-FBS-1b.jpg?v=1721788182"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 377,
        "name": "Ridley",
        "handle": "ridley-1",
        "description": "A stunning bouquet of Menta roses, perfectly complemented by delicate white accents and lush greenery. This elegant arrangement is wrapped in soft ivory paper, creating a sophisticated and timeless gift ideal for expressing admiration and love on any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Ridley-2a_ccdf39a8-9676-46ef-a90a-0c34163ccab2.jpg?v=1721017771",
            "/images/products/Ridley-1a_c3780c87-1bf3-4d48-8326-cecf4501a7d0-562796.jpg?v=1721049662"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Meta Roses  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 378,
        "name": "Madelyn",
        "handle": "madelyn",
        "description": "Colorful Spring Tulip Vase is a delightful mix of vibrant tulips in a rainbow of colors, complemented by fresh greenery and delicate daisies. Arranged in a clear glass vase and tied with a ribbon, this bouquet is a perfect way to celebrate the joys of spring and brighten up any room.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Madelyn-2a-372473.jpg?v=1724600050",
            "/images/products/Madelyn-1a-764799.jpg?v=1724600050"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Daisy . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*32cm"
    },
    {
        "id": 379,
        "name": "Isabella",
        "handle": "isabella",
        "description": "Country Meadow Daisy Bouquet features a delightful blend of daisies and wildflowers, arranged in a classic glass jar with a rustic twine accent. This bouquet captures the essence of a blooming countryside, bringing a fresh and natural charm to your home or as a thoughtful gift for someone special.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Isabella-2b.jpg?v=1724548843",
            "/images/products/Isabella-1b.jpg?v=1724548843"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Daisies . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*32cm"
    },
    {
        "id": 380,
        "name": "Sandra",
        "handle": "sandra",
        "description": "The Romantic Pink and Red Rose Arrangement features a captivating mix of soft pink roses, vibrant red blooms, and delicate white accents, beautifully arranged with lush greenery. Presented in a clear glass vase, this bouquet is a perfect expression of love and admiration, ideal for anniversaries, Valentine’s Day, or any romantic occasion.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Sandra-2a-538491.jpg?v=1724600284",
            "/images/products/Sandra-1a.jpg?v=1724549735"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Peonies . Tulips . Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*32cm"
    },
    {
        "id": 381,
        "name": "Quincy",
        "handle": "quincy",
        "description": "Celebrate life's special moments with our vibrant mixed flower bouquet. Featuring a delightful blend of peach and white roses, cheerful pink carnations, and charming daisies, this arrangement is accented with lush greenery. Wrapped in elegant white paper, it's perfect for expressing joy, gratitude, or love, bringing a burst of color and happiness to any occasion.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Quincy-2a.jpg?v=1721268492",
            "/images/products/Quincy-1a.jpg?v=1721268492"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Garden Rose . Rose . Spray Roses . Carnation  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 382,
        "name": "Gwyndolin",
        "handle": "gwyndolin",
        "description": "\"It is health that is real wealth and not pieces of gold and silver.\" - Mahatma Gandhi",
        "type": "Hampers",
        "tags": [],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/FD-NP-1-min-_1_-min.jpg?v=1628486650",
            "/images/products/FD-NP-1.2-min_1.jpg?v=1641329030"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "Peanut Assortment . Carlsberg (4 Cans) . Moet Champagne"
            ]
        },
        "dimention": "Width*Height: 35cm*30cm"
    },
    {
        "id": 383,
        "name": "Kaathe",
        "handle": "kaathe",
        "description": "\"It is health that is real wealth and not pieces of gold and silver.\" - Mahatma Gandhi",
        "type": "Hampers",
        "tags": [],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/FD-NP-2-min-_1_-min.jpg?v=1641315013",
            "/images/products/FD-NP-2.1-min_1.jpg?v=1641315003"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "Peanut Assortments . Carlsberg Beer (5 Cans)"
            ]
        },
        "dimention": "Width*Height: 35cm*30cm"
    },
    {
        "id": 384,
        "name": "Teagan",
        "handle": "teagan",
        "description": "Love is an endless act of forgiveness - Beyonce",
        "type": "Bouquets",
        "tags": [
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Teagan-1-min_1.jpg?v=1641304090",
            "/images/products/Teagan-2-min_3_1.jpg?v=1641304081"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Freesia . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*36cm"
    },
    {
        "id": 385,
        "name": "Uriah",
        "handle": "uriah",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Uriah-1.jpg?v=1625011637",
            "/images/products/Uriah-2.jpg?v=1641303714"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 21cm*25cm"
    },
    {
        "id": 386,
        "name": "Kaelyn",
        "handle": "kaelyn",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Kaelyn-1.jpg?v=1625011379",
            "/images/products/Kaelyn-2.jpg?v=1625011380"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 21cm*28cm"
    },
    {
        "id": 387,
        "name": "Ivy",
        "handle": "ivy",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Ivy-Final-1.jpg?v=1628486652",
            "/images/products/Ivy-Final-2.jpg?v=1628486652"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 20cm*20cm"
    },
    {
        "id": 388,
        "name": "Queena",
        "handle": "queena",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "171.15",
        "inclTax": "171.15",
        "img": [
            "/images/products/Queena-final-1.jpg?v=1628486653",
            "/images/products/Queena-final-2.jpg?v=1628486653"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 21cm*28cm"
    },
    {
        "id": 389,
        "name": "Willow",
        "handle": "willow",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Willow-F1.jpg?v=1625012535",
            "/images/products/Willow-F2.jpg?v=1625012536"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 20cm*20cm"
    },
    {
        "id": 390,
        "name": "Kelly",
        "handle": "kelly",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Kelly-1.1.jpg?v=1628486653",
            "/images/products/Kelly-2.jpg?v=1628486654"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 20cm*20cm"
    },
    {
        "id": 391,
        "name": "Georgina",
        "handle": "georgina",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Georgina-f1-min_1.jpg?v=1641319339",
            "/images/products/Georgina-f2-min_1.jpg?v=1641319334"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 21cm*25cm"
    },
    {
        "id": 392,
        "name": "Penelope",
        "handle": "penelope",
        "description": "Introducing our Springtime Tulip Bouquet, a stunning arrangement that captures the essence of fresh, blooming gardens. Featuring a delightful mix of pastel-colored tulips and delicate greenery, this bouquet is beautifully arranged in a chic glass vase, complete with a ribbon bow. Perfect for brightening any room, this elegant display brings a touch of spring to your home or a loved one’s day.",
        "type": "Vase Arrangement",
        "tags": [
            "Tulip Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Penelope-2a-894665.jpg?v=1724338306",
            "/images/products/Penelope-1a-500345.jpg?v=1724338306"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Daisy . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*32cm"
    },
    {
        "id": 393,
        "name": "Ornstein",
        "handle": "ornstein",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [
            "Corporate Hampers"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Ornstein-1.1-min_1.jpg?v=1641309140",
            "/images/products/Ornstein-2.2-min_1.jpg?v=1641309134"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerberra . Roses . Spray Roses . Eustomas . Fillers & Foliage . 20cl Moet Champagne"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 394,
        "name": "Pauline",
        "handle": "pauline",
        "description": "Indulge in the perpetual allure of our bouquet showcasing preserved pastel pink roses and the soft petals of hydrangea. This timeless arrangement is a symbol of everlasting love and admiration, making it a cherished and enduring gift for your special moments.",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Pauline-1g_fa338a82-b2a1-444f-93e6-ce04e87c6a03-130570.jpg?v=1704810097",
            "/images/products/Pauline-2g_16bbaf2f-1656-4ca8-8d87-3d32ec649cd3-946039.jpg?v=1704810097"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 395,
        "name": "Rosaria",
        "handle": "rosaria",
        "description": "\"The emotion that can break your heart is sometimes the very one that heals it.\" - Nicholas Sparks",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "456.17",
        "inclTax": "456.17",
        "img": [
            "/images/products/Rosaria-1a.jpg?v=1662569384",
            "/images/products/Rosaria-2a.jpg?v=1662569384"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 396,
        "name": "Layla",
        "handle": "layla",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Layla-fp11-288020.jpg?v=1671685468",
            "/images/products/Layla-2-997225.jpg?v=1671685469"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Tulips . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*25cm"
    },
    {
        "id": 397,
        "name": "Cora",
        "handle": "cora",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Cora3A-726799.jpg?v=1671685195",
            "/images/products/Cora3B.jpg?v=1666155447"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 21cm*25cm"
    },
    {
        "id": 398,
        "name": "Ellen",
        "handle": "ellen",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/final-form-ellenn1-884532.jpg?v=1671685199",
            "/images/products/Ellen-2-min_1.jpg?v=1641334100"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 21cm*25cm"
    },
    {
        "id": 399,
        "name": "Soraya",
        "handle": "soraya",
        "description": "\"Life is not a matter of holding good cards, but sometimes, playing a poor hand well.\" - Jack London",
        "type": "Bouquets",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Soraya-1-min_1.jpg?v=1641316579",
            "/images/products/Soraya-2-min_1.jpg?v=1641316576"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Dried Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 400,
        "name": "Seraphina",
        "handle": "seraphina",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [
            "Corporate Hampers"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Seraphina-1.1-min_3_1.jpg?v=1641317039",
            "/images/products/Seraphina-2.1-min_3_1.jpg?v=1641317033"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerberra . Roses . Spray Roses . Eustomas . Fillers & Foliage . 20cl Moet Champagne"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 401,
        "name": "Vanessa",
        "handle": "vanessa",
        "description": "This bouquet features striking blue roses and a coordinating blue pompom flower, complemented by white roses, blue tulips and a mix of greenery. The arrangement is wrapped in light blue paper and tied with a white ribbon, creating a cool and elegant presentation. The blue tones give it a serene and unique look, perfect for making a statement.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Vanessa-2a.jpg?v=1721141250",
            "/images/products/Vanessa-1a.jpg?v=1721141250"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Mum . Eustomas . Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 402,
        "name": "Bridal VI",
        "handle": "bridal-set",
        "description": "This beautiful bridal arrangement will come together with a Vase =)",
        "type": "Bouquets",
        "tags": [
            "Wedding Flowers",
            "Engagement Flowers & Bouquets"
        ],
        "price": "684.37",
        "inclTax": "684.37",
        "img": [
            "/images/products/set-b.jpg?v=1628486791",
            "/images/products/WhatsAppImage2021-07-15at2.18.24PM_1_1.jpg?v=1641337340",
            "/images/products/WhatsAppImage2021-07-15at2.18.24PM_2_1.jpg?v=1641337335"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Bridal Bouquet . 2x Small Bridesmaid Bouquet . White Roses . Eustomas . Delphinium . Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 403,
        "name": "Raven",
        "handle": "raven",
        "description": "The Blue Elegance Floral Arrangement features a captivating collection of deep blue roses, accented with sprigs of greenery and baby's breath. Presented in a glass vase tied with a ribbon, this bouquet is ideal for those who appreciate a touch of the extraordinary, bringing a cool and calming beauty to any occasion.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Raven-2a-226230.jpg?v=1724688265",
            "/images/products/Raven1a-467970.jpg?v=1724688265"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Blue Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 32cm*32cm"
    },
    {
        "id": 404,
        "name": "Catarina",
        "handle": "catarina",
        "description": "Embrace the romance with our delicate pastel bouquet. Featuring a beautiful mix of blush pink, soft lavender, and creamy menta roses, accented with pink carnations and lush greenery, this arrangement is wrapped in rustic beige paper. Perfect for expressing love, appreciation, or celebrating any special occasion, it adds a touch of elegance and charm to any setting.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Catarina-2a.jpg?v=1721313701",
            "/images/products/Catarina-1a.jpg?v=1721313700"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnation . Matthiola . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 405,
        "name": "Jennie",
        "handle": "jennie",
        "description": "Celebrate with the timeless elegance of our mixed rose bouquet. Featuring a beautiful blend of red, pink, and white roses, accented with delicate fillers and lush eucalyptus, this arrangement is wrapped in soft pink paper and tied with a satin ribbon. Perfect for expressing love, appreciation, or marking any special occasion, it adds a touch of classic beauty to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Jennie-2a.jpg?v=1721350796",
            "/images/products/Jennie-1a.jpg?v=1721350796"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 406,
        "name": "Nala",
        "handle": "nala",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Nala-FP-10-min_1.jpg?v=1641312949",
            "/images/products/Nala-FP2.jpg?v=1628049763"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Tulips . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*25cm"
    },
    {
        "id": 407,
        "name": "Aurora",
        "handle": "aurora",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Aurora-1.1.jpg?v=1628486978",
            "/images/products/Aurora-2.jpg?v=1628486978"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Tulips . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*25cm"
    },
    {
        "id": 408,
        "name": "Cruella",
        "handle": "cruella",
        "description": "\"There is nothing prettier in this whole wide world as a girl in love with every breath she takes.\" - Atticus",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Cruella-f10-min_1.jpg?v=1641342118",
            "/images/products/Cruella-f20-min_1.jpg?v=1641342110"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Fillers & Foliages"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 409,
        "name": "Fate",
        "handle": "fate",
        "description": "Immerse in the serene beauty of our soft lavender rose bouquet. Featuring delicate lavender and cream roses, complemented by charming daisies and lush greenery, this arrangement is wrapped in elegant pink paper. Perfect for expressing love, gratitude, or celebrating any special occasion with grace and sophistication, it adds a touch of tranquility and elegance to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Fate-2a.jpg?v=1721265139",
            "/images/products/Fate-1a.jpg?v=1721265139"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Daisies . Astilbe . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 410,
        "name": "Cindy",
        "handle": "cindy",
        "description": "The Classic White and Blush Floral Display features a harmonious blend of white roses, soft pink carnations, and ethereal baby’s breath, all set against a backdrop of lush greenery. Presented in a clear vase tied with a ribbon, this arrangement is ideal for adding a touch of understated luxury to any event or space.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Cindy-2a.jpg?v=1724549217",
            "/images/products/Cindy-1a.jpg?v=1724549216"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Spray Roses . Calla Lilies . Matthiola . Carnation . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*32cm"
    },
    {
        "id": 411,
        "name": "Merida",
        "handle": "merida",
        "description": "“A friend is someone who knows all about you and still loves you.” -  Elbert Hubbard",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/16th-A1000-min_1.jpg?v=1641313681",
            "/images/products/WhatsAppImage2021-08-16at2.25.12PM_1.jpg?v=1641313676"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 412,
        "name": "Rapunzel",
        "handle": "rapunzel",
        "description": "Brighten any occasion with our radiant sunflower bouquet. Featuring vibrant sunflowers, delicate white lisianthus, and soft peach roses, this arrangement is accented with lush greenery and wrapped in rustic brown paper. Perfect for expressing joy, gratitude, or love, it brings a touch of sunshine and warmth to any setting.",
        "type": "Bouquets",
        "tags": [
            "Sunflower Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Rapunzel-2a.jpg?v=1721269485",
            "/images/products/Rapunzel-1a.jpg?v=1721269485"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Roses . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 413,
        "name": "Quelaag",
        "handle": "quelaag",
        "description": "\"The emotion that can break your heart is sometimes the very one that heals it.\" - Nicholas Sparks",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "1049.49",
        "inclTax": "1049.49",
        "img": [
            "/images/products/WhatsAppImage2021-08-17at1.22.16PM_2.jpg?v=1641311396",
            "/images/products/WhatsAppImage2021-08-17at1.22.16PM_1_1.jpg?v=1641311389"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangeas . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 45cm*55cm"
    },
    {
        "id": 414,
        "name": "Buffy",
        "handle": "buffy",
        "description": "This charming bouquet features delicate pink roses and tulips, enhanced by soft white lisianthus and dusty miller foliage. Wrapped in light pink paper and adorned with a pink ribbon, this arrangement exudes a sweet and romantic aura, perfect for expressing love and affection on special occasions.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Buffy-2a.jpg?v=1721366150",
            "/images/products/Buffy-1a.jpg?v=1721366150"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustomas . Carnations . Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*36cm"
    },
    {
        "id": 415,
        "name": "Jela",
        "handle": "copy-of-candy",
        "description": "“Life doesn’t get easier or more forgiving, we get stronger and more resilient.” - Steve Maraboli",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/WhatsAppImage2021-09-28at11.14.33AM_1.jpg?v=1641316100",
            "/images/products/WhatsAppImage2021-09-09at2.35.47PM_1_1.jpg?v=1641316094"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Tulip . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 416,
        "name": "Larisa",
        "handle": "larisa",
        "description": "This striking bouquet features rich red roses, pale pink lisianthus, and delicate pink carnations. Accentuated with lush greenery and wrapped in an iridescent, holographic paper, the arrangement is both bold and elegant. Perfect for adding a touch of glamour to any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Larisa-2a.jpg?v=1721367314",
            "/images/products/Larisa-1a.jpg?v=1721367314"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 417,
        "name": "Euphrasia",
        "handle": "euphrasia",
        "description": "Spring Blossom Vase is a beautiful mix of tulips and carnations, featuring shades of pink, red, and yellow. This lively arrangement, set in a green-tinted glass vase and tied with a ribbon, brings a fresh and cheerful vibe to any space, making it a thoughtful gift for loved ones.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Euphrasia-2a-837418.jpg?v=1724599806",
            "/images/products/Euphrasia-1a-781620.jpg?v=1724599806"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Daisy . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*32cm"
    },
    {
        "id": 418,
        "name": "Belladonna",
        "handle": "belladonna",
        "description": "This striking bouquet features vibrant blue roses surrounded by delicate white baby's breath, creating a stunning contrast. Wrapped in elegant pale blue paper, it exudes a sense of calm and serenity. Perfect for special occasions, anniversaries, or to convey a unique and memorable message.",
        "type": "Bouquets",
        "tags": [
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2022-01-07at1.50.19PM_2.jpg?v=1641534711",
            "/images/products/WhatsAppImage2022-01-07at1.50.19PM_3.jpg?v=1641534854"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "9 Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 419,
        "name": "Adelle",
        "handle": "adelle",
        "description": "Embrace Elegance with Our Champagne Spray Rose and Baby's Breath BouquetThis stunning arrangement features soft champagne spray roses surrounded by a cloud of white baby's breath, all elegantly wrapped in pristine white paper with black accents. Perfect for any occasion, this bouquet radiates grace and sophistication.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Adelle-1a.jpg?v=1720882750",
            "/images/products/Adelle-2a.jpg?v=1720882750"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose Spray . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 420,
        "name": "Jillian",
        "handle": "jillian",
        "description": "This charming bouquet features elegant pink tulips, symbolizing love and care. Wrapped in pristine white paper with a delicate pink ribbon, it exudes simplicity and grace. Perfect for birthdays, anniversaries, or to brighten someone's day with a touch of natural beauty and elegance.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/WhatsAppImage2021-08-23at11.30.53.jpg?v=1641315859",
            "/images/products/Adelaide-II-2B.jpg?v=1630301114",
            "/images/products/Jillian---Delivery_1.jpg?v=1716433146",
            "/images/products/Adelaide-II-3B.jpg?v=1716433146"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 421,
        "name": "Aria FBS",
        "handle": "aria",
        "description": "“Life isn't about finding yourself. Life is about creating yourself.”  - George Bernard Shaw",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2021-08-25at1.49.29PM_2.jpg?v=1641355442",
            "/images/products/WhatsAppImage2021-08-25at1.49.29PM_1_1.jpg?v=1641355436"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 422,
        "name": "Alexa",
        "handle": "alexa",
        "description": "This enchanting bouquet features a stunning array of pastel-colored baby's breath, creating a dreamy and whimsical appearance. Wrapped in natural brown paper, it offers rustic charm perfect for any occasion. Ideal for birthdays, anniversaries, or simply to brighten someone's day with a touch of magic.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Alexa-1a.jpg?v=1720533900",
            "/images/products/Alexa-2a.jpg?v=1720533900"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 423,
        "name": "Aniya",
        "handle": "aniya",
        "description": "This bright and cheerful bouquet features vibrant yellow tulips, beautifully arranged with lush greenery. Wrapped in soft yellow paper and tied with a white ribbon, it’s an ideal gift for any occasion, radiating happiness and positivity.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Aniya-1a.jpg?v=1720788475",
            "/images/products/Aniya-2a.jpg?v=1720788475"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Fillers & Foliages"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 424,
        "name": "Anadia",
        "handle": "anadia",
        "description": "Express Affection with Our Elegant Red Roses and Pink Lilies BouquetFeaturing vibrant red roses and delicate pink lilies, accented with lush greenery and wrapped in dusty pink paper. Perfect for expressing deep affection, celebrating special moments, or showing appreciation, it's a classic and romantic choice for any heartfelt occasion.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Lilies Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/c1505210-d40a-4257-8ef0-f6e03b674103-656746.jpg?v=1671684750",
            "/images/products/Anadia-2.jpg?v=1630301115",
            "/images/products/Anadia-3-363818.jpg?v=1671684750"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Lilies . Roses . Fillers & Foliage "
            ]
        },
        "dimention": "Width*Height:30cm*36cm"
    },
    {
        "id": 425,
        "name": "Alaina",
        "handle": "alaina",
        "description": "Immerse Yourself in the Beauty of Simplicity with Our Enchanting Red Rose BouquetFeaturing 6 deep red roses elegantly accompanied by delicate caspia and parvifolia, this bouquet creates a harmonious and sophisticated arrangement. Ideal for expressing love and admiration on various occasions, it blends classic romance with natural elegance.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Alaina-1a.jpg?v=1720778081",
            "/images/products/Alaina-2a.jpg?v=1720778080"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Red Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 426,
        "name": "Adelaide",
        "handle": "adelaide",
        "description": "Brighten Any Occasion with Our Mixed Color Flowers BouquetVibrant hues come together in our Mixed Color Flowers Bouquet, a kaleidoscope of nature's beauty. Roses, tulips, and eustoma in a myriad of colors are elegantly bundled and embraced in fresh green wrapping. This lively arrangement is a perfect gift to brighten any occasion.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Adelaide-2e-911833.jpg?v=1721048737",
            "/images/products/Adelaide-1e.jpg?v=1721013978"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Tulip . Carnations . Eustoma . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 427,
        "name": "Abbey",
        "handle": "abbey",
        "description": "This bouquet features soft pastel menta roses and lisianthus flowers in shades of pink and cream, complemented by delicate greenery. The arrangement is wrapped in light yellow paper and tied with a matching ribbon, creating a gentle and elegant presentation. The pastel tones give it a romantic and soothing look, perfect for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Abbey-2a.jpg?v=1721141655",
            "/images/products/Abbey-1a.jpg?v=1721141655"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses .  Matthiola  .  Eustomas  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 428,
        "name": "Astoria",
        "handle": "astoria",
        "description": "\"Whatever you're fighting for, you go with full passion and love.\" - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-03-23at11.00.47AM_2.jpg?v=1648014733",
            "/images/products/WhatsAppImage2022-03-23at11.00.47AM_1_1.jpg?v=1648014733"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 429,
        "name": "Adeline",
        "handle": "adeline",
        "description": "Express Your Feelings with Our Red and Cappuccino Rose BouquetThis exquisite arrangement features vibrant red roses and delicate cappuccino roses, accented by lush greenery and tiny white daisies. Elegantly wrapped in soft cream paper, it’s perfect for expressing love and appreciation on any occasion.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Adelaide-2a-824407.jpg?v=1721048737",
            "/images/products/Adeline-1a.jpg?v=1721015181"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*36cm"
    },
    {
        "id": 430,
        "name": "Abigail",
        "handle": "abigail",
        "description": "Convey Enchantment with Our Violet Rose BouquetA mesmerizing bouquet featuring seven radiant violet roses, elegantly encased in lace-patterned wrapping. The intricate lace detailing enhances the beauty of the bouquet, adding sophistication to the striking purple blooms. Perfect for making a lasting impression.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Abigail-1aa-628616.jpg?v=1706108684",
            "/images/products/Abigail-2aa-764015.jpg?v=1706108684"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Violet Roses"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 431,
        "name": "Aaliyah",
        "handle": "aaliyah",
        "description": "Embrace Elegance with Our Exquisite Pink Rose BouquetImmerse yourself in the delicate charm of our exquisite bouquet, featuring soft pink roses and lisianthus, accented with sprigs of white waxflowers and dusty miller foliage. Perfect for birthdays, anniversaries, or any celebration of love, this bouquet is a beautiful expression of your heartfelt sentiments.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Aaliyah-2a.jpg?v=1721015735",
            "/images/products/Aaliyah-1a.jpg?v=1721015735"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 432,
        "name": "Adilene",
        "handle": "adilene",
        "description": "Experience Serenity with Our Purple Tulip and White Eustoma BouquetThis stunning arrangement features delicate dyed purple tulips and white eustomas, accented by lush greenery and sprigs of baby's breath. Elegantly wrapped in soft blue paper, this bouquet embodies tranquility and elegance, perfect for any occasion.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "422.17",
        "inclTax": "422.17",
        "img": [
            "/images/products/Adilene-2a.jpg?v=1721792049",
            "/images/products/Adilene-1a.jpg?v=1721792050"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 433,
        "name": "Alexandria FBS",
        "handle": "alexandria",
        "description": "Embrace Unique Elegance with Our Black Preserved Rose Bouquet BagThis captivating arrangement features a striking black rose surrounded by dried hydrangeas, pampas grass, and lush greenery. Presented in a stylish bag with black handles, it exudes sophistication and charm. Perfect for adding a touch of elegance to any setting.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-03-23at10.41.19AM_2.jpg?v=1648003359",
            "/images/products/WhatsAppImage2022-03-23at10.41.19AM_1_1.jpg?v=1648003359"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 434,
        "name": "Alivia FBS",
        "handle": "alivia",
        "description": "Delight in the Charm of Our Pastel Pink Floral BagThis charming arrangement features a delicate pink preserved rose, soft cotton blooms, and an array of pastel-hued flowers, all beautifully presented in a stylish brown bag with black handles. The combination of textures and colors creates a whimsical and elegant display, perfect for any occasion.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Alivia1-565960.jpg?v=1671684750",
            "/images/products/Alivia2-460817.jpg?v=1671684750"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 435,
        "name": "Amada",
        "handle": "amada",
        "description": "Celebrate Elegance with Our Vibrant Vase ArrangementThis delightful vase arrangement features a blend of cheerful champagne and white eustoma, serene peach spray roses, and the delicate charm of white gerberas. Accented with sprigs of greenery and dainty daisies, this bouquet radiates joy and sophistication. Perfect for any occasion, it brings a touch of elegance and happiness to any setting.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Amada-2a-872009.jpg?v=1724687450",
            "/images/products/Amada-1a-124730.jpg?v=1724687450"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerberra . Spray Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 436,
        "name": "Amaze",
        "handle": "amaze",
        "description": "Brighten Any Room with Our Sunny Vase ArrangementThis vibrant vase arrangement features cheerful sunflowers, bright yellow gerberas, and charming orange roses. Accented with lush greenery and delicate blooms, it brings a burst of sunshine and happiness to any setting. Perfect for celebrating special occasions or simply brightening someone's day.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Amaze-2d.jpg?v=1726627730",
            "/images/products/Amaze-1d.jpg?v=1726627730"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera. Sunflowers . Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 437,
        "name": "Andrea",
        "handle": "andrea",
        "description": "Immerse in tranquility with our serene blue hydrangea bouquet. Accented with elegant white tulips and lush greenery, this exquisite arrangement is wrapped in crisp white paper with black trim, creating a sophisticated and calming presentation. Perfect for expressing your deepest sentiments or simply brightening someone's day with a touch of elegance.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Andrea-1a.jpg?v=1721188282",
            "/images/products/Andrea-2a.jpg?v=1721188282"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea . Tulips"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 438,
        "name": "Annalee FBS",
        "handle": "annalee",
        "description": "Delicate carnations and spray roses in various shades of pink harmonize, creating a soft and romantic display. This sophisticated ensemble, presented in a chic bag, is perfect for expressing love, admiration, or celebrating special occasions with a touch of floral splendor.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Annalee-FBS-1a-761185.jpg?v=1720116839",
            "/images/products/Annalee-FBS-2a-126540.jpg?v=1720116839"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Spray Roses & Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 439,
        "name": "Anne",
        "handle": "anne",
        "description": "This elegant vase arrangement features a serene blend of soft blush roses, creamy white blooms, and delicate calla lilies, accented with lush greenery and dainty daisies. The subtle mix of textures and gentle hues creates a bouquet that exudes timeless beauty and sophistication. Perfect for any occasion, it brings a touch of grace and tranquility to any setting.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Anne-2a-257181.jpg?v=1724687449",
            "/images/products/Anne-1a.jpg?v=1724634584"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Spray Roses . Carnation . Eustoma . Calla Lilies . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 440,
        "name": "Ariya",
        "handle": "ariya",
        "description": "A sunbeam in bloom, this bouquet features a bold sunflower amid blushing pink blossoms and variegated carnations. Subtle lavender accents and feathery greenery add to its allure. Wrapped in kraft paper and tied with a pink ribbon boasting a tender message, it’s a heartwarming gesture of joy and affection.",
        "type": "Bouquets",
        "tags": [
            "Get Well Soon Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Ariya-1a.jpg?v=1720749671",
            "/images/products/Ariya-2a.jpg?v=1720749671"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Rose . Eustomas . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 441,
        "name": "Amente",
        "handle": "amente-1",
        "description": "Express Affection with Our Elegant Pink-Tipped Rose and Calla Lily BouquetFeaturing pink-tipped roses and calla lilies, accented with white caspia and lush greenery, all cradled in soft white wrapping paper. Perfect for expressing affection, celebration, or appreciation, it's a classic choice for any heartfelt occasion.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Amente1d-403117.jpg?v=1671684750",
            "/images/products/Amente2d-787439.jpg?v=1671684750"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses . Calla Lily . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 442,
        "name": "Lucinda",
        "handle": "lucinda",
        "description": "Presenting a bouquet that epitomizes elegance and tranquility, featuring a harmonious blend of soft pastel blooms. This arrangement includes delicate lavender roses, vibrant purple tulips, and charming white and yellow daisies, complemented by lush greenery and accent flowers.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Lucinda-2a.jpg?v=1721360156",
            "/images/products/Lucinda-1a.jpg?v=1721360156"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 443,
        "name": "Morgana",
        "handle": "morgana",
        "description": "This Flowers Malaysia bouquet is a romantic composition of blush and deep red roses, accented with soft pink blooms and lush greenery, elegantly tied with a satin ribbon—perfect for expressing heartfelt emotions.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Morgana-2b.jpg?v=1721269208",
            "/images/products/Morgana-1b.jpg?v=1721269208"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Astilbe . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 444,
        "name": "Tremaine",
        "handle": "tremaine",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [
            "Corporate Hampers"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-08-24at12.49.41PM_2.jpg?v=1641303852",
            "/images/products/WhatsAppImage2021-08-24at12.49.41PM_1_1.jpg?v=1641303847"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerberra . Roses . Spray Roses . Eustomas . Fillers & Foliage . 20cl Moet Champagne"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 445,
        "name": "Grimhilde",
        "handle": "grimhilde",
        "description": "Serenity Blue Hydrangea Arrangement brings a sense of tranquility with its lush blue hydrangeas, complemented by elegant white blooms and vibrant greenery. Arranged in a clear vase and finished with a delicate ribbon, this bouquet exudes calm and sophistication, making it a perfect gift for any occasion where peace and comfort are desired.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Grimhilde-2a-507359.jpg?v=1724599866",
            "/images/products/Grimhilde-1a-172210.jpg?v=1724599866"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea . Tulips . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 446,
        "name": "Ingrith",
        "handle": "ingrith",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/ingrith-10.jpg?v=1630301145",
            "/images/products/WhatsAppImage2021-08-25at10.10.44AM_3_1.jpg?v=1641318829"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 8cm*12cm"
    },
    {
        "id": 447,
        "name": "Narissa",
        "handle": "narissa",
        "description": "\"Whatever you're fighting for, you go with full passion and love.\" - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Carnation Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-08-25at11.51.31AM-min_1.jpg?v=1641309973",
            "/images/products/WhatsAppImage2021-08-25at11.36.42AM_4_-min.jpg?v=1641309968"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnations . Eustoma . Gerbera . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*35cm"
    },
    {
        "id": 448,
        "name": "Queenie",
        "handle": "queenie",
        "description": "Indulge in the sheer opulence of our extraordinary bouquet featuring 99 pink-tinted white roses, exquisitely presented in an elegant white wrapping. This grand ensemble radiates sophistication and purity, making it a spectacular gesture for momentous celebrations, grand events, or as an extravagant declaration of love. Each bloom, delicately touched with pink, adds a sublime touch to this breathtaking floral masterpiece, creating a visual symphony of elegance and grace.",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "1140.77",
        "inclTax": "1140.77",
        "img": [
            "/images/products/WhatsAppImage2021-08-27at11.28.29AM_1.jpg?v=1641311408",
            "/images/products/WhatsAppImage2021-08-27at10.47.40AM_1_1.jpg?v=1641311403"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses. "
            ]
        },
        "dimention": "Width*Height: 45cm*55cm"
    },
    {
        "id": 449,
        "name": "Lamia",
        "handle": "lamia",
        "description": "Bask in the radiant beauty of our exquisite basket arrangement featuring two vibrant sunflowers, elegantly complemented by the grace of tulips and the timeless allure of roses. This stunning ensemble captures the essence of joy and sophistication, making it the perfect floral expression for any occasion. Revel in the harmonious blend of nature's finest blooms with this captivating arrangement.",
        "type": "Flower Baskets",
        "tags": [
            "Sunflower Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Lamia-1dd.jpg?v=1715132529",
            "/images/products/Lamia-2dd.jpg?v=1715132529"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Roses . Tulip . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 22cm*28cm"
    },
    {
        "id": 450,
        "name": "Pandora",
        "handle": "pandora",
        "description": "Make a bold statement with our vibrant mixed flower bouquet. Featuring striking red roses, cheerful orange carnations, and delicate alstroemeria, accented with lush greenery and white filler flowers, this arrangement is wrapped in dazzling holographic and black paper. Perfect for birthdays, celebrations, or any special occasion, it brings a touch of glamour and joy to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Pandora-2a.jpg?v=1721312596",
            "/images/products/Pandora-1a-123256.jpg?v=1721397153"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Tulip . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 451,
        "name": "Ravana",
        "handle": "ravana",
        "description": "\"The emotion that can break your heart is sometimes the very one that heals it.\" - Nicholas Sparks",
        "type": "Bouquets",
        "tags": [
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2021-08-30at2.36.23PM_1.jpg?v=1641311036",
            "/images/products/WhatsAppImage2021-08-30at2.30.27PM_1_1.jpg?v=1641311028"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby breathe "
            ]
        },
        "dimention": "Width*Height: 36cm*40cm"
    },
    {
        "id": 452,
        "name": "Empathy (V)",
        "handle": "empathy-v",
        "description": "In heartfelt condolence, our flower tripod stands feature pure white chrysanthemums  gerberas against a dignified black theme. Adorned with black fan decorations, white balloons and a tasteful net dressing, this arrangement provides a respectful tribute, symbolizing compassion and remembrance during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Empathy-_V_-1b.jpg?v=1720105960",
            "/images/products/Condolence-Card-2024_1_84e74d2f-8bf9-4f65-9d66-5af67d5dd173.jpg?v=1720105970",
            "/images/products/Empathy-_V_-2b.jpg?v=1720105959"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Gerberra . Mum . Rose . Artificial Orchid. Carnation. Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 453,
        "name": "Dominique",
        "handle": "dominique",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/WhatsAppImage2021-08-31at1.41.24PM.jpg?v=1641334820",
            "/images/products/WhatsAppImage2021-08-31at1.41.24PM_1.jpg?v=1641334814"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 454,
        "name": "Ainsley",
        "handle": "ainsley",
        "description": "Embrace the Beauty of Spring with Our Serene Blue and Purple Vase ArrangementThis stunning bouquet features lush blue hydrangeas, purple lisianthus, pink roses, and sprigs of thistle, elegantly arranged in a glass vase with a light purple ribbon. Perfect for any occasion, it brings a touch of elegance and serenity to any setting.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Ainsley-2b.jpg?v=1724126267",
            "/images/products/Ainsley-1b-162120.jpg?v=1724157934"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea . Tulips . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 455,
        "name": "Samara",
        "handle": "samara",
        "description": "A beautiful arrangement of vibrant pink tulips, elegantly wrapped in pristine white paper and accented with fresh green foliage. The bright pink ribbon adds a playful touch, making this bouquet a delightful gift for celebrating special moments and spreading joy.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Samara-2a.jpg?v=1721018233",
            "/images/products/Samara-1a.jpg?v=1721018233"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 456,
        "name": "Dana",
        "handle": "dana",
        "description": "Indulge in the enchanting beauty of our pastel floral bouquet. Featuring a harmonious blend of lavender and white roses, delicate purple carnations, and lush greenery, this arrangement is wrapped in dreamy iridescent paper. Perfect for expressing affection, gratitude, or celebrating any special occasion, it adds a touch of whimsy and elegance to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Dana-2a-974510.jpg?v=1721396489",
            "/images/products/Dana-1a-411877.jpg?v=1721396489"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 457,
        "name": "Charlene",
        "handle": "charlene",
        "description": "\"Whatever you're fighting for, you go with full passion and love.\" - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Carnation Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-09-07at11.31.13AM_1.jpg?v=1641336240",
            "/images/products/WhatsAppImage2021-09-07at11.16.46AM_1.jpg?v=1641336235"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Tulips . Carnations . Eustoma . Calla Lily . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 458,
        "name": "Sadie",
        "handle": "sadie",
        "description": "This bouquet features a delightful mix of pink, purple, and lavender blooms, including roses and tulips, complemented by sprigs of greenery. The flowers are wrapped in soft pink paper and tied with a matching ribbon, creating an elegant and charming presentation. ",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Sadie-2a-372389.jpg?v=1721223175",
            "/images/products/Sadie-1a.jpg?v=1721141897"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 459,
        "name": "Luxury (II)",
        "handle": "luxury-ii",
        "description": "Unveil elegance at all grand business openings with this enchanting floral ensemble. Bursting with vibrant colors and lush greenery, it creates a captivating and sophisticated atmosphere, symbolizing success and prosperity for your flourishing venture.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Luxury-_II_-1d.jpg?v=1720493628",
            "/images/products/Grand-Opening-Card-2024_23f214ad-caca-449b-95f4-70e72f8e3f4f.jpg?v=1720493643",
            "/images/products/Luxury-_II_-2d.jpg?v=1720493628"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Brassica . Orchids . Lilies . Red Ginger . Birds of Paradise . Willows . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 460,
        "name": "Empathy (VI)",
        "handle": "empathy-vi",
        "description": "In times of profound sympathy, our condolence flower stands feature serene white and violet-themed chrysanthemums, gracefully complemented by pure white gerberas. This gentle arrangement offers solace, symbolizing compassion and remembrance, providing comfort during moments of deep grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Empathy-_VI_-1d.jpg?v=1720181426",
            "/images/products/Condolence-Card-2024_1_44bb6fbf-0128-4981-bbcb-a3ccb97b4652.jpg?v=1720181440",
            "/images/products/Empathy-_VI_-2d.jpg?v=1720181426"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerberra . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 461,
        "name": "Charon",
        "handle": "charon",
        "description": "\"The best and most beautiful things in this world cannot be seen or even heard, but must be felt with the heart.\" -  Helen Keller",
        "type": "Flower Baskets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-11-17at1.45.22PM_2.jpg?v=1641343579",
            "/images/products/WhatsAppImage2021-11-17at1.45.22PM_1_1.jpg?v=1641343573"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Chamelaucium . Astilbe. Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 462,
        "name": "Carmilla",
        "handle": "carmilla",
        "description": "\"The greatest happiness of life is the conviction that we are loved; loved for ourselves, or rather, loved in spite of ourselves.\" - Victor Hugo",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/WhatsAppImage2021-09-10at12.55.07PM_2.jpg?v=1641336860",
            "/images/products/WhatsAppImage2021-09-10at12.55.07PM_1_1.jpg?v=1641336853"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 463,
        "name": "Morana",
        "handle": "morana",
        "description": "\"Love is totally nonsensical. But we have to keep doing it or else we're lost and love is dead, and humanity should just pack it in. Because love is the best thing we do.\" - Josh Radnor",
        "type": "Bouquets",
        "tags": [
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-09-10at3.18.47PM_2.jpg?v=1641313202",
            "/images/products/WhatsAppImage2021-09-10at3.18.47PM_1_1.jpg?v=1641313195"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Eustoma . Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 464,
        "name": "Lavender FBS",
        "handle": "lavender",
        "description": "A sophisticated arrangement that exudes luxury, with velvety purple roses interwoven with delicate pink blooms and subtle greenery, all contained in a bespoke kraft paper box adorned with a graceful lavender ribbon. This bouquet represents a symphony of elegance and charm.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Lavender-FBS-2b.jpg?v=1721798379",
            "/images/products/Lavender-FBS-1b.jpg?v=1721798379"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 465,
        "name": "Blanca",
        "handle": "blanca",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-09-15at12.00.31PM_2.jpg?v=1641353778",
            "/images/products/WhatsAppImage2021-09-15at12.00.31PM_1_1.jpg?v=1641353772"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Tulips . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*25cm"
    },
    {
        "id": 466,
        "name": "Addison",
        "handle": "addison",
        "description": "Experience Serenity with Our Blue Hydrangea BouquetDive into tranquility with our exquisite Blue Hydrangea Bouquet. This stunning arrangement features lush blue hydrangeas surrounded by delicate white daisies, all elegantly wrapped in matching blue paper. Perfect for any occasion, this bouquet brings a sense of calm and beauty to any setting.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Addison-2a.jpg?v=1720918387",
            "/images/products/Addison-1a.jpg?v=1720918387"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Daisy"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 467,
        "name": "Luxury (III)",
        "handle": "luxury-ii-1",
        "description": "Unleash the Majestic Faux Lily Symphony with our Jumbo Floral Stand. Towering faux pink lilies stand tall, harmonizing with a vibrant array of gerberas, creating a visually opulent and grand centerpiece for all grand business openings.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "684.37",
        "inclTax": "684.37",
        "img": [
            "/images/products/Luxury-_III_-1c.jpg?v=1720524776",
            "/images/products/Grand-Opening-Card-2024_824643d5-39d7-404d-b5b6-33168ed9c126.jpg?v=1720524789",
            "/images/products/Luxury-_III_-2c.jpg?v=1720524776"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Faux Lily . Orchids . Carnations . Golden Phoenix . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 150cm*185cm"
    },
    {
        "id": 468,
        "name": "Magnus",
        "handle": "magnus",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2021-09-20at2.21.31PM_1.jpg?v=1641321493",
            "/images/products/WhatsAppImage2021-09-20at2.26.50PM_1.jpg?v=1641321486"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 21cm*25cm"
    },
    {
        "id": 469,
        "name": "Ayden",
        "handle": "ayden",
        "description": "“Keep love in your heart. A life without it is like a sunless garden when the flowers are dead.” - Oscar Wilde",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers",
            "Sunflower Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/WhatsAppImage2021-09-20at4.06.47PM_3.jpg?v=1641354720",
            "/images/products/WhatsAppImage2021-09-20at4.06.47PM_1_2.jpg?v=1641354714"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Roses . Gerbera . Eustoma . Daisy . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 470,
        "name": "Athena",
        "handle": "athena",
        "description": "This graduation bouquet bursts with a mix of lively purple, soft white, and sunny yellow blooms, including a striking sunflower and elegant roses. At its heart is a plush yellow plushy with a graduation cap, adding a whimsical touch of celebration to the sophisticated arrangement, all nestled within lush greenery for a joyous tribute to achievement.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers",
            "Sunflower Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Athena-2b-681627.jpg?v=1720788381",
            "/images/products/Athena-1b.jpg?v=1720667417"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Roses . Spray Roses . Eustoma . Carnation . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 471,
        "name": "Luxury (IV)",
        "handle": "luxury-iv",
        "description": "Unveil sophistication at all grand openings with our Elegance in Bloom Floral Stand. Featuring a trio of enchanting blooms – pink Gerbera, delicate hot pink Roses, and whimsical orange orchids – this floral masterpiece stands tall, symbolizing grace and prosperity, creating a captivating focal point for all momentous occasions.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Luxury-_IV_-1d-183863.jpg?v=1721487966",
            "/images/products/Grand-Opening-Card-2024_484de493-4f19-4133-9457-32a0987bdfe4.jpg?v=1721487966",
            "/images/products/Luxury-_IV_-2d-255367.jpg?v=1720552464"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Roses . Orchids . Heliconia . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 472,
        "name": "Thalia",
        "handle": "thalia",
        "description": "Brighten any day with our vibrant sunflower and tulip bouquet. Featuring cheerful sunflowers and charming orange tulips, accented with delicate baby's breath, this arrangement is wrapped in elegant beige paper. Perfect for expressing joy, gratitude, or celebrating any special occasion, it brings a burst of sunshine and happiness to any space.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sunflower Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Thalia-2a.jpg?v=1721267465",
            "/images/products/Thalia-1a-409139.jpg?v=1721311226"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Baby Breathes. Tulips. Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 473,
        "name": "Danica",
        "handle": "danica",
        "description": "Bring joy and whimsy with our delightful pastel bunny bouquet. Featuring a charming mix of soft pink roses, creamy yellow blooms, and delicate white flowers, accented with lush greenery and a playful bunny decoration. Wrapped in elegant pink paper, this arrangement is perfect for celebrating special occasions or simply adding a touch of happiness to any day.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Danica-2a.jpg?v=1721264541",
            "/images/products/Danica-1a.jpg?v=1721264541"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnation . Matthiola . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 474,
        "name": "Grecia",
        "handle": "grecia",
        "description": "Discover the vibrant allure of our mixed flower bouquet, featuring an exquisite blend of pink and peach carnations, delicately complemented by fresh green foliage and white accents. This stunning arrangement is elegantly wrapped in soft green paper, making it a perfect choice for bringing joy and beauty to any occasion. Ideal for celebrations, expressions of love, or simply to brighten someone's day, this bouquet is sure to leave a lasting impression with its charming colors and delightful presentation.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Grecia-2a.jpg?v=1721354649",
            "/images/products/Grecia-1a.jpg?v=1721354649"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Oxypetalum  . Astromeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 475,
        "name": "Audrey",
        "handle": "audrey",
        "description": "“Life doesn’t get easier or more forgiving, we get stronger and more resilient.” - Steve Maraboli",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/WhatsAppImage2021-09-29at2.22.14PM_2.jpg?v=1641354949",
            "/images/products/WhatsAppImage2021-09-29at2.22.14PM_1_1.jpg?v=1641354858"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Tulip . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 476,
        "name": "Embry",
        "handle": "embry",
        "description": "The bouquet features a prominent sunflower, surrounded by white eustomas and smaller blooms, accented with eucalyptus leaves, daisies and white caspia. Wrapped in light blue paper and tied with a branded white ribbon, it presents a cheerful and inviting aesthetic.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Embry-1a.jpg?v=1720889515",
            "/images/products/Embry-2a.jpg?v=1720889515"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Roses . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 477,
        "name": "Ariana",
        "handle": "ariana",
        "description": "This charming bouquet features a radiant sunflower at its center, surrounded by elegant white roses and delicate pink carnations. Accented with fresh greenery and wrapped in soft beige paper, it’s a perfect arrangement to brighten any room or occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Ariana-2a.jpg?v=1720841535",
            "/images/products/Ariana-1a.jpg?v=1720841535"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Sunflower  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 478,
        "name": "Selena",
        "handle": "selena",
        "description": "This bouquet features a combination of delicate pink roses and vibrant purple tulips, accented with baby's breath for a soft, romantic look. The flowers are elegantly wrapped in white paper and tied with a pink ribbon, creating a graceful and sophisticated arrangement. ",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Selena-2a.jpg?v=1721142166",
            "/images/products/Selena-1a.jpg?v=1721142166"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Tulips . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 479,
        "name": "Britta",
        "handle": "britta",
        "description": "This beautiful bouquet features a radiant sunflower, surrounded by vibrant red roses, soft pink roses, and delicate greenery. Wrapped in rustic brown paper and tied with a white ribbon, it’s a perfect gift to brighten any occasion with warmth and charm.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Britta-2a_2c8e3b02-1b8b-45b3-ade3-94ff6fe92167-746464.jpg?v=1720875616",
            "/images/products/Britta-1a.jpg?v=1720793158"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnation .  Sunflower  . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 480,
        "name": "Cathie",
        "handle": "cathie",
        "description": "\"Whatever you're fighting for, you go with full passion and love.\" - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Carnation Flowers & Bouquets"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/WhatsAppImage2021-10-01at2.49.54PM.jpg?v=1641336477",
            "/images/products/WhatsAppImage2021-10-01at2.51.50PM.jpg?v=1641336470"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnations . Eustoma . Gerbera . Peony . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*35cm"
    },
    {
        "id": 481,
        "name": "Clarabelle FBS",
        "handle": "clarabelle",
        "description": "This captivating bouquet artfully combines the boldness of a single sunflower with the delicate elegance of ivory roses and vibrant carnations, all tastefully arranged in a stylish kraft paper box and finished with a chic satin ribbon, embodying luxury in bloom.",
        "type": "Bag Series",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets"
        ],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2021-10-01at5.37.48PM_2.jpg?v=1641343195",
            "/images/products/WhatsAppImage2021-10-01at5.37.48PM_1_1.jpg?v=1641343188"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Roses . Spray roses . Eustoma . Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*35cm"
    },
    {
        "id": 482,
        "name": "Avira FBS",
        "handle": "avira",
        "description": "Vivid and enchanting, this floral arrangement bursts with richly hued roses in magenta, sunshine yellow, and deep purple, complemented by soft pink blooms and white baby's breath. Encased in a sleek kraft paper bag with an innovative double hoop handle, it exudes contemporary flair, ideal for a striking gift or a captivating home accent.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Avira-FBS-2a-412754.jpg?v=1721804103",
            "/images/products/Avira-FBS-1a-490279.jpg?v=1721804103"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 483,
        "name": "Ziva FBS",
        "handle": "ziva",
        "description": "This exquisite floral arrangement features a vibrant mix of pink gerbera daisies, carnations, roses, and purple blooms, elegantly displayed in a unique handbag-style container. Perfect for any occasion, this stylish and charming bouquet brings a touch of elegance and freshness to any space, making it an ideal gift or a beautiful addition to your home decor.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Ziva-FBS-2b.jpg?v=1721693811",
            "/images/products/Ziva-FBS-1a.jpg?v=1721693758"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Spray Roses & Carnations . Gerbera . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 484,
        "name": "Faven FBS",
        "handle": "faven",
        "description": "This stunning arrangement features rich red and soft pink roses, accented with delicate white fillers and lush greenery. Presented in an elegant tan handbag with a satin ribbon, it's the perfect way to express your love and appreciation for any special occasion.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Faven1_c31d9207-8037-436a-a1d7-00f097a29629-733080.jpg?v=1675473763",
            "/images/products/Faven2-363603.jpg?v=1675473763"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 485,
        "name": "Mendy",
        "handle": "mendy",
        "description": "Brighten someone's day with our charming tulip bouquet. Featuring an exquisite mix of vibrant pink and pristine white tulips, elegantly arranged with lush greenery and wrapped in delicate pink paper. This delightful arrangement is perfect for expressing love, gratitude, or simply bringing joy to any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Mendy-1a.jpg?v=1721186991",
            "/images/products/Mendy-2a.jpg?v=1721186991"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Parvifolia"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 486,
        "name": "Amelia",
        "handle": "amelia",
        "description": "Embrace Tranquility with Our Purple and White Tulip BouquetThis elegant bouquet features a beautiful mix of purple and white tulips, accented with lush greenery and wrapped in soft lavender paper. The serene color combination creates a peaceful and sophisticated arrangement, perfect for any occasion.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Amelia-1a-423070.jpg?v=1721222347",
            "/images/products/Amelia-2a.jpg?v=1721188949"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Parvifolia"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 487,
        "name": "Princess III",
        "handle": "princess-iii",
        "description": "This bouquet is a poetic composition, where a dome of lavender and periwinkle hydrangeas suggests the subtle transition from spring to summer. The blooms are cradled within a lacework of pure white baby's breath, imparting a dreamy softness.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Princess-III-2a_1.jpg?v=1720745760",
            "/images/products/Princess-III-2a_2.jpg?v=1720745760"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 488,
        "name": "Luxury (V)",
        "handle": "wealth-v-1",
        "description": "Celebrate all business launches with our Pastel Dreamscape Tripod Stand. Adorned with pink and white blooms and a delicate array of pastel-colored flowers, it creates a charming, joy-filled focal point, symbolizing the beginning of a successful and delightful journey.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "661.55",
        "inclTax": "661.55",
        "img": [
            "/images/products/Luxury-_V_-1b.jpg?v=1720064966",
            "/images/products/Grand-Opening-Card-2024_dd7eb0c7-5ccc-44be-a8ef-329e38a85e3b.jpg?v=1720064972",
            "/images/products/Luxury-_V_-2b.jpg?v=1720064965"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnations . Eustoma . Faux Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 489,
        "name": "Everleigh",
        "handle": "everleigh",
        "description": "This whimsical bouquet features enchanting lavender baby's breath, creating a dreamy and ethereal look. Topped with a charming silver tiara, it's perfect for making someone feel like royalty. Wrapped in soft white paper with a delicate lavender ribbon, it's ideal for special occasions or simply to brighten someone's day.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Everleigh-2a.jpg?v=1721018405",
            "/images/products/Everleigh-1a.jpg?v=1721018405"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 490,
        "name": "Esmeralda",
        "handle": "esmeralda",
        "description": "A charming bouquet featuring vibrant orange tulips paired with delicate white daisies, creating a refreshing and lively display. Wrapped in an elegant cream-colored paper and tied with a matching ribbon, this arrangement exudes warmth and cheer, perfect for brightening up any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Esmeralda-2a-255548.jpg?v=1721135940",
            "/images/products/Esmeralda-1a.jpg?v=1721055673"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 18cm*32cm"
    },
    {
        "id": 491,
        "name": "Elektra",
        "handle": "elektra",
        "description": "A vibrant bouquet featuring bold red and soft pink roses, complemented by peach and yellow blooms. The arrangement is accentuated with delicate greenery, wrapped in elegant teal and white paper, and tied with a white ribbon, making it a perfect gift for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Elektra-2a.jpg?v=1721056341",
            "/images/products/Elektra-1a.jpg?v=1721056342"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnation . Matthiola . Astilbe . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 492,
        "name": "Crimson",
        "handle": "crimson",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2021-10-27at10.29.06AM.jpg?v=1641342128",
            "/images/products/WhatsAppImage2021-10-27at10.29.06AM_1.jpg?v=1641342123"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 21cm*25cm"
    },
    {
        "id": 493,
        "name": "Laurie",
        "handle": "laurie",
        "description": "This bouquet features bright yellow tulips and lush green foliage, creating a cheerful and vibrant arrangement. The flowers are wrapped in earthy brown paper, tied with a simple, elegant ribbon. This bouquet exudes a fresh, natural charm, perfect for brightening someone's day or celebrating a joyous occasion with its sunny and uplifting appearance.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Laurie-2a.jpg?v=1721142447",
            "/images/products/Laurie-1a.jpg?v=1721142448"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Holland Tulips"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 494,
        "name": "Evangeline",
        "handle": "evangeline",
        "description": "This delightful bouquet features a mix of yellow tulips, peach carnations, and white roses, creating a harmonious blend of colors. Wrapped in soft lilac paper and accented with a satin ribbon, this arrangement exudes elegance and charm, making it an ideal gift for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Evangeline-1a-934302.jpg?v=1721396676",
            "/images/products/Evangeline-2a.jpg?v=1721361698"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 495,
        "name": "Martha",
        "handle": "martha",
        "description": "Explore the charm of our pastel-themed bouquet, featuring a delightful mix of soft pink and yellow roses, accented with delicate baby's breath and green foliage. This arrangement is thoughtfully wrapped in elegant brown paper, making it a perfect gift for any occasion, whether it's to celebrate a special event or to simply brighten someone's day with its gentle beauty and serene colors.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Martha-2a-957878.jpg?v=1721396909",
            "/images/products/Martha-1a.jpg?v=1721354452"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 496,
        "name": "Devora",
        "handle": "devora",
        "description": "This bouquet is a vision of sweetness, boasting a beautiful blend of pink tulips and white/pink roses. The tulips bring a splash of spring joy, while the roses add a timeless touch of purity and love. Together, they create a lovely contrast of colors and shapes, making this bouquet a delightful gift to celebrate any of life’s special moments.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Newborn Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "342.07",
        "inclTax": "342.07",
        "img": [
            "/images/products/Devora-1b-813159.jpg?v=1712275557",
            "/images/products/Devora-2b-291137.jpg?v=1712275557"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "9 Pink Roses . 9 White Roses . 12 Pink Tulips"
            ]
        },
        "dimention": "Width*Height: 30cm*36cm"
    },
    {
        "id": 497,
        "name": "Renee",
        "handle": "renee",
        "description": "\"Happily ever after is not a fairy tale—it's a choice.\" - Fawn Weaver",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2021-11-02at2.31.13PM_2.jpg?v=1641310807",
            "/images/products/WhatsAppImage2021-11-02at2.31.13PM_1_1.jpg?v=1641310801"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea . Tulips  .  Eustomas . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 498,
        "name": "Pamela",
        "handle": "pamela",
        "description": "A charming bouquet showcasing vibrant orange roses paired with delicate white daisies. Wrapped in soft ivory paper and finished with a matching ribbon, this arrangement exudes a fresh and cheerful elegance, perfect for brightening any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Pamela-2a-812280.jpg?v=1721136345",
            "/images/products/Pamela-1a-834680.jpg?v=1721136345"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Spray Roses  . Daisy .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 499,
        "name": "Barbara",
        "handle": "barbara",
        "description": "The Sunflower and Rose Harmony bouquet combines the bright and bold beauty of sunflowers with the soft elegance of roses, accented by unique billy balls and greenery. Presented in a glass vase, this arrangement is a perfect choice for those who appreciate a blend of rustic charm and sophisticated style, making any occasion feel special.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Barbara-2b-991935.jpg?v=1726743000",
            "/images/products/Barbara-1b-123084.jpg?v=1726743000"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Roses . Mum . Baby's breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 500,
        "name": "Zendaya",
        "handle": "zendaya",
        "description": "“Love is an irresistible desire to be irresistibly desired.” -  Robert Frost",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2021-11-19at10.11.20AM_3.jpg?v=1641302410",
            "/images/products/WhatsAppImage2021-11-18at2.28.22PM_3_1_1.jpg?v=1641302401"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Tulip .  Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 501,
        "name": "Mikasa",
        "handle": "mikasa",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-11-23at11.24.48AM_2.jpg?v=1641313454",
            "/images/products/WhatsAppImage2021-11-23at11.24.48AM_1_1.jpg?v=1641313447"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Tulips . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*25cm"
    },
    {
        "id": 502,
        "name": "Erina",
        "handle": "erina",
        "description": "“Life doesn’t get easier or more forgiving, we get stronger and more resilient.” - Steve Maraboli",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/WhatsAppImage2021-11-24at2.27.22PM_2.jpg?v=1641323648",
            "/images/products/WhatsAppImage2021-11-24at2.27.22PM_1_1.jpg?v=1641323641"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Tulip . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 503,
        "name": "Yuna",
        "handle": "yuna",
        "description": "Exude elegance with our enchanting lily bouquet. This stunning arrangement features pristine white lilies surrounded by delicate pink baby's breath and lush greenery. Wrapped in soft pink paper and tied with a satin ribbon, it is perfect for expressing sympathy, celebrating a special occasion, or simply bringing a touch of serenity and beauty to any space.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Lilies Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Yuna-2a.jpg?v=1721215076",
            "/images/products/Yuna-1a.jpg?v=1721215076"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Lilies . Baby's Breath . Fillers"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 504,
        "name": "Hattie",
        "handle": "hattie",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/WhatsAppImage2021-11-27at12.12.35PM_2.jpg?v=1641326654",
            "/images/products/WhatsAppImage2021-11-27at12.12.35PM_1_1.jpg?v=1641326648"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 15cm*25cm"
    },
    {
        "id": 505,
        "name": "Harriet",
        "handle": "harriet",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/WhatsAppImage2021-11-28at10.32.26AM_1.jpg?v=1641326750",
            "/images/products/WhatsAppImage2021-11-28at10.34.10AM_1.jpg?v=1641326660"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 15cm*25cm"
    },
    {
        "id": 506,
        "name": "Gabby",
        "handle": "gabby",
        "description": "A delightful bouquet featuring soft pink gerbera daisies, complemented by sprigs of greenery and tiny pink blooms. The arrangement is elegantly wrapped in a shimmering iridescent and soft pink paper, tied together with a matching pink ribbon, creating a charming and cheerful presentation that radiates joy and beauty.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Gabby-2a.jpg?v=1721056857",
            "/images/products/Gabby-1a.jpg?v=1721056857"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 507,
        "name": "Rudolph",
        "handle": "rudolph",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/WhatsAppImage2021-12-01at1.12.52PM_3.jpg?v=1641310451",
            "/images/products/WhatsAppImage2021-12-01at1.12.52PM_1_2.jpg?v=1641310437"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 8cm*12cm"
    },
    {
        "id": 508,
        "name": "Carol",
        "handle": "carol",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/WhatsAppImage2021-12-01at1.13.00PM_2.jpg?v=1641336621",
            "/images/products/WhatsAppImage2021-12-01at1.13.00PM_1_1.jpg?v=1641336614"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 8cm*12cm"
    },
    {
        "id": 509,
        "name": "Belle",
        "handle": "belle",
        "description": "This charming bouquet features a lush arrangement of delicate white chamomile flowers, radiating simplicity and elegance. Wrapped in soft white paper and tied with a gold ribbon, it's a perfect gift for any occasion, offering natural beauty and grace.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Belle-2a-995904.jpg?v=1720788383",
            "/images/products/Belle-1a-755013.jpg?v=1720788383"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Daisy . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 18cm*30cm"
    },
    {
        "id": 510,
        "name": "Serena",
        "handle": "serena",
        "description": "\"The best and most beautiful things in this world cannot be seen or even heard, but must be felt with the heart.\" -  Helen Keller",
        "type": "Flower Boxes",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-12-08at2.00.32PM_2.jpg?v=1641316939",
            "/images/products/WhatsAppImage2021-12-08at2.00.32PM_1_1.jpg?v=1641316933"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Chamelaucium . Astilbe. Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 511,
        "name": "Michelle",
        "handle": "michelle",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-12-10at10.00.23AM.jpg?v=1641313547",
            "/images/products/WhatsAppImage2021-12-10at10.00.23AM_1.jpg?v=1641313458"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 21cm*25cm"
    },
    {
        "id": 512,
        "name": "Queen I",
        "handle": "queen-i",
        "description": "This stunning bouquet features vibrant pink tulips paired with delicate light pink blooms, creating a harmonious and visually appealing arrangement. The flowers are accentuated by lush green foliage and wrapped in iridescent, shimmering paper, adding a touch of elegance and sophistication.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Queen-I-2a.jpg?v=1721142688",
            "/images/products/Queen-I-1a-780489.jpg?v=1721223117"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Spray Roses . Parvifolia"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 513,
        "name": "Queen II",
        "handle": "queen-ii",
        "description": "This bouquet showcases bold and beautiful red tulips, elegantly arranged with lush green foliage. The vibrant flowers are wrapped in delicate, iridescent pink paper, adding a touch of sparkle and sophistication. ",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Queen-II-2a.jpg?v=1721143726",
            "/images/products/Queen-II-1a.jpg?v=1721143725"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Spray Roses . Parvifolia"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 514,
        "name": "Queen III",
        "handle": "queen-iii",
        "description": "This bouquet features a delightful combination of bright yellow tulips and soft peach roses, creating a vibrant and charming display. The flowers are beautifully wrapped in iridescent paper that adds a subtle shimmer, enhancing the overall elegance.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Queen-III-2b.jpg?v=1721144631",
            "/images/products/Queen-III-1b.jpg?v=1721144630"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Spray Roses . Parvifolia"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 515,
        "name": "Queen IV",
        "handle": "queen-iv",
        "description": "This striking bouquet features vibrant orange tulips and roses, creating a bold and lively display. The flowers are arranged with lush greenery, adding a fresh and natural touch. The bouquet is wrapped in iridescent and dark paper, giving it a modern and sophisticated look.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Queen-IV-2a.jpg?v=1721144878",
            "/images/products/Queen-IV-1a.jpg?v=1721144878"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Spray Roses . Parvifolia"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 516,
        "name": "Queen V",
        "handle": "queen-v",
        "description": "This bouquet features vibrant purple tulips paired with delicate white roses, wrapped in iridescent and pastel paper, tied with a white ribbon. The lush greenery adds a fresh touch, creating a modern and elegant arrangement perfect for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Queen-V-2a.jpg?v=1721145122",
            "/images/products/Queen-V-1a.jpg?v=1721145122"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Spray Roses . Parvifolia"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 517,
        "name": "Stacy",
        "handle": "stacy",
        "description": "This enchanting bouquet combines soft pastel roses with delicate baby's breath, creating a dreamy and romantic arrangement. Wrapped in elegant cream paper and adorned with a satin ribbon, it’s an ideal gift for celebrating love, friendship, or any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Stacy-2a.jpg?v=1721362101",
            "/images/products/Stacy-1a.jpg?v=1721362101"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Hydrangea . Carnation . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 518,
        "name": "Fondness V",
        "handle": "fondness-v",
        "description": "Elevate the atmosphere with the pure and timeless beauty of our bouquet, showcasing six pristine white roses tenderly surrounded by the delicate grace of baby's breath. This enchanting arrangement exudes elegance and purity, making it an ideal choice for expressing heartfelt sentiments on any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fondness-V-2b.jpg?v=1720958457",
            "/images/products/Fondness-V-1b.jpg?v=1720958457"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 519,
        "name": "Fondness VI",
        "handle": "fondness-vi",
        "description": "Indulge in the subtle elegance of our exquisite bouquet featuring six champagne roses, gracefully surrounded by the delicate clusters of baby's breath. This enchanting arrangement exudes sophistication and charm, making it a perfect choice for expressing refined sentiments and creating moments of timeless beauty. ",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fondness-VI-2b.jpg?v=1720956831",
            "/images/products/Fondness-VI-1b.jpg?v=1720956831"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 520,
        "name": "Fondness VII",
        "handle": "fondness-vii",
        "description": "Dive into the vibrancy of love with our captivating bouquet featuring six striking hot pink roses, delicately embraced by the soft, cloud-like beauty of baby's breath. This enchanting ensemble exudes energy and charm, making it the perfect expression of passion and affection for your cherished moments. ",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fondness-VI-2c.jpg?v=1720958128",
            "/images/products/Fondness-VII-1c.jpg?v=1720958128"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 521,
        "name": "Minnie",
        "handle": "minnie",
        "description": "Immerse yourself in the captivating beauty of our bouquet featuring a harmonious blend of red and purple roses, adorned with the whimsical charm of rainbow baby's breath. The rich red and regal purple hues create a striking visual contrast, while the vibrant baby's breath adds an enchanting touch. Perfect for expressing love, admiration, or celebrating special occasions, this bouquet is a vibrant and elegant symbol of affection.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "456.17",
        "inclTax": "456.17",
        "img": [
            "/images/products/WhatsAppImage2021-12-23at2.28.30PM_2.jpg?v=1641313323",
            "/images/products/WhatsAppImage2021-12-23at2.28.30PM_1_1.jpg?v=1641313316"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Rainbow Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 32cm*40cm"
    },
    {
        "id": 522,
        "name": "Jolyne",
        "handle": "jolyne",
        "description": "This charming preserved arrangement showcases soft blue roses, delicate cotton blooms, and ivory hydrangeas, artfully complemented by dried fillers and baby’s breath. Displayed in a petite glass jar and adorned with a satin ribbon, it offers a timeless touch of elegance. Ideal for adding a calming, everlasting beauty to any space or special occasion.",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Jolyne-2a.jpg?v=1727140775",
            "/images/products/Jolyne-1a.jpg?v=1727140775"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 20cm*20cm"
    },
    {
        "id": 523,
        "name": "Suzi",
        "handle": "suzi",
        "description": "This enchanting preserved arrangement features soft pink roses, delicate cotton blooms, and elegant hydrangeas, complemented by subtle dried accents in shades of cream and blush. Housed in a glass jar and tied with a pink satin ribbon, it exudes a romantic and timeless charm. Perfect for adding a touch of gentle elegance to any room or occasion.",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Suzi-2a.jpg?v=1727142097",
            "/images/products/Suzi-1a.jpg?v=1727142098"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 20cm*20cm"
    },
    {
        "id": 524,
        "name": "Ermes",
        "handle": "ermes",
        "description": "This delicate preserved arrangement features soft beige roses, fluffy cotton blooms, and neutral-toned dried flowers, creating a serene and timeless aesthetic. Nestled in a clear glass jar and accented with a subtle white ribbon, it exudes an air of calm sophistication. A perfect choice for those seeking an everlasting touch of natural elegance in their space.",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Ermes-2a.jpg?v=1727141686",
            "/images/products/Ermes-1a.jpg?v=1727141686"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 20cm*20cm"
    },
    {
        "id": 525,
        "name": "Lisa",
        "handle": "lisa",
        "description": "This enchanting preserved arrangement features soft pink roses, delicate cotton blooms, and elegant hydrangeas, complemented by subtle dried accents in shades of cream and blush. Housed in a glass jar and tied with a pink satin ribbon, it exudes a romantic and timeless charm. Perfect for adding a touch of gentle elegance to any room or occasion.",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Lisa-2a.jpg?v=1727141883",
            "/images/products/Lisa-1a.jpg?v=1727141883"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 20cm*20cm"
    },
    {
        "id": 526,
        "name": "Trish",
        "handle": "trish",
        "description": "“Many of life’s failures are people who did not realize how close they were to success when they gave up.” – Thomas A. Edison",
        "type": "Bouquets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-01-04at11.05.09AM_2.jpg?v=1641303840",
            "/images/products/WhatsAppImage2022-01-04at11.05.09AM_1_1.jpg?v=1641303834"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 527,
        "name": "Nena",
        "handle": "nena",
        "description": "Experience the everlasting allure of our preserved red and pink roses intertwined with delicate gossypium in this enchanting bouquet. Meticulously preserved, these blooms retain their natural beauty indefinitely, symbolizing enduring love and timeless elegance. ",
        "type": "Bouquets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Nena-1aa_6b5ab146-a350-4999-b888-f701dbb577b9.jpg?v=1704334063",
            "/images/products/Nena-2aa_a1166a21-f646-4210-b54f-54f9c6b78794.jpg?v=1704334064"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 528,
        "name": "Gloria",
        "handle": "gloria",
        "description": "A serene Flowers Malaysia arrangement, this bouquet combines creamy roses and soft cotton sprigs with subtle greenery, wrapped in a gentle yellow paper and ribbon, invoking a soothing, dreamy atmosphere.",
        "type": "Bouquets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-01-04at11.05.27AM_2.jpg?v=1641319201",
            "/images/products/WhatsAppImage2022-01-04at11.05.27AM_1_1.jpg?v=1641319195"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 529,
        "name": "Sugar",
        "handle": "sugar",
        "description": "Immerse yourself in the perpetual joy of our everlasting preserved rainbow rose and rainbow gossypium bouquet. This vibrant arrangement captures the spectrum of colors, meticulously preserved to maintain its vivid beauty indefinitely. ",
        "type": "Bouquets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Sugar-1bb_e901f550-09ce-4a40-b613-b0c8bb8f6815.jpg?v=1704724654",
            "/images/products/Sugar-2bb_3b1f2fe0-534b-45dc-8699-9ec9585888c9.jpg?v=1704724654"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 530,
        "name": "Irene",
        "handle": "irene",
        "description": "This elegant bouquet features a delicate blend of soft pink roses, beautifully accented with lush greenery and caspias. Wrapped in pastel pink paper and tied with a ribbon, it’s a perfect gift for expressing love and appreciation on any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Irene-2a.jpg?v=1720747298",
            "/images/products/Irene-1a.jpg?v=1720747298"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 531,
        "name": "Donatella",
        "handle": "donatella",
        "description": "This beautiful bouquet features a stunning mix of red and pink roses, elegantly complemented by delicate white and pink flowers and lush greenery. The arrangement is wrapped in a soft white and pink paper, tied together with a pastel pink ribbon, creating a romantic and sophisticated presentation that exudes elegance and charm.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Donatella-2a.jpg?v=1721057421",
            "/images/products/Donatella-1a-699430.jpg?v=1721135914"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 532,
        "name": "Huat I",
        "handle": "huat-i",
        "description": "Graceful Phalaenopsis orchid, adorned with delicate, butterfly-like blooms, elegantly cradled in a resplendent pink-clothed pot. This symbol of beauty and refinement, set against the opulence of pink, creates a perfect centerpiece for Chinese New Year celebrations, bringing prosperity, good fortune, and a touch of natural elegance to your festive decor.",
        "type": "Bouquets",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Huat-I-1a-277635.jpg?v=1705155937",
            "/images/products/Huat-I-2a-148008.jpg?v=1705155937"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Phalaenopsis orchid"
            ]
        },
        "dimention": "Width*Height: 15cm*35cm"
    },
    {
        "id": 533,
        "name": "Huat II",
        "handle": "huat-ii",
        "description": "Exquisite Phalaenopsis orchid, a symbol of beauty and refinement, graces an elegant pot, adding a touch of sophistication to your Chinese New Year celebrations. The lush blooms and sleek design create a harmonious centerpiece, symbolizing prosperity and good fortune as you welcome the Lunar New Year with style and grace.",
        "type": "Bouquets",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Huat-II-1a-131663.jpg?v=1705155937",
            "/images/products/Huat-II-2a-819203.jpg?v=1705155937"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Phalaenopsis orchid"
            ]
        },
        "dimention": "Width*Height: 15cm*35cm"
    },
    {
        "id": 534,
        "name": "Huat III",
        "handle": "huat-iii",
        "description": "Celebrate Chinese New Year with a stunning floral arrangement featuring vibrant chrysanthemums and carnations, symbolizing longevity and good luck. Graceful pussy willows enhance the display, signifying growth and resilience. A golden fan adds an elegant touch, representing prosperity. This arrangement brings a harmonious blend of tradition and beauty to your festive decor.",
        "type": "Bouquets",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Huat-III-1b-425360.jpg?v=1705155938",
            "/images/products/Huat-III-2b-736416.jpg?v=1705155938"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Carnations . Pussy Willows . Golden Fan"
            ]
        },
        "dimention": "Width*Height: 15cm*35cm"
    },
    {
        "id": 535,
        "name": "Huat IV",
        "handle": "huat-iv",
        "description": "Celebrate Chinese New Year with a stunning floral arrangement featuring vibrant chrysanthemums and carnations, symbolizing longevity and good luck. Graceful pussy willows enhance the display, signifying growth and resilience. A golden fan adds an elegant touch, representing prosperity. This arrangement brings a harmonious blend of tradition and beauty to your festive decor.",
        "type": "Bouquets",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Huat-IV-1a-329496.jpg?v=1705155937",
            "/images/products/Huat-IV-2a-532205.jpg?v=1705155937"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Carnations . Pussy Willows . Golden Fan"
            ]
        },
        "dimention": "Width*Height: 15cm*35cm"
    },
    {
        "id": 536,
        "name": "Huat V",
        "handle": "huat-v",
        "description": "Celebrate Chinese New Year with our arrangement symbolizing everlasting prosperity. Rich reds and radiant golds blend in a harmonious dance, adorned with auspicious accents to bring good fortune to your home.",
        "type": "Bouquets",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Huat-_V_-1b-497304.jpg?v=1706716479",
            "/images/products/Huat-_V_-2b-588528.jpg?v=1706716479"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Pussy Willow . Preserved Hydrangea"
            ]
        },
        "dimention": "Width*Height: 15cm*40cm"
    },
    {
        "id": 537,
        "name": "Sweetheart",
        "handle": "sweetheart",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-01-08at12.02.52PM_2.jpg?v=1641881635",
            "/images/products/WhatsAppImage2022-01-08at12.02.52PM_1_1.jpg?v=1641881629"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 12 Roses . & Babybreath "
            ]
        },
        "dimention": "Width*Height: 15cm*25cm"
    },
    {
        "id": 538,
        "name": "99 Roses",
        "handle": "99-roses",
        "description": "Bask in the Grandeur of Our Extravagant 99 Red Roses BouquetA breathtaking arrangement of 99 deep red roses encircled by delicate white baby's breath. Ideal for grand celebrations, anniversaries, or as a lavish expression of love, this bouquet is a stunning representation of timeless beauty and romance.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [],
        "price": "912.57",
        "inclTax": "912.57",
        "img": [
            "/images/products/99-Roses-With-Love-156077.jpg?v=1671684749"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "99 Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 50cm*50cm"
    },
    {
        "id": 539,
        "name": "Elva",
        "handle": "elva",
        "description": "Celebrate with elegance using our stunning hydrangea and rose bouquet. Featuring a gorgeous pink hydrangea at its center, surrounded by soft yellow and blush roses, delicate carnations, and lush greenery, this arrangement is wrapped in beautiful pink paper. Perfect for birthdays, anniversaries, or any special occasion, it adds a touch of grace and charm to any setting.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Elva-2a.jpg?v=1721271101",
            "/images/products/Elva-1a.jpg?v=1721271101"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Eustoma . Spray Roses. Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 540,
        "name": "Ronda",
        "handle": "ronda",
        "description": "“And now these three remain: faith, hope and love. But the greatest of these is love.” -  Anonymous",
        "type": "Flower Baskets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-03-04at11.27.13AM_1_8a9abe77-b647-4858-b83e-4fc30ef4a66c.jpg?v=1646364490",
            "/images/products/WhatsAppImage2022-03-04at11.16.44AM_1_1.jpg?v=1646364490"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses . Carnations . Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 541,
        "name": "Mikaela",
        "handle": "mikaela",
        "description": "“And now these three remain: faith, hope and love. But the greatest of these is love.” -  Anonymous",
        "type": "Flower Baskets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-03-04at11.25.41AM_1.jpg?v=1646364367",
            "/images/products/WhatsAppImage2022-03-04at11.16.44AM_2.jpg?v=1646364367"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses . Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 542,
        "name": "Natasha BBS",
        "handle": "natasha",
        "description": "This lovely floral arrangement is elegantly presented in a chic pink box, adorned with a vibrant pink ribbon. It features a striking combination of a bold red rose, soft pink roses, and delicate purple flowers, surrounded by lush greenery. Perfect for gifting, this arrangement adds a touch of sophistication and charm to any setting.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Natasha-BBS-2a-551465.jpg?v=1721658056",
            "/images/products/Natasha-BBS-1a-326569.jpg?v=1721658056"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses . Spray Roses . Matthiola .Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 543,
        "name": "MacKenzie",
        "handle": "mackenzie",
        "description": "Experience the vibrant beauty of our multi-color tulips collection. Bursting with a kaleidoscope of hues, these tulips add a lively touch to any occasion. Each stem is a masterpiece, creating a stunning bouquet that symbolizes joy, diversity, and the wonders of nature. Elevate your floral experience with our mesmerizing tulips.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Newborn Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2022-03-04at1.24.37PM_2.jpg?v=1646373068",
            "/images/products/WhatsAppImage2022-03-04at1.24.37PM_1_1.jpg?v=1646373068"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*36cm"
    },
    {
        "id": 544,
        "name": "Prietio",
        "handle": "prietio",
        "description": "This bouquet showcases a vibrant arrangement of bright yellow tulips, each flower standing tall and exuding a cheerful energy. The tulips are beautifully wrapped in a crisp white paper, accentuated with a matching yellow ribbon, creating a clean and fresh presentation that radiates positivity and joy.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Prietio-2a-342712.jpg?v=1721136345",
            "/images/products/Prietio-1a.jpg?v=1721057662"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 545,
        "name": "Rousey",
        "handle": "rousey",
        "description": "This charming bouquet combines the beauty of pink gerberas, white eustomas, and a variety of other fresh blooms. Wrapped in delicate white paper, it exudes a soft and elegant vibe, perfect for any special occasion. The harmonious mix of colors and flowers makes it a delightful gift for expressing warmth and affection.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Rousey-2a.jpg?v=1721361473",
            "/images/products/Rousey-1a.jpg?v=1721361474"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera .Spray Roses. Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 546,
        "name": "Sonja",
        "handle": "sonja",
        "description": "This cheerful bouquet features a mix of colorful blooms, including red tulips, yellow carnations, orange roses, and purple flowers, complemented by delicate greenery and white daisies. Wrapped in soft pink and green paper and tied with a white ribbon, it brings a vibrant touch to any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Sonja-2a.jpg?v=1721145542",
            "/images/products/Sonja-1a.jpg?v=1721145542"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Tulip . Carnations. Daisy. Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 547,
        "name": "Marion",
        "handle": "marion",
        "description": "This delightful bouquet features a mix of vibrant yellow, soft pink, and bold red blooms, including chrysanthemums, tulips, and roses. Accented with delicate greenery and wrapped in a pastel purple paper, it brings a cheerful and fresh touch to any setting, perfect for celebrating joyous occasions.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Marion-2a.jpg?v=1721367489",
            "/images/products/Marion-1a.jpg?v=1721367489"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera .Spray Roses. Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 548,
        "name": "Peggy",
        "handle": "peggy",
        "description": "This cheerful bouquet features a vibrant sunflower, bright yellow tulips, and elegant white roses, accented with lush greenery and delicate fillers. Wrapped in soft pink and beige paper and tied with a ribbon, it's a perfect gift to brighten any day.",
        "type": "Bouquets",
        "tags": [
            "Get Well Soon Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Peggy-2a.jpg?v=1720780669",
            "/images/products/Peggy-1a.jpg?v=1720780669"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Rose . Eustomas . Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 549,
        "name": "Billyn",
        "handle": "billyn",
        "description": "This bouquet is a vibrant and colorful arrangement featuring an assortment of flowers. The bright yellow roses, purple tulips, pink stock, and small white daisies create a lively and cheerful display. The bouquet is wrapped in a delicate lavender paper with matching ribbons, adding to its elegant and festive appearance.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Billyn-2a.jpg?v=1721058175",
            "/images/products/Billyn-1a.jpg?v=1721058175"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Tulip . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 550,
        "name": "Claudia FBS",
        "handle": "claudia",
        "description": "This striking arrangement features bold fuchsia roses surrounded by delicate baby's breath, creating a stunning contrast. Presented in a chic tan handbag with a matching fuchsia ribbon, it’s the perfect gift to make a lasting impression and bring joy to any occasion.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Claudia-FBS-2b.jpg?v=1721694283",
            "/images/products/Claudia-FBS-1b.jpg?v=1721694283"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 551,
        "name": "Corin FBS",
        "handle": "corin",
        "description": "“Life isn't about finding yourself. Life is about creating yourself.”  - George Bernard Shaw",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-03-17at2.42.46PM.jpg?v=1647499483",
            "/images/products/WhatsAppImage2022-03-17at2.42.46PM_1.jpg?v=1647499483"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 552,
        "name": "Sympathy (VII)",
        "handle": "sympathy-vii",
        "description": "In a grand expression of sympathy, our jumbo condolence flower stands showcase a lavish arrangement of pure white and yellow chrysanthemums, blue faux hydrangeas and delicate lilies. This opulent ensemble provides a dignified and comforting tribute, symbolizing compassion and remembrance during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "682.32",
        "inclTax": "682.32",
        "img": [
            "/images/products/Sympathy-_VII_-2c.jpg?v=1727186307",
            "/images/products/Condolence-Card-2024_1_0a9d2e95-ea3f-4344-8876-79bcb47d2631.jpg?v=1720190387",
            "/images/products/Sympathy-_VII_-1c.jpg?v=1727186308"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerberas . Chrysanthemum. Lilies . Artificial flowers. Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 553,
        "name": "Miranda",
        "handle": "miranda",
        "description": "Embrace the tranquility of our lavender and white bouquet. This beautiful arrangement features soft lavender roses, pristine white blooms, and delicate purple accents, complemented by lush greenery. Wrapped in elegant white paper and tied with a satin ribbon, it is perfect for weddings, anniversaries, or any occasion where you want to convey serenity and grace.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Miranda-2a.jpg?v=1721225048",
            "/images/products/Miranda-1a.jpg?v=1721225048"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 554,
        "name": "Nariya",
        "handle": "nariya",
        "description": "“And now these three remain: faith, hope and love. But the greatest of these is love.” -  Anonymous",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-03-23at2.19.08PM_1.jpg?v=1648016392",
            "/images/products/WhatsAppImage2022-03-23at9.53.48AM_3_1.jpg?v=1648016392"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses . Gerbera . Eustoma  . .Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 555,
        "name": "Tyrian FBS",
        "handle": "tyrian",
        "description": "This bag series showcases a delicate lavender rose, surrounded by soft purple hydrangeas and lush foliage. The floral ensemble is accentuated by two playful cotton puffs, adding a whimsical touch to this sophisticated design.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Tyrian-FBS-2a.jpg?v=1723683220",
            "/images/products/Tyrian-FBS-1a.jpg?v=1723683220"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Carnation . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 556,
        "name": "Fortune (II)",
        "handle": "fortune-ii",
        "description": "Experience the allure of Scarlet Harmony with our grand opening tripod stand. The fiery elegance of ginger flowers, rich red chrysanthemums, and striking red Anthurium unite in a triumphant display, creating a stunning focal point that embodies passion, prosperity, and the promise of success.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Fortune-_II_-1d.jpg?v=1720093479",
            "/images/products/Grand-Opening-Card-2024_834cb499-9555-4478-a829-f99008eda531.jpg?v=1720093484",
            "/images/products/Fortune-_II_-sd.jpg?v=1720093479"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Mum . Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 557,
        "name": "Empathy (VII)",
        "handle": "empathy-vii",
        "description": "Expressing sympathy with elegance, our condolence flower stands showcase serene white chrysanthemums and pure white eustomas, adorned in a tasteful array of white and blue-themed skirtings. This graceful arrangement offers solace and a dignified tribute during moments of grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Empathy-_VII_-1d.jpg?v=1720189542",
            "/images/products/Condolence-Card-2024_1_29489b1c-ca03-4e34-a801-4af1cbed9be5.jpg?v=1720189553",
            "/images/products/Empathy-_VII_-2d.jpg?v=1720189541"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Mum. Eustoma . Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 100cm*180cm"
    },
    {
        "id": 558,
        "name": "Arica",
        "handle": "arica",
        "description": "This charming bouquet features a bright sunflower, surrounded by delicate chamomile and baby's breath, accented with a graduation teddy bear. Wrapped in cheerful checkered paper and tied with a ribbon, it's a delightful gift for celebrating academic achievements.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Arica-2a-288522.jpg?v=1720788381",
            "/images/products/Arica-1a-848508.jpg?v=1720788381"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Daisy . Baby breath . Fillers & Foliage "
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 559,
        "name": "Allyssa",
        "handle": "allyssa",
        "description": "Celebrate Success with Our Graduation Floral BouquetThis bouquet blends the cheer of a bright sunflower with the tranquility of blue hydrangeas and the delicate charm of champagne spray roses. A plush teddy bear in a graduation cap peeks above, symbolizing pride in one's academic achievements. Arranged amid lush greenery and subtle daisy flowers, it forms a vibrant celebration of success.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Allyssa-2b-879328.jpg?v=1720788379",
            "/images/products/Allyssa-1b-562499.jpg?v=1720788379"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Daisy . Hydrangea. Spray Roses. Fillers & Foliage "
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 560,
        "name": "Hillion",
        "handle": "hillion",
        "description": "“Loving people live in a loving world. Hostile people live in a hostile world. Same world.” - Wayne Dyer",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2022-04-10at12.20.42PM_2.jpg?v=1649564692",
            "/images/products/WhatsAppImage2022-04-10at12.20.42PM_1_1.jpg?v=1649564692"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Eustoma . Matthiola . Silver Brunia . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 18cm*30cm"
    },
    {
        "id": 561,
        "name": "Judy BBS",
        "handle": "judy",
        "description": "This luxurious bloom box by Flowers Malaysia is a tapestry of floral elegance, featuring lilac-hued roses, majestic purple blossoms, and a striking pink lily. The ensemble sits in a stylish cream box with a handle, adorned with a lavender satin ribbon, perfect for any grand occasion.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Judy-BBS-2c.jpg?v=1721615574",
            "/images/products/Judy-BBS-1c.jpg?v=1721615574"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Lily . Rose . Spray Roses . Carnations . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 22cm*30cm"
    },
    {
        "id": 563,
        "name": "Easter",
        "handle": "easter",
        "description": "Celebrate with the vibrant charm of our tulip bouquet. Featuring a delightful mix of pink and purple tulips, accented with whimsical decorative balls, this arrangement is wrapped in elegant white paper and tied with a pink satin ribbon. Perfect for birthdays, anniversaries, or any joyful occasion, it brings a burst of color and happiness to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Easter-2a.jpg?v=1721274100",
            "/images/products/Easter-1a.jpg?v=1721274100"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 564,
        "name": "Nami",
        "handle": "nami",
        "description": "This radiant bouquet showcases an assortment of vibrant carnations in red, pink, yellow, and white, beautifully arranged with lush greenery. Wrapped in iridescent and white paper, it exudes a lively and festive charm, perfect for celebrating special moments or bringing cheer to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Nami-2a-281883.jpg?v=1721222927",
            "/images/products/Nami-1a.jpg?v=1721145763"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 565,
        "name": "Alana",
        "handle": "alana",
        "description": "Make a Bold Statement with Our Enchanting 60 Red Roses DomeAn enchanting dome of 60 vibrant red roses, encircled by a lace of gypsophila, makes a bold statement of passion and beauty. Wrapped in classic ivory paper with golden accents, this bouquet is a timeless expression of deep love and admiration.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [],
        "price": "684.37",
        "inclTax": "684.37",
        "img": [
            "/images/products/WhatsAppImage2022-04-13at12.01.10PM_1.jpg?v=1650336854",
            "/images/products/WhatsAppImage2022-04-13at11.17.58AM_2_1.jpg?v=1650336859"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "60 Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 50cm*50cm"
    },
    {
        "id": 566,
        "name": "Diamante",
        "handle": "diamante",
        "description": "\"Anyone can love a thing  because. That's as easy as putting a penny in your pocket. But to love something  despite. To know the flaws and love them too. That is rare and pure and perfect.\" - Patrick Rothfuss",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/WhatsAppImage2022-06-29at2.31.22PM_2.jpg?v=1656562907",
            "/images/products/WhatsAppImage2022-06-29at2.31.22PM_1_1.jpg?v=1656562907"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 567,
        "name": "Jane FBS",
        "handle": "jane",
        "description": "“Life isn't about finding yourself. Life is about creating yourself.”  - George Bernard Shaw",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-04-20at10.39.25AM_3_1.jpg?v=1650422480",
            "/images/products/WhatsAppImage2022-04-20at10.39.25AM_2_1.jpg?v=1650422480"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 568,
        "name": "Smiley",
        "handle": "smiley",
        "description": "This charming bouquet features bright yellow pom-pom flowers, arranged in a delightful smiley face pattern, accented with delicate white daisies. The cheerful arrangement is wrapped in white paper with a gingham ribbon, making it a perfect gift to bring joy and positivity.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Smiley-2a-586039.jpg?v=1721224012",
            "/images/products/Smiley-1a.jpg?v=1721173272"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 569,
        "name": "Pinkie",
        "handle": "pinkie",
        "description": "\"The emotion that can break your heart is sometimes the very one that heals it.\" - Nicholas Sparks",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/WhatsAppImage2022-04-23at12.28.06PM_1.jpg?v=1650688218",
            "/images/products/WhatsAppImage2022-04-23at12.25.29PM_1.jpg?v=1650688215"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Ohara and Scented Roses . "
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 570,
        "name": "Mavis",
        "handle": "mavis",
        "description": "A charming mix of pink carnations and variegated carnations comes together in this handcrafted Flowers Malaysia basket, adorned with a vibrant red ribbon for a touch of classic romance. It’s a sweet token of love and appreciation.",
        "type": "Flower Baskets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Mavis-1b-162164.jpg?v=1714965776",
            "/images/products/Mavis-2b-738067.jpg?v=1714965776"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " .  Carnations  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*30cm"
    },
    {
        "id": 571,
        "name": "Nikita",
        "handle": "nikita",
        "description": "Delight in the luxurious elegance of our bouquet featuring 30 enchanting purple roses, gracefully adorned with delicate sprigs of baby's breath. The regal purple hues create a mesmerizing visual display, complemented by the airy beauty of the baby's breath. Perfect for expressing admiration, celebrating milestones, or adding a touch of sophistication to any occasion, this bouquet is a stunning celebration of grace and beauty.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Nikita-1a.jpg?v=1713355038",
            "/images/products/Nikita-2a-551224.jpg?v=1713490372"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "30 Stalks of Purple Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 30cm*38cm"
    },
    {
        "id": 572,
        "name": "Jada",
        "handle": "baselyn",
        "description": "\"Whatever you're fighting for, you go with full passion and love.\" - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Carnation Flowers & Bouquets"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/WhatsAppImage2022-04-23at12.10.56PM_2.jpg?v=1650772313",
            "/images/products/WhatsAppImage2022-04-23at12.10.56PM_1_1.jpg?v=1650772313"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Eustoma . Carnations . Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 573,
        "name": "Smiley II",
        "handle": "smiley-ii",
        "description": "This adorable bouquet features pink pom-pom flowers arranged in a circle with a cute smiley face in the center, surrounded by a bed of delicate white baby’s breath. The arrangement is wrapped in white paper with a pink gingham ribbon, making it an ideal gift to spread happiness and cheer.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Smiley-II-2a.jpg?v=1721173444",
            "/images/products/Smiley-II-1a.jpg?v=1721173444"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 574,
        "name": "Maveena",
        "handle": "maveena",
        "description": "\"You don't love someone for their looks, or their clothes, or for their fancy car, but because they sing a song only you can hear.\" - Oscar Wilde",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2022-04-24at12.09.05PM_2.jpg?v=1650773484",
            "/images/products/WhatsAppImage2022-04-24at12.09.05PM_1.jpg?v=1650773484"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera .Spray Roses. Eustoma . Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 575,
        "name": "Sammy",
        "handle": "sammy",
        "description": "\"The best love is the kind that awakens the soul; that makes us reach for more, that plants the fire in our hearts and brings peace to our minds. That’s what I hope to give you forever.\" - The Notebook",
        "type": "Flower Baskets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-04-27at9.11.48AM_2.jpg?v=1651022102",
            "/images/products/WhatsAppImage2022-04-27at9.11.48AM_1_1.jpg?v=1651022104"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses .  Carnations  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*30cm"
    },
    {
        "id": 576,
        "name": "Melo",
        "handle": "melo",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-04-27at9.11.58AM_3.jpg?v=1651023008",
            "/images/products/WhatsAppImage2022-04-27at9.11.58AM_2.jpg?v=1651023010"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 20cm*20cm"
    },
    {
        "id": 577,
        "name": "Milan",
        "handle": "milan",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-04-27at10.51.40AM_2.jpg?v=1651028045",
            "/images/products/WhatsAppImage2022-04-27at10.51.40AM_1_1.jpg?v=1651028045"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 20cm*20cm"
    },
    {
        "id": 578,
        "name": "Chantelle",
        "handle": "chantelle",
        "description": "This vibrant arrangement from Flowers Malaysia bursts with rich red carnations, set in a handwoven basket. Adorned with a ‘I Love You’ heart and a satin ribbon, it’s a heartfelt expression of love and devotion.",
        "type": "Flower Baskets",
        "tags": [
            "Carnation Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-05-06at5.04.16PM_2.jpg?v=1651827947",
            "/images/products/WhatsAppImage2022-05-06at5.04.16PM_1_1.jpg?v=1651827947"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 579,
        "name": "Joanna",
        "handle": "joanna",
        "description": "Nestled in a quaint woven basket, this charming array of creamy yellow carnations from Flowers Malaysia blossoms with rustic elegance, complete with a heartwarming ‘I Love You’ embellishment and a delicate ribbon, perfect for heartfelt moments.",
        "type": "Bouquets",
        "tags": [
            "Carnation Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/WhatsAppImage2022-05-06at5.04.16PM_3_1.jpg?v=1651828029",
            "/images/products/WhatsAppImage2022-05-06at5.04.16PM_4_1.jpg?v=1651828029"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 580,
        "name": "Chaarles",
        "handle": "chaarles",
        "description": "\"The emotion that can break your heart is sometimes the very one that heals it.\" - Nicholas Sparks",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "456.17",
        "inclTax": "456.17",
        "img": [
            "/images/products/WhatsAppImage2022-05-14at11.59.44AM_2.jpg?v=1652672069",
            "/images/products/WhatsAppImage2022-05-14at11.59.44AM_1_1.jpg?v=1652672070"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Ohara and Scented Roses . "
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 581,
        "name": "Leanie",
        "handle": "leanie",
        "description": "This elegant bouquet combines white roses and lisianthus with blush pink carnations and vibrant pink tulips. The greenery and white wrapping paper add a touch of freshness and sophistication, making it an ideal gift for any occasion that calls for a delicate and timeless floral arrangement.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Leanie-2a.jpg?v=1721366737",
            "/images/products/Leanie-1a.jpg?v=1721366737"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 582,
        "name": "Winley",
        "handle": "winley",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-06-02at8.05.57AM_2.jpg?v=1654480830",
            "/images/products/WhatsAppImage2022-06-02at8.05.57AM_1_1.jpg?v=1654480830"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Daisy . Fillers & Foliage . 20cl Moet Champagne"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 583,
        "name": "Inessa",
        "handle": "inessa",
        "description": "\"Sometimes two people have to fall apart to realize how much they need to fall back together.\" - Anonymous",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/WhatsAppImage2022-06-06at2.26.30PM_1.jpg?v=1654496836",
            "/images/products/WhatsAppImage2022-05-31at1.02.22PM_2.jpg?v=1654496836"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Roses  . Baby Breath .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 50cm*60cm"
    },
    {
        "id": 584,
        "name": "Vida",
        "handle": "lori",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [
            "Corporate Hampers"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-06-07at1.34.36PM_2.jpg?v=1654580324",
            "/images/products/WhatsAppImage2022-06-07at1.34.36PM_1_1.jpg?v=1654580325"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerberra . Roses . Spray Roses . Eustomas . Fillers & Foliage . 20cl Moet Champagne"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 585,
        "name": "Greiner",
        "handle": "greiner",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [
            "Corporate Hampers"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-06-07at1.34.29PM_2.jpg?v=1654580365",
            "/images/products/WhatsAppImage2022-06-07at1.34.29PM_1_1.jpg?v=1654580365"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerberra . Roses . Spray Roses . Eustomas . Fillers & Foliage . 20cl Moet Champagne"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 586,
        "name": "Rhesley",
        "handle": "rhesley",
        "description": "\"The emotion that can break your heart is sometimes the very one that heals it.\" - Nicholas Sparks",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/WhatsAppImage2022-06-13at2.23.03PM_2.jpg?v=1655101571",
            "/images/products/WhatsAppImage2022-06-13at2.23.03PM_1_1.jpg?v=1655101571"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Christa & Furusato Roses . "
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 587,
        "name": "Ayesha",
        "handle": "ayesha",
        "description": "\"The emotion that can break your heart is sometimes the very one that heals it.\" - Nicholas Sparks",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/WhatsAppImage2022-06-16at7.26.26PM_2.jpg?v=1655444674",
            "/images/products/WhatsAppImage2022-06-16at7.26.26PM_1_1.jpg?v=1655444679"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Christa & Furusato Roses . "
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 588,
        "name": "Larson",
        "handle": "larson",
        "description": "\"The emotion that can break your heart is sometimes the very one that heals it.\" - Nicholas Sparks",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/WhatsAppImage2022-06-16at7.26.27PM_2.jpg?v=1655444802",
            "/images/products/WhatsAppImage2022-06-16at7.26.27PM_1_1.jpg?v=1655444804"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Christa & Furusato Roses . "
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 589,
        "name": "Marley",
        "handle": "marley",
        "description": "Experience the serene beauty of our blue hydrangea bouquet. Featuring a stunning blue hydrangea at its center, complemented by delicate white and lavender roses, charming daisies, and lush greenery, this arrangement is wrapped in elegant white paper. Perfect for weddings, anniversaries, or any special occasion, it brings a touch of tranquility and elegance to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Marley-2a.jpg?v=1721351352",
            "/images/products/Marley-1a.jpg?v=1721351352"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Eustoma . Roses. Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 590,
        "name": "Bruna",
        "handle": "bruna",
        "description": "Indulge in the splendor of this artfully composed bouquet, where peachy garden rose, creamy tulips, and vibrant sprigs mingle luxuriously, wrapped in soft, pastel paper and finished with a satin ribbon—truly a symphony of floral elegance for the discerning admirer.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-06-20at1.32.16PM_2.jpg?v=1655704826",
            "/images/products/WhatsAppImage2022-06-20at1.32.16PM_1_1.jpg?v=1655704827"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Garden Rose. Tulip . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 591,
        "name": "Igglion",
        "handle": "igglion",
        "description": "Embrace the enchanting beauty of our bouquet featuring 22 graceful pink roses. Each delicate bloom is a testament to beauty and tenderness, artfully arranged to create a captivating ensemble. Ideal for expressing affection, celebrating joyous moments, or adding a touch of elegance to any space, this bouquet radiates warmth and charm in its soft, rosy hues.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Rapunzel-2a_60369545-1660-4ec0-86ae-d898cf2be975-340399.jpg?v=1721309887",
            "/images/products/Igglion-1a-181038.jpg?v=1721309887"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "22 Roses"
            ]
        },
        "dimention": "Width*Height: 30cm*36cm"
    },
    {
        "id": 592,
        "name": "Antonela",
        "handle": "antonela",
        "description": "\"Whatever you're fighting for, you go with full passion and love.\" - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Carnation Flowers & Bouquets"
        ],
        "price": "216.79",
        "inclTax": "216.79",
        "img": [
            "/images/products/WhatsAppImage2022-06-24at7.44.16AM_2.jpg?v=1656027985",
            "/images/products/WhatsAppImage2022-06-24at7.44.16AM_1_1.jpg?v=1656027985"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnations . Eustoma . Gerbera . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*35cm"
    },
    {
        "id": 593,
        "name": "Shayk",
        "handle": "shayk",
        "description": "Elegant and sophisticated, this bouquet features luscious pink tulips paired with a cloud of gypsophila, also known as baby's breath. It's wrapped in a gradient of pink paper, creating a lovely contrast, and is tied together with a long, pink satin ribbon.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Shayk-2b.jpg?v=1720662889",
            "/images/products/Shayk-1a.jpg?v=1720526011"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 594,
        "name": "Shelby",
        "handle": "shelby",
        "description": "\"Anyone can love a thing  because. That's as easy as putting a penny in your pocket. But to love something  despite. To know the flaws and love them too. That is rare and pure and perfect.\" - Patrick Rothfuss",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/WhatsAppImage2022-06-22at8.38.38AM.jpg?v=1656297412",
            "/images/products/WhatsAppImage2022-06-22at8.38.38AM_1.jpg?v=1656297413"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 595,
        "name": "Burgess",
        "handle": "burgess",
        "description": "\"Anyone can love a thing  because. That's as easy as putting a penny in your pocket. But to love something  despite. To know the flaws and love them too. That is rare and pure and perfect.\" - Patrick Rothfuss",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/WhatsAppImage2022-06-22at8.38.23AM_1_1.jpg?v=1656297762",
            "/images/products/WhatsAppImage2022-06-22at8.38.23AM_2_1.jpg?v=1656297762"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 596,
        "name": "Ada",
        "handle": "ada",
        "description": "\"Anyone can love a thing  because. That's as easy as putting a penny in your pocket. But to love something  despite. To know the flaws and love them too. That is rare and pure and perfect.\" - Patrick Rothfuss",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/WhatsAppImage2022-06-22at8.38.31AM_1_1.jpg?v=1656297562",
            "/images/products/WhatsAppImage2022-06-22at8.38.31AM_2_1.jpg?v=1656297564"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 597,
        "name": "Sakura",
        "handle": "sakura",
        "description": "“And, in the end The love you take is equal to the love you make.” -  Paul McCartney",
        "type": "Bouquets",
        "tags": [
            "Calla Lilies & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2022-06-27at1.27.04PM.jpg?v=1656308487",
            "/images/products/WhatsAppImage2022-06-27at1.27.04PM_1.jpg?v=1656308487"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Calla Lilies . Roses .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 598,
        "name": "Ymir",
        "handle": "ymir-1",
        "description": "\"Whatever you're fighting for, you go with full passion and love.\" - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Sunflower Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-06-27at1.33.41PM_2.jpg?v=1656309149",
            "/images/products/WhatsAppImage2022-06-27at1.33.41PM_1_1.jpg?v=1656309149"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Sunflowers . Spray Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*35cm"
    },
    {
        "id": 599,
        "name": "Ken",
        "handle": "ken",
        "description": "\"Whatever you're fighting for, you go with full passion and love.\" - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-06-27at2.28.14PM_2.jpg?v=1656311780",
            "/images/products/WhatsAppImage2022-06-27at2.28.14PM_1_1.jpg?v=1656311780"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*35cm"
    },
    {
        "id": 600,
        "name": "Empathy (IX)",
        "handle": "empathy-ix",
        "description": "In solemn tribute, our Condolence Flower Stands in a calming purple theme showcase the serene beauty of chrysanthemums, the elegance of white orchids, and the timeless grace of faux white hydrangeas. This arrangement offers solace and compassion, extending sympathy during moments of profound grief and remembrance.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Empathy-_IX_-1d.jpg?v=1720156099",
            "/images/products/Condolence-Card-2024_1_3ff735d2-4e8b-48fb-8ccc-b6da38db9ade.jpg?v=1720156105",
            "/images/products/Empathy-_IX_-2d.jpg?v=1720156099"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses. Mum . Artificial Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 601,
        "name": "Karin FBS",
        "handle": "karin",
        "description": "This delightful arrangement features soft pink tip roses surrounded by delicate baby's breath, creating a sweet and romantic display. Presented in a stylish tan handbag with a pastel pink ribbon, it's the perfect gift to brighten someone's day and show your affection.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Karin-FBS-2b.jpg?v=1721782124",
            "/images/products/Karin-FBS-1b.jpg?v=1721782124"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 602,
        "name": "Elden",
        "handle": "elden",
        "description": "\"The emotion that can break your heart is sometimes the very one that heals it.\" - Nicholas Sparks",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/WhatsAppImage2022-06-29at10.12.59AM_2.jpg?v=1656570119",
            "/images/products/WhatsAppImage2022-06-29at10.12.59AM_1_1.jpg?v=1656570120"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Christa & Furusato Roses . "
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 604,
        "name": "Thara",
        "handle": "thara",
        "description": "\"Sometimes two people have to fall apart to realize how much they need to fall back together.\" - Anonymous",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "342.07",
        "inclTax": "342.07",
        "img": [
            "/images/products/WhatsAppImage2022-07-05at1.38.05PM_1.jpg?v=1657088867",
            "/images/products/WhatsAppImage2022-07-05at1.37.34PM_1.jpg?v=1657088867"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Roses  .  Eustomas  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 35cm*35cm"
    },
    {
        "id": 605,
        "name": "Zeus",
        "handle": "zeus",
        "description": "This stunning bouquet features a vibrant mix of sunflowers, red and peach roses, and cheerful orange blooms, all beautifully wrapped in soft yellow paper. Accented with lush greenery, this arrangement exudes warmth and joy, making it a perfect choice for celebrating life’s happy moments. Whether as a heartfelt gift or a bright centerpiece, this bouquet brings sunshine and happiness to any occasion.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Zeus-2a-304643.jpg?v=1724777098",
            "/images/products/Zeus-1a-264748.jpg?v=1724777098"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Roses . Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 45cm*55cm"
    },
    {
        "id": 606,
        "name": "Million",
        "handle": "millie-1",
        "description": "This vibrant bouquet features cheerful sunflowers, soft blue roses, and lush greenery, highlighted by a cute teddy bear in graduation attire. Wrapped in stylish blue and white paper and tied with a ribbon, it's a perfect gift for celebrating a graduation.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Million-2a.jpg?v=1720666401",
            "/images/products/Million-1a.jpg?v=1720666401"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Roses. Fillers & Foliage "
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 607,
        "name": "Peace (I)",
        "handle": "peace-i",
        "description": "In times of deep condolence, our flower stands elegantly present a somber palette of black and white chrysanthemums, harmonized with grey lilies and orchids. This sophisticated arrangement offers a respectful tribute, symbolizing compassion and remembrance, providing solace during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Peace-_I_-1d.jpg?v=1720181111",
            "/images/products/Condolence-Card-2024_1_c97d684e-7e28-4069-a96e-9043f8e23b61.jpg?v=1720181129",
            "/images/products/Peace-_I_-2d.jpg?v=1720181112"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerberra . Mum . Orchids . Faux Phalaenopsis . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*170cm"
    },
    {
        "id": 608,
        "name": "Harmony (I)",
        "handle": "harmony-i",
        "description": "Celebrate triumph with our Majestic Fusion Floral Stand featuring purple Brassica, vibrant orange Roses, and striking red Anthurium. This captivating blend symbolizes prosperity and enthusiasm, creating a grand opening statement that resonates with elegance and success.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Harmony-_I_-1d.jpg?v=1720493859",
            "/images/products/Grand-Opening-Card-2024_4033b3c4-d584-4456-9669-dce9a875fb5f.jpg?v=1720493866",
            "/images/products/Harmony-_I_-2d.jpg?v=1720493859"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Brassica . Roses . Anthurium . Mum . Faux Delphinium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 609,
        "name": "Peace (II)",
        "handle": "peace-ii",
        "description": "In a grand expression of sympathy, our jumbo condolence flower stands showcase a lavish arrangement of pure white chrysanthemums, gerberas and delicate lilies. This opulent ensemble provides a dignified and comforting tribute, symbolizing compassion and remembrance during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Peace-_II_-2d.jpg?v=1727191541",
            "/images/products/Condolence-Card-2024_1_d14792b0-266e-478e-b4cf-c6d1df2e9e06.jpg?v=1720191415",
            "/images/products/Peace-_II_-1d.jpg?v=1727191541"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Gerbera . Faux Lily . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 120cm*180cm"
    },
    {
        "id": 610,
        "name": "Cyron",
        "handle": "cyron",
        "description": "\"Anyone can love a thing  because. That's as easy as putting a penny in your pocket. But to love something  despite. To know the flaws and love them too. That is rare and pure and perfect.\" - Patrick Rothfuss",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/WhatsAppImage2022-07-25at2.50.47PM_2.jpg?v=1658731997",
            "/images/products/WhatsAppImage2022-07-25at2.50.47PM_1_1.jpg?v=1658731997"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 611,
        "name": "Peace (III)",
        "handle": "peace-iii",
        "description": "In moments of condolence, our flower stands tenderly feature pure white chrysanthemums, embracing the soft elegance of pink hydrangeas and gerberas. This delicate arrangement offers solace, symbolizing compassion and remembrance, providing gentle comfort during times of grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Peace-_III_-1e.jpg?v=1720158493",
            "/images/products/Condolence-Card-2024_1_2f9f7d12-ed38-4351-90af-42f60963cc56.jpg?v=1720158501",
            "/images/products/Peace-_III_-2e.jpg?v=1720158492"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Gerbera . Faux Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 120cm*180cm"
    },
    {
        "id": 612,
        "name": "Nicola",
        "handle": "nicola",
        "description": "This striking bouquet features vibrant red gerbera daisies beautifully surrounded by delicate white baby's breath. Wrapped in elegant red and white paper and finished with a satin ribbon, this arrangement is perfect for expressing love and admiration on any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Nicola-2a.jpg?v=1721361939",
            "/images/products/Nicola-1a-823187.jpg?v=1721396913"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Baby Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 613,
        "name": "Peace (IV)",
        "handle": "peace-iv",
        "description": "In a gentle expression of sympathy, our condolence flower stands present a tranquil blend of white and blue-themed hydrangeas, gracefully accompanied by serene gerberas. This elegant arrangement offers solace, symbolizing compassion and remembrance during moments of grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Peace-_IV_-1d.jpg?v=1720181979",
            "/images/products/Condolence-Card-2024_1_6780842f-a77c-4da8-8880-9a34ac5f5b4f.jpg?v=1720181992",
            "/images/products/Peace-_IV_-2d.jpg?v=1720181979"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Gerbera . Faux Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 120cm*180cm"
    },
    {
        "id": 614,
        "name": "Ramires",
        "handle": "ramires",
        "description": "This vibrant bouquet features bold red roses paired with soft pink lisianthus and creamy blush roses. Accents of red hypericum berries and lush greenery add depth and contrast, while the delicate beige wrapping paper ties the arrangement together. Perfect for making a statement and brightening any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Ramires-2a.jpg?v=1721367077",
            "/images/products/Ramires-1a.jpg?v=1721367077"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 615,
        "name": "Peace (V)",
        "handle": "peace-v",
        "description": "In heartfelt condolence, our flower stands tenderly feature pure white chrysanthemums  gerberas adorned in a soothing green-themed arrangement. This serene ensemble offers a gentle tribute, symbolizing compassion and remembrance, providing solace and comfort during moments of deep grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Peace-_V_-1d.jpg?v=1720189762",
            "/images/products/Condolence-Card-2024_1_6d3abf75-ada1-4fd4-a028-392f036c2202.jpg?v=1720189778",
            "/images/products/Peace-_V_-2d.jpg?v=1720189761"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Gerbera . Faux Lily . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 120cm*180cm"
    },
    {
        "id": 616,
        "name": "Kaidon",
        "handle": "kaidon",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/WhatsAppImage2022-08-12at1.58.37PM_1.jpg?v=1660283959",
            "/images/products/WhatsAppImage2022-08-08at2.28.16PM_1_1.jpg?v=1660283959"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "25 stalks of roses  "
            ]
        },
        "dimention": "Width*Height: 20cm*35cm"
    },
    {
        "id": 617,
        "name": "Robin",
        "handle": "ring",
        "description": "Here is a refined bouquet that radiates elegance and tenderness, perfect for expressing heartfelt sentiments. This stunning arrangement features delicate pink roses, ruffled pink carnations, and soft baby’s breath, accented by fresh green foliage. Wrapped in a sheer white and pink wrapping paper with a delicate ribbon, this bouquet exudes a timeless charm and grace, ideal for any special occasion or as a beautiful centerpiece.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Robin-2a.jpg?v=1721360788",
            "/images/products/Robin-1a-909029.jpg?v=1721397229"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses .  Matthiola  .  Eustomas  . Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 618,
        "name": "Ronny",
        "handle": "ronny",
        "description": "Explore the serene elegance of our blue and white hydrangea bouquet. This stunning arrangement features a blend of pristine white hydrangeas and delicate blue roses, creating a calming and sophisticated aesthetic. Perfect for expressing sympathy, celebrating new beginnings, or simply adding a touch of tranquility to any space, this bouquet is beautifully wrapped in soft white paper. The combination of lush blooms and tasteful wrapping makes this arrangement a standout choice for any occasion, radiating grace and peace.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Newborn Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Ronny-2a.jpg?v=1721359608",
            "/images/products/Ronny-1a.jpg?v=1721359608"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea . Roses . Delphinium . Eustoma . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 619,
        "name": "Harmony (II)",
        "handle": "harmony-ii",
        "description": "Create a Harmony in Blooms with our Jumbo Floral Stand. Towering sunflowers, vibrant gerberas, elegant lilies, and lush hydrangeas harmonize to form a visually captivating and cohesive centerpiece for all grand business openings.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "684.37",
        "inclTax": "684.37",
        "img": [
            "/images/products/Harmony-_II_-1c.jpg?v=1720524925",
            "/images/products/Grand-Opening-Card-2024_171361c1-cc38-4013-bf28-97a039c8be75.jpg?v=1720524937",
            "/images/products/Harmony-_II_-2c.jpg?v=1720524925"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Gerbera . Faux Hydrangea . Faux Cymbidium Orchids . Birds of Paradise . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 120cm*180cm"
    },
    {
        "id": 620,
        "name": "Smiss",
        "handle": "smiss",
        "description": "This enchanting bouquet features a large, lush purple hydrangea, beautifully surrounded by delicate chamomile flowers. Wrapped in soft white paper and tied with a matching ribbon, it’s a perfect gift for any occasion, radiating charm and elegance.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Smiss-2a.jpg?v=1720710817",
            "/images/products/Smiss-1a-475698.jpg?v=1720791364"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Daisy"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 621,
        "name": "Raiden",
        "handle": "raiden",
        "description": "\"Whatever you're fighting for, you go with full passion and love.\" - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Carnation Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-08-12at1.37.42PM_2.jpg?v=1660282814",
            "/images/products/WhatsAppImage2022-08-12at1.37.42PM_1_1.jpg?v=1660282816"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Peony . Spray Roses . Carnations . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*35cm"
    },
    {
        "id": 622,
        "name": "Hamilia",
        "handle": "hamilia",
        "description": "Indulge in the soft elegance of our pastel pink bouquet. Featuring delicate pink roses and tulips accented with touches of blue flowers and lush eucalyptus, this arrangement is wrapped in gentle pink paper. Perfect for expressing love, gratitude, or celebrating any special occasion, it brings a touch of grace and beauty to any moment.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Hamilia-2a.jpg?v=1721264068",
            "/images/products/Hamilia-1a.jpg?v=1721264068"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses  .  Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 623,
        "name": "Peace (VI)",
        "handle": "peace-vi",
        "description": "In a grand expression of sympathy, our jumbo condolence flower stands showcase a lavish arrangement of pure white chrysanthemums and delicate lilies. This opulent ensemble provides a dignified and comforting tribute, symbolizing compassion and remembrance during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "501.81",
        "inclTax": "501.81",
        "img": [
            "/images/products/Peace-_VI_-3c.jpg?v=1727192703",
            "/images/products/Condolence-Card-2024_1_79ebe900-e033-4f1a-b1e1-e928694a1494.jpg?v=1720190129",
            "/images/products/Peace-_VI_-1c.jpg?v=1727192702"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Mum . Artificial Lilies . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 120cm*180cm"
    },
    {
        "id": 624,
        "name": "Yeman",
        "handle": "yeman",
        "description": "Exude elegance with our pristine white lily bouquet. Featuring stunning white lilies surrounded by lush greenery, this arrangement is wrapped in soft beige paper and tied with a satin ribbon. Perfect for weddings, anniversaries, or any special occasion, it brings a touch of serenity and sophistication to any setting.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Lilies Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Yeman-2a.jpg?v=1721272696",
            "/images/products/Yeman-1a.jpg?v=1721272696"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Lilies . Fillers & Foliage "
            ]
        },
        "dimention": "Width*Height:30cm*36cm"
    },
    {
        "id": 625,
        "name": "Brendino",
        "handle": "brendino",
        "description": "This radiant bouquet features vibrant sunflowers and elegant white lilies, accented with lush greenery. Wrapped in soft pink paper and tied with a delicate ribbon, it’s a perfect gift for any occasion, bringing warmth and cheer to any setting.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Lilies Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Brendino-2a-570688.jpg?v=1720788559",
            "/images/products/Brendino-1a-865529.jpg?v=1720788559"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers. Lilies . Fillers & Foliage "
            ]
        },
        "dimention": "Width*Height:30cm*36cm"
    },
    {
        "id": 626,
        "name": "Whis",
        "handle": "whis",
        "description": "This sensuous bouquet with Rainbow baby’s breath give away your heart's desire to your one and only..",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-08-19at10.11.43AM_2.jpg?v=1660875526",
            "/images/products/WhatsAppImage2022-08-19at10.11.43AM_1_1.jpg?v=1660875527"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Cotton Flowers . Preserved Roses. Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 627,
        "name": "Jolin",
        "handle": "jolin",
        "description": "This elegant bouquet features soft pink gerbera daisies and alstroemeria, accented with lush greenery. Wrapped in rustic brown paper and tied with a delicate pink ribbon, it's a beautiful gift for any occasion, radiating charm and freshness.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Jolin-2a.jpg?v=1720689552",
            "/images/products/Jolin-1a.jpg?v=1720689552"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 628,
        "name": "Teresa",
        "handle": "teresa",
        "description": "This exquisite bouquet features a blend of soft pink carnations, white tulips, and elegant white roses, complemented by lush greenery. Wrapped in delicate white paper and tied with a matching ribbon, this arrangement is perfect for celebrating weddings, anniversaries, or any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Teresa-2a_1f9cb106-2aef-472c-ab19-87d994ed002f.jpg?v=1720887182",
            "/images/products/Teresa-1a_27452f3d-61ad-4d2d-b5e8-0b468267543c-628524.jpg?v=1720963729"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 629,
        "name": "Inoshee",
        "handle": "inoshee",
        "description": "This delicate and dreamy bouquet features a combination of pastel roses, blue hydrangeas, and soft daisies, all elegantly wrapped in iridescent paper that catches the light beautifully. The gentle color palette and charming arrangement make it perfect for any special occasion, bringing a touch of elegance and grace.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Hilton-2b_dc8faca6-f810-4ebb-8da8-f8a9d9309de7.jpg?v=1721541947",
            "/images/products/Innoshee-1a.jpg?v=1721541948"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnations . Eustoma. Hydrangea . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 18cm*30cm"
    },
    {
        "id": 630,
        "name": "Kodomo",
        "handle": "kodomo",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [
            "Corporate Hampers"
        ],
        "price": "593.09",
        "inclTax": "593.09",
        "img": [
            "/images/products/WhatsAppImage2022-09-06at10.06.36AM_1.jpg?v=1662430220",
            "/images/products/WhatsAppImage2022-09-06at10.08.18AM_1.jpg?v=1662430222"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Roses . Eustomas . Fillers & Foliage . Moet Champagne"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 631,
        "name": "Desiree BHP",
        "handle": "desiree-bhp",
        "description": "“What you eat literally becomes you. you have a choice in what you’re made of.” - Anonymous",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Desiree-WHP-1_1_1c4ffb1c-62b2-4e77-b5a6-b2f299018f92.jpg?v=1663239980",
            "/images/products/Desiree-WHP-2_1_6633480e-cd8c-4b5c-8ab8-ca48241f007a.jpg?v=1663239980"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "Brands Bird Nest 42ml (6 Bottles) . Shears Baby Gift set . Floral Arrangement"
            ]
        },
        "dimention": "Width*Height: 35cm*30cm"
    },
    {
        "id": 632,
        "name": "Genesis BHP",
        "handle": "genesis-bhp",
        "description": "\"Happiness is the highest form of health.\" - Dalai Lama",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Genesis-WHP-1_9976d6e1-1b7d-4e4f-b992-a33947049a3d-357835.jpg?v=1671685311",
            "/images/products/Genesis-WHP-2_13981bb1-0d9f-4ac8-a53b-0ed90104fc3e-264569.jpg?v=1671685312"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "HockHua's Chicken Essence 75ml (6 Bottles) . Shears Baby Gift Set . Floral Arrangement"
            ]
        },
        "dimention": "Width*Height: 35cm*30cm"
    },
    {
        "id": 633,
        "name": "Feed Him Well BHP",
        "handle": "rayson-bhp",
        "description": "Feed Him Well This thoughtfully gathered hamper is lovingly made with daily needs, cloth, and toys. It’s sure to keep any little child happy and comfortable through the day!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Feed-Him-Well2-909056.jpg?v=1708913557",
            "/images/products/Feed-Him-Well1-399914.jpg?v=1708913557"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tBaby K Romper Gift Set",
                "2. Dino Baby Plate",
                "3.\tLucky Baby Platter Suction Bowl",
                "4.\tLucky Baby Suction Fork & Spoon",
                "5. Munchkin -Formula Milk Dispenser Combo Pack",
                "6. Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 634,
        "name": "Little Mr Muffet BHP",
        "handle": "douglas-bhp",
        "description": "Little Mr MuffetBaby doesn’t need a tuffet to enjoy mealtime delights because we have included a suction plate and easy grip cups to make sure that food stays in its place! Perfect for babies exploring self-feeding and fun snacking, the Little Mr Muffet baby hamper is a colour-coordinated bundle of mealtime essentials to make sure baby always enjoy their foody fun! ",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Little-Mr-Muffet-BHP1.jpg?v=1722228897",
            "/images/products/Little-Mr-Muffet-BHP2.jpg?v=1722228897",
            "/images/products/Little-Mr-Muffet-BHP3.jpg?v=1722228896"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Baby K Romper Gift Set",
                "2.  Dino Baby Plate",
                "3.  Fiffy Liquid Cleanser 750ml",
                "4.  Lucky Baby Hippy Milk Formula Dispenser",
                "5.  Lucky Baby Kutter On The Go Essential",
                "6.  Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 635,
        "name": "Princely Comfort and Care BHP",
        "handle": "thomas-bhp",
        "description": "Princely Comfort and CarePrepare for royal comfort with the Princely Comfort and Care baby gift hamper! Presented in an exquisite tray box, this thoughtfully curated gift features quality fleece blanket and organic washcloths, as well as playtime buddies that are fit for a princeling!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Thomas2-486931.jpg?v=1671685637",
            "/images/products/Thomas1-295050.jpg?v=1671685637"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tBaby K Fleece Blanket",
                "2.\tBaby K- Organic Bamboo 6 pcs pack Washcloth",
                "3.\tMunchkin - Formula Milk Dispenser Combo Pack",
                "4.\tBandana Buddies Taggies Lion",
                "5.\tDrypers Wee Wee Dry Diapers M size (4pcs)",
                "6.\tBunny Plush Toy",
                "7.\tExquisite Gift Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 30cm"
    },
    {
        "id": 636,
        "name": "Mum’s Perfect Prince BHP",
        "handle": "pierre-bhp",
        "description": "Mum’s Perfect PrinceSay hello to the newborn baby boy with the Mum’s Perfect Prince baby gift hamper! It includes receiving blankets that will offer the softest touch to baby from their first breath. Baby will also be comforted with the BabyK wooden toy car and a bunny plush toy, whilst mummy takes a restorative shots of bird’s nest with ginseng, and berry essence.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "221.13",
        "inclTax": "221.13",
        "img": [
            "/images/products/Pierre5-282054.jpg?v=1671685494",
            "/images/products/Pierre1-629994.jpg?v=1671685494"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tBird’s Nest with Ginseng & Rock Sugar 70ml x 2",
                "2.\tFormec’s Berry Essence 70ml x 2",
                "3.\tOwen -2 pcs Receiving Blanket",
                "4.\tBaby K Fleece Blanket",
                "5.\tBaby K Wooden Toy Car",
                "6.\tBunny Plush Toy",
                "7.\tDrypers Wee Wee Dry Diapers M size (4pcs)",
                "8.\tExquisite Tray Box",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 30cm"
    },
    {
        "id": 637,
        "name": "Feed Her Well BHP",
        "handle": "sharon-bhp",
        "description": "Feed Her WellThis thoughtfully gathered hamper is lovingly made with daily needs, cloth, and toys. It’s sure to keep any little child happy and comfortable through the day!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Feed-Her-Well-BHP1_ca907348-2626-4b4b-854e-f18b5b92ba0b-449377.jpg?v=1722357610",
            "/images/products/Feed-Her-Well-BHP2.jpg?v=1722227789",
            "/images/products/Feed-Her-Well-BHP3.jpg?v=1722227788"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Baby K Romper Gift Set",
                "2.  Dino Baby Plate",
                "3.  Lucky Baby Groomy Suction Bowl with Spoon",
                "4.  Lucky Baby Skoop Heat Sensitive Spoons 2pcs",
                "5.  Munchkin -Formula Milk Dispenser Combo Pack",
                "6.  Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 638,
        "name": "Little Miss Muffet BHP",
        "handle": "yvonne-bhp",
        "description": "Little Miss MuffetBaby doesn’t need a tuffet to enjoy mealtime delights because we have included a suction plate and easy grip cups to make sure that food stays in its place! Perfect for babies exploring self-feeding and fun snacking, the Little Mrs Muffet baby hamper is a colour-coordinated bundle of mealtime essentials to make sure baby always enjoy their foody fun! ",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Little-Miss-Muffet-BHP1_465f9c24-5055-4460-be9f-42cf967011c5.jpg?v=1722228505",
            "/images/products/Little-Miss-Muffet-BHP2.jpg?v=1722228504",
            "/images/products/Little-Miss-Muffet-BHP3.jpg?v=1722228504"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Baby K Romper Gift Set",
                "2.  Dino Baby Plate",
                "3.  Fiffy Liquid Cleanser 750ml",
                "4.  Lucky Baby Hippy Milk Formula Dispenser",
                "5.  Lucky Baby Kutter On The Go Essential",
                "6.  Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 639,
        "name": "Princess Comfort and Care BHP",
        "handle": "britney-bhp",
        "description": "Princess Comfort and CarePrepare for royal comfort with the Princess Comfort and Care baby gift hamper! Presented in an exquisite tray box, this thoughtfully curated gift features quality fleece blanket and organic washcloths, as well as playtime buddies that are fit for a little princess!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Britney2-139579.jpg?v=1671685637",
            "/images/products/Britney1-202684.jpg?v=1671685637"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1. Baby K Fleece Blanket",
                "2. Baby K- Organic Bamboo 6 pcs pack Washcloth",
                "3. Munchkin - Formula Milk Dispenser Combo Pack",
                "4. Bandana Buddies Taggies Lion",
                "5. Drypers Wee Wee Dry Diapers M size (4pcs)",
                "6. Bunny Plush Toy",
                "7. Exquisite Tray Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 640,
        "name": "Sunshine Girl BHP",
        "handle": "tiffany-bhp",
        "description": "Sunshine GirlThe Sunshine Girl baby gift hamper is sure to perk up any new parent, and welcome their newborn in style. Packed in an exquisite gift basket, this hamper for newborns contains not just baby essentials like hypoallergenic baby wipes and organic bamboo washcloths from BabyK, but also mummy’s favourite bird’s nest with ginseng!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Sunshine-Girl-BHP1_358b63bb-8110-49a8-9747-4c25beffb225.jpg?v=1723438469",
            "/images/products/Sunshine-Girl-BHP2_2e07da7b-9b51-4753-95ae-17e943dff4c5.jpg?v=1723438469",
            "/images/products/Sunshine-Girl-BHP3.jpg?v=1723438470"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Birds Nest with Ginseng & Rock Sugar 2 x70ml",
                "2.  Baby K Romper Gift Set",
                "3.  Fiffy Organic Moisturizing Head To Toe Wash 750ml",
                "4.  Fiffy Organic Moisturizing Lotion 450ml",
                "5.  Fiffy Mini Cotton Buds 400 Buds",
                "6.  Baby K Organic Bamboo 6 pcs pack Washcloth",
                "7.  KinderNurture Organic Baby Wipes 80s",
                "8.  Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 641,
        "name": "Snacks oh Crumbly GHP",
        "handle": "crumbly-ghp",
        "description": "Snacks oh CrumblyWhat’s a celebration without delectable treats? The Snacks oh Crumbly Hamper features a compilation of well-loved snacks that will delight loved ones, colleagues, and even the neighbors!",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers",
            "Halal Food Hampers"
        ],
        "price": "145.82",
        "inclTax": "145.82",
        "img": [
            "/images/products/Snacks-oh-Crumbly-GHP1-296263.jpg?v=1687215928",
            "/images/products/Snacks-oh-Crumbly-GHP2-399564.jpg?v=1687145560"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tGolden Bon Bon Almond Crunchy Maple Nougat 70g",
                "2.\tMelvados Citrus Pistachio Biscotti 100g",
                "3.\tVochelle Doypack 100g",
                "4.\tAmazin Graze Banana Bread Granola Bread 40g",
                "5.\tBeryl’s Chocolate Butter Cookies 70g",
                "6.\tMission Tortilla Chips Hot & Spicy 65g",
                "7.\tGretel Sprouted Cashew Nuts Natural Baked 50g",
                "8.\tK.Harrodson Blueberry Butter Cookies 70g",
                "9.\tExquisite Tray Box",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm*30cm"
    },
    {
        "id": 642,
        "name": "Splendour GHP",
        "handle": "splendour-ghp",
        "description": "SplendourThe most comprehensive snack pack you’ll ever find, the Splendour gourmet food hamper delights with its tasty chocolate treats and crunchy delectable nuts. Perfect for an afternoon tea party, or simply a present to delight even the fussiest snackers.",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "157.23",
        "inclTax": "157.23",
        "img": [
            "/images/products/Splendour1.jpg?v=1701063148",
            "/images/products/Splendour2_0ff53832-9ce7-4f92-a80b-e333c7e5f503-453906.jpg?v=1701170506"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tExcelcium - Pralines BonBons De Chocolate 200g",
                "2.\tCupido Dark Chocolate Sticks 125g",
                "3.\tForum Belgian Biscuits Wafers 100g",
                "4.\tGolden Bon Bon",
                "5.\tSoft Almond Nougat 70g",
                "6.\tWalker Strawberry & Cream Biscuit 150g",
                "7.\tNibbles Premium Mixed Nuts 45g",
                "8.\tBickfords Premium Soft Drink Australia",
                "9.\tCream Soda 275 ml",
                "10.\tExquisite Gift Basket",
                ""
            ]
        },
        "dimention": "\r\nWidth*Height: 35cm x 40cm"
    },
    {
        "id": 643,
        "name": "Lavish Treats GHP",
        "handle": "lavish-ghp",
        "description": "Lavish Treats",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "157.23",
        "inclTax": "157.23",
        "img": [
            "/images/products/Lavish-Treats-GHP1.jpg?v=1707104670",
            "/images/products/Lavish-Treats-GHP2.jpg?v=1707104671"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Golden Bon Bon Nougat DOr Choc coated Soft Honey Almond Nougat 70g",
                "2.  Esprit Sparkling Soft Drink Passionfruit 300ml",
                "3.  St Michel Mini Madeleines with Choc Chips 75g",
                "4.  Droste Milk/Dark Pastilles 85g",
                "5.  Fiorella Monte Classic Bitter Chocolate Mounted Biscuits 102g",
                "6.  Nibbles Premium Natural Mixed Nuts 45g",
                "7.  Jacker Wafer Cube 100g",
                "8.  Vicenzi Minisnack with Hazelnut Cream 75g",
                "9.  Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 644,
        "name": "Sweet Treats GHP",
        "handle": "treats-ghp",
        "description": "Sweet TreatsThe Sweet Treats snack hamper features a premium selection of not just sweet, but also savoury snacks for those special romantic moments. If you’re on the look out for a snack gift basket delivery, then look no further! Our stylishly presented snack pack is one of the best ways to send your sweetest thoughts!",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "166.36",
        "inclTax": "166.36",
        "img": [
            "/images/products/Sweet-Treats-GHP1.jpg?v=1707106685",
            "/images/products/Sweet-Treats-GHP2.jpg?v=1707106685"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Golden Bon Bon Nougat DOr Choc coated Soft Honey Almond Nougat 70g",
                "2.  Esprit Sparkling Soft Drink Passionfruit 300ml",
                "3.  St Michel Mini Madeleines with Choc Chips 75g",
                "4.  Fiorella Monte Chocolate Mounted Biscuits 102g",
                "5.  Rossella Pouchette Fondant Frutta 45g",
                "6.  Yava Krispi Puffs Sweet Cashews 45g",
                "7.  La Mere Poulard 1888 Les Sables French Butter Cookies 46.8g",
                "8.  Vochelle Almond Doypack 100g",
                "9.  Hunters Gourmet Cherry Tomato Olive Hand Cooked Potato Chips 25g",
                "10.  Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 645,
        "name": "Fun Surprises GHP",
        "handle": "fun-ghp",
        "description": "Fun SurprisesA gastronomical experience that is curated to surprise and delight your receiver, the Fun Surprises gourmet food hamper is sure to make a lasting impression. This special gift of good tastes comes with a crowd favorite, an UNO card game, to entertain guests, who love to game as much as they love to snack.",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "202.87",
        "inclTax": "202.87",
        "img": [
            "/images/products/Fun2-763367.jpg?v=1671685208",
            "/images/products/Fun1-552284.jpg?v=1671685208"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tWernli Truffet 100g",
                "2.\tMovietime Flavoured Popcorn 30g",
                "3.\tK.Harrodson Blueberry Butter Cookies 70g",
                "4.\tKettle Honey Dijion Potato Chips 56g",
                "5.\tCoca-Cola Classic Can 250ml x2",
                "6.\tCharades For Kids Board Game",
                "7.\tUno Card Game",
                "8.\tExquisite Tray Box",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm*30cm"
    },
    {
        "id": 646,
        "name": "Chocolicious GHP",
        "handle": "chocolicious-ghp",
        "description": "ChocoliciousOrder the most luxurious chocolate hamper to delight serious chocoholics. Our Chocolicious hamper will indulge them with the wide selection of chocolate treats, designed to impress with its curated assortment of mouth-watering and gorgeous delicacies.",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "246.23",
        "inclTax": "246.23",
        "img": [
            "/images/products/Chocolicious2.jpg?v=1664377933",
            "/images/products/Chocolicious1-747214.jpg?v=1671685022"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tExcelcium . Pralines BonBons De Chocolat 200g",
                "2.\tCupido Hazelnut Chocolate Seashells 250g",
                "3.\tForum Hamlet Finest Waffle Crisp 100g",
                "4.\tSwiss Miss Dark Chocolate Sensation Hot Cocoa 283g",
                "5.\tK.Harrodson Blueberry Butter Cookies 180g",
                "6.\tBickfords Premium Soft Drink Australia",
                "7.\tLemon Lime 275ml",
                "8.\tBickfords Premium Soft Drink Australia",
                "9.\tSarsaparilla 275ml",
                "10.\tKettle Honey Dijion Potato Chips 56g",
                "11.\tVochelle Hazelnuts Doypack 100g",
                "12.\tExquisite Tray Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 647,
        "name": "Crunch, Fizz, Chill GHP",
        "handle": "crunch-ghp",
        "description": "Crunch, Fizz, ChillPerfect as an all-in-one picnic hamper, or simply a treat of snacks for any good ol’ gatherings, this delectable assortment of sweet and savory snacks is a gastronomical delight even for the fussiest of snackers. Challenge each other with the Drunken Tower Drinking Game for an extra fun time! Try our Crunch, Fizz, Chill gourmet hamper for a full on gaming and snacking experience!",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "225.69",
        "inclTax": "225.69",
        "img": [
            "/images/products/Crunch2.jpg?v=1664377954",
            "/images/products/Crunch1-110333.jpg?v=1671685197"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tMovietimes Original Popcorn 20g",
                "2.\tFox Nacho Cheese Chips 40g",
                "3.\tHeineken Bottle Beer 330g x3",
                "4.\tTai Sun Roasted Broad Beans 150g",
                "5.\tVochelle Doypack 100g",
                "6.\tTong Garden Honey Roasted Cashew Nuts 40g",
                "7.\tDrunken Tower Drinking Game",
                "8.\tExquisite Tray Box",
                ""
            ]
        },
        "dimention": "Width*Height: Width*Height: 30cm*35cm"
    },
    {
        "id": 648,
        "name": "Sweet Tooth GHP",
        "handle": "brunet-ghp",
        "description": "Sweet ToothPresenting a range of delectable sugary treats and delicacies for some serious indulgence. The Sweet Tooth chocolate hamper is a symphony of gourmet treats curated for the most discerning palate. Reserved for special occasions only, because this will definitely leave a lasting impression!",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "253.07",
        "inclTax": "253.07",
        "img": [
            "/images/products/Brunet2.jpg?v=1664377815",
            "/images/products/Brunet1-369967.jpg?v=1671775914"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBickfords Premium Soft Drink Australia",
                "2.\tRed Grape With Hibiscus 700ml",
                "3.\tHamlet – Assortiment Belgian Chocolate 125g",
                "4.\tHamlet Oublies Forum 125g",
                "5.\tFeiny Biscuits Wafers Cocoa 250g",
                "6.\tNibbles Bite Butter Chocolate Cookies 50g",
                "7.\tBasilur Tea Pack 30g",
                "8.\tK.Harrodson Raspberry Butter Cookies 180g",
                "9.\tExcelcium - Pralines BonBons De Chocolat 200g",
                "10.\tBeryl’s Almond milk Chocolate 70g",
                "11.\tExquisite Tray Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 649,
        "name": "Machie Boy BHP",
        "handle": "owen-bhp",
        "description": "Machie BoyThe Machie Boy baby gift hamper will welcome any bouncing baby boy in style! It contains a BabyK romper gift set to make sure baby is fashionable and comfortable, and also grooming essentials like gentle wipes and cotton buds. We made sure to include some bird’s nest so that the new parents will also feel pampered.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Machie-Boy-BHP1_68b52ef6-d380-4b97-9e7b-e6620b506fd5.jpg?v=1723438099",
            "/images/products/Machie-Boy-BHP2_6b727dc7-d6ac-4305-b72a-3e273dbae3e2.jpg?v=1723438099",
            "/images/products/Machie-Boy-BHP3.jpg?v=1723438099"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Birds Nest with Ginseng & Rock Sugar 2 x70ml",
                "2.  Baby K Romper Gift Set",
                "3.  Fiffy Organic Moisturizing Head To Toe Wash 750ml",
                "4.  Fiffy Organic Moisturizing Lotion 450ml",
                "5.  Fiffy Mini Cotton Buds 400 Buds",
                "6.  Baby K Organic Bamboo 6 pcs pack Washcloth",
                "7.  KinderNurture Organic Baby Wipes 80s",
                "8.  Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 650,
        "name": "Bunny Blossom BHP",
        "handle": "tracey-bhp",
        "description": "Bunny BlossomGift baby their first buddy with this Bunny Blossom newborn baby hamper. It includes essentials for new parents, like the swaddling blanket and romper gift set from BabyK, and a survival guidebook to tide them over the challenging first months. And to help parents over those sleepless nights, we have also thoughtfully added the Fomec Berry Essence.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Bunny-Blossom-BHP1-845696.jpg?v=1708969689",
            "/images/products/Bunny-Blossom-BHP2.jpg?v=1708924221"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Fomec Berry Essence 41ml x 2",
                "2.  Bunny Plush Toy",
                "3.  Baby K Romper Gift Set",
                "4.  Baby K Organic Bamboo Swaddling Blanket",
                "5.  Baby K Organic Baby Hand Rattles",
                "6.  The Baby Care  A Survival Guide Book For New Parents",
                "7.  Baby K Sippy Cup",
                "8.  Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 651,
        "name": "Organic Health Box OHP",
        "handle": "kate-ohp",
        "description": "Organic Health BoxThis organic wellness box is one to present a vegan who is on the road to recovering his/her health. You can be sure that the receiver will not only enjoy the things in it but also keep fond memories of you.",
        "type": "Hampers",
        "tags": [
            "Organic Hampers"
        ],
        "price": "246.23",
        "inclTax": "246.23",
        "img": [
            "/images/products/Kate-OHP1-632072.jpg?v=1671685498",
            "/images/products/Kate-OHP2-449137.jpg?v=1671685498"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tThermo Flask 500ml",
                "2.\tGerminal Organic Cookie Sticks with Dried Fruits Vegan 110g",
                "3.\tOrigins Healthfood Organic Rolled Oat 500g",
                "4.\tDj & A Fruity Crisps Strawberry & Apple 25g",
                "5.\tSerious Cookie Certified Organic Chocolate Chip Cookie 170g",
                "6.\tFiorentini Organic Rice Snack with Rosemary 40g",
                "7.\tSun Blast Organic Juice Orange 200ml",
                "8.\tSun Blast Organic Juice Apple and Guava 200ml",
                "9.\tExquisite Gift Box",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 30cm"
    },
    {
        "id": 652,
        "name": "The Party Starter GHP",
        "handle": "party-ghp",
        "description": "The Party StarterThis delightful basket of treats is the life of the party itself! The Party Starter gourmet hamper features a delicious variety of sweet and savory snacks and the crowd favorite GB Roulette 16 Glass Lucky Shot Drinking Game, curated just for fun and exciting gatherings! You will never have a dull moment with this delicious snack pack!",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "266.77",
        "inclTax": "266.77",
        "img": [
            "/images/products/Party2-858131.jpg?v=1671775917",
            "/images/products/Party1-118775.jpg?v=1671775917"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBickfords Premium Soft Drink Australia",
                "2.\tRed Grape with Hibiscus 700ml",
                "3.\tHamlet – Assortiment Belgian Chocolate 125g",
                "4.\tHamlet Oublies Forum 125g",
                "5.\tFeiny Biscuits Wafers Cocoa 250g",
                "6.\tNibbles Bite Butter Chocolate Cookies 50g",
                "7.\tBasilur Tea Pack 30g",
                "8.\tK.Harrodson Raspberry Butter Cookies 180g",
                "9.\tExcelcium - Pralines BonBons De Chocolat 200g",
                "10.\tBeryl’s Almond Milk Chocolate 70g",
                "11.\tExquisite Tray Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 35cm"
    },
    {
        "id": 653,
        "name": "Bunny Bluebell BHP",
        "handle": "wayne-bhp",
        "description": "Bunny BluebellSurprise new parents with the Bunny Bluebell newborn baby gift hamper. It includes essentials for new parents, like the swaddling blanket and romper gift set from BabyK, and a survival guidebook to tide them over the challenging first months. And to help parents over those sleepless nights, we have also thoughtfully added the Fomec Berry Essence.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Bunny-Bluebell-BHP2-395462.jpg?v=1708969690",
            "/images/products/Bunny-Bluebell-BHP1-399615.jpg?v=1708969690"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Fomec Berry Essence 41ml x 2",
                "2.  Bunny Plush",
                "3.  Baby K Romper Gift Set",
                "4.  Baby K- Organic Bamboo Swaddling Blanket",
                "5.  Baby K Organic Baby Hand Rattles",
                "6.  The Baby Care  A Survival Guide Book For New Parents",
                "7.  Baby K Sippy Cup",
                "8.  Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 654,
        "name": "Here Comes Bailey BHP",
        "handle": "peter-bhp",
        "description": "Here Comes BaileySay Hello to the Here Comes Bailey Hamper! Included in this set is a Bailey the Dog plushie, as featured in our Bailey  Friends collection! Curated for parents who value useful essentials and are also looking to make memories that last, this delightful set is housed in a vintage inspired carry trunk, designed also to house all your precious mementos as a keepsake case.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "287.30",
        "inclTax": "287.30",
        "img": [
            "/images/products/Peter2-692586.jpg?v=1671685463",
            "/images/products/Peter1-840074.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Bailey The Dog Plush Toy",
                "2. Kayen & Kayes  Keepsake Mold Kit",
                "3. 3D Airmesh Pillow 11.5 x 7.5",
                "4. BabyK  The Baby Care Compass",
                "5. BabyK  4 Pcs Cotton Starter Set",
                "-Romper",
                "-Mitten",
                "-Booties",
                "-Cap",
                "6. BabyK  Baby Wash Cloth",
                "7. BabyK  Multi Purpose Flannel Blanket",
                "8. Bailey & Friends Keepsake Box",
                "9. White Paper Bag + Joybox Logo"
            ]
        },
        "dimention": "Width*Height: 26cm x 26cm"
    },
    {
        "id": 655,
        "name": "Little Prince Recreation BHP",
        "handle": "patrick-bhp",
        "description": "Little Prince RecreationGet ready for a princely playtime with the Little Prince Recreation baby hamper! It includes a fleece blanket and an organic cotton head shaping pillow from BabyK to make sure baby gets a royally good rest, and a plush bunny and a wooden race car, for play sessions that are fit for the little princeling!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "282.74",
        "inclTax": "282.74",
        "img": [
            "/images/products/Patrick2-726518.jpg?v=1671685423",
            "/images/products/Patrick1-285032.jpg?v=1671685423"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tBaby K- Organic Cotton Head Shaping Baby Pillow",
                "2.\tBaby K Fleece Blanket – Blue",
                "3.\tMarcus & Marcus -Snack Bowl Blue",
                "4.\tBaby K -Wooden Car Toy",
                "5.\tOwen -2 pc Receiving Blanket",
                "6.\tBunny Plush Toy",
                "7.\tExquisite Gift Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 656,
        "name": "Mum's Blue Basket with Balloon BHP",
        "handle": "alexis-bhp",
        "description": "Mum's Blue Basket with BalloonCelebrate both mum and bub with the Mum’s Blue Basket newborn gift hamper. Arranged artistically in an exquisite gift basket, it contains treats for both mummy and the newborn baby prince! A useful hamper gift including a blue fleece blanket for baby and wholesome tonics and tea for mummy, this baby hamper ticks all the right boxes so you can send the best impression!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "326.10",
        "inclTax": "326.10",
        "img": [
            "/images/products/Alexis2-424255.jpg?v=1671685492",
            "/images/products/Alexis1-349245.jpg?v=1671685492"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Bird’s Nest with Ginseng & Rock Sugar 2 x70ml2. SpaFoods - ",
                " Longan & Red Date Tea 15 x 12g sachets per pack",
                "3. Shears Muji 4 pcs Gift Set – Gray Zebra",
                "4. Baby K Fleece Blanket – Blue",
                "5. Marcus & Marcus - Snack Bowl",
                "6. Assorted Non-Helium Balloon",
                "7. Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 657,
        "name": "Cheer and Toast (Non Alcoholic) GHP",
        "handle": "cheers-ghp",
        "description": "Cheer and Toast (Non Alcoholic)",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "282.74",
        "inclTax": "282.74",
        "img": [
            "/images/products/Cheer-and-Toast-_Non-Alcoholic_-GHP2.jpg?v=1718173061",
            "/images/products/Cheer-and-Toast-_Non-Alcoholic_-GHP1_54c47b2d-0ac6-46a7-9925-cc5d091e9671.jpg?v=1718173062"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Bickfords Cordial Australia 750ml",
                "2.  Excelcium Black  Pralines BonBons De Chocolat 200g",
                "3.  San Andres Marzipan with Candied Fruit 200g",
                "4.  Hamlet Oublies 125g",
                "5.  Primo Forno Traditional Breadsticks with Parmesan Cheese 125g",
                "6.  Forum Caramel Wafer 230g",
                "7.  Ore Liete Cantuccini with Dark Chocolate Chips 180g",
                "8.  Exquisite Picnic Basket (M)"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 658,
        "name": "Twinkle Twinkle Little Girl BHP",
        "handle": "eunice-bhp",
        "description": "Twinkle Twinkle Little GirlSay congratulations with the sweetest newborn baby hamper! The Twinkle Twinkle Little Girl baby girl hamper includes an exciting series of utensils and toys to keep baby excited and entertained! We have also added the Baby Milestone Book so that parents can keep track of their exciting new journey!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Eunice2-459192.jpg?v=1671775918",
            "/images/products/Eunice1-190489.jpg?v=1671775918"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "\n1.\tFomec Berry Essence 41ml x 2",
                "2.\tBird’s Nest with Ginseng & Rock Sugar 70ml x 2",
                "3.\tFun Orbit Turn Happy Ball Toy Set",
                "4.\tBaby K Food Grade Silicon Giraffee Teether",
                "5.\tBaby K Sippy Cup",
                "6.\tBaby K Silicone Self Feeding Spoon Set",
                "7.\tBaby K Penguin Wobbler",
                "8.\tAssorted Flower Packaging",
                "9.\tExquisite Gift Box",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 659,
        "name": "Here Comes Molly BHP",
        "handle": "molly-bhp",
        "description": "Here Comes MollySay Hello to the Here Comes Bailey Hamper! Included in this set is a Molly the Cat plushie, as featured in our Bailey  Friends collection! Curated for parents who value useful essentials and are also looking to make memories that last, this delightful set is housed in a vintage inspired carry trunk, designed also to house all your precious mementos as a keepsake case.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "287.30",
        "inclTax": "287.30",
        "img": [
            "/images/products/Molly2-900952.jpg?v=1671685463",
            "/images/products/Molly1-184833.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Molley The Cat Plush Toy",
                "2. Kayen & Kayes  Keepsake Mold Kit",
                "3. 3D Airmesh Pillow 11.5 x 7.5",
                "4. BabyK  The Baby Care Compass",
                "5. BabyK  4 Pcs Cotton Starter Set",
                "-Romper",
                "-Mitten",
                "-Booties",
                "-Cap",
                "6. BabyK  Baby Wash Cloth",
                "7. BabyK  Multi Purpose Flannel Blanket",
                "8. Bailey & Friends Keepsake Box"
            ]
        },
        "dimention": "Width*Height: 26cm x 26cm"
    },
    {
        "id": 660,
        "name": "Little Princess Recreation BHP",
        "handle": "shirley-bhp",
        "description": "Little Princess RecreationGet ready for a princessy playtime with the Little Princess Recreation baby hamper! It includes a fleece blanket and an organic cotton head shaping pillow from BabyK to make sure baby gets a royally good rest, and a plush bunny and a wooden race car, for play sessions that are fit for little royalty!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "282.74",
        "inclTax": "282.74",
        "img": [
            "/images/products/Shirley2-351100.jpg?v=1671685469",
            "/images/products/Shirley1-882489.jpg?v=1671685469"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tBaby K- Organic Cotton Head Shaping Baby Pillow",
                "2.\tBaby K Fleece Blanket – Pink",
                "3.\tMarcus & Marcus - Snack Bowl Pink",
                "4.\tBaby K -Wooden Car Toy",
                "5.\tOwen -2 pc Receiving Blanket",
                "6.\tBunny Plush Toy",
                "7.\tExquisite Gift Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 661,
        "name": "Mum’s Pink Passion with Balloon BHP",
        "handle": "eileen-bhp",
        "description": "Mum’s Pink Passion with BalloonCelebrate both mummy and bub with the Mum’s Pink Passion newborn gift hamper. Arranged artistically in an exquisite gift basket, it contains treats for both mummy and the newborn baby princess! A useful hamper gift including a pink fleece blanket for baby and wholesome tonics and tea for mummy, this baby hamper ticks all the right boxes so you can send the best impression!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "326.10",
        "inclTax": "326.10",
        "img": [
            "/images/products/Eileen2-555281.jpg?v=1671685494",
            "/images/products/Eileen1-130176.jpg?v=1671685494"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "\n1.\tBird’s Nest with Ginseng & Rock Sugar 70ml x 2",
                "2.\tSpaFoods-Longan & Red Date Tea 15 x 12g sachets per pack",
                "3.\tShears Muji 4 pcs Gift Set – Kitty Pink",
                "4.\tBaby K Fleece Blanket – Pink",
                "5.\tMarcus & Marcus -Snack Bowl",
                "6.\tAssorted Non- Helium Balloon",
                "7.\tExquisite Gift Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 662,
        "name": "Indulgence (Non Alcoholic) GHP",
        "handle": "indulgence-ghp",
        "description": "Indulgence (Non Alcoholic)",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "326.10",
        "inclTax": "326.10",
        "img": [
            "/images/products/Indulgence-_Non-Alcoholic_-GHP2_fd05691f-9c7b-4c6d-a52e-ffc075c0be3c.jpg?v=1718245014",
            "/images/products/Indulgence-_Non-Alcoholic_-GHP1_1c87d163-b29e-45ac-b2f5-88b7aec0a13f.jpg?v=1718245014"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Bickfords Cordial Australia 750ml",
                "2.  Cupido Seashells Belgian Chocolate with Hazelnut Filling 250g",
                "3.  Primo Forno Traditional Breadsticks with Parmesan Cheese 125g",
                "4.  Gavottes Crpes Dentelle 125g",
                "5.  GBB Soft Almond Nougat Giftbox 130g",
                "6.  The Fine Cheese Co Rosemary & Extra Virgin Oil Crackers 125g",
                "7.  Tchibo Golden Selection Coffee 50g",
                "8.  Danisa Butter Cookies 200g",
                "9.  Jules Destrooper Almond Thins With Dark Chocolate & Orange 125g",
                "10.  Exquisite Picnic Basket (M)"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 663,
        "name": "Cheer and Toast (White Wine) GHP",
        "handle": "orvieto-ghp",
        "description": "Cheer and Toast (White Wine)The Cheer and Toast (White Wine) gourmet food hamper is a tasty assortment of gourmet treats, perfectly paired with the fruity and mellow De Valier Bianco. Indulge in good taste and cheer and toast to delightful moments, made extra special with this vibrant curation of flavors!",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "394.79",
        "inclTax": "394.79",
        "img": [
            "/images/products/Orvieto2.jpg?v=1664465110",
            "/images/products/Orvieto1-730995.jpg?v=1671685194"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tDe Valier Bianco 75cl",
                "2.\tAalst Whole Almonds Milk Chocolate Gift Tin 150g",
                "3.\tCupido Dessert Cups Tiramisu 125g",
                "4.\tJuan tea 9g x 2 boxes",
                "5.\tK.Harrodson Butter Cookies Tin 180g",
                "6.\tMrs Bridges – Orange Marmalade with Honey 340g",
                "7.\tForum Hamlet Oublies 125g",
                "8.\tExcelcium – Pralines BonBons De Chocolat 200g",
                "9.\tExquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 664,
        "name": "Indulgence (Red Wine) GHP",
        "handle": "nougat-ghp",
        "description": "Indulgence (Red Wine)",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Indulgence-_Red-Wine_-GHP2_11c197c8-7fc8-41f9-b93b-951c28a389bd.jpg?v=1718173941",
            "/images/products/Indulgence-_Red-Wine_-GHP1_fb0772df-0953-4566-a988-730189e19686.jpg?v=1718173940"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Noblesse Cabernet Sauvignon 75cl",
                "2.  Cupido Seashells Belgian Chocolate with Hazelnut Filling 250g",
                "3.  Primo Forno Traditional Breadsticks with Parmesan Cheese 125g",
                "4.  Gavottes Crpes Dentelle 125g",
                "5.  GBB Soft Almond Nougat Giftbox 130g",
                "6.  The Fine Cheese Co Rosemary & Extra Virgin Oil Crackers 125g",
                "7.  Tchibo Golden Selection Coffee 50g",
                "8.  Danisa Butter Cookies 200g",
                "9.  Jules Destrooper Almond Thins With Dark Chocolate & Orange 125g",
                "10.  Exquisite Picnic Basket (M)"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 665,
        "name": "Indulgence (White Wine) GHP",
        "handle": "jules-ghp",
        "description": "Indulgence (White Wine)Our Indulgence (White Wine) gourmet hamper is curated just to take your tea party up a notch! Featuring tea time favorites and a rich sauvignon blanc, you can be sure that your receiver will indulge happily in this delectable basket of treats!",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "415.32",
        "inclTax": "415.32",
        "img": [
            "/images/products/Jules2.jpg?v=1664494085",
            "/images/products/Jules1.jpg?v=1664494085"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tNoblesse Sauvignon Blanc 75cl",
                "2.\tDanisa Butter Cookies 200g",
                "3.\tGuylian Belgian Chocolates Premium Dark 72% 100g",
                "4.\tJules Destrooper Chocolate Thins 100g",
                "5.\tGolden Bon Bon Soft Almond Nougat Gift Box 130g",
                "6.\tCupido Hazelnut Chocolate Seashells 250g",
                "7.\tMrs Bridges Mini Traditional Preserve Pack 42g x 3",
                "8.\tTipson Ceylon Green Tea 25 Tea Bags",
                "9.\tFarmhouse Luxury Salted Caramel Biscuits 150g",
                "10.\tExquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 666,
        "name": "Gastronome (Non Alcoholic) GHP",
        "handle": "gastronome-ghp",
        "description": "Gastronome (Non Alcoholic)Here is a gift pack every oenophile will cherish and that is basically down to the bottle of luscious Bickfords Cordials in it. But that’s not all; there are also many tasteful treats.",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "394.56",
        "inclTax": "394.56",
        "img": [
            "/images/products/Gastronome2-979474.jpg?v=1671685311",
            "/images/products/Gastronome1-785808.jpg?v=1671685311"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBickfords Cordials 750ml Excelcium",
                "2.\tExcelcium – Pralines BonBons De Chocolat 200g",
                "3.\tSt.Michael 9Grandes Galettes Chocolate Butter 150g",
                "4.\tPopcorn Shed Butterscotch 80g",
                "5.\tHamlet Assortiment Belgian Chocolate 125g",
                "6.\tDavidoff Café Grande Cuvee Rich Aroma 45g",
                "7.\tWalkers Belgian Chocolate Chunk Biscuits 150g",
                "8.\tGolden Boronia Nougat Original Crunchy 250g",
                "9.\tTiptree Morello Cherry Conserve 340g",
                "10.\tTiptree Orange & Tangerine Fine Cut Marmalade 340g",
                "11.\tExquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 667,
        "name": "Cheer and Toast (Sparkling) GHP",
        "handle": "sparkling-ghp",
        "description": "Cheer and Toast (Sparkling)The Cheer and Toast (Sparkling) gourmet food hamper is a tasty assortment of gourmet treats, perfectly paired with Lindeman’s Sparkling Cuvée. Indulge in good taste and cheer and toast to delightful moments, made extra special with this vibrant curation of flavors!",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "440.43",
        "inclTax": "440.43",
        "img": [
            "/images/products/Sparkling2.jpg?v=1664495955",
            "/images/products/Sparkling1-330851.jpg?v=1671684909"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tVal D’OGA Millesimato Prosecco 75cl",
                "2.\tAalst Whole Almonds Milk Chocolate Gift Tin 150g",
                "3.\tCupido Dessert Cups Tiramisu 125g",
                "4.\tJuan tea 9g x 2 boxes",
                "5.\tK.Harrodson Butter Cookies Tin 180g",
                "6.\tMrs Bridges – Orange Marmalade with Honey 340g",
                "7.\tForum Hamlet Oublies 125g",
                "8.\tExcelcium – Pralines BonBons De Chocolate 200g",
                "9.\tExquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 668,
        "name": "Indulgence (Sparkling) GHP",
        "handle": "frizzante-ghp",
        "description": "Indulgence (Sparkling)Our Indulgence (Sparkling) gourmet hamper is curated just to take your tea party up a notch! Featuring tea time favorites and sweet and delectable treats, you can be sure that your receiver will indulge happily in this delectable basket of treats!",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "458.68",
        "inclTax": "458.68",
        "img": [
            "/images/products/Frizzante-2.jpg?v=1664495946",
            "/images/products/Frizzante-1.jpg?v=1664495946"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tVal D’Oga Millesimato Prosecco 75cl",
                "2.\tDanisa Butter Cookies 200g",
                "3.\tGuylian Belgian Chocolates Premium Dark 72% 100g",
                "4.\tJules Destrooper Chocolate Thins 100g",
                "5.\tGolden Bon Bon Soft Almond Nougat Gift Box 130g",
                "6.\tCupido Hazelnut Chocolate Seashells 250g",
                "7.\tMrs Bridges Mini Traditional Preserve Pack 42g x 3",
                "8.\tTipson Ceylon Green Tea 25 Tea Bags",
                "9.\tFarmhouse Luxury Salted Caramel Biscuits 150g",
                "10.\tExquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 669,
        "name": "The Celebration (Non Alcoholic) GHP",
        "handle": "celebration-ghp",
        "description": "The Celebration (Non Alcoholic)",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "481.27",
        "inclTax": "481.27",
        "img": [
            "/images/products/The-Celebration-_Non-Alcoholic_-GHP1.jpg?v=1706589461",
            "/images/products/The-Celebration-_Non-Alcoholic_-GHP2-443615.jpg?v=1706717674"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Bickfords Cordial Australia  Blackcurrant 750ml",
                "2.  Rossella Pouchette Fondant Frutta 45g",
                "3.  Wallkers Scottish Biscuits for Cheese 250g",
                "4.  El Almendro Dark Chocolate Bombones 70% Cacao 150g",
                "5.  Primo Forno Traditional Breadsticks with Parmesan Cheese 125g",
                "6.  Heidi Mountains Hazel Nuts Premium Chocolate 150g",
                "7.  San Andres Almonds Nougat 150g",
                "8.  Fiorella Chocolate Mounted Biscuits 102g",
                "9.  Nairns Raspberry & Blueberry Biscuits 160g",
                "10.  Beechs Violet Creams 90g",
                "11.  La Mere Poulard Cookies Eclat Chocolat  Chocolate Chips Cookies 200g",
                "12.  Exquisite Picnic Basket (L)"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 670,
        "name": "Gastronome (Red Wine) GHP",
        "handle": "celebrazione-ghp",
        "description": "Gastronome (Red Wine)Here is a gift pack every oenophile will cherish and that is basically down to the bottle of luscious red wine in it. But that’s not all; there are also many tasteful treats.",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "531.48",
        "inclTax": "531.48",
        "img": [
            "/images/products/Celebrazione2-125707.jpg?v=1671685312",
            "/images/products/Celebrazione1-839031.jpg?v=1671685312"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tElegance Cabernet Sauvignon 75cl",
                "2.\tExcelcium – Pralines BonBons De Chocolat 200g",
                "3.\tSt.Michael 9 Grandes Galettes Chocolate Butter 150g",
                "4.\tPopcorn Shed Butterscotch 80g",
                "5.\tHamlet – Assortiment Belgian Chocolate 125g",
                "6.\tDavidoff Café – Grande Cuvee Rich Aroma 45g",
                "7.\tWalkers Belgian Chocolate Chunk Biscuits 150g",
                "8.\tGolden Boronia Nougat Original Crunchy 250g",
                "9.\tTiptree – Morello Cherry Conserve 340g",
                "10.\tTiptree – Orange & Tangerine Fine Cut Marmalade 340g",
                "11.\tExquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 671,
        "name": "Cutie Pie BHP",
        "handle": "precious-bhp",
        "description": "Cutie PieCelebrate the arrival of a new cutie pie with the Cutie Pie newborn baby gift hamper! We have included fun toys and grooming essentials, as well as the Hello Baby Milestone Book, just so all these precious milestones can be recorded.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "166.36",
        "inclTax": "166.36",
        "img": [
            "/images/products/Jenny2a-122773.jpg?v=1671685198",
            "/images/products/Jenny1-727449.jpg?v=1671685198"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "\n1.\tBird’s Nest with Ginseng & Rock Sugar 3 x70ml",
                "2.\tFun Orbit Turn Happy Ball Toy Set",
                "3.\tDrypers Wee Wee Dry Diapers M size (4pcs)",
                "4.\tPigeon Baby Wipes 30s 100% Pure Water",
                "5.\tHello Baby Milestone Book",
                "6.\tBaby K Flower Teether",
                "7.\tExquisite Gift Box",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 672,
        "name": "Honey Little BHP",
        "handle": "honey-bhp",
        "description": "Honey LittleThe Honey Little newborn baby gift tells new parents just how sweet their exciting new journey will be! Other than grooming essentials, we have also included the BabyK romper gift set to make sure baby gets dressed in style! Packed in an exquisite gift basket, this hamper will surely delight new parents.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "166.36",
        "inclTax": "166.36",
        "img": [
            "/images/products/Honey-Little-BHP1-627399.jpg?v=1722357913",
            "/images/products/Honey-Little-BHP2-458731.jpg?v=1722357913"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Baby K Romper Gift Set",
                "2.  Birds Nest with Ginseng & Rock Sugar 2 x70ml",
                "3.  KinderNurture Organic Baby Wipes 20s",
                "4.  Fiffy Organic Moisturizing Head To Toe Wash 750ml",
                "5.  Fiffy Mini Cotton Buds 400 Buds",
                "6.  Exquisite Gift Basket (S)"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 673,
        "name": "Mummy’s Love BHP",
        "handle": "sandy-bhp",
        "description": "Mummy’s LoveCelebrate mummy’s love and tell her it’s a job well done with the Mummy Love newborn baby hamper. We have included an organic bamboo swaddling blanket, washcloths and a hand rattle from BabyK, as well as organic baby wipes for on the go grooming! And for that extra touch of pampering, we have added bird’s nest with ginseng, to make sure mummy’s efforts are rewarded!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "184.61",
        "inclTax": "184.61",
        "img": [
            "/images/products/Sandy2-869901.jpg?v=1671685492",
            "/images/products/Sandy1-789801.jpg?v=1671685492"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tBird’s Nest with Ginseng & Rock Sugar 2 x70ml",
                "2.\tBaby K- Organic Bamboo Swaddling Blanket",
                "3.\tBaby K- Organic Bamboo 6 pcs pack Washcloth",
                "4.\tBaby K Organic Baby Hand Rattles",
                "5.\tKinderNurture Organic Baby Wipes 40’s",
                "6.\tExquisite Gift Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 674,
        "name": "Demure Celebration BHP",
        "handle": "selphvy-bhp",
        "description": "Demure CelebrationThe Demure Celebration baby gift commemorates the arrival of the previous newborn in style. Quietly sophisticated and subtly beautiful, this hamper is loaded with baby essentials that are organic and safe for use on delicate newborn skin. We have also included the Hello Baby Milestone Book, to make sure those precious memories are recorded and remembered.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Selphvy2-383468.jpg?v=1671685199",
            "/images/products/Selphvy1-164010.jpg?v=1671685199"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tBaby K- Organic Cotton Head Shaping Baby Pillow Safari",
                "2.\tBaby K- Organic Bamboo 6 pcs pack Washcloth",
                "3.\tHello Baby Milestone Book",
                "4.\tFomec Berry Essence 41ml x 2",
                "5.\tBaby K Food Grade Silicone Giraffe Teether",
                "6.\tBaby K Bath Brush Set",
                "7.\tExquisite Gift Box",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 675,
        "name": "My Outdoor Essential BHP",
        "handle": "emmanuel-bhp",
        "description": "My Outdoor EssentialGet ready for outdoor adventures with the My Outdoor Essential newborn gift hamper! Keep baby cool and safe from pests with the mini clip-on fan with ultrasonic mosquito repellent! Entertain their senses on the go with the BabyK fruit teether pacifier, and clean up any mess with our handy wet wipes! This gift hamper is truly the perfect outdoor essential for families who love adventuring!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/My-Outdoor-Essential-BHP1_7ec110e4-fb2d-41c9-abc1-413fd27c8c3e-747635.jpg?v=1722358168",
            "/images/products/My-Outdoor-Essential-BHP2_ad249e6c-b7f4-46e4-9bc9-41363fd5cab5-842500.jpg?v=1722358168",
            "/images/products/My-Outdoor-Essential-BHP3_f0373ec8-b8f0-403e-b264-705529b3afaf-319408.jpg?v=1722358168"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Birds Nest with Ginseng & Rock Sugar 2 x70ml",
                "2.  New Moon Essence of Chicken 2 x 68ml",
                "3.  Lucky Baby Sunny Shield Sun Shade Clip",
                "4.  Pigeon Liquid Cleanser 200ml",
                "5.  Baby K Fruit Feeder Pacifier",
                "6.  Drypers Wee Wee Dry Diapers M size (4pcs)",
                "7.  Pigeon Baby Wipes 30s 100% Pure Water",
                "8.  Fiffy Baby Compressed Disaposable Towel (70cm x 105cm) x 2",
                "9.  My Little One Mosquito Repellent Patch 10s",
                "10.  Exquisite Tray Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 30cm"
    },
    {
        "id": 676,
        "name": "Mum’s Purest Love BHP",
        "handle": "xavier-bhp",
        "description": "Mum’s Purest LoveThe Mum’s Purest Love baby hamper is one of the best hampers in Malaysia that you can get for new parents. For babies, we have included an organic head shaping pillow and organic bamboo washcloths from BabyK, and for mummies, we have added bird’s nest for that extra touch of pampering.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Angelia2-510020.jpg?v=1671685495",
            "/images/products/Angelia1-390206.jpg?v=1671685495"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tBird’s Nest with Ginseng & Rock Sugar 2 x70ml",
                "2.\tBaby K Organic Bamboo 6 pcs pack Washcloth (green & yellow)",
                "3.\tBaby K Organic Cotton Head Shaping Baby Pillow Safari",
                "4.\tBaby K Organic Hand Shake Toy",
                "5.\tNaturaverde Bio Organic Body and Hair Wash 200ml",
                "6.\tKinderNurture Organic Baby Wipes 40’s",
                "7.\tExquisite Gift Box",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 677,
        "name": "Mum And Baby Precious Girl BHP",
        "handle": "ivine-bhp",
        "description": "Mum And Baby Precious GirlHerald the arrival of a precious baby girl with the Mum and Baby newborn gift hamper. An indulgent selection of playtime favorites are exquisitely packed alongside a Baby K Penguin Wobbler and a Baby Milestone Book to truly pamper baby, and make sure each milestone is recorded!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "200.59",
        "inclTax": "200.59",
        "img": [
            "/images/products/Ivine2.jpg?v=1664683006",
            "/images/products/Ivine1-894134.jpg?v=1671685490"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tBaby K Penguin Wobbler",
                "2.\tBaby K Dino Food Grade Teething Toy",
                "3.\tLucky Baby Edee Sippy Cup",
                "4.\tLucky Baby Sippy Buddy Adjustable Strap Fastens Baby Bottle, Cup etc",
                "5.\tBunny Plush Toy",
                "6.\tBird’s Nest with Ginseng & Rock Sugar 2 x70ml",
                "7.\tExquisite Tray Box",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 30cm"
    },
    {
        "id": 678,
        "name": "Mum And Baby Precious Boy BHP",
        "handle": "adrian-bhp",
        "description": "Mum And Baby Precious BoyHerald the arrival of a precious baby boy with the Mum and Baby newborn gift hamper. An indulgent selection of playtime favorites are exquisitely packed alongside a Baby K Penguin Wobbler and a Baby Milestone Book to truly pamper baby, and make sure each milestone is recorded!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "200.59",
        "inclTax": "200.59",
        "img": [
            "/images/products/Adrian2-719061.jpg?v=1671685491",
            "/images/products/Adrian1-702097.jpg?v=1671685491"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Baby K Penguin Wobbler w Baby Milestone Book",
                "2. Baby K Dino Food Grade Teething Toy",
                "3. Lucky Baby Edee Sippy Cup",
                "4. Lucky Baby Sippy Buddy Adjustable Strap Fastens Baby Bottle, Cup etc",
                "5. Bunny Plush Toy",
                "6. Bird’s Nest with Ginseng & Rock Sugar 2 x70ml",
                "7. Exquisite Tray Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 30cm"
    },
    {
        "id": 679,
        "name": "Blooming Fruits FHP",
        "handle": "fruities-fhp",
        "description": "Blooming FruitsWhen fresh fruits meet flowers, that’s how we created Blooming Fruits. It has a various quality of fresh fruits and flowers. This basket includes a mixture of 7 varieties juicy fresh fruit giving extra benefit to the owner.",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers",
            "Corporate Hampers"
        ],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Fruities1-902251.jpg?v=1671684899",
            "/images/products/Fruities2-108676.jpg?v=1671684899"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Dragon Fruits",
                "2. Nam Shui Pear",
                "3. Apple",
                "4. Green Apple",
                "5. Kiwi",
                "6. Forelle Pear",
                "7. Green Grape",
                "8. Comes with flowers and packaging"
            ]
        },
        "dimention": "Size of Hamper: 33cm x 23cm x 26cm"
    },
    {
        "id": 680,
        "name": "Nourishing Delights FHP",
        "handle": "nourish-fhp",
        "description": "Nourishing DelightsThis classic hamper contains the healthy essentials that anyone needs to feel rejuvenated and revitalized. If you’re struggling to decide on which hamper to buy, the Nourishing Delights is a safe bet!",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers",
            "Corporate Hampers"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Nourish1-747827.jpg?v=1671685496",
            "/images/products/Nourish2-175752.jpg?v=1671685496"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Brands Chicken Essence 42ml x 6",
                "2. Orange",
                "3. Fuji Apple",
                "4. Green Apple",
                "5. Kiwi",
                "6. Forelle Pear",
                "7. Gerbera",
                "8. Roses",
                "9. Rose Spray"
            ]
        },
        "dimention": "Size of Hamper: 29cm x 21cm x 34cm"
    },
    {
        "id": 681,
        "name": "Fanny BBS",
        "handle": "fanny",
        "description": "This exquisite bloom box from Flowers Malaysia presents a symphony of roses in blush and bold orange, paired with snow-white blooms and white caspia. Arranged in a modern cream box with a sleek handle and satin ribbon, it's a chic and portable floral statement.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Fanny-BBS-2b.jpg?v=1721619661",
            "/images/products/Fanny-BBS-1b.jpg?v=1721619661"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses . Spray Roses . Matthiola .Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 22cm*30cm"
    },
    {
        "id": 682,
        "name": "Carine BBS",
        "handle": "carine",
        "description": "This charming floral arrangement in a soft pink box features a delightful mix of pink gerbera daisies, roses, and carnations, accented with white blooms and lush greenery. The arrangement is elegantly topped with a pink ribbon, adding a touch of sophistication and sweetness, perfect for any occasion that calls for a feminine and graceful touch.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Carine-BBS-2a-747967.jpg?v=1721657635",
            "/images/products/Carine-BBS-1a.jpg?v=1721614240"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Rose . Gerbera . Carnation . Spray Carnations . Eustoma .Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 22cm*30cm"
    },
    {
        "id": 683,
        "name": "Hilda BBS",
        "handle": "hilda",
        "description": "This elegant arrangement in a sophisticated black box features a stunning combination of pink, purple, and blue flowers. The arrangement is highlighted by a central purple rose, surrounded by delicate pink blooms, blue accents, and lush greenery. Topped with a lavender ribbon, it exudes a sense of luxury and grace, perfect for making a lasting impression on any occasion.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Hilda-BBS-2a.jpg?v=1721614824",
            "/images/products/Hilda-BBS-1a.jpg?v=1721614824"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Rose . Spray Carnations . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 22cm*30cm"
    },
    {
        "id": 684,
        "name": "Floral Fresh FHP",
        "handle": "betty-fhp",
        "description": "Floral FreshThese vibrant fruits are arranged delicately in a high-quality wicker basket, topped with a classy ribbon and a delightful bouquet of vivid, fresh flowers.",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers"
        ],
        "price": "282.74",
        "inclTax": "282.74",
        "img": [
            "/images/products/Betty1-661008.jpg?v=1671685205",
            "/images/products/Betty2-997423.jpg?v=1671685205"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Mango",
                "2. Dragon Fruits",
                "3. Fuji Apple",
                "4. Forelle Pear",
                "5. Green Grape",
                "6. Nam Shui Pear",
                "7. Orange",
                "8. Green Apple",
                "9. Kiwi",
                "10. Promogranate",
                "11. Flower Arrangement: Roses, Carnation, Eustoma, Coin Leaf"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 685,
        "name": "Pick-me-up FHP",
        "handle": "shannon-fhp",
        "description": "Pick-me-up",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers"
        ],
        "price": "305.56",
        "inclTax": "305.56",
        "img": [
            "/images/products/Shannon1_1-806267.jpg?v=1671685636",
            "/images/products/Shannon2_1-987345.jpg?v=1671685636"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Brands Birdnest 70ml x 6",
                "2. Purple Grape",
                "3. Green Apple",
                "4. Fuji Apple",
                "5. Orange",
                "6. Kiwi"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 686,
        "name": "Sweet Revitalisation FHP",
        "handle": "verdure-fhp",
        "description": "Sweet RevitalisationWhen fresh fruits meet flowers  balloon, that’s how we created Sweet Revitalization. It has a various quality of fresh fruits and flowers. This basket includes a mixture of 8 varieties juicy fresh fruit giving extra benefit to the owner.",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers"
        ],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/verdure1-542942.jpg?v=1671775915",
            "/images/products/verdure2-782335.jpg?v=1671775915"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Crystal Clear Get Well Soon Balloon",
                "2. Orange",
                "3. Kiwi",
                "4. Dragon Fruits",
                "5. Green Grape",
                "6. Avocado",
                "7. Green Apple",
                "8. Fuji Apple",
                "9. Nam Shui Pear",
                "10. Wooden Crate (L)",
                "11. Flower Arrangement: Roses, Rose Spray, Carnation, Eustoma, Veronica"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 687,
        "name": "Sasha BBS",
        "handle": "sasha",
        "description": "This elegant arrangement, showcased in a sleek black box adorned with a matching black ribbon, features a sophisticated mix of flowers. At its heart, a delicate blush rose is surrounded by deep red roses, rich burgundy blooms, and lush green foliage. Accents of red berries add a festive touch, making it a perfect statement piece for any occasion.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Sasha-BBS-2a-224601.jpg?v=1721658151",
            "/images/products/Sasha-BBS-1a.jpg?v=1721613077"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Cappuccino Rose . Spray Roses . Carnations . Eustoma . Hypericum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 22cm*30cm"
    },
    {
        "id": 688,
        "name": "Celia BBS",
        "handle": "celia",
        "description": "This elegant floral arrangement, presented in a chic cream-colored box, features a stunning mix of vibrant yellow lilies, pastel pink roses, and delicate white blooms. Perfectly accented with lush greenery and tied with a graceful ribbon, this bouquet exudes sophistication and charm, making it an ideal gift for any occasion.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Celia-BBS-2a.jpg?v=1721615905",
            "/images/products/Celia-BBS-1b-625190.jpg?v=1726200290"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Menta Rose . Mum . Eustoma . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 22cm*30cm"
    },
    {
        "id": 689,
        "name": "Jennifer BBS",
        "handle": "jennifer",
        "description": "This delightful floral arrangement, presented in a stylish pink box with a bright pink ribbon, features a cheerful mix of flowers. A striking yellow gerbera daisy takes center stage, surrounded by a red carnation, soft pink roses, and creamy white blooms, complemented by lush greenery. Perfect for brightening any space, this arrangement is a charming and thoughtful gift.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Jennifer-BBS-2a-972018.jpg?v=1721657935",
            "/images/products/Jennifer-BBS-1a.jpg?v=1721612681"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Rose . Eustoma . Carnation . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 22cm*30cm"
    },
    {
        "id": 690,
        "name": "Fortune (III)",
        "handle": "fortune-iii",
        "description": "Elevate all grand openings with the Radiant Bloom Trio Tripod Stand. A captivating fusion of pink, champagne, yellow, red, and white Gerbera, this floral masterpiece emanates joy and diversity, symbolizing the vibrant journey that lies ahead. The perfect centerpiece for a momentous celebration.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Fortune-_III_-1b.jpg?v=1720094022",
            "/images/products/Grand-Opening-Card-2024_10fabc28-8207-42c8-a1ee-ae4fc02ac3f3.jpg?v=1720094029",
            "/images/products/Fortune-_III_-2b.jpg?v=1720094021"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerberas . Golden Phoenix . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 691,
        "name": "Tasty & Hearty Pack with Flower WHP",
        "handle": "julian-whp",
        "description": "Tasty  Hearty Pack with FlowerAn intricately packed wellness hamper that will be attractive to the sight of the receiver and also bring about nourishment to heart with some of the best health drinks one will ever find and love to taste.",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Julian-WHP1A-818204.jpg?v=1671775916",
            "/images/products/Julian-WHP2A-713061.jpg?v=1671775916"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBrand’s Essence of Chicken 3 x 41ml",
                "2.\tBrand’s Bird Nests with Rock Sugar 2 x 41ml",
                "3.\tYu Xiang Yan White Fungus Bird’s Nest Drink 3 x 75ml",
                "4.\tAssorted Flowers Packaging",
                "5.\tExquisite Gift Basket",
                ""
            ]
        },
        "dimention": "Approximate dimension : 35cm x 40cm"
    },
    {
        "id": 692,
        "name": "Health Is Wealth WHP",
        "handle": "amy-whp",
        "description": "Health Is WealthAn intricately packed wellness hamper that will be attractive to the sight of the receiver and also bring about nourishment to heart with some of the best health drinks one will ever find and love to taste.",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "246.23",
        "inclTax": "246.23",
        "img": [
            "/images/products/Amy-WHP1-144866.jpg?v=1671685322",
            "/images/products/Amy-WHP2_c591f310-8120-493f-9ea5-5824e3d3f7a3-435068.jpg?v=1671685322"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tKinohimitsu Bird’s Nest Gift Set 8 x 75g",
                "2.\tDragon King- Ginseng Qing Dun Tang (人参清炖汤) 100g",
                "3.\tDragon King- Liu Wei Qing Bu Tang (六味清补汤) 100g",
                "4.     Juan Tea Indulgence Floral Blend Tea Set 10s",
                "5.\tExquisite Gift Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 693,
        "name": "Honey Hive With Flower OHP",
        "handle": "chloe-ohp",
        "description": "Honey Hive With FlowerThis organic floral wellness box is one to present a vegan who is on the road to recovering his/her health. You can be sure that the receiver will not only enjoy the things in it but also keep fond memories of you.",
        "type": "Hampers",
        "tags": [
            "Organic Hampers"
        ],
        "price": "358.05",
        "inclTax": "358.05",
        "img": [
            "/images/products/Chloe-OHP1.jpg?v=1667530809",
            "/images/products/Chloe-OHP2-398909.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tAmazin’ Grace Antioxidant Berry and Nuts Trail Mix 130g",
                "2.\tAmazin’ Graze Mexican Chia Seeds 300g",
                "3.\tFruity Crisps Strawberry & Apple 25g",
                "4.\tSerious Cookies White Chocolate Macadamia 170g",
                "5.\tSun Blast Organic Juice Orange 200ml",
                "6.\tSun Blast Organic Juice Apple and Guava 200ml",
                "7.\tBreitsamer Organic Golden Selection Raw Honey 500g",
                "8.\tPost Great Grains-Cranberry Almond Crunch 396g",
                "9.\tAssorted Flower Packaging",
                "10.\tExquisite Gift Box",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 35m"
    },
    {
        "id": 694,
        "name": "Mum’s Perfect Princess BHP",
        "handle": "lavinia-bhp",
        "description": "Mum’s Perfect PrincessSay hello to the newborn baby girl with the Mum’s Perfect Princess baby gift hamper! It includes receiving blankets that will offer the softest touch to baby from their first breath. Baby will also be comforted with the BabyK wooden toy car and a bunny plush toy, whilst mummy takes a restorative shots of bird’s nest with ginseng, and berry essence. Send love and care today with this happy hamper today.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "221.13",
        "inclTax": "221.13",
        "img": [
            "/images/products/Lavinia2-174369.jpg?v=1671685494",
            "/images/products/Lavinia1-317589.jpg?v=1671685494"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tBird’s Nest with Ginseng & Rock Sugar 70ml x 2",
                "2.\tFormec’s Berry Essence 70ml x 2",
                "3.\tOwen - 2 pcs Receiving Blanket",
                "4.\tBaby K Fleece Blanket",
                "5.\tBaby K Wooden Toy Car",
                "6.\tBunny Plush Toy",
                "7.\tDrypers Wee Wee Dry Diapers M size (4pcs)",
                "8.\tExquisite Tray Box",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 30cm"
    },
    {
        "id": 695,
        "name": "Here Comes Buddy BHP",
        "handle": "aiden-bhp",
        "description": "Here Comes BuddySay Hello to the Here Comes Buddy Hamper! Included in this set is a Buddy the Bear plushie, as featured in our Bailey  Friends collection! Curated for parents who value useful essentials and are also looking to make memories that last, this delightful set is housed in a vintage inspired carry trunk, designed also to house all your precious mementos as a keepsake case.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "287.30",
        "inclTax": "287.30",
        "img": [
            "/images/products/Aiden2-690513.jpg?v=1671685463",
            "/images/products/Aiden1-736580.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Buddy The Bear Plush Toy",
                "2. Kayen & Kayes  Keepsake Mold Kit",
                "3. 3D Airmesh Pillow 11.5 x 7.5",
                "4. BabyK  The Baby Care Compass",
                "5. BabyK  4 Pcs Cotton Starter Set",
                "-Romper",
                "-Mitten",
                "-Booties",
                "-Cap",
                "6. BabyK  Baby Wash Cloth",
                "7. BabyK  Multi Purpose Flannel Blanket",
                "8. Bailey & Friends Keepsake Box"
            ]
        },
        "dimention": "Width*Height: 26cm x 26cm"
    },
    {
        "id": 696,
        "name": "Foodtime Fun BHP",
        "handle": "foodtime-bhp",
        "description": "Foodtime FunMealtimes will never be dull with the Foodtime Fun baby gift hamper! Comes with the BabyK Thermal Food Jar and Silicone Spoons Set to make feeding a fun and exciting experience, and baby wipes to mop up any learning mess, eating will only be about thrills and not the spills! Introduce baby to the joy of eating today!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Foodtime-BHP2-752823.jpg?v=1671685311",
            "/images/products/Foodtime-BHP1-277595.jpg?v=1671685311"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBaby K Thermal Food Jar",
                "2.\tMunchkin -Formula Milk Dispenser Combo Pack",
                "3.\tBaby K Bamboo Silicone Spoons Set",
                "4.\tPigeon Baby Wipes 30s 100% Pure Water",
                "5.\tPigeon Liquid Cleanser 200ml",
                "6.\tBaby K Food Grade Silicone Giraffe Teether",
                "7.\tExquisite Tray Box",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 30cm"
    },
    {
        "id": 697,
        "name": "Welcome To The World BHP",
        "handle": "dearest-bhp",
        "description": "Welcome To The WorldThe Welcome To The World baby gift hamper ensures that the exciting new arrival is celebrated in style. We have included baby essentials such as organic bamboo washcloths and a head shaping pillow from BabyK, and a hand rattle to aid baby’s development! We also did not forget about new mummies and daddies, and have added bird’s nest to help them to recharge and relax.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Dearest2-358761.jpg?v=1671775978",
            "/images/products/Dearest1-398488.jpg?v=1671775978"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tNaturaverde Bio Organic Body and Hair Wash 200ml",
                "2.\tBaby K- Organic Cotton Head Shaping Baby Pillow",
                "3.\tShears Organic 3-pc bibs",
                "4.\tBaby K Organic Hand Shake Toy",
                "5.\tBaby K- Organic Bamboo 6 pcs pack Washcloth",
                "6.\tBird’s Nest with Ginseng & Rock Sugar 3 x70ml",
                "7.\tExquisite carton box",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 30cm"
    },
    {
        "id": 698,
        "name": "Mum and Baby First Gift BHP",
        "handle": "mummy-bhp",
        "description": "Mum and Baby First GiftWelcome a very special newborn baby with the Mum and Baby First Gift hamper. We have included a swaddling blanket to keep baby cosy, and an organic romper set to keep baby stylish! Packed exquisitely in a wooden crate, this newborn baby hamper will make your wishes an unforgettable one.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "241.66",
        "inclTax": "241.66",
        "img": [
            "/images/products/Mum-and-Baby-First-Gift-BHP1-449894.jpg?v=1722358166",
            "/images/products/Mum-and-Baby-First-Gift-BHP2.jpg?v=1722231167",
            "/images/products/Mum-and-Baby-First-Gift-BHP3_ade2bf6f-c3a0-49c5-97c3-44e9be091a9e.jpg?v=1722231205"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Fiffy Laundry Detergent with Softener 1000ml",
                "2.  Baby K Organic Bamboo Swaddling Blanket",
                "3.  Shears Organic 2 pc Gift Set with Romper",
                "4.  Assorted Flower Packaging",
                "5.  Exquisite Wooden Crate (L)"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 699,
        "name": "Tyke Care BHP",
        "handle": "cubie-bhp",
        "description": "Tyke CareA beautiful newborn hamper featuring mealtime essentials, the Tyke Care bundle is carefully curated to make feeding fun and exciting for baby! Presented in an exquisite gift basket, the Sassy Bib ties everything together for a perfect mealtime delight that baby and mom can look forward daily!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Cubie2-380228.jpg?v=1671775918",
            "/images/products/Cubie1-870217.jpg?v=1671775918"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tShears -5PCS Bamboo Feeding Set",
                "2.\tMunchkin – Soft Tip Infant Spoon 6 pk",
                "3.\tMunchkin Formula Dispenser",
                "4.\tLucky Baby Safety First Food Cutter",
                "5.\tPigeon Liquid Cleanser 700 ml x 2 bottles",
                "6.\tSassy Bib 1pc",
                "7.\tExquisite Gift Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 700,
        "name": "Mum’s Little Angel BHP",
        "handle": "angel-bhp",
        "description": "Mum’s Little AngelThe Mum’s Little Angel beautiful newborn baby hamper includes all the bathtime essentials to keep new parents prepared! Pamper mummies with birds’ nest to ensure that they can keep up with their new bundle of joy! Send the best wishes to new parents today with this baby gift hamper.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/Mum_s-Little-Angel1.jpg?v=1705976299",
            "/images/products/Mum_s-Little-Angel2.jpg?v=1705976300"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBird’s Nest with Ginseng & Rock Sugar 3x70ml",
                "2.\tBaby K Romper Gift Set",
                "3.\tPigeon Baby Laundry Detergent Pure 800ml",
                "4.\tPigeon Natural Botanical Baby Travel 4pc Set",
                "– Baby Head & Body Wash 45ml",
                "– Baby Water Gel 45ml",
                "– Baby Milky Lotion 45ml",
                "– Baby Massage Oil 45ml",
                "5.\tAssorted Flowers Packaging",
                "6.\tExquisite Wooden Crate (L)",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 701,
        "name": "Bunny’s Welcome BHP",
        "handle": "bunny-bhp",
        "description": "Bunny’s WelcomeCommemorate the joyful occasion with the Bunny’s Welcome baby gift hamper. We celebrate not just the precious arrival of a newborn, but also the new parents! This is why we have included not just baby essentials, but also a survival guidebook to tide them over the challenging first months. We have also added a bunny best friend, for extra comfort and cuddles.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "383.15",
        "inclTax": "383.15",
        "img": [
            "/images/products/Bunny_s-Welcome-BHP2-416407.jpg?v=1708969689",
            "/images/products/Bunny_s-Welcome-BHP1.jpg?v=1708921778"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBaby K Diaper Caddy Organizer Basket",
                "2.\tKorean Collagen Drink 5300mg 6 x 50ml",
                "3.\tThe Baby Care – A Survival Guide Book For New Parents",
                "4.\tBunny Plush Toy",
                "5.\tPigeon Natural Botanical Baby Head and Body Wash 200ml",
                "6.\tPigeon Natural Botanical Baby Milky Lotion 200ml",
                "7.\tCloversoft Nature & Organic Unbleached Bamboo Wipes 70s",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 35cm"
    },
    {
        "id": 702,
        "name": "Joyous Welcome BHP",
        "handle": "joyous-bhp",
        "description": "Joyous WelcomeCommemorate the joyful occasion with the Joyous Welcome baby gift hamper. We celebrate not just the precious arrival of a newborn, but also the new parents! This is why we have included not just baby essentials, but also a survival guidebook to tide them over the challenging first months. For extra pampering, we have also added bird’s nest as a treat for mummies and daddies!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "346.64",
        "inclTax": "346.64",
        "img": [
            "/images/products/Joyous-Welcome-672277.jpg?v=1698138496",
            "/images/products/Joyous-Welcome1-975065.jpg?v=1698138496"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBaby K Signature Diaper Bag",
                "2.\tThe Baby Care – A Survival Guide Book for New Parents",
                "3.\tBird’s Nest with Ginseng & Rock Sugar 2 x70ml",
                "4.\tBaby K Romper Gift Set",
                "5.\tBaby K Thermal Food Jar",
                "6.\tDrypers DryPantz Diapers M size (4 pcs)",
                "7.\tHello Baby Milestone Book",
                "8.\tExquisite White Basket (L)",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 703,
        "name": "Soothing Soliloquy with Flower BHP",
        "handle": "soliloquy-bhp",
        "description": "Soothing Soliloquy with FlowerSooth new mummies with the delectable and nourishing contents of the Soothing Soliloquy baby gift hamper. Curated with new parents and especially new mummies in mind, it invites them to take a break from the hectic schedule of new parenthood, and seek a tranquil moment of peace for a spa-like treat.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "405.97",
        "inclTax": "405.97",
        "img": [
            "/images/products/Soliloquy2-253789.jpg?v=1671775907",
            "/images/products/Soliloquy1-560254.jpg?v=1671775907"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBaby K Signature Diaper Bag",
                "2.\tSpaFoods -Longan & Red Date Tea 15 x 12g sachets per pack",
                "3.\tThermal Flask",
                "4.\tSpaFoods - Original Aged Ginger Tea – Petite Jar 100g",
                "5.\tAssorted Flower Packaging",
                "6.\tExquisite Gift Basket (L)",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 704,
        "name": "Belinda",
        "handle": "belinda",
        "description": "This exquisite bouquet showcases a harmonious blend of purple, pink, and white blooms, including chrysanthemums, carnations, and lisianthus. Wrapped in elegant white paper and adorned with a lavender ribbon, it makes a stunning gift for any celebration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Belinda-2c.jpg?v=1720692684",
            "/images/products/Belinda-1c.jpg?v=1720692684"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Carnations . Matthiola  .  Eustomas  .  Tulip . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 705,
        "name": "Alanis",
        "handle": "alanis",
        "description": "Embrace Serenity with Our Blue-Tinted White Rose BouquetThis exquisite bouquet features 6 blue-tinted white roses, delicately accented with sprigs of greenery and wrapped in elegant white paper. The soft hues and graceful arrangement make it a perfect expression of calm and beauty for any occasion.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Alanis-2a.jpg?v=1720920236",
            "/images/products/Alanis-1a.jpg?v=1720920237"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 706,
        "name": "Fruity Essentials FHP",
        "handle": "botany-fhp",
        "description": "Fruity EssentialsIn this lovely wooden crate lays the freshest and most delicious fruits that we could find! The scrumptious and refreshing fruits are arranged lovingly in a beautiful crate, perfect for gift-giving.",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers"
        ],
        "price": "166.36",
        "inclTax": "166.36",
        "img": [
            "/images/products/Aimer-FHP1-638115.jpg?v=1671685311",
            "/images/products/Aimer-FHP2-401451.jpg?v=1671685311"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1. Nam Shui Pear",
                "2. Purple Grape",
                "3. Forelle Pear",
                "4. Orange",
                "5. Dragon Fruits",
                "6. Fuji Apple",
                "7. Avocado",
                "8. Kiwi",
                "9. Wooden Crate (M)"
            ]
        },
        "dimention": "Size of Hamper : 30cm x 23cm x 25cm"
    },
    {
        "id": 707,
        "name": "Ocean Dreams FNB",
        "handle": "andrius-fnb",
        "description": "Ocean DreamsSail away with the Ocean Dreams flower and balloon bouquet, and enjoy the peace and serenity channeled through the soothing blue hues of this flower box arrangement. Flowers including roses, eustoma and preserved hydrangeas in this exquisite hat box make it perfect for complementing any message you want to send.",
        "type": "Balloons & Flowers",
        "tags": [
            "Congratulations Balloons & Flowers ",
            "Graduation Balloons Bouquet"
        ],
        "price": "241.66",
        "inclTax": "241.66",
        "img": [
            "/images/products/Andrius-FNB1-844310.jpg?v=1671685497",
            "/images/products/Andrius-FNB2-352019.jpg?v=1671685497"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  Roses",
                "2. Eustoma",
                "3. Preserved Hydragea",
                "4. Eucalyptus",
                "5. Personalised Text On Balloon with 4 Mini Balloons",
                "6. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 708,
        "name": "Whimsical Sunset FNB",
        "handle": "laurice-fnb",
        "description": "Whimsical SunsetThe Whimsical Sunset is marries fairytale with romance in a lovely blend of lush greens and flowers including roses, eustomas, gerberas, preserved hydrangea, and coin leaves. Presented in an exquisite hat box, this flower bouquet with balloons sends a personalised message on the balloon to let the ones you love know how much they light up your life.",
        "type": "Balloons & Flowers",
        "tags": [
            "Happy Birthday Balloons Bouquet"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Laurice1-808690.jpg?v=1671775978",
            "/images/products/Laurice2_1d43ad42-f678-485a-b1eb-3cfcd18bb344-162505.jpg?v=1671775978"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.   Roses",
                "2.  Eustoma",
                "3.  Gerbera",
                "4.  Preserved Hydrangea",
                "5.  Eucalyptus",
                "6.  Personalized Text On Balloon",
                "7.  Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 709,
        "name": "Endearing Wishes FNB",
        "handle": "monalisa-fnb",
        "description": "Endearing WishesLike its namesake, the Endearing Wishes flower and balloon arrangement offers up just the warmest and heartfelt wishes to your recipient in the most unforgettable way. Roses, eustoma and matthiola in this elegant flower box arrangement deliver just the right sentiment for any occasion. Send a personalised message on the balloon, to make this gift extra special.",
        "type": "Balloons & Flowers",
        "tags": [
            "Happy Birthday Balloons Bouquet"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Monalisa-FNB1-868010.jpg?v=1671685202",
            "/images/products/Monalisa-FNB2-716363.jpg?v=1671685202"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  Roses",
                "2. Eustoma",
                "3. Rose Spray",
                "4. Eucalyptus",
                "5. Matthiola",
                "6. Preserved Rice Flower",
                "7. Personalized Text On Balloon with 4 Mini Balloons",
                "8. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 710,
        "name": "Ivory Dreams FNB",
        "handle": "claudine-fnb",
        "description": "Ivory DreamsThe definition of grace and beauty, the Ivory Dreams is an elegant bouquet that brings cheer and flowering sophistication to your recipient. Featuring a collection of hydrangea, eustoma and roses, and highlighted with hypericum, caspia and coin leaves.",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Balloons Bouquet"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Claudine1A-757902.jpg?v=1671685464",
            "/images/products/Claudine3-902323.jpg?v=1671685464"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.   Roses",
                "2.  Eustoma",
                "3.  Rose Spray",
                "4.  Preserved Hydrangea",
                "5.  Caspia",
                "6.  Eucalyptus",
                "7.  Personalised Text On Balloon",
                "8.  Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 711,
        "name": "Dusk FNB",
        "handle": "shanice-fnb",
        "description": "DuskChanneling the romance and mystery right before nightfall, the Dusk flower and balloon bouquet features the rich hues of nature through roses, eustoma, carnations and matthiola.",
        "type": "Balloons & Flowers",
        "tags": [
            "Congratulations Balloons & Flowers "
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/DuskFNB1-443429.jpg?v=1709056409",
            "/images/products/Shanice2-934469.jpg?v=1709013253"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.   Roses",
                "2.  Eustoma",
                "3.  Carnation",
                "4.  Preserved Hydragea",
                "5.  Caspia",
                "6.  Eucalyptus",
                "7.  Personalized Text On Balloon with 4 Mini Balloons",
                "8.  Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 712,
        "name": "Demure Whispers FNB",
        "handle": "stella-fnb",
        "description": "Demure WhispersExpress comfort, love and endearment with the Demure Whispers flower and balloon bouquet. Styled using blushing pink and pure white blooms of hydrangea, eustoma and roses, and accented with hypericum, caspia and coin leaves, this is an arrangement for those sweetest moments. ",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "323.82",
        "inclTax": "323.82",
        "img": [
            "/images/products/Demure-Whispers-FNB1-454412.jpg?v=1709143338",
            "/images/products/Aila2A_cfb46203-7468-49ae-86ef-0f1c0d09da0e-930299.jpg?v=1709083986"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.   Hydrangea",
                "2.  Eustoma",
                "3.  Roses",
                "4.  Rose Spray",
                "5.  Hypericum",
                "6.  Caspia",
                "7.  Eucalyptus",
                "8.  Personalized Text On Balloon with 4 Mini Balloons",
                "9.  Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 713,
        "name": "Blue Fairy FNB",
        "handle": "emma-fnb",
        "description": "Blue FairyThe Blue Fairy flower and balloon hamper includes roses, eustomas, preserved hydrangeas and coin leaves, to send your recipient the spirit of calm and serenity. Pamper them by helping them further relax with our wood mist diffuser in Sleep Ocean, to gently sweep them away with the calmness of the sea. ",
        "type": "Balloons & Flowers",
        "tags": [
            "Anniversary Balloons & Flowers"
        ],
        "price": "583.96",
        "inclTax": "583.96",
        "img": [
            "/images/products/Emma1A-621180.jpg?v=1671685011",
            "/images/products/Emma2-668178.jpg?v=1671685011"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.   Wood Mist Small Diffuser  Sleep Ocean 60ml",
                "2.  Roses",
                "3.  Eustoma",
                "4.  Preserved Hydrangea",
                "5.  Eucalyptus"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 714,
        "name": "Magical Minstrel FNB",
        "handle": "sophia-fnb",
        "description": "Magical MinstrelThe Magical Minstrel is a bouquet of flowers and balloons in a blushing display of loving kindness. Soft pink roses are sweetly dazzling amongst eustomas, gerberas, waxflowers, carnations and the lush greens from hypericum and coin leaves.",
        "type": "Balloons & Flowers",
        "tags": [
            "Happy Birthday Balloons Bouquet"
        ],
        "price": "303.28",
        "inclTax": "303.28",
        "img": [
            "/images/products/Sophia1B-103244.jpg?v=1671685491",
            "/images/products/Sophia2-362093.jpg?v=1671685491"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  Roses",
                "2. Eustoma",
                "3. Gerbera",
                "4. Waxflower",
                "5. Hypericum",
                "6. Carnation",
                "7. Eucalyptus",
                "8. Personalized Text On Balloon with 4 Mini Balloons",
                "9. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 715,
        "name": "Pure Freshness FHP",
        "handle": "radiance-fhp",
        "description": "Pure FreshnessThe Pure Freshness will surely be a great gift for anyone feeling a little down. With gorgeous flowers, the freshest fruits and wholesome bird’s nest, who wouldn’t feel revitalized.",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers",
            "Corporate Hampers"
        ],
        "price": "355.76",
        "inclTax": "355.76",
        "img": [
            "/images/products/Radiance1a-574205.jpg?v=1671685641",
            "/images/products/Radiance2a-321311.jpg?v=1671685641"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  New Moon  Bird Nest 70ml x 6",
                "2. Avocado",
                "3. Kiwi",
                "4. Green Apple",
                "5. Orange",
                "6. Fuji Apple",
                "7. Roses",
                "8. Gerbera",
                "9. Orchid  Medium",
                "10. Eustoma",
                "11. Wooden Crate (L)"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 716,
        "name": "Sakura Dreams FNB",
        "handle": "mia-fnb",
        "description": "Sakura DreamsPops of pink in this arrangement featuring dry cotton flowers, roses and rose sprays will make sure your recipient feels like you have showered them in the beautiful cherry blossoms that signify new beginnings. Personalise your well wishes with a customisable balloon message!",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet",
            "Happy Birthday Balloons Bouquet",
            "Congratulations Balloons & Flowers ",
            "Graduation Balloons Bouquet"
        ],
        "price": "323.82",
        "inclTax": "323.82",
        "img": [
            "/images/products/Sakura-Dreams-FNB1.jpg?v=1709094099",
            "/images/products/Mia2-929632.jpg?v=1709094099"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  Dry Flower Cotton",
                "2. Roses",
                "3. Eustoma",
                "4. Rose Spray",
                "5. Hypericum",
                "6. Preserved Hydrangea",
                "7. Eucalyptus",
                "8. Personalized Text On Balloon with 6 Mini Balloons",
                "9. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 717,
        "name": "Recovery Pack FHP",
        "handle": "flourish-fhp",
        "description": "Recovery PackThis amazing fruit  tonic hamper will surely be a great gift for anyone feeling a little down. With gorgeous flowers, the freshest fruits and wholesome Brand's chicken essence, who wouldn’t feel revitalized the same day.",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers",
            "Corporate Hampers"
        ],
        "price": "408.25",
        "inclTax": "408.25",
        "img": [
            "/images/products/Flourish-FHP1-350877.jpg?v=1702988906",
            "/images/products/Flourish-FHP2-288362.jpg?v=1702988906"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Brands Chicken Essence 42ml x 6",
                "2. Purple Grape",
                "3. Orange",
                "4. Mango",
                "5. Dragon Fruits",
                "6. Kiwi",
                "7. Flower Arrangement: Hydrangea, Roses, Gerbera, Matthiola, Eustoma",
                "8. Wooden Crate (L)"
            ]
        },
        "dimention": "Width*Height: 35cm x 35cm"
    },
    {
        "id": 718,
        "name": "Picnic Essentials FHP",
        "handle": "naturie-fhp",
        "description": "Picnic EssentialsThis amazing fruit  flowers basket will surely be a great gift for anyone today. With gorgeous flowers and the freshest fruits, who wouldn’t feel revitalized the same day.",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers",
            "Corporate Hampers"
        ],
        "price": "431.07",
        "inclTax": "431.07",
        "img": [
            "/images/products/Naturie-FHP1-676778.jpg?v=1671685636",
            "/images/products/Naturie-FHP2-364632.jpg?v=1671685636"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Fuji Apple",
                "2. Orange",
                "3. Kiwi",
                "4. Green Apple",
                "5. Nam Shui Pear",
                "6. Pomegranate",
                "7. Dragon Fruits",
                "8. Forelle Pear",
                "9. Strawberry",
                "10. Avocado",
                "11. Green Grape",
                "12. Flower Arrangement: Gerbera, Roses, Rose Spray, Hypericum, Coin Leaf",
                "13. Large Hamper Basket (Change of Picnic Basket)"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 719,
        "name": "Petal Dreams FNB",
        "handle": "vida-fnb",
        "description": "Petal DreamsA deliberately delicate flower and balloon bouquet comprising dry cotton flowers, roses and rose sprays, Petal Dreams sends more than just a subtle message! Each bloom is a gentle but thoughtful reminder of your well wishes and love, to create a lovely way to express your warmest sentiments.",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet",
            "Happy Birthday Balloons Bouquet",
            "Congratulations Balloons & Flowers ",
            "Anniversary Balloons & Flowers",
            "Graduation Balloons Bouquet"
        ],
        "price": "378.58",
        "inclTax": "378.58",
        "img": [
            "/images/products/Petal-Dreams-FNB1.jpg?v=1709094495",
            "/images/products/Vida2_2f558139-7286-4ce0-ad16-026d83ed0725-362869.jpg?v=1709094495"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  Lindt Swiss Thin Chocolate",
                "2. Dry Flower Cotton",
                "3. Roses",
                "4. Eustoma",
                "5. Rose Spray",
                "6. Hypericum",
                "7. Preserved Hydrangea",
                "8. Eucalyptus",
                "9. Personalized Text On Balloon with 6 Mini Balloons",
                "10. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 720,
        "name": "Renewed Wellness FHP",
        "handle": "wellness-fhp",
        "description": "Renewed WellnessThe Pure Freshness will surely be a great gift for anyone feeling a little down. With gorgeous flowers, the freshest fruits and wholesome bird’s nest, who wouldn’t feel revitalized.",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers",
            "Corporate Hampers"
        ],
        "price": "451.61",
        "inclTax": "451.61",
        "img": [
            "/images/products/Wellness-FHP1-655946.jpg?v=1671685641",
            "/images/products/Wellness-FHP2.jpg?v=1666319006"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  New Moon  Bird Nest 70ml x 6",
                "2. Mango",
                "3. Kiwi",
                "4. Blueberry",
                "5. Green Grape",
                "6. Dragon Fruits Vietnam",
                "7. Flower Arrangement:  Anthurium Small ,Roses, Gerbera, Orchid  Medium, Protea"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 721,
        "name": "Back To Your Best FHP",
        "handle": "sequoia-fhp",
        "description": "Back To Your BestThis beautifully crafted fruit plus tonic basket hamper will surely be a great gift for anyone today. With gorgeous flowers, the freshest fruits, Yomeishu, Chicken Essence and wholesome Berry Essence, who wouldn’t feel revitalized right now.",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers",
            "Corporate Hampers"
        ],
        "price": "691.22",
        "inclTax": "691.22",
        "img": [
            "/images/products/Sequoia-FHP1-727059.jpg?v=1671684752",
            "/images/products/Sequoia-FHP2-461133.jpg?v=1671684752"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Yomeishu 750ml",
                "2. Brand Berry Essense",
                "3. Brands Chicken Essence 42ml x 6",
                "4. Mango",
                "5. Crystal Clear Get Well Soon Balloon",
                "6. Green Grape",
                "7. Fuji Apple",
                "8. Green Apple",
                "9. Nam Shui Pear",
                "10. Kiwi",
                "11. Flower Plant"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 722,
        "name": "Autumn Blooms FNB",
        "handle": "della-fnb",
        "description": "Autumn BloomsThe Autumn Blooms flower and balloon bouquet pays tribute to the coziest time of the year. Roses, eustomas, hydrangeas and waxflowers are artistically styled in an exquisite hat box by some of the best florists in Malaysia.",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "472.15",
        "inclTax": "472.15",
        "img": [
            "/images/products/Della1-560832.jpg?v=1671684751",
            "/images/products/Della2-367445.jpg?v=1671684751"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.   Roses",
                "2.  Eustoma",
                "3.  Hydrangea",
                "4.  Rose Spray",
                "5.  Waxflower",
                "6.  Pink Cushion",
                "7.  Chinese lantern",
                "8.  Wild Flower",
                "9.  Eucalyptus",
                "10.  Personalized Text On Balloon with 4 Mini Balloons",
                "11.  Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 723,
        "name": "Truly Yours FNB",
        "handle": "alice-fnb",
        "description": "Truly YoursWish your loved one a truly special occasion with the Truly Yours balloon and flower hamper. Comprising a mini blackforest cake, this balloon hamper will be presented in an exquisite hat box that is filled with preserved roses, hydrangeas and rice flowers. ",
        "type": "Balloons & Flowers",
        "tags": [
            "Happy Birthday Balloons Bouquet"
        ],
        "price": "545.17",
        "inclTax": "545.17",
        "img": [
            "/images/products/Truly-Yours-FNB1.jpg?v=1709014877",
            "/images/products/Alice3-224521.jpg?v=1709014877"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.   Mini Blackforest Cake",
                "2.  Preserved Rose",
                "3.  Preserved Hydrangea",
                "4.  Preserved Rice Flower",
                "5.  Pittosporum Mini Seed",
                "6.  Preserved Gerbera",
                "7.  Eucalyptus",
                "8.  Wooden Happy Birthday Tag"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 724,
        "name": "Oatly Nuttylicious With Flower WHP",
        "handle": "fann-whp",
        "description": "Oatly Nuttylicious With FlowerA delightful assortment of fresh flowers and healthy snacks in the Oatly Nuttylicious With Flower Get Well Soon Hamper makes this truly an amazing gift. The well-loved assortment of snacks is presented in an exquisite crate to help your loved one feel better immediately! ",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "337.51",
        "inclTax": "337.51",
        "img": [
            "/images/products/FannWHP1a-400037.jpg?v=1671685496",
            "/images/products/Fann-WHP2a-488051.jpg?v=1671685496"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tAmazin Grace Granola Variety Box x 8 packs 320g",
                "2.\tThe Muesli Company Instant Oats Low Sugar Cereal 500g",
                "3.\tEnglish Tea Shop – Organic Super Berries Premium Tea 40g",
                "4.\tColada Wholemeal Biscuits 144g",
                "5.\tPacific Organic Non-Dairy Beverage Oat 946ml",
                "6.\tHarvest Box Super Mix 45g",
                "7.\tHarvest Box PowerMix 45g",
                "8.\tAssorted Flower Packaging",
                "9.\tExquisite Crate (L)",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 725,
        "name": "Sweet Vitality FNB",
        "handle": "kara-fnb-2",
        "description": "Sweet VitalityPacked full of essential health tonics, the Sweet Vitality balloon hamper will relate your well wishes directly, and help a loved one get back on their feet in no time! Presented in an exquisite hat box, you can be certain that your sweet sentiments will be a delight to behold! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Kara1-425005.jpg?v=1671775915",
            "/images/products/Kara2_cd990c6b-e174-4202-a2ce-d2340b91ce26-115049.jpg?v=1671775915"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  Fomec Berry Essences 2 x 42g",
                "2.  Fomec Berry Essences 2 x 42g",
                "3. New Moon Chicken Essences 4 x 68g"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 726,
        "name": "Feast of Colours FNB",
        "handle": "alaska-fnb",
        "description": "Feast of ColoursA feast for the eyes and the mouth, the Feast of Colours fruits hamper is truly our bestseller.  This beautiful arrangement delights the senses with its myriad of fresh colours of nature that will commemorate just about any occasion. Perfect for fruit-lovers, or those who simply cannot resist nature, this balloon fruits hamper is a gift that truly speaks from your heart. ",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Feast-of-Colours-FNB1-721251.jpg?v=1709143343",
            "/images/products/Alaska2-635136.jpg?v=1709083432"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  Strawberry",
                "2.  Mango",
                "3. Orange",
                "4. Apple",
                "5. Kiwi ",
                "6. Green Apple",
                "7. Dragonfruit"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 727,
        "name": "Nature's Gift FNB",
        "handle": "emily-fnb",
        "description": "Nature's GiftWe believe that a gift from nature is the best gift you can receive! With that in mind, we created the Nature’s Gift fruits basket balloon hamper, designed to delight the senses and also the palate! Deliver an extra dose of delight with a personalised message displayed on our balloon!",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Nature_s-Gift-FNB1.jpg?v=1709084250",
            "/images/products/Emily2-387663.jpg?v=1709084250"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Formec Berry Essences 2 x 42g",
                "2. Taste Asia Vegetarian Porridge 400g",
                "3. Cavendish & Harvey Tropical Selection Jar 300g",
                "4. Bickford's Traditional Soda – Sarsaparilla 275ml",
                "5. Blueberry Fruit"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 728,
        "name": "Glow Up FNB",
        "handle": "evie-fnb",
        "description": "Glow UpSurprise the health enthusiast with the Glow Up fruits and balloon hamper! Comprising delectable treats from Amazin’ Graze, delicious fresh fruits and juices, this fruit basket of joy will truly spoil your recipient! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Congratulations Balloons & Flowers "
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Glow-Up-FNB1-553106.jpg?v=1709056521",
            "/images/products/Evie2-770749.jpg?v=1709013929"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Amazin Glaze Goodness Bowl",
                "2.  Colada Wholemeal Biscuits 144g",
                "3.  Gusto Organic Sicilian Blood Orange 275ml",
                "4.  Orange",
                "5.  Apple",
                "6.  Green Apple",
                "7.  Avocado",
                "8.  Kiwi",
                "9.  Pear"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 729,
        "name": "Stunning Smiles FNB",
        "handle": "florence-fnb",
        "description": "Stunning SmilesYour recipient will be beaming with delight when they receive the Stunning Smiles balloon and food hamper! Filled with delectable treats, this hamper is simply perfect for all occasions. To make your gift extra special, include a personalised message on our display balloon! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet",
            "Congratulations Balloons & Flowers "
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Stunning-Smiles-FNB1_75a2147c-10d0-4a2e-9206-714ab12d58f0-389261.jpg?v=1709144582",
            "/images/products/Florence2-570213.jpg?v=1709085455"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Golden Bon Bon Soft Almond Nougat Giftbox 130g",
                "2. Cupido Hazelnut Chocolate Seashells 250g",
                "3. Cavendish & Harvey Tropical Fruit Drops 200g",
                "4. Gavottes Crepe Dentelle 125g",
                "5. ST Dalfour Fruit Spread ( 3 x 28g )",
                "6. Personalised Text on Balloon with 6 Mini Balloons",
                "7. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 730,
        "name": "Simple Harmony FNB",
        "handle": "maya-fnb",
        "description": "Simple HarmonyPacked full of essential health tonics, the Sweet Vitality balloon hamper will relate your well wishes directly, and help a loved one get back on their feet in no time! Presented in an exquisite hat box, you can be certain that your sweet sentiments will be a delight to behold! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/Maya1-328078.jpg?v=1671775907",
            "/images/products/Maya2_1ea941a0-961f-4b3f-8151-471d69e52a24-233210.jpg?v=1671775907"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  Golden Treasure Snow Fungus Bird's Nest 3x70ml",
                "2. Bickfords Traditional Soda – Lemon Lime & Bitters 275ml",
                "3.  Tiptree – Orange & Tangerine Fine Cut Marmalade 340g",
                "4. Colada Wholemeal Biscuits 144g",
                "5. Bunny Plush Toy"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 731,
        "name": "Uplifting Mood FNB",
        "handle": "heidi-fnb",
        "description": "Uplifting MoodA wholesome selection of delectable and nourishing goodies in the Uplifting Mood balloon hamper is set to send your warmest get well wishes in style. Presented in an exquisite hat box, all this gift hamper needs is a personalised balloon message to help your recipient get back on their feet!",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "419.66",
        "inclTax": "419.66",
        "img": [
            "/images/products/Heidi1-160979.jpg?v=1671775918",
            "/images/products/Heidi2-528771.jpg?v=1671775919"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBotanica Round Gel Light 90g",
                "2.\tGerminal Organic buckwheat Flakes 200g",
                "3.\tBeryl’s Waffle Cookies with Gianduja Milk Chocolate 80g",
                "4.\tColada Wholemeal Biscuits 144g",
                "5.\tMrs. Bridges Orange and Tangerine Marmalade",
                "6.\tTiptree – Orange & Tangerine Fine Cut Marmalade 340g",
                "7.\tGeorgia’s Natural 100% Natural Juice – Pear 300ml",
                "8.\tGretel Sprouted Cashews Himalayan Pink Salt, 150g"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 732,
        "name": "Annabelle",
        "handle": "annabelle",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "171.15",
        "inclTax": "171.15",
        "img": [
            "/images/products/Annabelle1-395461.jpg?v=1671684749",
            "/images/products/Annabelle2.jpg?v=1666850558"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mixture of Dried & Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: 21cm*25cm"
    },
    {
        "id": 733,
        "name": "Comfort (IV)",
        "handle": "comfort-iv",
        "description": "Expressing sincere condolences, our flower stands present a serene blend of white and champagne-themed chrysanthemums, gracefully accompanied by pure white lilies and tender gerberas. This elegant arrangement offers a dignified tribute, symbolizing compassion and remembrance, providing solace during moments of grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Comfort-_IV_-1d.jpg?v=1720181297",
            "/images/products/Condolence-Card-2024_1_93a23b96-2f79-40ad-bf81-caa9540b660b.jpg?v=1720181313",
            "/images/products/Comfort-_IV_-2d.jpg?v=1720181297"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Spray Carnations . Faux Flowers . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 734,
        "name": "Well Wishes FNB",
        "handle": "nyra-fnb",
        "description": "Well WishesPerfect for those recovering at home, as they snack on our homely and tasty treats that are selected to warm the soul. We have also included a Botanica Home Fragrance Candle for an extra boost of relaxation! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "513.22",
        "inclTax": "513.22",
        "img": [
            "/images/products/Nyra1_65b220a6-f87b-46d1-b785-5485f534fe98-668107.jpg?v=1671775978",
            "/images/products/Nyra2-937954.jpg?v=1671775978"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBotanica Home Fragrance Fleur Candle",
                "2.\tGerminal Organic Buckwheat Flakes 200g",
                "3.\tCavendish & Harvey Tropical Selection Jar 300g",
                "4.\tTaylors of Harrogate - Lemon and Ginger 50g",
                "5.\tMrs. Bridges Orange and Tangerine Marmalade",
                "6.\tBeryl’s Waffle Cookies with Gianduja Milk Chocolate 80g",
                "7.\tTiptree – Orange & Tangerine Fine Cut Marmalade 340g"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 735,
        "name": "Pink Fizzy/Sophistication FNB",
        "handle": "brianna-fnb",
        "description": "Pink Fizzy/SophisticationPop the champagne and get ready to make this their best celebration ever! The Pink Fizzy flower and balloon bouquet features Monet Champagne, roses, rose spray, eustoma, preserved hydrangeas, hypericum and coin leaves, and is a party in itself! Topped with a balloon that displays your personalised message, this unique flower box gift will bring a smile to your recipient’s face and make it a truly memorable celebration.",
        "type": "Balloons & Flowers",
        "tags": [
            "Happy Birthday Balloons Bouquet",
            "Congratulations Balloons & Flowers ",
            "Anniversary Balloons & Flowers"
        ],
        "price": "574.84",
        "inclTax": "574.84",
        "img": [
            "/images/products/Pink-Fizzy-Sophistication-FNB1.jpg?v=1709013645",
            "/images/products/Brianna2_591d1bcb-66b3-40dc-9d43-5306170e20e8-597711.jpg?v=1709013645"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  Moet and Chandron 375ml",
                "2. Dry Flower Cotton",
                "3. Roses",
                "4. Eustoma",
                "5. Rose Spray",
                "6. Hypericum",
                "7. Preserved Hydrangea",
                "8. Eucalyptus",
                "9. Personalized Text On Balloon with 6 Mini Balloons",
                "10. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 736,
        "name": "Flourishing Health Basket with Flower WHP",
        "handle": "mildred-whp",
        "description": "Flourishing Health Basket with FlowerSpark joy with the Flourishing Health Basket with Flower Get Well Soon Hamper that is loaded with nourishing and delightful treats! Add an extra boost of health to your recipients with our premium health tonics presented in an exquisite gift basket!",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/Mildred2a-270828.jpg?v=1671685207",
            "/images/products/Mildred1a-593942.jpg?v=1671685207"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBird’s Nest with Ginseng & Rock Sugar 6 x70ml",
                "2.\tBrand’s Essence of Chicken 3 x 41ml",
                "3.\tYu Xiang Yan White Fungas Bird’s Nest Drink 3 x 75ml",
                "4.\tKorean Collagen Drink 5300mg 6 x 50ml",
                "5.\tAssorted Flowers Packaging",
                "6.\tExquisite Gift Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 737,
        "name": "Bountiful Beauty FNB",
        "handle": "carmen-fnb",
        "description": "Bountiful BeautyBeauty is health and health is happiness! Wish someone wonderful health and beauty with the Bountiful Beauty balloon and fruits hamper! Loaded with wholesome goodies and fresh fruits, this magnificent hamper is sure to put a smile on your recipient’s face. ",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "595.37",
        "inclTax": "595.37",
        "img": [
            "/images/products/Bountiful-Beauty-FNB1-737754.jpg?v=1709143101",
            "/images/products/Carmen2-665481.jpg?v=1709084428"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBrands Bird's Nest 2 x 42ml",
                "2.\tBenedictine DOM 37.5ml",
                "3.\tAalst Whole Hazelnut Milk Chocolate Tin 150g",
                "4.\tBreitsamer Honig Raw Honey 500g",
                "5.\tApple",
                "6.\tAvocado",
                "7.\tGreen Apple",
                "8.\tKiwi"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 738,
        "name": "Tawny Enchantment FNB",
        "handle": "alma-fnb",
        "description": "Tawny EnchantmentTawny comes from the Anglo-Norman word, taune, which means tanned, and this is exactly the sunkissed mood we are channeling with the Tawny Enchantment flower and balloon box. Beautiful roses, eustomas, rose sprays and coin leaves are set in an exquisite hat box that gives a comfortable modern vibe. ",
        "type": "Balloons & Flowers",
        "tags": [
            "Graduation Balloons Bouquet"
        ],
        "price": "241.66",
        "inclTax": "241.66",
        "img": [
            "/images/products/Alma1-294385.jpg?v=1671775916",
            "/images/products/Alma2-760761.jpg?v=1671775916"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  Roses",
                "2. Eustoma",
                "3. Hypericum",
                "4. Rose Spray",
                "5. Ping Pon",
                "6. Eucalyptus",
                "7. Personalized Text On Balloon with 3 Mini Balloons"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 739,
        "name": "Loyal Embrace FNB",
        "handle": "davina-fnb",
        "description": "Loyal EmbraceLoyalty is a virtue that should be duly celebrated, and what better way to do so through the Loyal Embrace? A magnificent flower and balloon bouquet that makes a giant impression with elegant roses, eustomas, hypericum and coin leaves will send the proper message of appreciation on an occasion that demands only extravagance and style. ",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Davina1-555625.jpg?v=1671685424",
            "/images/products/Davina2.jpg?v=1667266787"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  Roses",
                "2. Eustoma",
                "3. Ping Pon Flower",
                "4. Hypericum",
                "5. Eucalyptus",
                "6. Personalized Text On Balloon with 4 Mini Balloons",
                "7. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 740,
        "name": "Elegant Pearl FNB",
        "handle": "charley-fnb",
        "description": "Elegant PearlSimple sophistication with anthuriums, Veronicas, eustomas, matthiola and preserved rice flowers was what our florists were going for, when they created the Elegant Pearl flower and balloon box. And for that extra touch of fun, we have included the JP. Chenet Ice Edition, to make this an all-in-one flower and balloon bouquet, for those parties that demand a bit more grandeur! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Congratulations Balloons & Flowers "
        ],
        "price": "378.58",
        "inclTax": "378.58",
        "img": [
            "/images/products/Elegant-Pearl-FNB1.jpg?v=1709004447",
            "/images/products/Charley2_c559649f-e539-4d7b-8c8a-4c549ef48e9f-659860.jpg?v=1709004447"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  JP. Chenet Colombard Sauvignon 250ml",
                "2. Roses",
                "3. Anthurium",
                "4. Veronica",
                "5. Eustoma",
                "6. Ping Pon Flower",
                "7. Matthiola",
                "8. Preserved Rice Flower",
                "9. Pittosporum Mini Seed",
                "10. Pittosporum leaves",
                "11. Personalised Text On Balloon with 3 Mini Balloons",
                "12. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 741,
        "name": "Charming Emerald FNB",
        "handle": "malina-fnb",
        "description": "Charming EmeraldThe Charming Emerald is guaranteed to spoil your recipient as you pamper them with this balloon and hamper bouquet, beautifully arranged using roses, eustomas, carnation sprays and preserved orange slices. Help your loved one relax with our wood mist diffuser in Sleep Ocean.",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "629.60",
        "inclTax": "629.60",
        "img": [
            "/images/products/Charming-Emerald-FNB1.jpg?v=1709084748",
            "/images/products/Malina2-337836.jpg?v=1709084748"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1.   Wood Mist Small Diffuser  Sleep Ocean 60ml",
                "2.  Roses",
                "3.  Eustoma",
                "4.  Carnation Spray",
                "5.  Preserved Orange Slice",
                "6.  Preserved Rice Flower",
                "7.  Pittosporum Mini Seed",
                "8.  Pittosporum Leaves"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 742,
        "name": "Angelica",
        "handle": "angelica",
        "description": "\"The emotion that can break your heart is sometimes the very one that heals it.\" - Nicholas Sparks",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Angelica1A-507952.jpg?v=1671684750",
            "/images/products/Angelica2A-445687.jpg?v=1671684750"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "RP Moab and Pomarosa Roses . "
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 743,
        "name": "Emmie",
        "handle": "emmie",
        "description": "This bouquet features a delicate blend of pastel roses, white eustoma, matthiola and subtle greenery, highlighted by touches of soft pink wax flowers and blue oxypetalum. It’s elegantly wrapped in light purple paper and ribbon, creating a graceful and romantic floral presentation perfect for gentle sentiments.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Emmie-2e.jpg?v=1720889708",
            "/images/products/Emmie-1e.jpg?v=1720889708"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 744,
        "name": "Russet Enchantment FNB",
        "handle": "rosa-fnb",
        "description": "Russet EnchantmentThe Russet Enchantment is a hand-picked flower and balloon arrangement of glorious rust pantones. Featuring roses, eustomas, carnations, hypericum and coin leaves, this is a true display of floral artistry.",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Rosa1-251436.jpg?v=1702988969",
            "/images/products/Rosa2_a17e3d5d-c740-4013-a11f-91c6e618672f-188444.jpg?v=1702988969"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  Roses",
                "2. Eustoma",
                "3. Carnation",
                "4. Hypericum",
                "5. Eucalyptus",
                "6. Personalized Text On Balloon with 4 Mini Balloons",
                "7. Wooden Crate"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 745,
        "name": "Joyful Caress FNB",
        "handle": "kassidy-fnb",
        "description": "Joyful CaressThe Joyful Caress is just what this flower and balloon bouquet was designed for. Cheerful red and pink hues from flowers like roses, rose spray, carnation, eustomas and hypericum are arranged artfully in a wooden crate to convey the happiest sentiments.",
        "type": "Balloons & Flowers",
        "tags": [
            "Congratulations Balloons & Flowers "
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Joyful-Caress-FNB1-753815.jpg?v=1709056634",
            "/images/products/Kassidy2-479080.jpg?v=1709013420"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  Roses",
                "2. Rose Spray",
                "3. Carnation",
                "4. Eustoma",
                "5. Hypericum",
                "6. Eucalyptus",
                "7. Personalized Text On Balloon with 3 Mini Balloons",
                "8. Wooden Crate"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 746,
        "name": "Sweet Lavender FNB",
        "handle": "estrella-fnb",
        "description": "Sweet LavenderShow your special someone that they are the Queen of your Heart when you send the Sweet Lavender flower and balloon bouquet. Extravagant roses, eustomas, Veronicas and matthiolas sit in a display crate, and a customizable message on the balloon makes this gorgeous array of lavender hues a gift fit for royalty!",
        "type": "Balloons & Flowers",
        "tags": [
            "Anniversary Balloons & Flowers"
        ],
        "price": "335.23",
        "inclTax": "335.23",
        "img": [
            "/images/products/Estrella1-497981.jpg?v=1671775914",
            "/images/products/Estrella2-553581.jpg?v=1671775914"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  Roses",
                "2. Dry Flower Cotton",
                "3. Eustoma",
                "4. Veronica",
                "5. Matthiola",
                "6. Rose Spray",
                "7. Carnation",
                "8. Preserved Rice Flower",
                "9. Pittosporum Mini",
                "10. Rosemary",
                "11. Personalised Text On Balloon",
                "12. Wooden Crate"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 747,
        "name": "Indigo Celebration FNB",
        "handle": "jayley-fnb",
        "description": "Indigo CelebrationThe Indigo Celebration pays homage to the saying “Fair Winds and Following Seas” which sends wishes of good weather and safe travels to those heading out to sea. This flower and balloon box wishes your recipient a safe journey and good fortune with roses, eustomas, preserved rice flowers, and hydrangeas.",
        "type": "Balloons & Flowers",
        "tags": [
            "Congratulations Balloons & Flowers "
        ],
        "price": "335.23",
        "inclTax": "335.23",
        "img": [
            "/images/products/Jayley1_9ce36f91-cf3b-4a07-9026-aa78461f6cea-401491.jpg?v=1671685463",
            "/images/products/Jayley2_597e4d83-3eda-4b11-ae64-cdecae865bf3-393423.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  Roses",
                "2. Eustoma",
                "3. Preserved Rice Flower",
                "4. Matthiola",
                "5. Preserved Hydrangea",
                "6. Astrantia",
                "7. Pittosporum Mini Seed",
                "8. Pittosporum leaves",
                "9. Personalized Text On Balloon with 3 Mini Balloons",
                "10. Wooden Crate"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 748,
        "name": "Fortune (V)",
        "handle": "fortune-v",
        "description": "Illuminate all grand openings with the Golden Radiance Tripod Stand, a dazzling fusion of champagne Roses, vibrant Sunflowers, and exotic Bird of Paradise blooms. This majestic floral arrangement stands tall, symbolizing prosperity, joy, and the beginning of a radiant journey. An embodiment of success and celebration.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Fortune-_V_-1b.jpg?v=1720101382",
            "/images/products/Grand-Opening-Card-2024_554b1409-9d1d-4a0d-b479-e970fa9bb716.jpg?v=1720101387",
            "/images/products/Fortune-_V_-2b.jpg?v=1720101382"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Sunflowers . Roses . Eustoma . Hypericum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 749,
        "name": "Fortune (VI)",
        "handle": "fortune-vi",
        "description": "Unveil sophistication with our Sunlit Elegance Tripod Stand. Adorned with yellow and white Roses, accented by opulent gold Anthuriums, all enveloped in a chic nude skirting. This arrangement signifies success and refined beginnings for all grand business openings.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "844.11",
        "inclTax": "844.11",
        "img": [
            "/images/products/Fortune-_VI_-1b.jpg?v=1720102411",
            "/images/products/Grand-Opening-Card-2024_242929ad-fc76-4e96-a086-70f8c5ca4340.jpg?v=1720102418",
            "/images/products/Fortune-_VI_-2b.jpg?v=1720102412"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Anthurium . Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 750,
        "name": "Fortune (VII)",
        "handle": "fortune-vii",
        "description": "Radiate success at all grand business openings with our Sun-Kissed Success Tripod Stand. Sunflowers and orange Roses, embraced by vibrant orange skirtings, create a captivating display symbolizing prosperity and the warmth of new beginnings. Illuminate all ventures with this vibrant and elegant centerpiece.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Fortune-_VII_-1b.jpg?v=1720101570",
            "/images/products/Grand-Opening-Card-2024_6e14825f-cb39-4064-8c04-67ea15e3347c.jpg?v=1720101575",
            "/images/products/Fortune-_VII_-2b.jpg?v=1720101570"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Sunflowers . Roses . Eustoma . Hypericum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 751,
        "name": "Cerulean Celebration FNB",
        "handle": "paulina-fnb",
        "description": "Cerulean CelebrationFloat away for endless summers in the Mediterranean seas with the Cerulean Celebration flower and balloon box. Subtle light blues and purples from carnations, rose spray, matthiolas convey a soft, charming, and stylish mood in this flower and balloon arrangement. ",
        "type": "Balloons & Flowers",
        "tags": [
            "Happy Birthday Balloons Bouquet"
        ],
        "price": "346.64",
        "inclTax": "346.64",
        "img": [
            "/images/products/Paulina1-601690.jpg?v=1671684909",
            "/images/products/Paulina2-324559.jpg?v=1671684909"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  Carnation",
                "2. Rose Spray",
                "3. Matthiola",
                "4. Preserved Hydrangea",
                "5. Preserved Rice Flower",
                "6. Pittosporum Mini",
                "7. Eustoma",
                "8. Pittosporum leaves",
                "9. Personalized Text On Balloon with 3 Mini Balloons",
                "10. Wooden Crate"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 752,
        "name": "Autumn Spice FNB",
        "handle": "cleo-fnb",
        "description": "Autumn SpiceSugar, Spice, and Everything Nice! The Autumn Spice flower and balloon bouquet captures the cozy beauty of Fall, and is guaranteed to fill your recipient with glee. Rich reds from roses, eustomas, carnation sprays and preserved rice flowers add depth and warmth to this bold flower and balloon arrangement which promises to draw attention with its beauty! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Anniversary Balloons & Flowers"
        ],
        "price": "399.12",
        "inclTax": "399.12",
        "img": [
            "/images/products/Cleo1-676369.jpg?v=1671684751",
            "/images/products/Cleo2-763804.jpg?v=1671684751"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  Roses Cappuccino",
                "2. Eustoma",
                "3. Carnation Spray",
                "4. Rose Spray",
                "5. Preserved Rice Flower",
                "6. Ping Pon Flower",
                "7. Matthiola",
                "8. Pittosporum Mini Seed",
                "9. Pittosporum leaves",
                "10. Personalized Text On Balloon with 3 Mini Balloons"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 753,
        "name": "Twinkle Twinkle Little Boy BHP",
        "handle": "liam-bhp",
        "description": "Twinkle Twinkle Little Boy Say congratulations with the sweetest newborn baby hamper! The Twinkle Twinkle Little Boy baby boy hamper includes an exciting series of utensils and toys to keep baby excited and entertained! We have also added the Baby Milestone Book so that parents can keep track of their exciting new journey!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "287.30",
        "inclTax": "287.30",
        "img": [
            "/images/products/Liam1-941559.jpg?v=1671775918",
            "/images/products/Twinkle-Twinkle-Little-Boy-BHP2-405974.jpg?v=1708971358"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tFomec Berry Essence 41ml x 2",
                "2.\tBird’s Nest with Ginseng & Rock Sugar 2 x70ml",
                "3.\tFun Orbit Turn Happy Ball Toy Set",
                "4.\tBaby K Giraffe Teether",
                "5.\tBaby K Sippy Cup",
                "6.\tBaby K Silicone Self Feeding Spoon x 2",
                "7.      Baby K Penguin Wobbler",
                "8.      Assorted Flower Packaging",
                "9.      Exquisite Gift Box",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 754,
        "name": "Lacey",
        "handle": "lacey",
        "description": "“You know it's love when all you want is that person to be happy, even if you're not part of their happiness.”- Julia Roberts",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Lacey1A-873093.jpg?v=1671685468",
            "/images/products/Lacey2A-249574.jpg?v=1671685468"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Spray Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 40cm*50cm"
    },
    {
        "id": 755,
        "name": "Somebunny's Teaparty FNB",
        "handle": "rosalee-fnb",
        "description": "Somebunny's TeapartyThe Somebunny’s Teaparty is the perfect snack hamper to celebrate the universal love for snacking! Find enough snacks to keep you going all day, including lemon curd, cranberry nougats, juice and more, all loaded into an exquisite wooden crate. We have even included a special guest to your very special teaparty! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Balloons Bouquet",
            "Happy Birthday Balloons Bouquet"
        ],
        "price": "453.89",
        "inclTax": "453.89",
        "img": [
            "/images/products/Somebunny_s-Teaparty-FNB1.jpg?v=1709015392",
            "/images/products/Rosalee2.jpg?v=1709015392"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Bunny Plush Toy",
                "2. Tiptree Lemon Curd 340g",
                "3. Botanica Candle Rose",
                "4. Georgias Natural 100% Natural Juice - Pear 300ml",
                "5. Nibbles Cranberry handmade soft Honey Nougats 126g",
                "6. Firefly Natural Juice - Lemon, Lime 330ml"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 756,
        "name": "Foodie Goodie BHP",
        "handle": "zora-bhp",
        "description": "Foodie GoodieThis thoughtfully gathered hamper is lovingly made with daily needs, cloth, and toys. It’s sure to keep any little child happy and comfortable through the day!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "189.18",
        "inclTax": "189.18",
        "img": [
            "/images/products/Zora1-324597.jpg?v=1671685207",
            "/images/products/Zora2-371575.jpg?v=1671685207"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Baby K Romper Gift Set",
                "2. Lucky Baby Calico Fun Bib",
                "3. Lucky Baby Safety First Food Cutter",
                "4. Drypers Wee Wee Dry Diapers M size (4pcs)",
                "5. Lucky Baby Munchie-Pot",
                "6. KinderNurture Organic Baby Wipes 20’s",
                "7. Bunny Plush Toy",
                "8. Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 30cm"
    },
    {
        "id": 757,
        "name": "Clean 'n' Pure BHP",
        "handle": "vanna-bhp",
        "description": "Clean 'n' Pure “The Clean ‘n’ Pure baby hamper ensures that bathtime will always be fun! We have included bathtime essentials like washcloths and a hooded towel from BabyK, as well as unbleached baby wipes, so that baby’s sensitive skin is properly protected. Discover the best baby hampers in Malaysia with us, and enjoy our complimentary newborn baby hamper delivery.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Vanna1-943584.jpg?v=1671685024",
            "/images/products/Vanna2-661767.jpg?v=1671685024"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBaby Carrie Organic Head to Toe Gentle Wash 500ml",
                "2.\tBaby K- Organic Bamboo Swaddling Blanket",
                "3.\tBaby K- Organic Bamboo 6 pcs pack Washcloth",
                "4.\tBaby K- Organic Bamboo Novelty Hooded Towel",
                "5.\tCloversoft Nature & Organic Unbleached Bamboo Wipes 70s",
                "6.\tExquisite Gift Basket (S)"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 758,
        "name": "Fruity Teaparty FNB",
        "handle": "kairi-fnb",
        "description": "Fruity TeapartySurprise someone with the Fruity Teaparty snack hamper! Loaded with tasty snacks that include cookies, chai, and Bickfords Traditional Soda, this snack hamper also pays tribute to local favourites like the Nonya Delight Kueh Kosui Cookies from Old Seng Choong. As an added touch of luxury, our Botanica Round Gel Light will guarantee that your tea party is a success! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "460.74",
        "inclTax": "460.74",
        "img": [
            "/images/products/Kairi1-102540.jpg?v=1671685312",
            "/images/products/Kairi2.jpg?v=1667874903"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Old Seng Choong – Nonya Delight Kueh Kosui Cookies 180g",
                "2.  Beryl’s Double Chocolate Chips Cookies 100g",
                "3. Botanica Round Gel Light 90g",
                "4. Taylors of Harrogate- Classic Chai 50g x 20 sachets",
                "5. Mrs. Bridges Orange and Tangerine Marmalade",
                "6. Bickfords Traditional Sode – Lemon Lime & Bitters 275ml",
                "7. Tiptree – Orange & Tangerine Fine Cut Marmalade 340g"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 759,
        "name": "Comfort (VIII)",
        "handle": "comfort-viii",
        "description": "Extend heartfelt condolences with our flower stands featuring vibrant orange orchids, pure white brassica, delicate chrysanthemums, fragrant matthiolas, and gentle gerberas. This harmonious arrangement offers solace, symbolizing compassion and remembrance during moments of grief, providing comfort and support in times of sorrow.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Comfort-_VIII_-1d.jpg?v=1720156910",
            "/images/products/Condolence-Card-2024_1_30873d2a-c0bc-46ef-96c0-81218e3b71ed.jpg?v=1720156919",
            "/images/products/Comfort-_VIII_-2d.jpg?v=1720156913"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Brassica . Gerbera . Mum . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 760,
        "name": "Empathy (VIII)",
        "handle": "empathy-viii",
        "description": "In times of sorrow, our Condolence Flower Stands, featuring pristine white chrysanthemums, soft champagne roses, and gentle gerberas, offer solace and a graceful tribute. This elegant arrangement symbolizes compassion and remembrance, extending comfort and support during moments of grief.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Empathy-_VIII_-1e.jpg?v=1720155537",
            "/images/products/Condolence-Card-2024_1_0927badd-4ee4-4cd2-bad5-9691b4ca79db.jpg?v=1720155545",
            "/images/products/Empathy-_VIII_-2e.jpg?v=1720155537"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Brassica . Gerbera . Roses . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 761,
        "name": "Sweet Lavish FNB",
        "handle": "vada-fnb",
        "description": "Sweet LavishThe perfect food hamper for you to lavish someone with your heartfelt sentiments, the Sweet Lavish balloon arrangement includes snack time favourites that will delight any palate! We call this an all-in-one picnic basket, which is sure to impress! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Happy Birthday Balloons Bouquet"
        ],
        "price": "460.74",
        "inclTax": "460.74",
        "img": [
            "/images/products/Sweet-Lavish-FNB1-918031.jpg?v=1709057857",
            "/images/products/Vada2-514976.jpg?v=1709015072"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Excelcium - Pralines BonBons De Chocolat 180g",
                "2.  Hamlet Assortiment Chocolate 125g",
                "3.  The Fine Cheese Co Rose Mary and Extra Virgin Oil Cracker 125g",
                "4.  St Dalfour Fruit Spread 3 x 28g",
                "5.  JP. Chenet Ice Edition",
                "6.  Tiptree - Orange & Tangerine Fine Cut Marmalade 340g",
                "7.  Temptation - Homemade Cookies Chocolate Chips 250g",
                "8.  Temptation - Homemade Cookies Double Chocolate 250g"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 762,
        "name": "English Teaparty FNB",
        "handle": "elia-fnb",
        "description": "English TeapartyThe English Teaparty is the perfect food hamper to celebrate the universal love for snacking! Find enough snacks to keep you going all day, including apricot preserve, fresh fruit, organic English tea, all loaded into an exquisite wooden crate. We have even included a special guest to your very special teaparty! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Happy Birthday Balloons Bouquet"
        ],
        "price": "545.17",
        "inclTax": "545.17",
        "img": [
            "/images/products/Elia1-897397.jpg?v=1671685200",
            "/images/products/Elia2.jpg?v=1667953062"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Bunny Plush Toy",
                "2. Tiptree - Apricot Preserve 340g",
                "3. Tiptree - Orange & Tangerine Fine Cut Marmalade 340g",
                "4. Georgias Natural 100% Natural Juice - Pear 300ml",
                "5. Engish Tea Shop organic Chamomile & Lavender 30g",
                "6. Botanica Candle Rose",
                "7. Fresh Grapes"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 763,
        "name": "Graceful Radiance FNB",
        "handle": "raina-fnb",
        "description": "Graceful RadianceGraceful Radiance was what imagined your recipient will be, when they receive this delightful balloon food hamper! Perfect for anyone with a sweet tooth, our assortment of delectable snacks will truly impress their discerning palate! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "335.23",
        "inclTax": "335.23",
        "img": [
            "/images/products/Graceful-Radiance-FNB1-426581.jpg?v=1709143470",
            "/images/products/Raina2-398433.jpg?v=1709085242"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  Beryl’s Waffle Cookies with Gianduja Milk Chocolate 80g",
                "2.  Hamlet Assortment Chocolate 125g",
                "3. Tiptree – Orange & Tangerine Fine Cut Marmalade 340g",
                "4. St Dalfour Fruit Spread 3 x 28g",
                "5. K.Harrodson Blueberry Butter Cookies 70g",
                "6. Cavendish & Harvey Tropical fruit drops 200g",
                "7. JP. Chenet Ice Edition 200ml",
                "8. K. Rogers Cornflake Cereal Crunch Cookies 150g",
                "9. Personalized Text on Balloon with 6 Mini Balloons",
                "10. Exquisite Wicker Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 764,
        "name": "Lexi",
        "handle": "lexi",
        "description": "Indulge in the enduring charm of our everlasting preserved tiffany blue roses and delicate gossypium intricately arranged in a heart-shaped wreath bouquet. This timeless creation symbolizes everlasting love and sophistication, capturing the essence of enduring beauty. Elevate any space with this exquisite arrangement, a perpetual declaration of love and grace.",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Lexi-1aa-471334.jpg?v=1704463726",
            "/images/products/Lexi-2aa.jpg?v=1704306838"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Preserved Hydrangea . Dried Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 765,
        "name": "Holiday Gourmet (NA)",
        "handle": "holiday-gourmet-na",
        "description": "Holiday Gourmet (NA)A bright and cheerful Christmas arrangement, the unique Holiday Gourmet Christmas Food Gift Hamper was designed to be displayed as a centerpiece, to decorate the home, or to simply add a touch of holiday cheer to just about any space throughout the holiday season. Jolly goodies fill this delightful gift to the brim, and we have included a blackcurrant cordial for some non-alcoholic fun! ",
        "type": "Hampers",
        "tags": [],
        "price": "278.18",
        "inclTax": "278.18",
        "img": [
            "/images/products/Holiday-Gourmet-_NA_1-607710.jpg?v=1671685463",
            "/images/products/Holiday-Gourmet-_NA_2-634244.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tBickfords Cordial Australia Blackcurrant Cordial 750ml",
                "2.\tLoison Amarena Panettoncino with Rasins and Candied Cherries 100g",
                "3.\tSt Michel Traditional Sponge Cake 150g",
                "4.\tHuntly & Palmers Seasmeal Rosemary & Garlic 200g",
                "5.\tDuncan’s of Deeside Traditional Shortbread Fingers 150g",
                "6.\tHamlet Oublies Forum 125g",
                "7.\tGodivine Matcha Biscotti 70g",
                "8. Excelcium – Pralines BonBons De Chocolat 200g",
                "9. Exquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 766,
        "name": "Festive Feast (NA)",
        "handle": "festive-feast-na",
        "description": "Festive Feast (NA)The Festive Feast Christmas Food Gift is a wonderful way to send your season’s greetings this December! Crowd-pleasing festive goodies are packed alongside a blackcurrant cordial, for a delightful gifting experience that is guaranteed to delight the senses!",
        "type": "Hampers",
        "tags": [],
        "price": "312.41",
        "inclTax": "312.41",
        "img": [
            "/images/products/Festive-Feast-_NA_1-731393.jpg?v=1671685310",
            "/images/products/Festive-Feast-_NA_2_945f273b-151c-4817-b44a-4b5c1a241243-874963.jpg?v=1671685310"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tBickfords Cordial Australia Blackcurrant Cordial 750ml",
                "2.\tNatrajacali Belgid’Or (Milk) Seashells 250g",
                "3.\tGavottes Milk Crepes Dentelle 90g",
                "4.\tDuncan’s of Deeside Salted Caramel All Butter Shortbread Hand Baked 150g",
                "5.\tGolden Bon Bon Soft Almond Nougat – Maple 70g",
                "6.\tWinter Woodlands Cookies 150g",
                "7.\tNibbles Baked Cashew Nuts 35g",
                "8.     Hunter Hand Cook Potato Chip Pesto Parmesan 150g",
                "9.     Exquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 767,
        "name": "Holiday Gourmet (RW)",
        "handle": "holiday-gourmet-rw",
        "description": "Holiday Gourmet (RW) Graceful Radiance was what imagined your recipient will be, when they receive this delightful balloon food hamper! Perfect for anyone with a sweet tooth, our assortment of delectable snacks will truly impress their discerning palate!",
        "type": "Hampers",
        "tags": [],
        "price": "332.94",
        "inclTax": "332.94",
        "img": [
            "/images/products/Holiday-Gourmet-_RW_1-151868.jpg?v=1671685349",
            "/images/products/Holiday-Gourmet-_RW_2-500941.jpg?v=1671685349"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tDe Valier Rosso 75cl",
                "2.\tLoison Amarena Panettoncino with Rasins and Candied Cherries 100g",
                "3.\tSt Michel Traditional Sponge Cake 150g",
                "4.\tHuntly & Palmers Seasmeal Rosemary & Garlic 200g",
                "5.\tDuncan’s of Deeside Traditional Shortbread Fingers 150g",
                "6.\tHamlet Oublies Forum 125g",
                "7.\tGodivine Matcha Biscotti 70g",
                "8. Excelcium – Pralines BonBons De Chocolat 200g",
                "9. Exquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 768,
        "name": "Holiday Gourmet (WW)",
        "handle": "holiday-gourmet-ww",
        "description": "Holiday Gourmet (WW) A bright and cheerful Christmas arrangement, the unique Holiday Gourmet Christmas Food Gift Hamper was designed to be displayed as a centerpiece, to decorate the home, or to simply add a touch of holiday cheer to just about any space throughout the holiday season. Jolly goodies fill this delightful gift to the brim, and we have included a festive white wine for some extra winter fun! ",
        "type": "Hampers",
        "tags": [],
        "price": "332.94",
        "inclTax": "332.94",
        "img": [
            "/images/products/Holiday-Gourmet-_WW_1-915722.jpg?v=1671685325",
            "/images/products/Holiday-Gourmet-_WW_2-254466.jpg?v=1671685325"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tDe Valier Bianco 75cl",
                "2.\tLoison Amarena Panettoncino with Rasins and Candied Cherries 100g",
                "3.\tSt Michel Traditional Sponge Cake 150g",
                "4.\tHuntly & Palmers Seasmeal Rosemary & Garlic 200g",
                "5.\tDuncan’s of Deeside Traditional Shortbread Fingers 150g",
                "6.\tHamlet Oublies Forum 125g",
                "7.\tGodivine Matcha Biscotti 70g",
                "8. Excelcium – Pralines BonBons De Chocolat 200g",
                "9. Exquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 769,
        "name": "Zorine",
        "handle": "zorine",
        "description": "This vibrant bouquet showcases a stunning sunflower as the centerpiece, surrounded by a variety of colorful blooms including pink carnations, lavender roses, and white pom-pom flowers. The arrangement is accented with delicate greenery and wrapped in cheerful orange gingham paper, making it a lively and joyful gift for any occasion.",
        "type": "Bouquets",
        "tags": [
            "Get Well Soon Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Zorine-2a.jpg?v=1721173643",
            "/images/products/Zorine-1a.jpg?v=1721173643"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Roses . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 770,
        "name": "Holiday Gourmet (S)",
        "handle": "holiday-gourmet-s",
        "description": "Holiday Gourmet (S)A bright and cheerful Christmas arrangement, the unique Holiday Gourmet Christmas Food Gift Hamper was designed to be displayed as a centerpiece, to decorate the home, or to simply add a touch of holiday cheer to just about any space throughout the holiday season. Jolly goodies fill this delightful gift to the brim, and we have included a festive sparkling bubbly for some extra winter fun!",
        "type": "Hampers",
        "tags": [],
        "price": "353.48",
        "inclTax": "353.48",
        "img": [
            "/images/products/Holiday-Gourmet-_S_1_ddc11d5d-04d4-4e54-bfd0-e1c43457d67f-408895.jpg?v=1671685463",
            "/images/products/Holiday-Gourmet-_S_2_16fd1870-a26b-4486-9757-d0be1c6933c7-630941.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tArione D.O.C Piemonte Brachetto Sparkling 75cl",
                "2.\tLoison Amarena Panettoncino with Rasins and Candied Cherries 100g",
                "3.\tSt Michel Traditional Sponge Cake 150g",
                "4.\tHuntly & Palmers Seasmeal Rosemary & Garlic 200g",
                "5.\tDuncan’s of Deeside Traditional Shortbread Fingers 150g",
                "6.\tHamlet Oublies Forum 125g",
                "7.\tGodivine Matcha Biscotti 70g",
                "8. Excelcium – Pralines BonBons De Chocolat 200g",
                "9. Exquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 771,
        "name": "Festive Feast (RW)",
        "handle": "festive-feast-rw",
        "description": "Festive Feast (RW)The Festive Feast Christmas Food Gift is a wonderful way to send your season’s greetings this December! Crowd-pleasing festive goodies are packed alongside a blackcurrant cordial, for a delightful gifting experience that is guaranteed to delight the senses!",
        "type": "Hampers",
        "tags": [],
        "price": "312.41",
        "inclTax": "312.41",
        "img": [
            "/images/products/Festive-Feast-_RW_1-793589.jpg?v=1671685203",
            "/images/products/Festive-Feast-_RW_2-634047.jpg?v=1671685203"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tVino Fernandez Merlot 75cl",
                "2.\tNatrajacali Belgid’Or (Milk) Seashells 250g",
                "3.\tGavottes Milk Crepes Dentelle 90g",
                "4.\tDuncan’s of Deeside Salted Caramel All Butter Shortbread Hand Baked 150g",
                "5.\tGolden Bon Bon Soft Almond Nougat – Maple 70g",
                "6.\tWinter Woodlands Cookies 150g",
                "7.\tNibbles Baked Cashew Nuts 35g",
                "8.     Hunter Hand Cook Potato Chip Pesto Parmesan 150g",
                "9.     Exquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 772,
        "name": "Festive Feast (WW)",
        "handle": "festive-feast-ww",
        "description": "Festive Feast (WW)The Festive Feast Christmas Food Gift is a wonderful way to send your season’s greetings this December! Crowd-pleasing goodies are packed alongside a festive white wine, for a delightful gifting experience that is guaranteed to delight the senses!",
        "type": "Hampers",
        "tags": [],
        "price": "362.61",
        "inclTax": "362.61",
        "img": [
            "/images/products/Festive-Feast-_WW_1-956896.jpg?v=1671685204",
            "/images/products/Festive-Feast-_WW_2-334560.jpg?v=1671685204"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tVino Fernandez Sauvignon Blanc 75cl",
                "2.\tNatrajacali Belgid’Or (Milk) Seashells 250g",
                "3.\tGavottes Milk Crepes Dentelle 90g",
                "4.\tDuncan’s of Deeside Salted Caramel All Butter Shortbread Hand Baked 150g",
                "5.\tGolden Bon Bon Soft Almond Nougat – Maple 70g",
                "6.\tWinter Woodlands Cookies 150g",
                "7.\tNibbles Baked Cashew Nuts 35g",
                "8.     Hunter Hand Cook Potato Chip Pesto Parmesan 150g",
                "9.     Exquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 773,
        "name": "Festive Feast (S)",
        "handle": "festive-feast-s",
        "description": "Festive Feast (S)The Festive Feast Christmas Food Gift is a wonderful way to send your season’s greetings this December! Crowd-pleasing goodies are packed alongside a festive sparkling pour, for a delightful gifting experience that is guaranteed to delight the senses! ",
        "type": "Hampers",
        "tags": [],
        "price": "383.15",
        "inclTax": "383.15",
        "img": [
            "/images/products/Festive-Feast-_S_1-331442.jpg?v=1671685310",
            "/images/products/Festive-Feast-_S_2-640100.jpg?v=1671685310"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tAnon Sparkling Cuvee 75cl",
                "2.\tNatrajacali Belgid’Or (Milk) Seashells 250g",
                "3.\tGavottes Milk Crepes Dentelle 90g",
                "4.\tDuncan’s of Deeside Salted Caramel All Butter Shortbread Hand Baked 150g",
                "5.\tGolden Bon Bon Soft Almond Nougat – Maple 70g",
                "6.\tWinter Woodlands Cookies 150g",
                "7.\tNibbles Baked Cashew Nuts 35g",
                "8.     Hunter Hand Cook Potato Chip Pesto Parmesan 150g",
                "9.     Exquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 774,
        "name": "Wonderland Treats (NA)",
        "handle": "wonderland-treats-na",
        "description": "Wonderland Treats (NA)There’s nothing like the holiday season, and we have designed the Wonderland Treats Christmas Food Gift to brighten up any space! Complete your Christmas decorations with this centerpiece that will impress and delight! Packed to the brim with luxurious snacks and the crowd-pleasing blackcurrant cordial, this will be an experience for the tastebuds and the senses! ",
        "type": "Hampers",
        "tags": [],
        "price": "405.97",
        "inclTax": "405.97",
        "img": [
            "/images/products/Wonderland-Treats-_NA_2-293086.jpg?v=1671775979",
            "/images/products/Wonderland-Treats-_NA_3-919265.jpg?v=1671775979"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tBickfords Cordial Australia Blackcurrant Cordial 750ml",
                "2.\tLoison Amarena Panettoncino with Rasins and Candied Cherries 100g",
                "3.\tWedgewood Handmade Honey Nougat Assorted Dark Chocolate Mint 120g",
                "4.\tHuntley & Palmers Flat Bread Toasted Sesame 125g",
                "5.\tFarmhouse Biscuits Luxury Apple Crumble 150g",
                "6.\tLes Cookies de La Mere Poulard All Chocolate French Cookies 200g",
                "7.     Excelcium – Pralines BonBons De Chocolat 200g",
                "8.     Godivine Nutty Mix Nuts 80g",
                "9. LIR Salted Caramel Truffles 145g",
                "10. Exquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 775,
        "name": "Wonderland Treats (RWW)",
        "handle": "wonderland-treats-rww",
        "description": "Wonderland Treats (RWW)There’s nothing like the holiday season, and we have designed the Wonderland Treats Christmas Food Gift to brighten up any space! Complete your Christmas decorations with this centerpiece that will impress and delight! Packed to the brim with luxurious snacks and the crowd-pleasing blackcurrant cordial, this will be an experience for the tastebuds and the senses! ",
        "type": "Hampers",
        "tags": [],
        "price": "567.99",
        "inclTax": "567.99",
        "img": [
            "/images/products/Wonderland-Treats-_RWW_1_66e28d42-03e5-4f45-b6a7-757c5d190c26-390780.jpg?v=1671775979",
            "/images/products/Wonderland-Treats-_RWW_2_71d82c79-a3d2-42b7-aeb9-fb85e3bea215-926853.jpg?v=1671775979"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tNoblesse Merlot 75cl",
                "2.\tNoblesse Sauvignon Blanc 75cl",
                "3.\tLoison Amarena Panettoncino with Rasins and Candied Cherries 100g",
                "4.\tWedgewood Handmade Honey Nougat Assorted Dark Chocolate Mint 120g",
                "5.\tHuntley & Palmers Flat Bread Toasted Sesame 125g",
                "6.\tFarmhouse Biscuits Luxury Apple Crumble 150g",
                "7.\tLes Cookies de La Mere Poulard All Chocolate French Cookies 200g",
                "8.     Excelcium – Pralines BonBons De Chocolat 200g",
                "9.     Godivine Nutty Mix Nuts 80g",
                "10. LIR Salted Caramel Truffles 145g",
                "11. Exquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 776,
        "name": "Wonderland Treats (S)",
        "handle": "wonderland-treats-s",
        "description": "Wonderland Treats (S)There’s nothing like the holiday season, and we have designed the Wonderland Treats Christmas Food Gift to brighten up any space! Complete your Christmas decorations with this centerpiece that will impress and delight! Packed to the brim with luxurious snacks and a sparkling prosecco, this will be an experience for the tastebuds and the senses!",
        "type": "Hampers",
        "tags": [],
        "price": "567.99",
        "inclTax": "567.99",
        "img": [
            "/images/products/Wonderland-Treats-_S_1-827063.jpg?v=1671775979",
            "/images/products/Wonderland-Treats-_S_2-952245.jpg?v=1671775979"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tSanta Margherita Prosecco, Italy 75cl",
                "2.\tLoison Amarena Panettoncino with Rasins and Candied Cherries 100g",
                "3.\tWedgewood Handmade Honey Nougat Assorted Dark Chocolate Mint 120g",
                "4.\tHuntley & Palmers Flat Bread Toasted Sesame 125g",
                "5.\tFarmhouse Biscuits Luxury Apple Crumble 150g",
                "6.\tLes Cookies de La Mere Poulard All Chocolate French Cookies 200g",
                "7.     Excelcium – Pralines BonBons De Chocolat 200g",
                "8.     Godivine Nutty Mix Nuts 80g",
                "9. LIR Salted Caramel Truffles 145g",
                "10. Exquisite Picnic Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 777,
        "name": "Sympathy (VIII)",
        "handle": "sympathy-viii",
        "description": "In a grand gesture of sympathy, our jumbo condolence flower stands feature opulent white brassica, pure white chrysanthemums, and graceful gerberas, accentuated by champagne eustomas. This luxurious arrangement provides a dignified tribute, symbolizing compassion and remembrance during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "613.86",
        "inclTax": "613.86",
        "img": [
            "/images/products/Sympathy-_VIII_-2c.jpg?v=1727189857",
            "/images/products/Condolence-Card-2024_1_ac356481-95d5-4a3f-8e5b-85510962f315.jpg?v=1720190727",
            "/images/products/Sympathy-_VIII_-1c.jpg?v=1727189857"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Brassica . Gerberas . Eustoma . Matthiola . Chrysanthemum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 120cm*180cm"
    },
    {
        "id": 778,
        "name": "Mistletoe Charm",
        "handle": "mistletoe-charm",
        "description": "Mistletoe CharmReady to gift in style and make this Christmas 2022 look picture perfect? This holiday, gift the Mistletoe Charm Christmas Gift Hamper to add a special sparkle to the season! Festive snacks and a kids’ board game ensures that there is something exciting for everyone in this arrangement!",
        "type": "Hampers",
        "tags": [],
        "price": "209.72",
        "inclTax": "209.72",
        "img": [
            "/images/products/Mistletoe-Charm1-719957.jpg?v=1671685491",
            "/images/products/Mistletoe-Charm2-515465.jpg?v=1671685492"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tCharades For Kids Board Game",
                "2.\tWerther’s Original Popcorn Classic 140g",
                "3.\tGodivine Cranberry Almond Cookies 70g",
                "4.\tVan Houten Cocoa Drink 100g",
                "5.\tCard Game",
                "6.\tBickfords Premium Soft Drink Australia – Sarsaparilla 275ml",
                "7.     Loacker Quadratini Chocolate Wafer 125g",
                "8.     Excelcium – Pralines BonBons De Chocolat 200g",
                "9. Exquisite Gift Basket",
                "10. Exquisite Picnic Basket (XL)",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 779,
        "name": "Season's Cheers (BB)",
        "handle": "seasons-cheers-bb",
        "description": "Season's Cheers (BB)Season’s Cheers is a Christmas Gift Hamper that speaks to the joyful and treasured moments that this special time of year holds! Exciting snacks and beer are packed in this festive arrangement, and we have also tossed in the Drunken Tower Drinking Game for the more adventurous! This season, get ready to play and giggle lots! Enjoy fuss-free gifting with our dedicated services! ",
        "type": "Hampers",
        "tags": [],
        "price": "269.05",
        "inclTax": "269.05",
        "img": [
            "/images/products/Season_sCheers1-479151.jpg?v=1702988969",
            "/images/products/Season_sCheers_BB_2-817162.jpg?v=1702988969"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tDrunken Tower Drinking Game",
                "2.\tHeineken Beer 330ml",
                "3.\tHoegarden Beer 330ml",
                "4.\tKronenbourg 1664 beer 330ml",
                "5.\tCorona Beer 355ml",
                "6.\tNibbles Premium Natural Mixed Nuts 45g",
                "7.     Tyrrells Mature Cheddar & Chives Potato Chips 40g",
                "8.     Farmland Thin Curv Chips 160g",
                "9. Hunter’s Gourmet Black Truffle Potato Chips 40g",
                "10. Lorenz Pomsticks 70g",
                "11. Exquisite Basket (L)",
                "12. Exquisite Picnic Basket (XL)"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 780,
        "name": "Christmas Spirit (BB)",
        "handle": "christmas-spirit-bb",
        "description": "Christmas Spirit (BB)Sending holiday cheer has never tasted so good! In our Christmas Spirit Christmas Gift Hamper, delightful treats are packed alongside festive beers and the crowd-pleasing Roulette 16 Glass Lucky Shot Drinking Game, for a jolly, fun, and exciting holiday!",
        "type": "Hampers",
        "tags": [],
        "price": "278.18",
        "inclTax": "278.18",
        "img": [
            "/images/products/Christmas-Spirit-_BB_1-644369.jpg?v=1671685195",
            "/images/products/Christmas-Spirit-_BB_2-269833.jpg?v=1671685195"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tRoulette 16 Glass Lucky Shot Drinking Game",
                "2.\tHeineken Beer 330ml",
                "3.\tHoegarden Beer 330ml",
                "4.\tKronenbourg 1664 beer 330ml",
                "5.\tCorona Beer 355ml",
                "6.\tNibbles Premium Natural Mixed Nuts 45g",
                "7.     Tyrrells Mature Cheddar & Chives Potato Chips 40g",
                "8.     Farmland Thin Curv Chips 160g",
                "9. Hunter’s Gourmet Black Truffle Potato Chips 40g",
                "10. Lorenz Pomsticks 70g",
                "11. Exquisite Basket (L)",
                "12. Exquisite Picnic Basket (XL)"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 781,
        "name": "Season's Cheers (SJ)",
        "handle": "seasons-cheers-sj",
        "description": "Season's Cheers (SJ)Season’s Cheers is a Christmas Gift Hamper that speaks to the joyful and treasured moments that this special time of year holds! Exciting snacks and soju are packed in this festive arrangement, and we have also tossed in the Drunken Tower Drinking Game for the more adventurous! This season, get ready to play and giggle lots! Enjoy fuss-free gifting with our dedicated services!",
        "type": "Hampers",
        "tags": [],
        "price": "289.59",
        "inclTax": "289.59",
        "img": [
            "/images/products/Season_s-Cheers-_SJ_1-625332.jpg?v=1702988970",
            "/images/products/Season_s-Cheers-_SJ_2_1ca6f367-6cd0-4aaf-862b-404114f04bd3-150506.jpg?v=1702988970"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tDrunken Tower Drinking Game",
                "2.\tJinro Soju 360ml x 2",
                "3.\tNibbles Premium Natural Mixed Nuts 45g",
                "4.     Tyrrells Mature Cheddar & Chives Potato Chips 40g",
                "5.     Farmland Thin Curv Chips 160g",
                "6. Hunter’s Gourmet Black Truffle Potato Chips 40g",
                "7. Lorenz Pomsticks 70g",
                "8. Exquisite Basket (L)",
                "9. Exquisite Picnic Basket (XL)"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 782,
        "name": "Christmas Spirit (SJ)",
        "handle": "christmas-spirit-sj",
        "description": "Christmas Spirit (SJ)Spreading holiday cheer has never tasted so good! In our Christmas Spirit Christmas Gift Hamper, delightful treats are packed alongside festive beers and the crowd-pleasing Roulette 16 Glass Lucky Shot Drinking Game, for a jolly, fun, and exciting holiday! ",
        "type": "Hampers",
        "tags": [],
        "price": "298.71",
        "inclTax": "298.71",
        "img": [
            "/images/products/Christmas-Spirit-_SJ_1-638025.jpg?v=1671685077",
            "/images/products/Christmas-Spirit-_SJ_2-244360.jpg?v=1671685077"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tRoulette 16 Glass Lucky Shot Drinking Game",
                "2.\tJinro Soju 360ml x 2",
                "3.\tNibbles Premium Natural Mixed Nuts 45g",
                "4.     Tyrrells Mature Cheddar & Chives Potato Chips 40g",
                "5.     Farmland Thin Curv Chips 160g",
                "6. Hunter’s Gourmet Black Truffle Potato Chips 40g",
                "7. Lorenz Pomsticks 70g",
                "8. Exquisite Basket (L)",
                "9. Exquisite Picnic Basket (XL)"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 783,
        "name": "Season's Cheers (LQ)",
        "handle": "seasons-cheers-lq",
        "description": "Season's Cheers (LQ)Season’s Cheers is a Christmas Gift Hamper that speaks to the joyful and treasured moments that this special time of year holds! Exciting snacks and the delectable Bailey’s Irish Cream are packed in this festive arrangement, and we have also tossed in the Drunken Tower Drinking Game for the more adventurous! This season, get ready to play and giggle lots! ",
        "type": "Hampers",
        "tags": [],
        "price": "332.94",
        "inclTax": "332.94",
        "img": [
            "/images/products/Season_s-Cheers-_LQ_1-341934.jpg?v=1702988970",
            "/images/products/Season_s-Cheers-_LQ_2_43c7c3eb-fc54-41de-89fb-f9cd6d893602-382507.jpg?v=1702988970"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tDrunken Tower Drinking Game",
                "2.\tBaileys Irish Cream 70cl",
                "3.\tNibbles Premium Natural Mixed Nuts 45g",
                "4.     Tyrrells Mature Cheddar & Chives Potato Chips 40g",
                "5.     Farmland Thin Curv Chips 160g",
                "6. Hunter’s Gourmet Black Truffle Potato Chips 40g",
                "7. Lorenz Pomsticks 70g",
                "8. Exquisite Basket (L)",
                "9. Exquisite Picnic Basket (XL)"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 784,
        "name": "Christmas Spirit (LQ)",
        "handle": "christmas-spirit-lq",
        "description": "Christmas Spirit (LQ)Spreading holiday cheer has never tasted so good! In our Christmas Spirit Holiday Gift Hamper, delightful treats are packed alongside festive beers and the crowd-pleasing Roulette 16 Glass Lucky Shot Drinking Game, for a jolly, fun, and exciting holiday! ",
        "type": "Hampers",
        "tags": [],
        "price": "342.07",
        "inclTax": "342.07",
        "img": [
            "/images/products/Christmas-Spirit-_LQ_1-699973.jpg?v=1671685195",
            "/images/products/Christmas-Spirit-_LQ_2-233172.jpg?v=1671685195"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tRoulette 16 Glass Lucky Shot Drinking Game",
                "2.\tBaileys Irish Cream 70cl",
                "3.\tNibbles Premium Natural Mixed Nuts 45g",
                "4.     Tyrrells Mature Cheddar & Chives Potato Chips 40g",
                "5.     Farmland Thin Curv Chips 160g",
                "6. Hunter’s Gourmet Black Truffle Potato Chips 40g",
                "7. Lorenz Pomsticks 70g",
                "8. Exquisite Basket (L)",
                "9. Exquisite Picnic Basket (XL)"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 785,
        "name": "Winter Break (T)",
        "handle": "winter-break-t",
        "description": "Winter Break (T)A wonderful way to deck your halls this holiday season or to send as a gift to wish someone the best Christmas, the Winter Break Christmas Hamper is curated to impress and delight! Designed with the delectable Juan Tea blends and relaxing treats to provide the ultimate indulgence, this unique Christmas gift box promises them the best me-time this holiday season. ",
        "type": "Hampers",
        "tags": [],
        "price": "191.46",
        "inclTax": "191.46",
        "img": [
            "/images/products/WinterBreak_T_2-883868.jpg?v=1671775978",
            "/images/products/WinterBreak_T_1-362271.jpg?v=1671775978"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tJuan Tea Indulgence Floral Blend Tea Set 10’s",
                "2.\tNeck Relaxer",
                "3.\tScalp Massager",
                "4.\tMegRhythm Steam Eye Mask 5’s",
                "5.\tGodivine Cranberry Almond Cookies 70g",
                "6.\tGodivine Matcha Biscotti 70g",
                "7.     Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 786,
        "name": "Winter Break (C)",
        "handle": "winter-break-c",
        "description": "Winter Break (C)A wonderful way to deck your halls this holiday season or to send as a gift to wish someone the best Christmas, the Winter Break Christmas Hamper is curated to impress and delight! Designed with the delectable Starbucks cafe mocha and relaxing treats to provide the ultimate indulgence, this unique Christmas gift box promises them the best me-time this holiday season. ",
        "type": "Hampers",
        "tags": [],
        "price": "191.46",
        "inclTax": "191.46",
        "img": [
            "/images/products/Winter-Break-_C_1-580054.jpg?v=1671775979",
            "/images/products/Winter-Break-_C_2_77315f75-b670-4e24-b94a-618529cbff14-177421.jpg?v=1671775979"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tStarbucks Caffe Mocha Premium Mixes 88g",
                "2.\tNeck Relaxer",
                "3.\tScalp Massager",
                "4.\tMegRhythm Steam Eye Mask 5’s",
                "5.\tGodivine Cranberry Almond Cookies 70g",
                "6.\tGodivine Matcha Biscotti 70g",
                "7.     Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 787,
        "name": "Warm & Glow (T)",
        "handle": "warm-glow-t",
        "description": "Warm  Glow (T)A wonderful way to help someone glow up for their best Christmas, the Warm  Glow Christmas Hamper is curated to impress and delight! Designed with the delectable Juan Tea blends and delectable munchies to provide the ultimate indulgence, this unique Christmas gift box promises them the best me-time on-the-go. ",
        "type": "Hampers",
        "tags": [],
        "price": "191.46",
        "inclTax": "191.46",
        "img": [
            "/images/products/Warm-_-Glow-_T_1-956053.jpg?v=1671775976",
            "/images/products/Warm-_-Glow-_T_2-610937.jpg?v=1671775976"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tJuan Tea Indulgence Floral Blend Tea Set 10’s",
                "2.\tCollapsible Cup",
                "3.\tCollapsible Lunch Box (M/L)",
                "4.\tRecycle Bag",
                "5.\tGodivine Cranberry Almond Cookies 70g",
                "6.\tGodivine Matcha Biscotti 70g",
                "7.     Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 788,
        "name": "Warm & Glow (C)",
        "handle": "warm-glow-c",
        "description": "Warm  Glow (C)A wonderful way to help someone glow up for their best Christmas, the Warm  Glow Christmas Hamper is curated to impress and delight! Designed with the robust Starbucks cafe mocha and delectable munchies to provide the ultimate indulgence, this unique Christmas gift box promises them the best me-time on-the-go. ",
        "type": "Hampers",
        "tags": [],
        "price": "191.46",
        "inclTax": "191.46",
        "img": [
            "/images/products/Warm-_-Glow-_C_1_777688d0-31e2-43e9-8f73-8b43fe4217d5-780590.jpg?v=1671775975",
            "/images/products/Warm-_-Glow-_C_2-491310.jpg?v=1671775975"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tStarbucks Caffe Mocha Premium Mixes 88g",
                "2.\tCollapsible Cup",
                "3.\tCollapsible Lunch Box (M/L)",
                "4.\tRecycle Bag",
                "5.\tGodivine Cranberry Almond Cookies 70g",
                "6.\tGodivine Matcha Biscotti 70g",
                "7.     Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 789,
        "name": "Winter Warmth",
        "handle": "winter-warmth",
        "description": "Winter WarmthThe Winter Warmth Christmas Gift Box is sure to bring warmth to someone special on a cold winter’s night. Treat their eyes to this beautiful curation of tasty treats, guaranteed to brighten up their day. Browse our extensive collection of festive Christmas gift hampers, and be prepared to delight and impress your recipient with our dedicated delivery service.",
        "type": "Hampers",
        "tags": [],
        "price": "166.36",
        "inclTax": "166.36",
        "img": [
            "/images/products/Winter-Warmth1-195881.jpg?v=1671775978",
            "/images/products/Winter-Warmth2-962508.jpg?v=1671775979"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tTyrells Sweet Chilli and Red Pepper Potato Chips 40g",
                "2.\tFarmland Thin Vurv Chips Sour Cream & Onion 160g",
                "3.\tEsprit Sparkling Soft Drink Passionfruit 300ml",
                "4.\tAmazin Graze Pandan Coconut Nut Mixed 30g",
                "5.\tEl Almendro Cubits White Chocolate with Raspberry 25g",
                "6. St Michel Mini Madeleines with Choc Chips 75g",
                "7.     Bonart Dragee Milk Chocolate Wallnut 100g",
                "8. Jacker Wafer Cube 100g",
                "9. Godivine Cranberry Almond Cookie 70g",
                "10. Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 790,
        "name": "Holiday Happiness",
        "handle": "holiday-happiness",
        "description": "Holiday HappinessHoliday Happiness is a winter-themed Christmas Gift Hamper that reflects the sharing spirit of the festive season. Wish someone the merriest Christmas this 2022 with this delightful arrangement of delicious munchies and festive beer today! ",
        "type": "Hampers",
        "tags": [],
        "price": "189.18",
        "inclTax": "189.18",
        "img": [
            "/images/products/Holiday-Happiness1_eac2dd0f-d0ae-47ff-b906-8ffba880e21e-581377.jpg?v=1671685351",
            "/images/products/Holiday-Happiness2-214475.jpg?v=1671685351"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tHeineken Beer 330ml",
                "2.\tHoegarden Beer 330ml",
                "3.\tKronenbourg 1664 beer 330ml",
                "4.\tCorona Beer 355ml",
                "5.\tAmazin Graze Pandan Coconut Nut Mixed 30g",
                "6. Hunter’s Gourmet Hand Cooked Black Truffle Potato Chips 40g",
                "7.     Movietime Sweet & Salty Popcorn 30g",
                "8. Nibbles Baked Cashews Nuts 35g",
                "9. Tyrrells Mature Cheddar & Chives Potato Chips 40g",
                "10. Toblerone Crunchy Almond 100g",
                "11. Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 791,
        "name": "Golden Holidays",
        "handle": "golden-holidays",
        "description": "Golden HolidaysThe Golden Holidays Christmas Gift Hamper is a wonderful way to celebrate the Christmas season! This gorgeous holiday hamper of sweet treats and sparkling wines is sure to sweeten up the season. Be the one to make the best impression with delectable treats and festive pours in this merry gift today! ",
        "type": "Hampers",
        "tags": [],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Golden-Holidays1-582136.jpg?v=1671685312",
            "/images/products/Golden-Holidays2-113319.jpg?v=1671685312"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tJP. Chenet Ice Edition 200ml",
                "2.\tJP. Chenet Ice Edition Rose 200ml",
                "3.\tGodiva Signature Dark Chocolate Almonds Bar 90g",
                "4.\tGretel Sprouted Cashews Himalayan Pink Salt 56g",
                "5.\tGolden Bon Bon Almond Nougat- Assorted 70g",
                "6. Excelcium – Pralines BonBons De Chocolat 200g Gold",
                "7.     Hunter’s Gourmet Hand Cooked Black Truffle Potato Chips 40g",
                "8. Jacker Wafer Cube 100g",
                "9. Exquisite Magnetic Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 792,
        "name": "Secret Santa",
        "handle": "secret-santa",
        "description": "Secret SantaCelebrate the holidays with this festive Secret Santa Christmas Wine Hamper! Indulge in a combination of chocolate and wine for the jolliest Christmas season! This no-fuss hamper makes festive gifting a breeze with these holiday essentials! ",
        "type": "Hampers",
        "tags": [],
        "price": "170.92",
        "inclTax": "170.92",
        "img": [
            "/images/products/Secret-Santa1-658655.jpg?v=1702988974",
            "/images/products/Secret-Santa2-112767.jpg?v=1702988974"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tChateau Fleurs Vignobles 75cl",
                "2.\tHamlet Assorted Belgian Chocolates 125g",
                "3.\tExquisite Wine Crate"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 793,
        "name": "Sparkling Dreams",
        "handle": "sparkling-dreams",
        "description": "Sparkling DreamsAdd some jolly ho-ho-ho’s to your gathering with this Sparkling Dreams Christmas Wine Hamper! Featuring a merry selection of chocolates, and accompanied by a festive red wine, this delightful gift is beautifully presented in an exquisite wooden crate. ",
        "type": "Hampers",
        "tags": [],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Sparkling-Dreams1_6b82ff51-2fa6-47c2-8afe-d7da5517410a-351845.jpg?v=1671775907",
            "/images/products/Sparkling-Dreams2-799898.jpg?v=1671775907"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tGrand Jacques Cabernet Sauvignon France Red Wine 75cl",
                "2.\tHamlet Winter Assortment with Hazelnut Filling 250g",
                "3.\tGuyLian The Original Seashells Premium Belgian Chocolate 250g",
                "4. Exquisite Wooden Wine Crate"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 794,
        "name": "Silver Bells",
        "handle": "silver-bells",
        "description": "Silver BellsCelebrate the holidays with this Silver Bells Christmas Wine Hamper! Indulge in our festive pours for the jolliest Christmas season! This no-fuss arrangement is presented in an exquisite double wine crate to make festive gifting presentable and delightful! ",
        "type": "Hampers",
        "tags": [],
        "price": "234.82",
        "inclTax": "234.82",
        "img": [
            "/images/products/Silver-Bells1-892826.jpg?v=1671775907",
            "/images/products/Silver-Bells2-692704.jpg?v=1671775907"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tLes Ormes De Cambras Cabernet Sauvignon 75cl",
                "2.\tLes Ormes De Cambras Sauvignon 75cl",
                "3.\tExquisite Double Wine Crate"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 795,
        "name": "Winter Angel",
        "handle": "winter-angel",
        "description": "Winter AngelToast to the holidays with the Winter Angel Christmas Wine Hamper! Indulge in our favourite festive bubbly for the jolliest Christmas season! This no-fuss arrangement is presented in an exquisite wooden crate to make festive gifting presentable and delightful! ",
        "type": "Hampers",
        "tags": [],
        "price": "597.66",
        "inclTax": "597.66",
        "img": [
            "/images/products/Winter-Angel1-578289.jpg?v=1671775978",
            "/images/products/Winter-Angel2-954505.jpg?v=1671775978"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tMoët & Chandon Imperial Brut Champagne 75cl",
                "2.\tVeuve Clicquot Yellow Label NV Champagne 75cl",
                "3.\tExquisite Wooden Crate"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 796,
        "name": "Celebration Spread",
        "handle": "celebration-spread",
        "description": "Celebration SpreadSending holiday cheer has never tasted so good! In our Celebration Spread Christmas Food Gift, delightful sweet treats are packed alongside savoury snacks, to wish your special someone a fun and joyful winter holiday! ",
        "type": "Hampers",
        "tags": [],
        "price": "312.41",
        "inclTax": "312.41",
        "img": [
            "/images/products/Celebration-Spread1-126128.jpg?v=1671685076",
            "/images/products/Celebration-Spread2-737455.jpg?v=1671685076"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tCantine Paolini Nero D Avola Terre Siciliane 75cl",
                "2.\tLa Mere Poulard Les Sables French Butter Cookies 125g",
                "3.\tWalker Scottish Biscuits for Cheese 250g",
                "4. Wernli Truffet 100g",
                "5. Excelcium – Pralines BonBons De Chocolat 200g (GOLD)",
                "6. Godivine Nutty Mix Nuts 80g",
                "7. Vicenzi Millefoglie Classic Puff Pastry Sticks 125g",
                "8. Godivine Matcha Biscotti 70g",
                "9. Exquisite Crate Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 797,
        "name": "Season's Celebrations",
        "handle": "seasons-celebrations",
        "description": "Season's CelebrationsTreat them to a festive snack spread this season with the Season’s Celebrations Christmas Gift Hamper. It’s brimming with favorite treats and festive pours to keep your loved ones jolly! A favourite of ours, this festive hamper comes beautifully arranged in an exquisite picnic basket.",
        "type": "Hampers",
        "tags": [],
        "price": "878.34",
        "inclTax": "878.34",
        "img": [
            "/images/products/Season_s-Celebrations1-547318.jpg?v=1702988969",
            "/images/products/Season_s-Celebrations2-471474.jpg?v=1702988969"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tLes Ormes De Cambras Cabernet Sauvignon 75cl",
                "2.\tLes Ormes De Cambras Sauvignon 75cl",
                "3.\tBaileys Truffles Tube 320g",
                "4.\tJuan Tea Indulgence Floral Blend Tea Set 18 Teabags",
                "5.\tWalkers Luxury Mini Gin Infused Fruit Tarts 135g",
                "6.\tThe Fine Cheese Co Rosemary & Extra Virgin Olive Oil Crackers 125g",
                "7.     Walters Angel Chocolate Nougat Biscuit 150g",
                "8.     Walkers Scottish Biscuits Assortment 250g",
                "9. Desobry Luxury Belgian Biscuits 375g",
                "10. \"Amazin Graze Nuts Variety Box\"",
                "– 2x Coconut Curry Lime Nut Mix 30g",
                "– 2x Sweet Chilli Nut Mix 30g",
                "– 2x Pandan Coconut Nut Mix 30g",
                "– 2x Zesty Maple Glazed Nut Mix 30g",
                "11. Mrs Bridges – Orange Marmalade with Honey 340g",
                "12. Farmhouse Biscuits Summer Garden Salted Caramel Tube 150g",
                "13. Exquisite Picnic Basket (XL)",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 798,
        "name": "Zoya",
        "handle": "zoya",
        "description": "Introducing our Garden of Elegance Bouquet, a lush and vibrant arrangement that celebrates the beauty of spring. This stunning display features a mix of radiant tulips, delicate roses, and playful gerberas, all artfully arranged with lush greenery. Presented in an elegant glass vase, this bouquet is perfect for any occasion, adding a touch of grace and color to any setting.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Zoya-2a-843623.jpg?v=1724340103",
            "/images/products/Zoya-1a-727202.jpg?v=1724340103"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Spray Rose . Eusotoma . Gerberra . Rose . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 799,
        "name": "Arlette",
        "handle": "arlette",
        "description": "Elegance blooms with our White Roses adorned with delicate Violet Baby's Breath, Daisies and vibrant Green Bell, exquisitely presented in a stylish Violet wrapping. This enchanting bouquet combines the purity of white roses with the whimsical beauty of violet and green accents, creating a stunning arrangement that captivates the senses.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Arlette-1aa.jpg?v=1706235654",
            "/images/products/Arlette-2aa.jpg?v=1706235653"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Baby Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 800,
        "name": "Mila",
        "handle": "mila",
        "description": "Cherish the eternal beauty of our everlasting preserved violet and pink roses meticulously crafted into a heart-shaped wreath bouquet. Symbolizing enduring love and tender emotions, this exquisite arrangement is a timeless expression of affection that will captivate and enchant, adding a touch of everlasting romance to any space.",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Mila-1aa-326052.jpg?v=1704463772",
            "/images/products/Mila-2aa-249109.jpg?v=1704463772"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Gossypium . Dried Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 801,
        "name": "Winter Wonders",
        "handle": "winter-wonders",
        "description": "Winter WondersThe Winter Wonders Christmas Hot Air Balloon Hamper is sure to bring warmth to someone special on a cold winter’s night. Treat their eyes to this beautiful curation of festive blooms, freshly picked just to brighten up their day. ",
        "type": "Balloons & Flowers",
        "tags": [],
        "price": "234.82",
        "inclTax": "234.82",
        "img": [
            "/images/products/Winter-Wonders1A-583869.jpg?v=1671775979",
            "/images/products/Winter-Wonders2_57a7c82a-bb83-4686-9688-e68b885df750-811341.jpg?v=1671775979"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Crystal Balloon (Max. Name Character: 8) . Red Rose . Gerbera . Hypericum . Pine Cone . Preserved Lime . Preserved Orange Slice . Orchid . Eucalyptus Sinara . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 802,
        "name": "Holiday Heritage",
        "handle": "holiday-heritage",
        "description": "Holiday HeritageThe Holiday Heritage Christmas Balloon Hamper is a winter-themed bouquet of blooms that reflect the cosy and warm colours of the festive season. Wish someone the merriest December this 2022 with this delightful arrangement. ",
        "type": "Balloons & Flowers",
        "tags": [],
        "price": "255.36",
        "inclTax": "255.36",
        "img": [
            "/images/products/Holiday-Heritage1A-929373.jpg?v=1671685463",
            "/images/products/Holiday-Heritage2-991978.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Crystal Balloon (Max. Name Character: 8) . Green Apple . Gerbera . Roses . Hypericum . Preserved Lime . Pine Cone . Christmas Leaves . Orchid . Gomphocarpus Physocarpus . Eucalyptus . Preserve Orange Slice . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 803,
        "name": "Christmas Carol",
        "handle": "christmas-carol",
        "description": "Christmas CarolAn homage to a wintery and cool Christmas, the Christmas Carol Hot Air Balloon Hamper will take your imagination far away. Think snow-capped cottages and festive warm brews. This jolly blue Christmas gift is sure to be a crowd-pleasing centerpiece at any home this festive season! ",
        "type": "Balloons & Flowers",
        "tags": [],
        "price": "255.36",
        "inclTax": "255.36",
        "img": [
            "/images/products/Christmas-Carol1-397690.jpg?v=1671685194",
            "/images/products/Christmas-Carol2-430901.jpg?v=1671685194"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Crystal Balloon (Max. Name Character: 8) . Pine Cone . Carnation . Purple Roses . Eustoma ( Lisanthum) . Waxflower . Veronica . Eucalyptus Sinara . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 804,
        "name": "Holiday Cheer",
        "handle": "holiday-cheer",
        "description": "Holiday CheerSending holiday cheer has never tasted so good! In our Holiday Cheer Christmas Hot Air Balloon Hamper, delightful treats are packed alongside festive beer and the crowd-pleasing drunken tower drinking game, to wish your special someone a fun and joyful winter holiday!",
        "type": "Balloons & Flowers",
        "tags": [],
        "price": "255.36",
        "inclTax": "255.36",
        "img": [
            "/images/products/Holiday-Cheer1A-570924.jpg?v=1671685323",
            "/images/products/Holiday-Cheer2-530182.jpg?v=1671685323"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1.\tWernli Truffet 100g",
                "2.\tBeryl’s Tiramisu Almond Milk Chocolate 65g",
                "3.\tHeineken Beer 330g",
                "4.\tDrunken Tower Drinking Game"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 805,
        "name": "Winter Wishes",
        "handle": "winter-wishes",
        "description": "Winter WishesMerry and bright, this joyful Winter Wishes Christmas Balloon Hamper is ready to spread Christmas greetings! Packed to the brim with savoury and sweet treats, we have also included a bubbly for those who are looking for extra indulgence!",
        "type": "Balloons & Flowers",
        "tags": [],
        "price": "469.86",
        "inclTax": "469.86",
        "img": [
            "/images/products/Winter-Wishes1A-270687.jpg?v=1671775979",
            "/images/products/Winter-Wishes2-276860.jpg?v=1671775979"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1.\tTyrrells Sweet Chilli & Red Pepper 40g",
                "2.\tDelfi Assortment 180g",
                "3.\tWalkers Strawberries & Cream Biscuits 150g",
                "4.\tMoët & Chandon Impérial 200ml "
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 806,
        "name": "Santa's Indulgence",
        "handle": "santas-indulgence",
        "description": "Santa's IndulgenceCelebrate the holidays with this festive Christmas Balloon Hamper! Santa’s Indulgence is sure to delight with its merry selection of delectable sweet treats and festive pours! A party-in-a-pack, this jolly hamper comes complete with a wine glass, for the ultimate indulgence.",
        "type": "Hampers",
        "tags": [],
        "price": "415.10",
        "inclTax": "415.10",
        "img": [
            "/images/products/Santa_s-Indulgence1_7ffabfb5-d6b0-47de-a571-d7030a3e3bbd-570465.jpg?v=1702988968",
            "/images/products/Santa_s-Indulgence2-699976.jpg?v=1702988968"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "",
                "1.\tHamlet assortment Belgian chocolates (Red)",
                "2.\tHamlet Excelcium Assorted Chocolate 180g",
                "3.\tBeryl’s Tiramisu Almond Milk Chocolate 65g",
                "4. Cavendish & Harvey Mixed Fruit Drops 200g",
                "5. Domini Veneti Classico Valpolicella DOC Pruviniano 2019 750 ML",
                "6. Wine Glass"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 807,
        "name": "Season's Surprise",
        "handle": "seasons-surprise",
        "description": "Season's SurpriseGive the best surprise this festive season with the Season’s Surprise Christmas Balloon Hamper! Celebrate joyful and treasured moments that this special time of year holds with this delectable assortment of festive treats that are sure to delight a sugarholic!  ",
        "type": "Hampers",
        "tags": [],
        "price": "266.77",
        "inclTax": "266.77",
        "img": [
            "/images/products/Season_s-Surprise1-162773.jpg?v=1702988970",
            "/images/products/Season_s-Surprise2-298481.jpg?v=1702988970"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "",
                "1.\tCupido Hazelnut Chocolate Seashells 250g",
                "2.\tLINDT Lindor Cornet Assorted 200g",
                "3.\tCavendish & Harvey Mixed Fruit Drops 200g",
                "4. Heineken Beer 330g",
                "5. Golden Bonbon Soft Almond Nougat 70g"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 808,
        "name": "Santa's Secret",
        "handle": "santas-secret",
        "description": "Santa's SecretAdd some jolly ho-ho-ho’s to your gathering with the Santa’s Secret Christmas Balloon Hamper! Featuring a merry selection of sweet treats and a festive sparkling wine, this delightful gift is beautifully presented in an exquisite wooden crate.  ",
        "type": "Hampers",
        "tags": [],
        "price": "298.71",
        "inclTax": "298.71",
        "img": [
            "/images/products/Santa_s-Secret1-932283.jpg?v=1702988969",
            "/images/products/Santa_s-Secret2_12f5c3e6-c038-40eb-b4e1-ce5894783cdf-793641.jpg?v=1702988969"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "",
                "1.\tCupido Hazelnut Chocolate Seashells 250g",
                "2.\tCavendish & Harvey Mixed Fruit Drops 200g",
                "3.\tLINDT Lindor Cornet Assorted 200g",
                "4. Hamlet assortment Belgian chocolates (Gold)",
                "5. Movietime Original Popcorn Sea Salt 20g",
                "6. JP. Chenet Ice Edition Blanc White Sparkling Wine 200ML"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 809,
        "name": "Festive Cheers",
        "handle": "festive-cheers",
        "description": "Festive CheersSending holiday cheer has never tasted so good! In our Festive Cheers Christmas Balloon Hamper, delightful treats are packed alongside festive pours, for a jolly, fun, and exciting holiday!",
        "type": "Hampers",
        "tags": [],
        "price": "725.45",
        "inclTax": "725.45",
        "img": [
            "/images/products/Festive-Cheers1_dbe5a098-313d-46e1-8e29-543e65c12a8f-272389.jpg?v=1671685303",
            "/images/products/Festive-Cheers2_0107740e-3e5f-45ad-a923-86fab1d92d0a-693074.jpg?v=1671685303"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "",
                "1.\tGolden Bonbon Soft Almond Nougat 130g",
                "2.\tJules Destrooper Biscuits – Belgian Chocolate Thins 100g",
                "3.\tVal d’Oca Blue Millesimato Prosecco Extra Dry 750ml",
                "4. Moet & Chandon Imperial 200ml ",
                "5. BlueBerry"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 810,
        "name": "Nutcracker's Delight",
        "handle": "nutcrackers-delight",
        "description": "Nutcracker's DelightBlossoming with a Christmas charm that evokes memories of fairytale festive seasons whilst nodding towards a traditional mode of styling, the Nutcracker’s Delight Christmas Balloon Hamper is ready to spread the joy of the season to your near and dear! Perfect as a Christmas gift for mom, or a showstopper at any celebration, all this needs is a personalised message from you to brighten up the festive cheer! ",
        "type": "Hampers",
        "tags": [],
        "price": "383.15",
        "inclTax": "383.15",
        "img": [
            "/images/products/Nutcracker_s-Delight1-782275.jpg?v=1671685496",
            "/images/products/Nutcracker_s-Delight2-267930.jpg?v=1671685496"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "",
                "1.\tDomini Veneti Classico Valpolicella DOC Pruviniano 750 ML",
                "2.  Red Roses",
                "3.\tGerbera",
                "4.\tHypericum",
                "5. Carnation",
                "6. Eucalyptus",
                "7. Preserved Lime"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 811,
        "name": "Fireside Cheers",
        "handle": "fireside-cheers",
        "description": "Fireside CheersA wonderful way to deck your halls this holiday season or to send as a gift to wish someone the best Christmas, the Fireside Cheers Christmas Hot Air Balloon Hamper is designed to impress and delight! A festive cabernet is positioned amongst jolly Christmas blooms to give this unique hamper the sophisticated and charming flair we envision. ",
        "type": "Hampers",
        "tags": [],
        "price": "447.04",
        "inclTax": "447.04",
        "img": [
            "/images/products/Fireside-Cheers1-818514.jpg?v=1671685311",
            "/images/products/Fireside-Cheers2-449397.jpg?v=1671685311"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "",
                "1.\tPenfolds Max’s Shiraz Cabernet 750ml",
                "2.  Protea",
                "3.\tNew Gerbera",
                "4.\tRed Roses",
                "5. Pine Cone",
                "6. Hypericum",
                "7. Orchid",
                "8. Eucalyptus"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 812,
        "name": "Festive Elegance",
        "handle": "festive-elegance",
        "description": "Festive EleganceReady to gift in style and make this Christmas 2022 look picture perfect? This holiday, gift the Festive Elegance Christmas Balloon Hamper to add a special sparkle to the season! Festive pours and Christmas flowers decorate this arrangement, for a jolly, holly and happy season’s greetings. ",
        "type": "Hampers",
        "tags": [],
        "price": "469.86",
        "inclTax": "469.86",
        "img": [
            "/images/products/Festive-Elegance1_230955ca-7509-43db-a1c1-adbe090eb7ee-852095.jpg?v=1671685309",
            "/images/products/Festive-Elegance2-715323.jpg?v=1671685309"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "",
                "1.\tGeorge Wyndham Bin 444 Cabernet Sauvignon 750ml",
                "2.  Penfolds Max’s Shiraz Cabernet 750ml",
                "3.\tExquisite Flower Arrangement"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 813,
        "name": "Christmas Sips",
        "handle": "christmas-sips",
        "description": "Christmas SipsAccented with an assortment of Christmas blooms and presented in an exquisite hatbox, the holiday Christmas Sips Hot Air Balloon Hamper, featuring a Moet  Chandon Imperial is set to get you noticed! Send your season’s greetings to your friends and family near and far, and share in their celebrations this festive season!",
        "type": "Hampers",
        "tags": [],
        "price": "478.99",
        "inclTax": "478.99",
        "img": [
            "/images/products/Christmas-Sips1-120484.jpg?v=1671685195",
            "/images/products/Christmas-Sips2-871837.jpg?v=1671685195"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "",
                "1.\tMoet & Chandon Imperial 200ml ",
                "2. Protea",
                "3.\tNew Gerbera",
                "4. Red Roses",
                "5. Pine Cone",
                "6. Hypericum",
                "7. Orchid",
                "8. Eucalyptus"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 814,
        "name": "Lucia",
        "handle": "lucia",
        "description": "This elegant bouquet showcases pristine white roses complemented by soft silver foliage and delicate white accents. Wrapped in luxurious white paper and finished with a matching ribbon, it exudes timeless grace and sophistication, perfect for weddings, anniversaries, or any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Lucia-2a.jpg?v=1720880003",
            "/images/products/Lucia-1a.jpg?v=1720880002"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 24cm*30cm"
    },
    {
        "id": 815,
        "name": "Prosperity (VI)",
        "handle": "prosperity-vi",
        "description": "Celebrate all grand openings in style with our Vibrant Harmony Floral Stand. A symphony of pink, champagne and red Gerbera, Roses and blooms comes together, creating a delightful visual melody that represents joy, diversity, and the vibrant spirit of new beginnings. This floral masterpiece stands tall, radiating elegance and positivity, making it the perfect centerpiece for your momentous occasion.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Prosperity-_VI_-1e.jpg?v=1720496961",
            "/images/products/Grand-Opening-Card-2024_417df5f5-5ae2-41bc-a85e-7a0c64f1031a.jpg?v=1720496972",
            "/images/products/Prosperity-_VI_-12.jpg?v=1720496962"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Gerbera . Eustoma . Liliies . Anthurium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 816,
        "name": "Wealth (IV)",
        "handle": "wealth-iv",
        "description": "Elevate all grand openings with our Peachy Petal Ensemble Tripod Stand, a harmonious blend of pink and peach Roses, paired with the delicate allure of pink and dusty Hydrangea Matthiola. This floral masterpiece stands tall, radiating elegance and charm, promising a celebration filled with beauty and success.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Wealth-_IV_-1b.jpg?v=1720101783",
            "/images/products/Grand-Opening-Card-2024_db40ba53-e800-42b9-acfa-ccdb17d5e3be.jpg?v=1720101788",
            "/images/products/Wealth-_IV_-2b.jpg?v=1720101783"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Hydrangeas . Matthiola . Willows . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 817,
        "name": "Wealth (VII)",
        "handle": "wealth-vii",
        "description": "Make a bold statement at all grand business openings with our Vibrant Citrus Bloom Tripod Stand. Bursting with orange and blue flowers, beautifully adorned with matching skirtings, this arrangement signifies energy, creativity, and the commencement of a dynamic venture.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Wealth-_VII_-1b.jpg?v=1720101928",
            "/images/products/Grand-Opening-Card-2024_d33ff1dd-b465-4fd2-ae0a-e67582ab7132.jpg?v=1720101936",
            "/images/products/Wealth-_VII_-2b.jpg?v=1720101928"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Roses . Hydrangeas . Eustoma . Artificial Orchids . Anthurium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 818,
        "name": "Wealth (VIII)",
        "handle": "wealth-viii",
        "description": "Immerse all grand openings in Neutral Opulence with our Tripod Stand. Soft and sophisticated nude-colored flowers harmoniously presented in coordinating skirtings, creating a luxurious centerpiece that signifies success and the dawn of a prestigious business journey.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Wealth-_VIII_-1b.jpg?v=1720102128",
            "/images/products/Grand-Opening-Card-2024_a34ef03a-4bee-47ed-9fda-e342c676cd87.jpg?v=1720102136",
            "/images/products/Wealth-_VIII_-2b.jpg?v=1720102129"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Eustoma . Matthiola . Anthurium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 819,
        "name": "Lilibet",
        "handle": "lilibet",
        "description": "This delicate bouquet features an array of soft, pastel eustoma, and small daisy-like blossoms. Accented with baby's breath and lush greenery, the arrangement is wrapped in a soft pink and white paper, giving it a romantic and elegant appearance perfect for any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Eustoma Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Lilibet-2a-368923.jpg?v=1721222880",
            "/images/products/Lilibet-1a-569404.jpg?v=1721222880"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Eustomas . Fillers & Foliage "
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 820,
        "name": "Abundance (I)",
        "handle": "abundance-i",
        "description": "Unveil Grandeur with Our Jumbo Flower StandAdorned in blue and pink hydrangeas and gerberas, this harmonious blend of colors signifies prosperity, sophistication, and a flourishing journey. Forming a visually stunning and cohesive centerpiece, it symbolizes success and elegance at your business opening.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Abundance-_I_-1h.jpg?v=1720496756",
            "/images/products/Grand-Opening-Card-2024.jpg?v=1720496763",
            "/images/products/Abundance-_I_-2g.jpg?v=1720494321"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Gerbera . Faux Hydrangeas . Matthiola . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 821,
        "name": "Caitlin",
        "handle": "caitlin",
        "description": "This stunning bouquet features elegant white gerbera daisies, soft peach roses, and lush greenery, topped with an adorable teddy bear in graduation attire. Wrapped in crisp white paper and tied with a ribbon, it's a perfect gift for a graduate.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Caitlin-2a.jpg?v=1720666147",
            "/images/products/Caitlin-1a-122571.jpg?v=1720788697"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera. Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 822,
        "name": "Zia",
        "handle": "zia-1",
        "description": "“Love is of all passions the strongest, for it attacks simultaneously the head, the heart and the senses.” - Lao Tzu",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Zia1C_9d6b1102-9a07-4540-82f5-819b01ecee12.jpg?v=1670371898",
            "/images/products/Zia2C.jpg?v=1670371995"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Secret Garden Roses . Silver Shadow Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 823,
        "name": "Abundance (II)",
        "handle": "abundance-ii",
        "description": "Embrace Success with Our Crimson Elegance Grand Opening StandBathed in the golden glow of sunflowers, our stand exudes warmth and positivity, embodying the spirit of new beginnings. Each sunflower, with its vibrant petals and towering stature, symbolizes the joy and abundance that the grand opening festivities bring.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Abundance-_II_-1e.jpg?v=1720494970",
            "/images/products/Grand-Opening-Card-2024_5f7887bb-e802-42b0-b97d-a7a3a97e110d.jpg?v=1720494977",
            "/images/products/Abundance-_II_-2e.jpg?v=1720494970"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Gerbera . Faux Hydrangeas . Matthiola . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 824,
        "name": "Sunrise FBS",
        "handle": "sunrise-fbs",
        "description": "Featuring a radiant sunflower at the center, surrounded by colorful baby's breath, this arrangement brings a burst of sunshine and joy. Presented in a stylish tan handbag with a satin ribbon, it's the perfect gift to brighten any day and spread happiness.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Sunrise-FBS-1b-725115.jpg?v=1720118499",
            "/images/products/Sunrise-FBS-2a.jpg?v=1720063489"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Rainbow Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 825,
        "name": "Prosperity (VII)",
        "handle": "prosperity-vii",
        "description": "Mark the beginning of a successful journey with our Blooming Success Floral Stand. Sunflowers stand tall amidst a sea of roses, embodying the vibrancy and achievement that all grand openings represent. This grand opening flower arrangement is a visual masterpiece that speaks volumes about the elegance and grace of all special occasions.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Prosperity-_VII_-1d.jpg?v=1720406605",
            "/images/products/Grand-Opening-Card-2024_9b84234f-3a06-4b7a-8f22-c47ccc7c731f.jpg?v=1720406608",
            "/images/products/Prosperity-_VII_-2d.jpg?v=1720406604"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 826,
        "name": "Gracious Sentiments FNB",
        "handle": "gracious-sentiments-fnb",
        "description": "Gracious Sentiments If you are looking to send gracious sentiments to your recipient, look no further. The Gracious Sentiments balloon and food hamper will make the right impression on your recipient, with its assortment of delightful snack time favourites! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "346.64",
        "inclTax": "346.64",
        "img": [
            "/images/products/Gracious-Sentiments-FNB1-740018.jpg?v=1709143475",
            "/images/products/Gracious-Sentiments2-630204.jpg?v=1709084592"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  Excelcium – Pralines BonBons De Chocolat 180g",
                "2.  Hamlet Assortment Chocolate 125g",
                "3. Lindt Lindor Assorted Swiss Chocolate 200g",
                "4. Tiptree – Orange & Tangerine Fine Cut Marmalade 340g",
                "5. Breitsamer Honig Raw Honey 500g",
                "6. Gusto Organic Sicilian Blood Orange 275ml",
                "7. Cupido Latte Macchiato Dessert Cups 125g",
                "8. Personalized Text on Balloon with 6 Mini Balloons",
                "9. Exquisite Wicker Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 827,
        "name": "Instant Joy FNB",
        "handle": "instant-joy-fnb",
        "description": "Instant JoyGive the gift of instant joy with our Instant Joy balloon and food hamper! Perfect for when someone you hold dear needs cheering up, this delightful arrangement of comfort treats will do the trick!",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet"
        ],
        "price": "335.23",
        "inclTax": "335.23",
        "img": [
            "/images/products/Instant-Joy-FNB1-182794.jpg?v=1709056579",
            "/images/products/Instant-Joy2_ee1dc805-6742-4698-9e47-7468e532f151-283659.jpg?v=1709012750"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Aalst- Fruits and Nuts Milk Chocolate Tin 150g",
                "2.  Bahlsen Deloba Blueberry 100g",
                "3.  Temptation- Homemade Cookies Chocolate Chip 250g",
                "4.  Tiptree  Orange & Tangerine Fine Cut Marmalade 340g",
                "5.  Oh So Healthy Guava Purple Yam Banana Fruit Crisps 40g",
                "6.  Geogias Natural Pear Juice 300ml",
                "7.  Personalized Text On Balloon with 6 Mini Balloons",
                "8.  Exquisite Wicker Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 828,
        "name": "Best Beginnings FNB",
        "handle": "best-beginnings-fnb",
        "description": "Best BeginningsWish someone the best beginnings with this delightful balloon and food hamper. Design to make the right impact, the Best Beginnings snack pack comes with a JP. Chenet Ice Edition to celebrate all the special moments! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Happy Birthday Balloons Bouquet"
        ],
        "price": "451.61",
        "inclTax": "451.61",
        "img": [
            "/images/products/Best-Beginnings-FNB1-570091.jpg?v=1708969544",
            "/images/products/Best-Beginnings2-416229.jpg?v=1708927848"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Golden BonBon Almond Nougat 130g",
                "2.  Excelcium  Pralines BonBons De Chocolat 180g",
                "3.  Hamlet Assortiment Chocolate 125g",
                "4.  Temptation- Homemade Cookies Chocolate Chip 250g",
                "5.  Temptation- Homemade Cookies Double Chocolate 250g",
                "6.  JP. Chenet Colombard Sauvignon 250ml",
                "7.  Taylors Classic Chai 50g",
                "8.  Personalised Text On Balloon with 6 Mini Balloons",
                "9.  Exquisite Wicker Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 829,
        "name": "Sweet Charms FNB",
        "handle": "sweet-charms-fnb",
        "description": "Sweet CharmsSweeten your loved one’s day with the Sweet Charms balloon and flower hamper. Presented in a mini transparent cake box, this unique hamper features a mini chocolate etoile cake, and blooms of preserved roses, chrysanthemum, and orange slices. ",
        "type": "Balloons & Flowers",
        "tags": [
            "Happy Birthday Balloons Bouquet"
        ],
        "price": "419.66",
        "inclTax": "419.66",
        "img": [
            "/images/products/Sweet-Charms1-939928.jpg?v=1671775914",
            "/images/products/Sweet-Charms2-766139.jpg?v=1671775914"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.   Mini Choc Etoile Cake",
                "2.  Preserved Rose",
                "3.  Preserved Orange Slice",
                "4.  Preserved Chrysanthemum",
                "5.  Preserved Gerbera",
                "6. Pittosporum Mini Seed",
                "7.  Pittosporum Leaves"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 830,
        "name": "Graceful Wishes FNB",
        "handle": "graceful-wishes-fnb",
        "description": "Graceful WishesSend graceful blooms that will get your recipient’s day started in style! The Graceful Wishes balloon bouquet includes a mini blackforest cake, and an arrangement with dry preserved roses, chrysanthemums, and orange slices. Presented in a mini transparent cake box, this balloon gift will ensure that special day will be an unforgettable one. ",
        "type": "Balloons & Flowers",
        "tags": [
            "Happy Birthday Balloons Bouquet"
        ],
        "price": "399.12",
        "inclTax": "399.12",
        "img": [
            "/images/products/Graceful-Wishes1-612352.jpg?v=1671685211",
            "/images/products/Graceful-Wishes2-602358.jpg?v=1671685211"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Mini Blackforest Cake",
                "2.  Preserved Rose",
                "3.  Preserved Orange Slice",
                "4.  Preserved Chrysanthemum",
                "5.  Preserved Gerbera",
                "6.  Pittosporum Mini Seed",
                "7.  Pittosporum Leaves"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 831,
        "name": "Lemony Teaparty FNB",
        "handle": "lemony-teaparty-fnb",
        "description": "Lemony TeapartyThe Lemony Teaparty is the perfect snack hamper that channels the summery vibes with its yellow balloons! Find enough snacks to keep you going all day, including lemon curd, chocolates, juice and more, all loaded into an exquisite wooden crate. We have even included a Botanica Home Fragrance for an extra relaxing tea sesh! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Congratulations Balloons & Flowers "
        ],
        "price": "472.15",
        "inclTax": "472.15",
        "img": [
            "/images/products/Lemony-Teaparty-FNB1-258873.jpg?v=1709056634",
            "/images/products/Lemony-Teaparty2-234394.jpg?v=1709014152"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Amazin Graze Goodness Bowl",
                "2.  Botanica Home Fragrance Fleur Candle",
                "3.  Wilkin & Sons Ltd. Lemon Curd 340g",
                "4.  Mrs. Bridges Orange and Tangerine Marmalade",
                "5.  Hamlet Assortiment Chocolate",
                "6.  Dry Flower Cotton",
                "7.  Firefly Natural Juice  Lemon, Lime 330ml",
                "8.  White Wild Flower"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 832,
        "name": "Bridal VII",
        "handle": "bridal-vii",
        "description": "This beautiful bridal arrangement will come together with a Vase =)",
        "type": "Bouquets",
        "tags": [
            "Wedding Flowers",
            "Engagement Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Bridal-_VII_1A-711104.jpg?v=1671685194",
            "/images/products/Bridal-_VII_2A-563933.jpg?v=1671685194"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips ."
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 833,
        "name": "Jolly Merrymaker",
        "handle": "jolly-merrymaker",
        "description": "Jolly MerrymakerMerry and bright, the Jolly Merrymaker Christmas Gift Hamper is ready to spread holiday cheers! Packed to the brim with savoury and sweet treats, we have also included hot cocoa to go with those delicious confections! Delight a chocoholic friend with this festive Christmas gift today!",
        "type": "Hampers",
        "tags": [],
        "price": "209.72",
        "inclTax": "209.72",
        "img": [
            "/images/products/Jolly-Merrymaker1-601186.jpg?v=1671685466",
            "/images/products/Jolly-Merrymaker2-576824.jpg?v=1671685466"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1.\tSwiss Miss Dark Chocolate Sensation Hot Cocoa 283g",
                "2.\tCupido Royal Mints 200g",
                "3.\tBeryl’s Tiramisu Almond Milk Chocolate 65g",
                "4. Danisa Traditional Butter Cookies 200g",
                "5. Quickbury Mini Brownies Chocolate and Hazelnut 125g",
                "6. Max’s Farm Cassava Chips 100g",
                "7. Jules Destrooper Belgian Choco Virtuoso 100g",
                "8. Royal Select Sparkling Grape Juice 750ml",
                "9. Exquisite Crate Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 834,
        "name": "Sugarplum Babybum FNB",
        "handle": "sugarplum-babybum-fnb",
        "description": "Sugarplum BabybumThe Sugarplum Babybum baby girl gift box is the perfect gift to say welcome to the world and congratulations on the special arrival. Filled with essentials, this pink newborn baby girl hamper features the Kayen  Kayes™ 100% cotton 3-piece Gift Set, teethers and silicone spoons that new parents will be delighted to receive! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Sugarplum-Babybum1.jpg?v=1700445247",
            "/images/products/Sugarplum-Babybum2.jpg?v=1700445247"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  Kayen & Kayes™ 100% cotton 3-Piece Gift Set (Pink) (Mitten/Romper/Socks)",
                "2. BabyK™ Bamboo Silicone Spoon (Set of 3)",
                "3.  BabyK™ Silicone Giraffe Toy",
                "4. BabyK™ Silicone Dinosaur Teether",
                "5. Wooden Crate",
                "6. Personalised Text on Balloon with 4 Mini Balloons"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 835,
        "name": "Little Boy Blue FNB",
        "handle": "little-boy-blue-fnb",
        "description": "Little Boy Blue The Little Boy Blue baby boy gift box is the perfect gift to say welcome to the world and congratulations on the special arrival. Filled with essentials, this blue newborn baby boy hamper features the BabyK 100% cotton 4-piece Gift Set, teethers and silicone spoons that new parents will be delighted to receive! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Little-Boy-Blue-FNB1.jpg?v=1709097860",
            "/images/products/Little-Boy-Blue-FNB2-603650.jpg?v=1709143576"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  BabyK™ 100% cotton 4-piece Gift Set (Blue) (Cap/Mitten/Romper/Sock)",
                "2. BabyK™ Bamboo Silicone Spoon (Set of 3)",
                "3.  BabyK™ Silicone Giraffe Toy",
                "4. BabyK™ Silicone Dinosaur Teether",
                "5. Wooden Crate",
                "6. Personalised Text on Balloon with 4 Mini Balloons"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 836,
        "name": "Baby's Breath FNB",
        "handle": "babys-breath-fnb",
        "description": "Baby's BreathThe Baby’s Breath baby gift box is the perfect gift to say welcome to the world and congratulations on the special arrival. Filled with essentials, this newborn baby boy hamper features the BabyK 100% cotton 4-piece Gift Set, teethers and silicone spoons that new parents will be delighted to receive! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Baby_s-Breath-FNB1-258072.jpg?v=1709142969",
            "/images/products/Baby_s-Breath2-784447.jpg?v=1709097054"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  BabyK™ 100% cotton 4-piece Gift Set (Yellow) (Cap/Mitten/Romper/Sock)",
                "2. BabyK™ Bamboo Silicone Spoon (Set of 3)",
                "3.  BabyK™ Silicone Giraffe Toy",
                "4. BabyK™ Silicone Dinosaur Teether",
                "5. Wooden Crate",
                "6. Personalised Text on Balloon with 4 Mini Balloons"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 837,
        "name": "Baby's Blush FNB",
        "handle": "babys-blush-fnb",
        "description": "The Baby’s Blush baby girl hamper is filled with adorable newborn baby essentials that is certainly a delight to receive. Presented in an exquisite rattan basket, it includes an organic head shaping pillow, washcloths, teethers and scalp massagers from BabyK, making this a must-have set for new parents! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "303.28",
        "inclTax": "303.28",
        "img": [
            "/images/products/Baby_s-Blush1-566484.jpg?v=1671684830",
            "/images/products/Baby_s-Blush2-439271.jpg?v=1671684830"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  BabyK™ Organic Head Shaping Pillow",
                "2. BabyK™ Bamboo Silicone Spoon (Set of 3)",
                "3.  BabyK™ 6 pcs Bamboo Wash cloth",
                "4. BabyK™ Silicone Teether",
                "5. BabyK™ Baby Bath Scalp Massager",
                "6. BabyK™ Bamboo Swaddling Cloth",
                "7. Hand crafted Wicker Basket",
                "8. Personalized Text on Balloon with 6 Mini Balloons"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 838,
        "name": "Baby's Starshine FNB",
        "handle": "babys-starshine-fnb",
        "description": "Baby's StarshineTreat a newborn baby boy with indulgent gifts from the Baby’s Starshine balloon hamper! Included inside are the BabyK 100% cotton 4-piece Gift Set, scalp massager, teether and keepsake album, all read to help new parents record their exciting new journey into parenthood!",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "314.69",
        "inclTax": "314.69",
        "img": [
            "/images/products/Baby_s-Starshine-FNB1.jpg?v=1709097248",
            "/images/products/Baby_s-Starshine-FNB2-379970.jpg?v=1709142969"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  BabyK™ Organic Head Shaping Pillow",
                "2. BabyK™ 100% cotton 4-piece Gift Set (Blue)(Cap/Mitten/Romper/Sock)",
                "3.  BabyK™ Baby Bath Scalp Massager",
                "4. BabyK™ Silicone Dinosaur Teether",
                "5. BabyK™ Keepsake Mini book",
                "6. Fleece Blanket",
                "7. Personalised Text on Balloon",
                "8. Hand crafted Wicker Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 839,
        "name": "Baby's Chuckle FNB",
        "handle": "babys-chuckle-fnb",
        "description": "Baby's ChuckleThe gender neutral Baby’s Chuckle baby hamper is filled with adorable newborn baby essentials that is certainly a delight to receive. Presented in an exquisite rattan basket, it includes an organic head shaping pillow, washcloths, teethers and scalp massagers from BabyK, making this a must-have set for new parents! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "303.28",
        "inclTax": "303.28",
        "img": [
            "/images/products/Baby_s-Chuckle1-106219.jpg?v=1671684898",
            "/images/products/Baby_s-Chuckle2-183475.jpg?v=1671684898"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  BabyK™ Organic Head Shaping Pillow",
                "2. BabyK™ Bamboo Silicone Spoon (Set of 3)",
                "3.  BabyK™ 6 pcs Bamboo Wash cloth",
                "4. BabyK™ Silicone Giraffe Toy",
                "5. BabyK™ Baby Bath Scalp Massager",
                "6. BabyK™ Bamboo Swaddling Cloth",
                "7. Hand crafted Wicker Basket",
                "8. Personalised Text on Balloon with 6 Mini Balloons"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 840,
        "name": "Constance",
        "handle": "constance",
        "description": "\"Anyone can love a thing  because. That's as easy as putting a penny in your pocket. But to love something  despite. To know the flaws and love them too. That is rare and pure and perfect.\" - Patrick Rothfuss",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Constance1A_5d4eafa4-565d-4ac3-8ebe-c48dedda425c-881966.jpg?v=1671685077",
            "/images/products/Constance2A-631200.jpg?v=1671685077"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Christa and Princess Hitomi Roses . Sancerre Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 841,
        "name": "Violeta",
        "handle": "violeta",
        "description": "“Love is of all passions the strongest, for it attacks simultaneously the head, the heart and the senses.” - Lao Tzu",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Violeta1B_93fe3060-f746-440e-8325-89e04b876c33-342327.jpg?v=1671775975",
            "/images/products/Violeta2B-801602.jpg?v=1671775975"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 842,
        "name": "Rachel",
        "handle": "rachel",
        "description": "“Love is of all passions the strongest, for it attacks simultaneously the head, the heart and the senses.” - Lao Tzu",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Rachel1D_74c16f23-953f-4295-bb6b-231565a5172b-740726.jpg?v=1702988905",
            "/images/products/Rachel2D-639331.jpg?v=1702988905"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Perfume de Grande Roses . "
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 843,
        "name": "Hello Prince FNB",
        "handle": "hello-prince-fnb",
        "description": "Hello PrinceThe Hello Prince! Baby gift box welcomes the newborn baby boy in style! We have included items for playtime, snack-time, and also reading time, to make this one of the best well-rounded newborn baby hampers in Malaysia!",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "378.58",
        "inclTax": "378.58",
        "img": [
            "/images/products/Hello-Prince1-745594.jpg?v=1671685463",
            "/images/products/Hello-Prince2-960520.jpg?v=1671685463",
            "/images/products/Hello-Prince-FNB3.jpg?v=1709098426"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  Fleece Blanket",
                "2. BabyK™ Sippy Cup",
                "3.  Kayen & Kayes™ Squeaker toy – Rabbit",
                "4. Baby K Diaper Caddy Organizer Basket",
                "5. BabyK™ Keepsake Mini book",
                "6. Buds-Infant Cream 75ml",
                "7. Gaia Mini Traveller Set",
                "-Bath & Body Wash 50ml",
                "-Baby Shampoo 50ml",
                "-Baby Moisturiser 50ml"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 844,
        "name": "Bubbly Baby FNB",
        "handle": "bubbly-baby-fnb",
        "description": "Bubbly BabyA happy baby is a bubbly baby. Welcome this new addition with the Bubbly Baby newborn baby boy hamper. Featuring a suction bowl, silicone spoons, and an exciting keepsake album to record the precious moments, this balloon hamper is just the perfect complement for baby’s development. We have also included bird’s nest shots to make sure that the new parents also get a boost to their health! All this balloon hamper needs is just a personalised note to set it off perfectly!",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "545.17",
        "inclTax": "545.17",
        "img": [
            "/images/products/Bubbly-Baby1-854156.jpg?v=1671684907",
            "/images/products/Bubbly-Baby2-771623.jpg?v=1671684907"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  Deli Swallow Bird’s Nest with Ginseng, White Fungus & Rock Sugar 3x70ml",
                "2. Marcus & Marcus Suction bowl – Lola, Yellow",
                "3. BabyK™ Bamboo Silicone Spoon ( Set of 3 )",
                "4. BabyK™ Keepsake Mini book",
                "5. Gaia Baby – Skin Soothing Lotion 100ml",
                "6. Naturaverde Bio Organic Body and Hair Wash 200ml",
                "7. Buds -Baby Safe Bottle & Utensil Cleaner 500ml",
                "8. Buds -Infant Cream 75ml",
                "9. Personalised Text on Balloon",
                "10. Hand crafted Wicker Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 845,
        "name": "Peek-a-boo Baby FNB",
        "handle": "peek-a-boo-baby-fnb",
        "description": "Peek-a-boo BabyThe Peek-a-boo Baby newborn baby hamper includes everything new parents need to keep baby comfortable and happy! We have included quality wash cloths and towels, as well as teethers to sooth baby and keep them fuss-free! We have also added buddy bunny to make sure baby has a new best friend to snuggle with.",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "335.23",
        "inclTax": "335.23",
        "img": [
            "/images/products/Peek-a-boo-Baby1-475475.jpg?v=1671685634",
            "/images/products/Peek-a-boo-Baby2-125862.jpg?v=1671685634"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  BabyK™ 6 pcs Bamboo Wash cloth",
                "2. Kayen & Kayes™ Silicone Teething Self Feeder",
                "3. BabyK™ Novelty Cotton Hooded Towel",
                "4. BabyK™ Silicone Dinosaur Teether",
                "5. Bunny Plush Toy",
                "6. Personalised Text on Balloon with 5 Mini Balloons",
                "7. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 846,
        "name": "Charming Baby FNB",
        "handle": "charming-baby-fnb",
        "description": "Charming BabyThe Charming Baby newborn baby girl hamper features quality toys that are designed to last a long time. We have also included a keepsake album to help new parents record every milestone of their exciting new journey. We also made sure that new daddies and mummies are also not forgotten, and this is why we have included treats like bird’s nest, pear juice, and marmalade to make their day even sweeter. ",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "346.64",
        "inclTax": "346.64",
        "img": [
            "/images/products/Charming-Baby1-478124.jpg?v=1671685076",
            "/images/products/Charming-Baby2-926858.jpg?v=1671685076"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  Deli Swallow Bird’s Nest with Ginseng, White Fungus & Rock Sugar 3x70ml",
                "2. Soft Building Blocks",
                "3. BabyK™ Penguin Wobbler Toy",
                "4. BabyK™ wooden Toy Car",
                "5. BabyK™ Keepsake Mini book",
                "6. Mrs. Bridges Orange and Tangerine Marmalade",
                "7. Georgia’s Natural Pear 300ml",
                "8. Personalised Text on Balloon",
                "9. Hand crafted Wicker Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 847,
        "name": "Sherbert Baby FNB",
        "handle": "sherbert-baby-fnb",
        "description": "Sherbert BabyThe Sherbert Baby newborn balloon hamper will delight even the fussiest baby! We have included comprehensively curated items that will make baby’s development a fun and memorable experience. Loaded inside an exquisite handcrafted hat box, all this beautiful balloon hamper needs is a personalised message that you can send on our balloon!",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "408.25",
        "inclTax": "408.25",
        "img": [
            "/images/products/Sherbert-Baby1-940953.jpg?v=1671775907",
            "/images/products/Sherbert-Baby2-185627.jpg?v=1671775907"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  BabyK™ Bamboo Swaddling Cloth",
                "2. BabyK™ Baby Bath Scalp Massager",
                "3. Kayen & Kayes™ Squeaker toy – Rabbit",
                "4. Kayen & Kayes™ Silicone Teething Self Feeder",
                "5. Buds -Infant Cream 75ml",
                "6. Buds -Infant Head To Toe Cleanser 225ml",
                "7. Personalised Text on Balloon with 4 Mini Balloons",
                "8. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 848,
        "name": "Sunny Baby FNB",
        "handle": "sunny-baby-fnb",
        "description": "Sunny BabySend sunshine and delight to new parents and their precious newborn with the Sunny Baby balloon hamper. We have selected popular mealtime essentials for baby’s first food adventure, as well as teatime delights for the new parents! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "408.25",
        "inclTax": "408.25",
        "img": [
            "/images/products/Sunny-Baby-FNB1-467852.jpg?v=1709144583",
            "/images/products/Sunny-Baby-FNB2-201399.jpg?v=1709144583"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Kayen & Kayes 100% cotton 3-piece Gift Set (Pink) (Mitten/Romper/Sock)",
                "2.  BabyK Fruit Teether",
                "3.  Baby K Thermal Food Jar",
                "4.  Buds -Baby Safe Bottle & Utensil Cleaner 500ml",
                "5.  Mrs. Bridges Orange and Tangerine Marmalade",
                "6.  BabyK Keepsake Mini book",
                "7.  Personalised Text On Balloon with 6 Mini Balloons",
                "8.  Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 849,
        "name": "Sweetpea Baby FNB",
        "handle": "sweetpea-baby-fnb",
        "description": "Sweetpea BabyThere is really nothing sweeter than a cooing baby girl than the Sweetpea newborn baby girl hamper! It is loaded with essentials, including the BabyK 100% cotton 4-piece Gift Set, baby-safe utensils cleaner, silicone spoons and a suction bowl to ensure that baby’s basic needs are taken care of! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "431.07",
        "inclTax": "431.07",
        "img": [
            "/images/products/Sweetpea-Baby1A.jpg?v=1703646540",
            "/images/products/Sweetpea-Baby2A-961925.jpg?v=1703684230"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  BabyK™ 100% cotton 4-piece Gift Set (Pink) (Cap/Mitten/Romper/Sock)",
                "2. BabyK™ Bamboo Silicone Spoon (Set of 3)",
                "3. Marcus & Marcus Suction bowl – Lola, Yellow",
                "4. Buds -Baby Safe Bottle & Utensil Cleaner 500ml",
                "5. Fleece Blanket",
                "6. BabyK™ Silicone Dinosaur Teether",
                "7. BabyK™ Keepsake Mini book",
                "8. Personalised Text On Balloon with 6 Mini Balloons",
                "9. Hand Crafted Wicker Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 850,
        "name": "Blossoming Baby FNB",
        "handle": "blossoming-baby-fnb",
        "description": "Blossoming BabyThe sweetest baby deserves the sweetest balloon hamper! The Blossoming Baby newborn gift hamper contains bath time favourites, as well as the BabyK bamboo swaddling cloth, to keep baby clean and fresh, cosy and fuss-free! Make this balloon bloom box one to be remembered with a personalised balloon message today!",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "431.07",
        "inclTax": "431.07",
        "img": [
            "/images/products/Blossoming-Baby1-744218.jpg?v=1671684899",
            "/images/products/Blossoming-Baby2-673743.jpg?v=1671684899"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. BabyK™ Bamboo Swaddling Cloth",
                "2. BabyK™ Baby Bath Scalp Massager",
                "3. Gaia Baby – Skin Soothing Lotion 100ml",
                "4. Naturaverde Bio Organic Body and Hair Wash 200ml",
                "5. Munchkins -Squirtin Barnard Friends -8pk Bath Toy Sets",
                "6. Personalised Text on Balloon with 4 Mini Balloons",
                "7. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 851,
        "name": "Baby's Sweetnote FNB",
        "handle": "babys-sweetnote-fnb",
        "description": "Baby's SweetnoteThe Baby’s Sweetnote baby girl hamper is filled with newborn baby essentials that is certainly a delight to receive. Presented in an exquisite rattan basket, it includes an organic head shaping pillow, towels, toys and teethers from BabyK, and a Moet and Chandron champagne for mummies and daddies!",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "545.17",
        "inclTax": "545.17",
        "img": [
            "/images/products/Baby_s-Sweetnote1-373406.jpg?v=1671684832",
            "/images/products/Baby_s-Sweetnote2-406462.jpg?v=1671684833"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. BabyK™ Organic Head Shaping Pillow",
                "2. BabyK™ Novelty Cotton Hooded Towel",
                "3. BabyK™ Bamboo Silicone Spoon (Set of 3)",
                "4. BabyK™ Silicone Dinosaur Teether",
                "5. BabyK™ wooden Toy Car",
                "6. Moet and Chandon 200ml",
                "7. Breitsamer Honig Raw Honey 500g",
                "8. Hand Crafted Wicker Basket",
                "9. Personalised Text on Balloon with 6 Mini Balloons"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 852,
        "name": "Perky Baby FNB",
        "handle": "perky-baby-fnb",
        "description": "Perky BabyBabies enjoy being pampered, so give them the gift of comfort and indulgence with the Perky Baby newborn balloon hamper! We have included bath time and grooming essentials, as well as toys to train baby on their developmental milestones! Personalise this balloon hamper with a message from the heart!",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "545.17",
        "inclTax": "545.17",
        "img": [
            "/images/products/Perky-Baby-FNB1.jpg?v=1709095879",
            "/images/products/Perky-Baby2-488115.jpg?v=1709095879"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. BabyK™ Bamboo Swaddling Cloth",
                "2. Kayen & Kayes™ Squeaker toy – Rabbit",
                "3. BabyK™ Silicone Giraffe Toy",
                "4. Gaia Baby – Skin Soothing Lotion 100ml",
                "5. Green People- Organics Babies Baby Lotion- Lavender 150ml",
                "6. Gaia Baby – Natural Baby Powder 100g",
                "7. Cloversoft Nature & Organic Unbleached Bamboo Wipes 70s",
                "8. Personalised Text on Balloon with 4 Mini Balloons",
                "9. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 853,
        "name": "Honeysuckle Baby FNB",
        "handle": "honeysuckle-baby-fnb",
        "description": "Honeysuckle BabyPamper both parents and baby with the Honeysuckle newborn balloon hamper! We have include bath time favourites and traditional tonics to make sure both baby and parents are taken care of! Add a personalised message on the balloon for a gift that truly shouts congratulations!",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "574.84",
        "inclTax": "574.84",
        "img": [
            "/images/products/Honeysuckle-Baby1-899605.jpg?v=1671685351",
            "/images/products/Honeysuckle-Baby2-276438.jpg?v=1671685351"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Deli Swallow Bird’s Nest with Ginseng, White Fungus & Rock Sugar 3x70ml",
                "2. Botanica Round Gel Light 120g",
                "3. Naturaverde Bio Organic Body and Hair Wash 200ml",
                "4. Gaia Baby – Skin Soothing Lotion 100ml",
                "5. Gaia Baby – Natural Baby Powder 100g",
                "6. BabyK™ Baby Care Book",
                "7. Fleece Blanket",
                "8. Mrs. Bridges Orange and Tangerine Marmalade",
                "9. Personalised Text on Balloon with 4 Mini Balloons",
                "10. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 50cm"
    },
    {
        "id": 854,
        "name": "Healthy Smiles WHP",
        "handle": "healthy-smiles-whp",
        "description": "Healthy Smiles Nothing says Get Well Soon better than gifting someone the gift of health. Loaded with healthy tonics to give them a boost of love and energy, the Healthy Smiles Get Well Soon Hamper will send the sincerest well wishes to the ones most important to us.",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Healthy-Smiles1_0b3b086a-b2db-4feb-b607-7518bf0f4f61-779118.jpg?v=1671685463",
            "/images/products/Healthy-Smiles2-262993.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Kinohimitsu Bird’s Nest Gift Set 8 x 75g",
                "2. New Moon Essence of Chicken 4 x 70g",
                "3. Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 855,
        "name": "Healthily Yummy WHP",
        "handle": "healthily-yummy-whp",
        "description": "Healthily YummyAn intricately packed wellness hamper that will be attractive to the sight of the receiver and also bring about nourishment to heart with some of the best health drinks one will ever find and love to taste.",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "243.95",
        "inclTax": "243.95",
        "img": [
            "/images/products/Healthily-Yummy1_f4e1df77-3072-4a8d-a4da-117b9b932d59-789792.jpg?v=1671685414",
            "/images/products/Healthily-Yummy2-183172.jpg?v=1671685414"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Bird’s Nest with Ginseng & Rock Sugar 3x70ml",
                "2. Brand’s Essence of Chicken 3 x 41ml",
                "3. Kelloggs’s Corn Flakes 275g",
                "4. FarmHouse UTH Fresh Milk 1L",
                "5. Old Katong Premium Milk Tea- Less Sugar 250g",
                "6. The Muesli Company Instant Oats Low Sugar Cereal 500g",
                "7. Colada Wholemeal Biscuits 144g",
                "8. Shoon Fatt Peanut Butter Sandwich 175g",
                "9. Assorted Flower Packing",
                "10. Exquisite Gift Box (L)"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 856,
        "name": "Healthy Indulgence WHP",
        "handle": "healthy-indulgence-whp",
        "description": "Healthy IndulgenceAn intricately packed wellness hamper that will be attractive to the sight of the receiver and also bring about nourishment to heart with some of the best health drinks one will ever find and love to taste.",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "246.23",
        "inclTax": "246.23",
        "img": [
            "/images/products/Healthy-Indulgence-WHP2-273073.jpg?v=1708536717",
            "/images/products/Healthy-Indulgence-WHP1-995762.jpg?v=1708536717"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Fomecs Berry Essence 2 x 42ml",
                "2.  Bakery Havana Oat Cookies with Chocochip 120g",
                "3.  Amazin Grace Chocolate Banana Bowl Oat-Based Meal 240g",
                "4.  Taste Asia Ready To Eat Chicken Porridge 400g",
                "5.  Cowhead Original Plain Cracker 180g (8 sachets)",
                "6.  Yogood Wildberry Yoghurt Coated Muesli Bars 138g",
                "7.  Harvest Box Super Mix Nuts 45g",
                "8.  Assorted Flower Packaging",
                "9.  Exquisite Wooden Crate (L)"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 857,
        "name": "Nature Palate with Flower OHP",
        "handle": "nature-palate-with-flower-ohp",
        "description": "Nature Palate with Flower",
        "type": "Hampers",
        "tags": [
            "Organic Hampers"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Nature-Palate-with-Flower1-193797.jpg?v=1671685496",
            "/images/products/Nature-Palate-with-Flower2-450486.jpg?v=1671685496"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Germinal BIO Organic Buckwheat Flakes 200g",
                "2. Pacific – Organic Soy Non-Dairy Beverage 946ml",
                "3. English Tea Shop – Organic Chamomile Lavender Premium Tea",
                "4. ORGRAN Classic Choc Biscotti Gluten Free 150g",
                "5. Amazin’ Grace Antioxidant Berry and Nuts Trail Mix 130g",
                "6. Brothers All Natural Fuji Apple Fruit Crisps 10g",
                "7. Assorted Flowers Packaging",
                "8. Exquisite Gift Box (S)"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 858,
        "name": "Glow and Smile with Flower WHP",
        "handle": "glow-and-smile-with-flower-ohp",
        "description": "Glow and Smile with FlowerThe sunny side of life is the brighter side of life, and you can help a beloved one rediscover that with this exquisite gift basket stuffed with essential health tonic and white fungus and beautified with dainty flowers.",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "506.38",
        "inclTax": "506.38",
        "img": [
            "/images/products/Glow-and-Smile-with-Flower1-236239.jpg?v=1671685312",
            "/images/products/Glow-and-Smile-with-Flower2-367769.jpg?v=1671685312"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Yomeishu Health Tonic 70cl",
                "2. New Moon Birds’ Nest with Rock Sugar 2 x 70ml",
                "3. New Moon Essence of Chicken 3 x 70ml",
                "4. Deli Swallow Bird’s Nest White Fungus 3 x 70g",
                "5. Assorted Flowers Packaging",
                "6. Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 859,
        "name": "Holiday Cheers",
        "handle": "holiday-cheers",
        "description": "Holiday CheersThe Holiday Cheers Christmas Food Gift is one that speaks to the joyful and treasured moments that this special time of year holds! Exciting snacks that are sweet and savoury are packed in this festive Christmas food hamper, which is arranged in a rustic crate. Enjoy fuss-free gifting with our dedicated services!",
        "type": "Hampers",
        "tags": [],
        "price": "289.59",
        "inclTax": "289.59",
        "img": [
            "/images/products/Holiday-Cheers1-931219.jpg?v=1671685324",
            "/images/products/Holiday-Cheers2-717685.jpg?v=1671685324"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "",
                "1. Prestigium Cuvee Speciale Rouge 75cl",
                "2. Duncan’s of Deeside Coconut Shortbread Hand Baked 150g",
                "3. Aalst Whole Hazelnut Milk Chocolate Tin 150g",
                "4. St Michel Chocolate Brownies 210g",
                "5. Walkers Thick & Crunchy Oatcakes 300g",
                "6. Godivine Nutty Mix Nuts 80g",
                "7. Hamlet B&C Chocolate Wafers 110g",
                "8. Hunter Hand Cooked Potatochip Black Truffle 40g Tin",
                "9. Exquisite Crate Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 860,
        "name": "Essence of Health HHP",
        "handle": "essence-of-health-whp",
        "description": "Essence of HealthSend your well wishes with the Essence Of Health Wellness Hamper! Packaged beautifully with stunning flowers, high-quality essence of chicken, and berry essence, this gift will surely put a smile on their face.",
        "type": "Hampers",
        "tags": [
            "Halal Food Hampers"
        ],
        "price": "200.59",
        "inclTax": "200.59",
        "img": [
            "/images/products/Essence-of-Health-WHP1-443416.jpg?v=1708536445",
            "/images/products/Essence-of-Health-WHP2-395445.jpg?v=1708536445"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Brands Essence of Chicken 6 x 41ml",
                "2.  Brands Berry Essence 3 x 41ml",
                "3.  Assorted Flowers Arrangement",
                "4.  Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 861,
        "name": "Plentiful Box 财运亨通 CNY",
        "handle": "plentiful-box-财运亨通-cny",
        "description": "Plentiful Box 财运亨通A good year starts with a good feast. Our Plentiful Box Chinese New Year Gift Set comes with traditional, festive favourites like Buddha’s Temptation, razor clams and fish maw scallop soup for a delightful treat that spoils family and friends!",
        "type": "Hampers",
        "tags": [],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Plentiful-Box-_1-433458.jpg?v=1671685637",
            "/images/products/Plentiful-Box-_2-663610.jpg?v=1671685637",
            "/images/products/Plentiful-Box-_3_92277758-ea4e-4251-9621-23e7d0358845-118506.jpg?v=1671685637"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Yu Xiang Yan Abalone Buddha Jumps Over The Wall 425g",
                "2. Bao Jin Tian Premium Razor Clams 425g",
                "3. Yu Xiang Yan Premium Scallop Fish Maw Soup 425g",
                "4. Exquisite Quilted Faux Leather CNY Gift Box",
                "\n宇鄕燕鲍鱼佛跳墙 425g",
                "鲍今天特级鲍贝 425g",
                "宇鄉燕特级带子花胶汤 425g",
                "精美仿皮新年礼盒"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 862,
        "name": "Flourishing Box 心想事成 CNY",
        "handle": "flourishing-box-心想事成-cny",
        "description": "Flourishing Box 心想事成Start this year of prosperity with a good feast. Our Flourishing Box Chinese New Year Gift Set comes with traditional, festive favourites like braised Japanese abalone, razor clams and Buddha’s Temptation for a delightful treat that spoils family and friends!",
        "type": "Hampers",
        "tags": [],
        "price": "246.23",
        "inclTax": "246.23",
        "img": [
            "/images/products/Flourishing-Box-_1-811411.jpg?v=1671685207",
            "/images/products/Flourishing-Box-_2-586688.jpg?v=1671685207",
            "/images/products/FlourishingBox_3-783946.jpg?v=1671685207"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Yu Xiang Yan Abalone Buddha Jumps Over The Wall 425g",
                "2. Kinohimitsu Imperial Braised Japanese Abalone 425g",
                "3. Bao Jin Tian Premium Razor Clams 425g",
                "4. Yu Xiang Yan Premium Scallop Fish Maw Soup 425g",
                "5. Exquisite Quilted Faux Leather CNY Gift Box",
                "\n宇鄉燕鲍鱼佛跳墙 425g",
                "Kinohimitsu 御皇红烧日本鲍鱼 425g",
                "鲍今天特级鲍贝 425g",
                "宇鄉燕特级带子花胶汤 425g",
                "精美仿皮新年礼盒"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 863,
        "name": "Bridget BBS",
        "handle": "luca-1",
        "description": "“And now these three remain: faith, hope and love. But the greatest of these is love.” -  Anonymous",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Luca1_6b0df1bc-83dc-464f-b656-e1d2d97547c0-854378.jpg?v=1711515702",
            "/images/products/Luca2-233586.jpg?v=1711515702"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 22cm*30cm"
    },
    {
        "id": 864,
        "name": "Jolly",
        "handle": "jolly-1",
        "description": "\"Gifts of time and love are surely the basic ingredients of a truly merry Christmas.” – Peg Bracken",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Jolly1B-674358.jpg?v=1671685464",
            "/images/products/Jolly2B.jpg?v=1670986632"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Pine Cone . Pine Leaf . "
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 865,
        "name": "Jingle",
        "handle": "jingle",
        "description": "“For it is in giving that we receive.” – Francis of Assisi",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Jingle1B_817dedda-dd21-4539-b25c-13e063622d93-951216.jpg?v=1671685464",
            "/images/products/Jingle2B_ee8e7873-1f20-4c65-8660-f976c7a371f4-656542.jpg?v=1671685464"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gossypium . Pine Cone . Pine Leaf . "
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 866,
        "name": "Angel",
        "handle": "angel",
        "description": "\"Christmas is the season for kindling the fire of hospitality.” – Washington Irving",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Angel1C_16607d45-f631-447b-a1d1-c52623aca648-613998.jpg?v=1671684750",
            "/images/products/Angel2C-645314.jpg?v=1671684750"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gossypium . Pine Cone . Pine Leaf . "
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 867,
        "name": "Chimney",
        "handle": "c",
        "description": "\"Christmas gives us the opportunity to pause and reflect on the important things around us.” – David Cameron",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Chimney1.jpg?v=1671071317",
            "/images/products/Chimney2_a0a263ea-19a2-43b0-8766-e407781002f0.jpg?v=1671071317"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gossypium . Pine Cone . Pine Leaf ."
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 868,
        "name": "Kringle",
        "handle": "kringle",
        "description": "\"Christmas is not as much about opening our presents as opening our hearts.” – Janice Maeditere",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Kringle1A_d10884b7-20b5-4a83-8176-912fc77ea622.jpg?v=1671071005",
            "/images/products/Kringle2A.jpg?v=1671071157"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gossypium . Pine Leaf ."
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 869,
        "name": "Prosperous Cheers 四季平安",
        "handle": "prosperous-cheers-四季平安",
        "description": "Prosperous Cheers 四季平安Wish them wellness and happiness this Chinese New Year 2023 with our Prosperous Happiness Chinese New Year Hamper! We have included premium festive delights like Buddha’s Temptation, abalone and bird’s nest, and rounded them up with a delightful red wine to keep the festivities going!",
        "type": "Hampers",
        "tags": [],
        "price": "417.38",
        "inclTax": "417.38",
        "img": [
            "/images/products/Prosperous-Cheers-_1_c6e189cb-fa3c-4590-adae-7989fb2ad295-109489.jpg?v=1671685638",
            "/images/products/Prosperous-Cheers-_2-179575.jpg?v=1671685638"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Chateau Fleurs Vignobles 75cl",
                "2. Kinohimitsu Imperial Braised Japanese Abalone 425g",
                "3. Yu Xiang Yan Premium Scallop Fish Maw Soup 425g",
                "4. Bao Jin Tian Single Abalone in bottle 3s’ in Gift Box",
                "5. Premium Red Dates 100g",
                "6. Premium White Fungus 100g",
                "7. Superior Tea Flower Mushrooms 100g",
                "8. Kinohimitsu Chicken of Essence American Ginseng & Cordyceps 75ml x 3",
                "9. Exquiste CNY Gift Basket",
                "\n红酒",
                "Kinohimitsu 御皇红烧日本鲍鱼 425g",
                "宇鄉燕特级带子花胶汤 425g",
                "鲍今天单只鲍鱼瓶礼盒 x 3",
                "特级红枣 100g",
                "特级银耳 100g",
                "上等茶花菇 100g",
                "Kinohimitsu 花旗参虫草鸡精 75ml x 3",
                "精美贺年礼篮"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 870,
        "name": "Prosperous Happiness 百花齐放",
        "handle": "prosperous-happiness-百花齐放",
        "description": "Prosperous Happiness 百花齐放Wish them wellness and happiness this Chinese New Year 2023 with our Prosperous Happiness Chinese New Year Hamper! We have included premium festive delights like Buddha’s Temptation, abalone and bird’s nest, and rounded them up with a delightful red wine to keep the festivities going!",
        "type": "Hampers",
        "tags": [],
        "price": "570.27",
        "inclTax": "570.27",
        "img": [
            "/images/products/Prosperous-Happiness-_1-661827.jpg?v=1671685637",
            "/images/products/Prosperous-Happiness-_2-749995.jpg?v=1671685638"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Grand Jacques Cabernet Sauvignon France Red Wine 75cl",
                "2. Bao Jin Tian Australia Braised Abalone 425g",
                "3. Yu Xiang Yan Abalone Buddha Jumps Over The Wall 425g",
                "4. Bao Jin Tian Single Abalone in bottle 3s’ in Gift Box",
                "5. Yu Xiang Yan Premium Bird’s Nest 80ml 3s’ in Gift Box",
                "6. Superior Tea Flower Mushrooms 100g",
                "7. Superior Dried Scallops 30g",
                "8. Premium Red Dates 100g",
                "9. Kinohimitsu Chicken of Essence Lingzhi 75ml x 3",
                "10. Gifted with Premium Fortune & Wealth Liuli",
                "11. Exquisite CNY Gift Basket",
                "\n红酒",
                "鲍今天澳洲红烧鲍鱼 425g",
                "宇鄕燕鲍鱼佛跳墙 425g",
                "鲍今天单只鲍鱼瓶礼盒 x 3",
                "宇鄕燕特级燕窝礼盒 80ml x 3",
                "上等茶花菇 100g",
                "上等干贝 30g",
                "特级红枣 100g",
                "Kinohimitsu灵芝鸡精 75ml x 3",
                "附送琉云漓彩琉璃艺品",
                "精美贺年礼篮"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 871,
        "name": "Prosperous Merriment 辉煌四射",
        "handle": "prosperous-merriment-辉煌四射",
        "description": "Prosperous Merriment 辉煌四射Wish them wellness and happiness this Chinese New Year 2023 with our Prosperous Merriment Chinese New Year Hamper! We have included premium festive delights like Buddha’s Temptation, abalone and bird’s nest, and rounded them up with a delightful red wine to keep the festivities going.",
        "type": "Hampers",
        "tags": [],
        "price": "759.68",
        "inclTax": "759.68",
        "img": [
            "/images/products/Prosperous-Merriment-_1-692452.jpg?v=1702988905",
            "/images/products/Prosperous-Merriment-_2-745799.jpg?v=1702988905"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Chateau Fleurs Vignobles 75cl",
                "2. Grand Jacques Cabernet Sauvignon France Red Wine 75cl",
                "3. Yu Xiang Yan Australia Abalone in Brine 425g",
                "4. Yu Xiang Yan Premium Scallop Fish Maw Soup 425g",
                "5. Yu Xiang Yan Abalone Buddha Jumps Over The Wall 425g",
                "6. Bao Jin Tian Premium Razor Clams 425g",
                "7. Yu Xiang Yan Premium Bird’s Nest 80ml x 4",
                "8. Kinohimitsu Chicken Of Essence American Ginseng & Cordyceps 3s’ 75ml in Gift Box",
                "9. Premium White Fungus 100g",
                "10. Superior Tea Flower Mushrooms 100g",
                "11. Superior Fish Maw 117g",
                "12. Yu Xiang Yan White Fungus Series Bird’s Nest 3s’ 75ml",
                "13. Gifted with Premium Fortune & Wealth Liuli",
                "14. Exquisite CNY Gift Basket",
                "\n红酒",
                "红酒",
                "宇鄉燕澳洲卤水鲍鱼 425g",
                "宇鄉燕特级带子花胶汤 425g",
                "宇鄕燕鲍鱼佛跳墙 425g",
                "鲍今天特级鲍贝 425g",
                "宇鄕燕特级燕窝 80ml x 4",
                "Kinohimitsu 花旗参虫草鸡精礼盒 75ml x 3",
                "特级银耳 100g",
                "上等茶花菇 100g",
                "上等鱼鳔 117g",
                "宇鄕燕银耳燕窝系列 75ml x 3",
                "附送琉云漓彩琉璃艺品",
                "精美贺年礼篮"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 872,
        "name": "Prosperous Indulgence 万紫千红",
        "handle": "prosperous-indulgence-万紫千红",
        "description": "Prosperous Indulgence 万紫千红Wish them wellness and happiness this Chinese New Year 2023 with our Prosperous Indulgence Chinese New Year Hamper! We have included premium festive delights like Buddha’s Temptation, abalone and bird’s nest, and rounded them up with a festive 12-year Chivas to keep the festivities going.",
        "type": "Hampers",
        "tags": [],
        "price": "971.90",
        "inclTax": "971.90",
        "img": [
            "/images/products/Prosperous-Indulgence-_1-942047.jpg?v=1671685638",
            "/images/products/Prosperous-Indulgence-_2-529195.jpg?v=1671685638"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Chivas Regal 12 Years 70cl",
                "2. Yu Xiang Yan Australia Abalone in Brine 425g",
                "3. Kinohimitsu Imperial Braised Japanese Abalone 425g",
                "4. Kinohimitsu Chicken Of Essence American Ginseng & Cordyceps 3s’ 75ml in Gift Box",
                "5. Yu Xiang Yan Premium Bird’s Nest 80ml x 3",
                "6. Yu Xiang Yan White Fungus Series Bird’s Nest 3s’ 75ml",
                "7. Yu Xiang Yan Abalone Buddha Jumps Over The Wall 425g",
                "8. Yu Xiang Yan Superior Bird’s Nest in Individual Canister 165ml",
                "9. Superior Fish Maw 117g",
                "10. Superior Tea Flower Mushrooms 100g",
                "11. Superior Abalone Slice 100g",
                "12. Premium Red Dates 100g",
                "13. Gifted with Premium Fortune & Wealth Liuli",
                "14. Exquisite CNY Gift Basket",
                "\n12年芝华士 70cl",
                "宇鄉燕澳洲卤水鲍鱼 425g",
                "Kinohimitsu 御皇红烧日本鲍鱼 425g",
                "Kinohimitsu 花旗参虫草鸡精礼盒 75ml x 3",
                "宇鄕燕特级燕窝 80ml x 3",
                "宇鄕燕银耳燕窝系列 75ml x 3",
                "宇鄕燕鲍鱼佛跳墙 425g",
                "宇鄕燕特级燕窝（单罐装） 165ml",
                "上等鱼鳔 117g",
                "上等茶花菇 100g",
                "上等鲍鱼片 100g",
                "特级红枣 100g",
                "附送琉云漓彩琉璃艺品",
                "精美贺年礼篮"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 873,
        "name": "Avalynn",
        "handle": "jaylani",
        "description": "This bouquet presents a sweet teddy bear graduate amidst a whimsical arrangement of tiffany blue flowers, highlighted by a majestic blue preserved rose and soft cotton accents, wrapped in elegant light blue paper for a joyful celebration of academic success.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Avalynn-2a.jpg?v=1720665186",
            "/images/products/Avalynn-1a.jpg?v=1720665186"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Preserved Hydrangea. Gossypium . Fillers & Foliage "
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 874,
        "name": "Fortune (VIII)",
        "handle": "fortune-viii",
        "description": "Celebrate all business launches with our Pastel Dreamscape Tripod Stand. Adorned with pink and white balloons and a delicate array of pastel-colored flowers, it creates a charming, joy-filled focal point, symbolizing the beginning of a successful and delightful journey.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Fortune-_VIII_-1b.jpg?v=1720095211",
            "/images/products/Grand-Opening-Card-2024_a3d8a5ee-fba7-4ec8-a2db-12b7e4066f0a.jpg?v=1720095216",
            "/images/products/Fortune-_VIII_-2b.jpg?v=1720095211"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses . Spray Roses . Eustoma . Mum . Faux Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 875,
        "name": "Fortune (IV)",
        "handle": "fortune-iv",
        "description": "Illuminate all grand openings with the Golden Radiance Tripod Stand, a dazzling fusion of champagne Roses, vibrant Sunflowers, and exotic Bird of Paradise blooms. This majestic floral arrangement stands tall, symbolizing prosperity, joy, and the beginning of a radiant journey. An embodiment of success and celebration.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Fortune-_IV_-1b.jpg?v=1720094243",
            "/images/products/Grand-Opening-Card-2024_145fd1c8-c4c6-4cca-9ce7-fce803bad4e9.jpg?v=1720094248",
            "/images/products/Fortune-_IV_-2b.jpg?v=1720094244"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Sunflowers . Roses . Eustoma . Birds of Paradise . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 876,
        "name": "Regan",
        "handle": "regan",
        "description": "This bouquet presents a sweet teddy bear graduate amidst a whimsical arrangement of pink and lilac flowers, highlighted by a majestic pink preserved rose and soft cotton accents, wrapped in elegant light pink paper for a joyful celebration of academic success.",
        "type": "Bouquets",
        "tags": [
            "Graduation Flowers"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Regan-2b-768189.jpg?v=1720789880",
            "/images/products/Regan-1b.jpg?v=1720664581"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Rose . Preserved Hydrangea. Gossypium . Fillers & Foliage "
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 877,
        "name": "Fortune (I)",
        "handle": "fortune-i",
        "description": "Mark the grand opening of all businesses with Opulent Harmony Tripod Stand. Featuring the pristine beauty of white Brassica, complemented by red and pink Roses, Orchids, and Eustomas, all elegantly wrapped in a red skirting. This luxurious arrangement symbolizes success, prosperity, and the beginning of a refined business journey.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Fortune-_I_-1b.jpg?v=1720095402",
            "/images/products/Grand-Opening-Card-2024_813f42f1-9838-4bfc-841b-439c7a74a143.jpg?v=1720095408",
            "/images/products/Fortune-_I_-2b.jpg?v=1720095402"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Brassica . Roses . Eustoma . Faux Orchids . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 878,
        "name": "Fortune (IX)",
        "handle": "fortune-ix",
        "description": "Celebrate with our Grand Business Opening Flower Tripod Stand with a Blue-themed Skirting and Pastel-Colored Flowers. This elegant ensemble features a harmonious blend of pastel hues, symbolizing sophistication, tranquility, and a flourishing start for your venture.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Fortune-_IX_-1b.jpg?v=1720095679",
            "/images/products/Grand-Opening-Card-2024_890d5d09-1f40-4853-847e-306232dbd23d.jpg?v=1720095684",
            "/images/products/Fortune-_IX_-2b.jpg?v=1720095679"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses . Gerbera . Eustoma . Mum . Faux Hydrangea . Sunflower . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 879,
        "name": "Comfort (IX)",
        "handle": "comfort-ix",
        "description": "In a solemn tribute, our condolence flower tripod stands showcase pure white chrysanthemums set against a tranquil blue theme, elegantly adorned in navy blue dressing. This arrangement offers a dignified and serene tribute, symbolizing compassion and remembrance during moments of deep grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Comfort-_IX_-1b.jpg?v=1720143731",
            "/images/products/Condolence-Card-2024_1_3b81dc6e-7e7e-4224-9ed7-d9d2d9364a76.jpg?v=1720143738",
            "/images/products/Comfort-_IX_-2b.jpg?v=1720143731"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Eustoma . Orchids . Alstroemeria . Faux Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 880,
        "name": "Abundance (III)",
        "handle": "abundance-iii",
        "description": "Elevate Grand Openings with the Golden Jubilee StandMake every grand opening unforgettable with the Golden Jubilee Grand Opening Stand. A jubilant fusion of orange gerberas, red roses, and sunflowers complemented by golden balloons, this radiant arrangement signifies prosperity, love, and success. Set the stage for a momentous celebration with this stunning floral display.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Abundance-_III_-1e.jpg?v=1720494638",
            "/images/products/Grand-Opening-Card-2024_f153ed0a-226b-4d6b-b1ef-1e72aa4a0382.jpg?v=1720494645",
            "/images/products/Abundance-_III_-2e.jpg?v=1720494638"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Gerbera . Roses . Sunflower . Balloons . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 881,
        "name": "Abundance (IV)",
        "handle": "abundance-iv",
        "description": "Ignite Opulence with Our Opulent Red Floral StandTransform grand business openings with our Opulent Red Floral Stand. Towering pink lilies, cheerful red roses, and delicate orchids are accented by exotic red ginger flowers. Encased in the richness of wavy red velvet skirting, this arrangement creates a grand and sophisticated centerpiece.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Abundance-_IV_-1f.jpg?v=1720497239",
            "/images/products/Grand-Opening-Card-2024_9b50ed97-bcc2-4f63-a54f-211da49e2f6b.jpg?v=1720497246",
            "/images/products/Abundance-_IV_-2f.jpg?v=1720497239"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Orchids . Roses . Mum . Ginger Flower . Faux Cymbidium Orchid . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 882,
        "name": "Basket Of Fruit FHP",
        "handle": "basket-of-fruit",
        "description": "Basket Of FruitYou cannot say no to a basket that contains 8 types of fruits yet full with vitamin A, C, E, and K. Decorated with vibrant flowers, it can bring a smile on the faces of your loved ones. Packed in an exclusive chocolate coloured basket, this gift is perfect for everyone.",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Basket-Of-Fruits1_f445d2bc-6f3c-42a6-8164-39962bcbd0f8-316422.jpg?v=1708969395",
            "/images/products/Basket-Of-Fruits2_66898be4-b07c-4f02-8624-98727d5708b2-188051.jpg?v=1708969396"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Nam Shui Pear",
                "2. Avocado",
                "3. Fuji Apple",
                "4. Green Apple",
                "5. Dragon Fruits",
                "6. Green Grape",
                "7. Orange",
                "8. Kiwi",
                "9. Basket (replace with light brown)",
                "10. Flower Arrangement: Gerbera, Rose, Hypericum, Wheat, Coin Leaf"
            ]
        },
        "dimention": "Size of Hamper : 40cm x 18cm x 31cm"
    },
    {
        "id": 883,
        "name": "Abundance (V)",
        "handle": "abundance-v",
        "description": "Illuminate Business Launches with Radiant BloomsCelebrate grand openings with radiant blooms, a harmonious stand symbolizing success and a bright future. This arrangement creates a memorable and flourishing start for your business.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Abundance-_V_-1f.jpg?v=1720407608",
            "/images/products/Grand-Opening-Card-2024_c93b27e5-9d1f-46f2-954b-2366e111bf16.jpg?v=1720407615",
            "/images/products/Abundance-_V_-2f.jpg?v=1720407608"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Gerbera . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 884,
        "name": "Nutritious Nibbles FHP",
        "handle": "nutritious-nibbles",
        "description": "Nutritious Nibbles",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers"
        ],
        "price": "241.66",
        "inclTax": "241.66",
        "img": [
            "/images/products/Nutritious-Nibbles1-246089.jpg?v=1671685496",
            "/images/products/Nutritious-Nibbles2-828598.jpg?v=1671685496"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Juan Tea 9g x 2 boxes",
                "2. Breitsamer Organic Golden Selection Raw Honey 500g",
                "3. Nam Shui Pear",
                "4. Strawberry",
                "5. Fuji Apple",
                "6. Green Apple",
                "7. Avocado",
                "8. Kiwi",
                "9. Flower Arrangement: Gerbera, Rose Spray, White Phoenix"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 885,
        "name": "Juice & Recharge FHP",
        "handle": "juice-recharge",
        "description": "Juice  Recharge",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Juice-_-Recharge1-111451.jpg?v=1671774899",
            "/images/products/Juice-_-Recharge2-518883.jpg?v=1671774899"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.  Brands Chicken Essence 42ml x 6",
                "2. Mango",
                "3. Orange",
                "4. Fuji Apple",
                "5. Green Grape",
                "6. Kiwi",
                "7. Flower plant",
                "8. Basket (replace with light brown)"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 886,
        "name": "Abundance (IX)",
        "handle": "fortune-ix-1",
        "description": "Transform Grand Business Openings with Our Purple-Themed Flower StandsViolet roses take center stage, creating an opulent display of elegance and prosperity. The harmonious blend of elegant white and purple hues symbolizes success and a flourishing venture ahead.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Abundance-_IX_-1b.jpg?v=1720100100",
            "/images/products/Grand-Opening-Card-2024_9ad558f8-89d6-4efa-8c45-80a8304a6fea.jpg?v=1720100104",
            "/images/products/Abundance-_IX_-2b_1547ef2d-615d-4411-b8a9-ee6e7d90e5a2.jpg?v=1720105309"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses . Eustoma . Mum . Carnation . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 887,
        "name": "Fortune (X)",
        "handle": "fortune-x",
        "description": "Step into an atmosphere of refined beauty with our Blushing Elegance Tripod Stand. Featuring pink Roses, Carnations, pink  white Eustomas, and violet Matthiola, this floral ensemble exudes grace, creating a stunning centerpiece for your grand opening celebration.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Fortune-_X_-1b.jpg?v=1720100265",
            "/images/products/Grand-Opening-Card-2024_1e1874f9-f020-4b2a-9f4e-4a4f9f60aeba.jpg?v=1720100271",
            "/images/products/Fortune-_X_-2b.jpg?v=1720100265"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses . Eustoma . Matthiola . Carnation . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 888,
        "name": "Zuri",
        "handle": "zuri",
        "description": "This exquisite bouquet features a soft palette of cream and blush roses, delicate hydrangeas, and cotton blooms, elegantly arranged with lush greenery and pampas grass. Wrapped in layers of ivory paper and finished with a satin ribbon, this arrangement exudes sophistication and charm. Perfect for special occasions or as a thoughtful gift, it adds a touch of timeless beauty to any setting.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Zuri-2b-407288.jpg?v=1724340103",
            "/images/products/Zuri-1b.jpg?v=1724305836"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Preserved Roses . Preserved Baby's Breath . Preserved Hydrangea . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 889,
        "name": "Sweet & Fresh Treats FHP",
        "handle": "sweet-fresh-treats-fhp",
        "description": "Sweet  Fresh Treats",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers"
        ],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/Sweet-_-Fresh-Treats-FHP1-130879.jpg?v=1672135278",
            "/images/products/Sweet-_-Fresh-Treats-FHP2-951900.jpg?v=1672135278"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Orange",
                "2. Fuji Apple",
                "3. Green Grape",
                "4. Kiwi",
                "5. Green Apple",
                "6. Hamlet Assortimemt belgian Chocolate",
                "7. Cupido Dessert Cup Latte Macchiato",
                "8. Gerbera",
                "9. Roses",
                "10. Eustoma",
                "11. Hypericum",
                "12. Coin Leaf",
                "13. Round Hat Box"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 890,
        "name": "Fruits & Blooms FHP",
        "handle": "fruits-blooms-fhp",
        "description": "Fruits  Blooms",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers"
        ],
        "price": "241.66",
        "inclTax": "241.66",
        "img": [
            "/images/products/Fruits-_-Blooms-FHP1-265400.jpg?v=1672134076",
            "/images/products/Fruits-_-Blooms-FHP2-656819.jpg?v=1672134076"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Green Apple",
                "2. Orange",
                "3. Kiwi",
                "4. Forelle Pear",
                "5. Nam Shui Pear",
                "6. Green Grape",
                "7. Dragon Fruits",
                "8. Flower Arrangement: Gerbera, Roses, Eustoma & Coin Leaf",
                "9. Round Hat Box"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 891,
        "name": "Fruity Blossom FHP",
        "handle": "fruity-blossom-fhp",
        "description": "Fruity BlossomThis delightful box arrives full of irresistible and scrumptious fruits. A lovely bouquet of sweet-smelling pink, lilac, and pastel flowers completes this touching gift.",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Fruity-Blossom1-134927.jpg?v=1672221466",
            "/images/products/Fruity-Blossom2-663557.jpg?v=1672221466"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  Mango",
                "2. Strawberry",
                "3. Avocado",
                "4. Orange",
                "5. Nam Shui Pear",
                "6. Purple Grape",
                "7. Dragon Fruits",
                "8. Fuji Apple",
                "9. Green Apple",
                "10. Wooden Crate (L)",
                "11. Wooden Crate (M)",
                "12. Flower Arrangement: Gerbera, Eustoma, Hypericum, Coin Leaf"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 892,
        "name": "Wellness Goodies FHP",
        "handle": "wellness-goodies-fhp",
        "description": "Wellness Goodies",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers"
        ],
        "price": "469.86",
        "inclTax": "469.86",
        "img": [
            "/images/products/Wellness-Goodies1-743940.jpg?v=1672222726",
            "/images/products/Wellness-Goodies2_a981bcaa-aa41-41ef-94a0-7f8f032186f3-348960.jpg?v=1672222726"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "Benedictine Dom 37.5cl . Brand’s Chicken Essence 42ml x 6 . Gerbera . Roses . Hypericum . Eustoma . Coin Leaf . Fuji Apple . Forelle Pear . Kiwi . Basket"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 893,
        "name": "Abundance (VI)",
        "handle": "abundance-vi",
        "description": "Elevate Business Openings with Our Elegance Blossoms Tripod StandAdorned with an assortment of sophisticated, elegance-colored flowers, this stunning arrangement embodies the essence of success and refinement, creating a captivating centerpiece for all grand events.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Abundance-_VI_-1b.jpg?v=1720100464",
            "/images/products/Grand-Opening-Card-2024_dc7d0bb9-e568-4337-af33-4fa3d2327405.jpg?v=1720100469",
            "/images/products/Abundance-_VI_-2b.jpg?v=1720100462"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Meta Roses . Eustoma . Gerbera . Pampas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 894,
        "name": "Abundance (VII)",
        "handle": "abundance-vii",
        "description": "Cappuccino Elegance Tripod Stand for Grand Business OpeningsEnveloped in cappuccino-colored roses, gossypium, and dried flowers, adorned with chic nude skirting, this arrangement signifies refined beginnings and enduring success. A tasteful centerpiece for a distinguished business venture.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Abundance-_VII_-1d.jpg?v=1720104911",
            "/images/products/Grand-Opening-Card-2024_8c7140c6-72d8-45d4-972b-308f4b9bdd5e.jpg?v=1720104918",
            "/images/products/Abundance-_VII_-2d.jpg?v=1720104912"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Cappuccino Roses . Cotton . Wheat . Pampas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 895,
        "name": "Heng I",
        "handle": "heng-i",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Heng-_I_-1A-666042.jpg?v=1672746064",
            "/images/products/Heng2-807601.jpg?v=1672723782"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Pussy Willow . Faux Flowers . Preserved Hydrangea"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 896,
        "name": "Abundance (VIII)",
        "handle": "abundance-viii",
        "description": "Unveil Sophistication with Our Elegance in Bloom Tripod StandFeaturing a trio of enchanting blooms – pink and champagne gerberas, delicate pink roses, and whimsical baby's breath – this floral masterpiece stands tall, symbolizing grace and prosperity. Create a captivating focal point for your grand opening with this stunning arrangement.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Abundance-_VIII_-1c.jpg?v=1720093252",
            "/images/products/Grand-Opening-Card-2024_4e11dba0-9f0b-4826-986b-155e9430746f.jpg?v=1720093258",
            "/images/products/Abundance-_VIII_-2c.jpg?v=1720093252"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses . Gerbera . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 897,
        "name": "Heng II",
        "handle": "heng-ii",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Heng-_II_-1A-153315.jpg?v=1672746066",
            "/images/products/Heng-_II_-2-427129.jpg?v=1672723479"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Pussy Willow . Mum’s . Faux Flowers"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 898,
        "name": "Bountiful (I)",
        "handle": "bountiful-i",
        "description": "Celebrate all business launches with our Pastel Dreamscape Floral Stand. Adorned with pink and white blooms and a delicate array of pastel-colored flowers, it creates a charming, joy-filled focal point, symbolizing the beginning of a successful and delightful journey.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Bountiful-_I_-1d.jpg?v=1720497819",
            "/images/products/Grand-Opening-Card-2024_957d3276-2506-42a8-a427-cab4daaa4602.jpg?v=1720497826",
            "/images/products/Bountiful-_I_-2e.jpg?v=1720497874"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Faux Orchids . Roses . Faux Hydrangeas . Matthiola . Eustoma . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 899,
        "name": "Heng III",
        "handle": "heng-iii",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Heng-_III_-1A-526594.jpg?v=1672397093",
            "/images/products/Heng-_III_-2A_08f93ca2-3c7f-4dc3-9d71-16ab287ddf9a-710476.jpg?v=1672658625"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Pussy Willow . Mum . Pink Carnation . Faux Flowers"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 900,
        "name": "Comfort (V)",
        "handle": "bountiful-ii",
        "description": "In sincere condolence, our flower tripod stands feature a soothing white and champagne theme with graceful roses and chrysanthemums, elegantly dressed in serene white. This arrangement provides a dignified and comforting tribute, symbolizing compassion and remembrance during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Comfort-_V_-1b.jpg?v=1720143421",
            "/images/products/Condolence-Card-2024_1_9c6bd4e4-bc49-4356-8954-e39a63fb6528.jpg?v=1720143428",
            "/images/products/Comfort-_V_-2b.jpg?v=1720143422"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses . Eustoma . Wax Flowers . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 901,
        "name": "Heng IV",
        "handle": "heng-iv",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Heng-_IV_-1A_18319db7-f753-4622-9104-de2d46577533-500419.jpg?v=1672397093",
            "/images/products/Heng-_IV_-2A-692814.jpg?v=1672397093"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Pussy Willow . Mum . Red Carnation . Faux Flowers"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 902,
        "name": "Comfort (X)",
        "handle": "comfort-x",
        "description": "In a serene expression of sympathy, our condolence flower tripod stands feature a calming green and white theme, adorned with graceful orchids, chrysanthemums, delicate eustomas, and hydrangea. This elegant arrangement provides a comforting tribute, symbolizing compassion and remembrance during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Comfort-_X_-1b.jpg?v=1720144317",
            "/images/products/Condolence-Card-2024_1_ce5eb82b-0891-4f67-9771-23a6320328dc.jpg?v=1720144322",
            "/images/products/Comfort-_X_-2b.jpg?v=1720144316"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Eustoma . Matthiola . Faux Orchid . Faux Hydrangea . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 903,
        "name": "Empathy (X)",
        "handle": "empathy-x",
        "description": "In sincere condolence, our flower tripod stands feature a comforting yellow theme, adorned with graceful orchids, chrysanthemums, and delicate eustomas. Dressed in checkers of orange, this arrangement provides a dignified and soothing tribute, symbolizing compassion and remembrance during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Empathy-_X_-1b.jpg?v=1720144187",
            "/images/products/Condolence-Card-2024_1_f3f67139-e036-4a88-9971-e8127c6a2939.jpg?v=1720144202",
            "/images/products/Empathy-_X_-2b.jpg?v=1720144187"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Mum . Eustoma . Matthiola . Rose . Rose Sprays . Faux Orchid . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 904,
        "name": "Condolence (IX)",
        "handle": "condolence-ix",
        "description": "Expressing condolences with grace, our flower tripod stands feature serene yellow-themed, pure white, and deep red blooms elegantly dressed in serene white. This arrangement offers a dignified tribute, symbolizing compassion and remembrance, providing solace during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Condolence-_IX_-1b.jpg?v=1720138069",
            "/images/products/Condolence-Card-2024_1_32847373-7ecb-4c59-85c4-71624e555fc4.jpg?v=1720138074",
            "/images/products/Condolence-_IX_-2b.jpg?v=1720138069"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Mum . Lilies . Rose . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 905,
        "name": "Heng V",
        "handle": "heng-v",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "157.18",
        "inclTax": "157.18",
        "img": [
            "/images/products/Heng-_V_-1-452672.jpg?v=1672833285",
            "/images/products/Heng-_V_-2-160696.jpg?v=1672833285"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Phalaenopsis Orchid"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 906,
        "name": "Heng VI",
        "handle": "heng-vi",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "157.18",
        "inclTax": "157.18",
        "img": [
            "/images/products/Heng-_VI_-1A_33612889-bf9c-450b-b2dc-fc47f86a74c7-663888.jpg?v=1672833285",
            "/images/products/Heng-_VI_-2A_9172ec48-54e6-4fce-bf2f-11604fa259b7-511013.jpg?v=1672833285"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Phalaenopsis Orchid"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 907,
        "name": "Solace (I)",
        "handle": "solace-i",
        "description": "In a tender expression of condolence, our flower tripod stands feature roses in pastel violet and soothing yellow hues, tastefully adorned in serene white dressing. This elegant ensemble offers a gentle tribute, symbolizing compassion and remembrance during moments of deep grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Solace-_I_-1c.jpg?v=1720105602",
            "/images/products/Condolence-Card-2024_1_7a0b062c-f2ff-4f2c-802b-746b3c02c6b5.jpg?v=1720105608",
            "/images/products/Solace-_I_-2c.jpg?v=1720105603"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Gerbera . Eustoma . Matthiola . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 908,
        "name": "Solace (II)",
        "handle": "solace-ii",
        "description": "In heartfelt condolence, our flower tripod stands feature a sophisticated blend of purple, nude, and blue tones, adorned with majestic roses and delicate eustomas. This elegant arrangement provides a dignified tribute, symbolizing compassion and remembrance, offering solace during moments of deep grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Solace-_II_-1b.jpg?v=1720142840",
            "/images/products/Condolence-Card-2024_1_17c0b9f4-543f-4d29-9924-050867833d54.jpg?v=1720142846",
            "/images/products/Solace-_II_-2b.jpg?v=1720142840"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Gerbera . Eustoma . Matthiola . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 909,
        "name": "Solace (III)",
        "handle": "solace-iii",
        "description": "In a respectful tribute, our condolence flower tripod stands feature pure white chrysanthemums elegantly adorned in a soothing green-themed arrangement. Dressed in serene white, this ensemble provides a dignified and gentle tribute, symbolizing compassion and remembrance during moments of deep grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Solace-_III_-1c.jpg?v=1720105760",
            "/images/products/Condolence-Card-2024_1_5948eb92-8d90-4bc5-a4ed-88234a331b6a.jpg?v=1720105768",
            "/images/products/Solace-_III_-2c.jpg?v=1720105760"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Eustoma . Matthiola . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*150cm"
    },
    {
        "id": 910,
        "name": "Solace (IV)",
        "handle": "solace-iv",
        "description": "Expressing condolences with grace, our flower tripod stands feature serene white chrysanthemums, delicate pink roses, and elegant eustomas. This arrangement offers a gentle and comforting tribute, symbolizing compassion and remembrance, providing solace during moments of profound grief and reflection with tender beauty.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Solace-_IV_-1b.jpg?v=1720142353",
            "/images/products/Condolence-Card-2024_1_b4d0255c-5ca5-4027-a6a4-6a98ccf6650a.jpg?v=1720142361",
            "/images/products/Solace-_IV_-2b.jpg?v=1720142354"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Mum . Faux Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 911,
        "name": "Auspicious Oranges 吉祥如意",
        "handle": "auspicious-oranges-吉祥如意",
        "description": "Auspicious Oranges 吉祥如意Rejoice to the Lunar New Year with an auspicious basket of mandarin oranges that brings good luck and fortune to your loved ones.",
        "type": "Hampers",
        "tags": [],
        "price": "145.82",
        "inclTax": "145.82",
        "img": [
            "/images/products/Auspicious-Oranges_1_7b2259b3-a82e-4c4e-aecb-fccb20ed827f-172020.jpg?v=1673358471",
            "/images/products/Template-696158.jpg?v=1673358471"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Mandarin Oranges x 16",
                "2. Basket"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 912,
        "name": "Bountiful Oranges 大吉大利",
        "handle": "bountiful-oranges-大吉大利",
        "description": "Bountiful Oranges 大吉大利Wish your family and friends an abundance of wealth and good health with an orange-filled festive basket, perfect for sharing.",
        "type": "Hampers",
        "tags": [],
        "price": "166.36",
        "inclTax": "166.36",
        "img": [
            "/images/products/Bountiful-Oranges-_1_23aeb64b-0b46-4a78-8cfb-6faa64379808-228471.jpg?v=1673446478",
            "/images/products/Template_d212cd96-57d2-43c7-8440-7016052caae7-486233.jpg?v=1673411351"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Mandarin Oranges x 18",
                "2. Crate Box"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 913,
        "name": "Spring Blossoms 春暖花开",
        "handle": "spring-blossoms-春暖花开",
        "description": "Spring Blossoms 春暖花开Celebrate the Spring Festival with a beautiful assortment of handpicked flora, to wish your family and friends a flourishing and prosperous year ahead.",
        "type": "Hampers",
        "tags": [],
        "price": "166.36",
        "inclTax": "166.36",
        "img": [
            "/images/products/Spring-Blossoms-_1-737668.jpg?v=1673360362",
            "/images/products/Template_21ad38c5-e840-4978-85a8-0e0a36b6e71b-438881.jpg?v=1673360362"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Flower Arrangement",
                "2. Box"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 914,
        "name": "Orange Treasures 橙心如意",
        "handle": "orange-treasures-橙心如意",
        "description": "Orange Treasures 橙心如意This treasure trove filled with plump oranges represents a year of plentiful success and riches, certainly a delightful gift for your family and friends.",
        "type": "Hampers",
        "tags": [],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Orange-Treasures-_1-621355.jpg?v=1673358868",
            "/images/products/Template_190e3062-5fa7-4275-9a23-b53eaf6495c8-541360.jpg?v=1673358868"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Mandarin Oranges x 18",
                "2. Basket"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 915,
        "name": "Fortune Oranges 橘祥如意",
        "handle": "fortune-oranges-橘祥如意",
        "description": "Fortune Oranges 橘祥如意The number ‘8’ holds huge significance as a lucky number that brings great wealth and success. Hence, this beautiful gift is perfect for your loved ones, which boast a collection of 8 oranges accompanied with a fine bottle of red wine.",
        "type": "Hampers",
        "tags": [],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Fortune-Oranges-_1-163592.jpg?v=1673446668",
            "/images/products/Template-600503.jpg?v=1673446668"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Mandarin Oranges x 8",
                "2. Crate Box",
                "3. Grand Jacques Cabernet Sauvignon 75cl"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 916,
        "name": "Floral Blessing 花开富貴",
        "handle": "floral-blessing-花开富貴",
        "description": "Floral Blessing 花开富貴Meticulously crafted to perfection, this artisanal bloom box is ideal for welcoming the spirit of Lunar New Year and bring about blessings of wealth and fortune to your family, friends, or colleagues.",
        "type": "Hampers",
        "tags": [],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Floral-Blessing-_1-149470.jpg?v=1673446668",
            "/images/products/Template_151fafa1-9d25-45a3-8429-de342cba19af-326577.jpg?v=1673446668"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Flower Arrangement",
                "2. Box"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 917,
        "name": "Garden of Wealth 花团锦族",
        "handle": "garden-of-wealth-花团锦族",
        "description": "Garden of Wealth 花团锦族This graceful and dreamy floral arrangement is the perfect gift for your family and friends during this upcoming Lunar New Year.",
        "type": "Hampers",
        "tags": [],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Garden-of-Wealth-_1-125521.jpg?v=1673446669",
            "/images/products/Template_6b64a1d8-6978-4b5c-9d1b-94fa8aed0a95-405460.jpg?v=1673446669"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Flower Arrangement",
                "2. Box"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 918,
        "name": "Blissful Oranges 橙心橙意",
        "handle": "blissful-oranges-橙心橙意",
        "description": "Blissful Oranges 橙心橙意A splendid gift for your loved ones, enjoy a beautiful arrangement of vibrant flowers accompanied with plump oranges and premium red wine.",
        "type": "Hampers",
        "tags": [],
        "price": "209.72",
        "inclTax": "209.72",
        "img": [
            "/images/products/Blissful-Oranges-_1-695648.jpg?v=1673446368",
            "/images/products/Template_b66f9c64-621f-43c0-bbf7-b19347259ffe-468794.jpg?v=1673446368"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Mandarin Oranges x 2",
                "2. Grand Jacques Cabernet Sauvignon 75cl",
                "3. Flower Arrangement",
                "4. Box"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 919,
        "name": "Gentle Bliss 百花齐放",
        "handle": "gentle-bliss-百花齐放",
        "description": "Gentle Bliss 百花齐放Wish a life filled with success and riches with a beautiful box of flowers, guaranteed to bring a smile to your loved ones.",
        "type": "Hampers",
        "tags": [],
        "price": "218.84",
        "inclTax": "218.84",
        "img": [
            "/images/products/Gentle-Bliss-_1-930968.jpg?v=1673446668",
            "/images/products/Template_a18040d7-7ea8-48a2-bc38-a870fe108134-676687.jpg?v=1673446668"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Flower Arrangement",
                "2. Box"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 920,
        "name": "Prosperous Oranges 吉隆之喜",
        "handle": "prosperous-oranges-吉隆之喜",
        "description": "Prosperous Oranges 吉隆之喜This modest basket of oranges, nutty treats, and a fine bottle of red wine is sure to bring a smile to the faces of your loved ones.",
        "type": "Hampers",
        "tags": [],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Prosperous-Oranges-_1-689810.jpg?v=1673447102",
            "/images/products/Template_31b95a8e-1ea7-4362-85e9-b50dea885f4b-251832.jpg?v=1673447102"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Mandarin Oranges x 2",
                "2. Amazin Grace Lunar Nut Mix Gift Box 8 x 30g Nut Mix (Variety) - Left Green Gift Box (Red is Out of Stock)",
                "3. Chateau Du Vigneron Tempranillo 75cl",
                "4. Basket"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 921,
        "name": "Prosperous Blooms 充满花香",
        "handle": "prosperous-blooms-充满花香",
        "description": "Prosperous Blooms 充满花香Add bright colours to your loved ones’ home during this auspicious holiday with a petite garden of majestic flora.",
        "type": "Hampers",
        "tags": [],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Prosperous-Blooms-_1-850055.jpg?v=1673447102",
            "/images/products/Template_ae94d631-5ec7-48fb-a58f-72bb416e525a-905007.jpg?v=1673447102"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Flower Arrangement",
                "2. Box"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 922,
        "name": "Orange Blessing 大功告橙",
        "handle": "orange-blessing-大功告橙",
        "description": "Orange Blessing 大功告橙Send your blessings and appreciation to your friends and colleagues with a premium gift basket lavished with beautifully ripe oranges, luxurious red wine, and an assortment of colourful blooms.",
        "type": "Hampers",
        "tags": [],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Orange-Blessing-_1_5beef075-4738-4ba4-aeed-4a96d3be6ba5-653531.jpg?v=1673446986",
            "/images/products/Template_1f5ea4f3-19ab-4852-9e61-82b0af056a7a-914094.jpg?v=1673446986"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Mandarin Oranges x 8",
                "2. Grand Jacques Cabernet Sauvignon 75cl",
                "3. Flower Arrangement",
                "4. Basket"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 923,
        "name": "Wishful Oranges 心想事橙",
        "handle": "wishful-oranges-心想事橙",
        "description": "Wishful Oranges 心想事橙Celebrate the festive holiday and savour fresh, luscious oranges and premium red wine together with your family and friends.",
        "type": "Hampers",
        "tags": [],
        "price": "401.40",
        "inclTax": "401.40",
        "img": [
            "/images/products/Wishful-Oranges-_1_daa31a39-7e4f-449b-8c14-a1a9c5f69643-611123.jpg?v=1673448121",
            "/images/products/Template_1c7296a4-256d-47cc-a9de-7e5ae280f44d-649383.jpg?v=1673448121"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "1. Mandarin Oranges x 18",
                "2. Castelforte Corvina Veronese Indicazione Geografica Tipica, 75cl",
                "3. Le Morette Bardolino Classico 2019 75cl",
                "4. Basket"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 924,
        "name": "Benevolence (I)",
        "handle": "benevolence-i",
        "description": "In solemn condolence, our flower stands gracefully feature pure white chrysanthemums in a tranquil blue-themed arrangement. This serene ensemble offers a tender tribute, symbolizing compassion and remembrance, providing solace and comfort during moments of deep grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Benevolence-_I_-1d.jpg?v=1720187683",
            "/images/products/Condolence-Card-2024_1.jpg?v=1720187690",
            "/images/products/Benevolence-_I_-2d.jpg?v=1720187683"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 925,
        "name": "Benevolence (II)",
        "handle": "benevolence-ii",
        "description": "In moments of condolence, our flower stands elegantly feature chrysanthemums in a soothing champagne  white theme. This serene arrangement offers a dignified tribute, symbolizing compassion and remembrance. May it bring solace and comfort during times of grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Benevolence-_II_-1d.jpg?v=1720181713",
            "/images/products/Condolence-Card-2024_1_e4a43a3c-35f3-422b-80e9-57c2d05d7890.jpg?v=1720181725",
            "/images/products/Benevolence-_II_-2d.jpg?v=1720181714"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 926,
        "name": "Benevolence (III)",
        "handle": "benevolence-iii",
        "description": "In heartfelt condolence, our flower stands tenderly feature pure white chrysanthemums in a soothing green-themed ensemble. This serene arrangement offers a gentle tribute, symbolizing compassion and remembrance, providing solace and comfort during moments of deep grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Benevolence-_III_-1d.jpg?v=1720187166",
            "/images/products/Condolence-Card-2024_1_3637f644-1546-4829-9a2a-71e96ef47ee0.jpg?v=1720187178",
            "/images/products/Benevolence-_III_-2d.jpg?v=1720187166"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 927,
        "name": "Benevolence (IV)",
        "handle": "benevolence-iv",
        "description": "In expressions of deepest sympathy, our condolence flower stands feature serene white and regal purple-themed chrysanthemums. This elegant arrangement offers a tender tribute, symbolizing compassion and remembrance, bringing solace and comfort during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Benevolence-_IV_-1d.jpg?v=1720187321",
            "/images/products/Condolence-Card-2024_1_19c5281a-8166-40a6-8c79-7c0374b41b9a.jpg?v=1720187334",
            "/images/products/Benevolence-_IV_-2d.jpg?v=1720187321"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 928,
        "name": "Benevolence (V)",
        "handle": "benevolence-v",
        "description": "In solemn condolence, our flower stands gracefully feature pure white and soothing yellow-themed chrysanthemums. This serene arrangement offers a tender tribute, symbolizing compassion and remembrance, bringing solace and comfort during moments of deep grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Benevolence-_V_-1d.jpg?v=1720187022",
            "/images/products/Condolence-Card-2024_1_9ad47213-828e-4072-baa1-34b8ccf7fe14.jpg?v=1720187034",
            "/images/products/Benevolence-_V_-2d.jpg?v=1720187022"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 929,
        "name": "Kamari",
        "handle": "kamari",
        "description": "\"There is nothing prettier in this whole wide world as a girl in love with every breath she takes.\" - Atticus",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Kamari1B_6fc128a6-4062-4702-8e5b-df5ed0704113-231855.jpg?v=1673622207",
            "/images/products/Kamari2B-650429.jpg?v=1673622207"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Mum . Fillers & Foliages"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 930,
        "name": "Augusta",
        "handle": "augusta",
        "description": "Elevate any occasion with our captivating bouquet featuring five sunflowers and aromatic eucalyptus. Whether celebrating a birthday, expressing gratitude, or sending well wishes, this arrangement exudes joy and elegance. Delight your loved ones with the vibrant hues and fresh fragrance of this stunning bouquet.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Augusta-1a.jpg?v=1720749796",
            "/images/products/Augusta-2a.jpg?v=1720749796"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Eucalyptus"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 931,
        "name": "Antoinette",
        "handle": "antoinette",
        "description": "This exquisite bouquet showcases pristine white roses and lisianthus, elegantly complemented by sprigs of eucalyptus. Wrapped in soft white paper and tied with a satin ribbon, it exudes pure elegance and serenity, making it a perfect choice for weddings, anniversaries, or any occasion that calls for a touch of grace and sophistication.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Antoinette-2a.jpg?v=1721365874",
            "/images/products/Antoinette-1a.jpg?v=1721365874"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 932,
        "name": "Maribelle",
        "handle": "maribelle",
        "description": "Experience the classic charm of our bouquet featuring 9 deep red roses thoughtfully paired with the aromatic elegance of eucalyptus. The rich, velvety red blooms symbolize passion, while the fragrant eucalyptus adds a refreshing touch. ",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Maribelle-1a.jpg?v=1721182595",
            "/images/products/Maribelle-2a.jpg?v=1721182594"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eucalyptus"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 933,
        "name": "Maeve",
        "handle": "maeve",
        "description": "Elevate your gifting experience with our luxurious arrangement featuring 12 passionate red roses beautifully presented in a round silver box. Each velvety bloom exudes elegance, creating a striking visual contrast against the metallic sheen. Ideal for expressing deep love or making a bold statement, this arrangement is a sophisticated blend of romance and modern aesthetics, encapsulating beauty within a silver embrace.",
        "type": "Flower Boxes",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Maeve-1a-136878.jpg?v=1702988691",
            "/images/products/Maeve-2a-531568.jpg?v=1702988691"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "12 Roses"
            ]
        },
        "dimention": "Width*Height: 15cm*20cm"
    },
    {
        "id": 934,
        "name": "Blissful Greetings HHP",
        "handle": "blissful-greetings-hhp",
        "description": "Blissful Greetings HHPThis Hari Raya Hamper features a variety of nutritious and delicious treats! Beautifully presented in an exquisite tray box, the Blissful Greetings halal gourmet hamper is the perfect gift for your loved ones this festive season. Get ready to indulge!",
        "type": "Hampers",
        "tags": [
            "Halal Food Hampers"
        ],
        "price": "138.97",
        "inclTax": "138.97",
        "img": [
            "/images/products/Blissful-Greetings1.jpg?v=1698123686",
            "/images/products/Blissful-Greetings2.jpg?v=1698123686"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Bonz Cookie Crunchy Oat & Sesame 100g",
                "2. Julie’s Butter Waffles 100g",
                "3. Beryl’s Almond Milk Chocolate 70g",
                "4. Cowhead Cappuccino Butter Cookies 150g",
                "5. Hausboom Sparkling Real Juice 275ml",
                "6. Amazin’ Graze Chocolate Hazelnut 40g",
                "7. Amazin Graze Mini Pandan Coconut Mixed Nuts 30g",
                "8. Exquisite Tray Box"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 935,
        "name": "Snack Bites HHP",
        "handle": "snack-bites-hhp",
        "description": "Snack Bites HHPCelebrate Raya joy with the Snack Bites Hari Raya Hamper. Packed in an exquisite tray box and loaded with delicious munchies, this halal gourmet hamper will amp up your Raya festivities, and make this celebration a memorable one.",
        "type": "Hampers",
        "tags": [
            "Halal Food Hampers"
        ],
        "price": "157.23",
        "inclTax": "157.23",
        "img": [
            "/images/products/Snack-Bites1_6a89c53d-8412-46fd-b49c-f669ff36bacd-715641.jpg?v=1687215928",
            "/images/products/Snack-Bites2.jpg?v=1687145542"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Loackers Quadratini Mini Dark Chocolate 125g",
                "2. Beryl’s Assortment Chocolate 70g",
                "3. Beryl’s Double Chocolate Chip Cookies 100g",
                "4. Melvados- Brownie Brittle 100g",
                "5. Mission Tortilla Chips Hot & Spicy 65g",
                "6. Tong Garden Nutrione Baked Nuts 85g",
                "7. Hausboom Sparkling Real Juice 275ml",
                "8. Tong Garden Honey Roasted Cashew Nuts 40g",
                "9. Exquisite Tray Box"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 936,
        "name": "Indigo BBS",
        "handle": "indigo",
        "description": "This elegant arrangement in a sleek black box features a pristine blend of white tulips, roses, and daisies, accented with sprigs of blue thistle and lush greenery. The black ribbon and box provide a striking contrast, highlighting the purity and simplicity of the white flowers. Perfect for a sophisticated and modern display.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Indigo-BBS-2a.jpg?v=1721613789",
            "/images/products/Indigo-BBS-1a.jpg?v=1721613789"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Tulips . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 937,
        "name": "Heng VII",
        "handle": "heng-vii",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Heng-_VII_-1-990255.jpg?v=1673974469",
            "/images/products/Heng-_VII_-2-683396.jpg?v=1673974469"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Dry and Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 938,
        "name": "Heng VIII",
        "handle": "heng-viii",
        "description": "“Life is a first impression. You get one shot at it. Make it everlasting.”  -  J.R. Rim",
        "type": "Bouquets",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Heng-_VIII_-1_47fff779-e8cd-46a9-b9ea-1a1d4e831dad-478809.jpg?v=1673974471",
            "/images/products/Heng-_VIII_1-835094.jpg?v=1673974471",
            "/images/products/Heng-_VIII_-2-669997.jpg?v=1673974471"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Dry and Preserved Flowers"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 939,
        "name": "Elijah",
        "handle": "elijah",
        "description": "A striking single sunflower bouquet, its vibrant yellow petals standing out against a crisp white wrapping, accented with a soft, beige ribbon. This sunny and cheerful floral arrangement conveys a message of warmth, happiness, and adoration in its simplicity and elegance.",
        "type": "Bouquets",
        "tags": [],
        "price": "45.41",
        "inclTax": "45.41",
        "img": [
            "/images/products/Elijah1a-385575.jpg?v=1674238789",
            "/images/products/Elijah2a-538451.jpg?v=1674238789"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 940,
        "name": "Arleth",
        "handle": "arleth",
        "description": "A bold, single deep red rose emerges from an artistic wrap of black and cream, creating a dramatic contrast. Tied with a cream ribbon, this striking bouquet symbolizes a deep and passionate love, perfectly encapsulated in one exquisite bloom.",
        "type": "Bouquets",
        "tags": [],
        "price": "45.41",
        "inclTax": "45.41",
        "img": [
            "/images/products/Arleth1a-739477.jpg?v=1674238112",
            "/images/products/Arleth2a.jpg?v=1674182586"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Red Rose"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 941,
        "name": "Zaria",
        "handle": "zaria",
        "description": "Savor the everlasting beauty of love – Nine Everlasting Preserved Red Roses showcased in a round box. This exquisite arrangement exudes passion and grace, capturing the essence of enduring love. ",
        "type": "Flower Boxes",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Zaria-1bb_c37e0c78-5081-4857-b41c-e37b06991473-127269.jpg?v=1704811217",
            "/images/products/Zaria-2bb_bf9d043a-5175-4b12-a820-1e4df5322ade-261797.jpg?v=1704811217"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "9 Preserved Roses"
            ]
        },
        "dimention": "Width*Height: 15cm*20cm"
    },
    {
        "id": 942,
        "name": "Julissa",
        "handle": "julissa",
        "description": "Savor the everlasting beauty of love with our preserved roses collection – Nine Everlasting Preserved Hot Pink Roses showcased in a round box. This exquisite arrangement exudes passion and grace, capturing the essence of enduring love. Elevate your Valentine's Day with the gift of eternal romance and enchantment.",
        "type": "Flower Boxes",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Julissa-1aa-620471.jpg?v=1704463727",
            "/images/products/Julissa-2aa-963358.jpg?v=1704463727"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "9 Preserved Roses"
            ]
        },
        "dimention": "Width*Height: 15cm*20cm"
    },
    {
        "id": 943,
        "name": "Kynlee",
        "handle": "kynlee",
        "description": "Savor the simplicity of love with our delightful bouquet featuring 5 vibrant red roses, artfully arranged in a square rattan basket. Each bloom exudes passion and charm, creating a visually pleasing ensemble. Ideal for expressing affection on various occasions or as a charming centerpiece, this bouquet in a square rattan basket is a perfect blend of elegance and rustic charm, making a heartfelt statement in any setting.",
        "type": "Flower Baskets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Kynlee1A-923417_e429a32b-2fce-4df9-983f-93a78084786f-559561.jpg?v=1674678208",
            "/images/products/Kynlee2A-501076_9259107f-49ff-4a53-90ae-3594c4a038e5-339832.jpg?v=1674678208"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses"
            ]
        },
        "dimention": "Width*Height: 15cm*25cm"
    },
    {
        "id": 944,
        "name": "Judith",
        "handle": "judith",
        "description": "Savor the charm of our enchanting bouquet featuring 5 delicate pink roses, elegantly accompanied by the graceful blooms of caspia and the textured foliage of parvifolia. This lovely ensemble is carefully arranged in a square rattan basket, adding a touch of rustic elegance. Ideal for expressing sweet sentiments on various occasions, this bouquet captures the essence of natural beauty and sophistication, presented in a charming square rattan basket.",
        "type": "Flower Baskets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Judith1-523817_a6c3ac35-0ecf-47d0-b7bb-4b97eb602c4b-570453.jpg?v=1674678092",
            "/images/products/Judith2-808904_988781a5-91fc-4a9d-bf67-049e531e8aba-564988.jpg?v=1674678092"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses"
            ]
        },
        "dimention": "Width*Height: 15cm*25cm"
    },
    {
        "id": 945,
        "name": "Elsa",
        "handle": "elsa",
        "description": "Unveil the enchantment of love with our Everlasting Preserved Violet Roses in a round box, meticulously crafted for any day of the year. Nine captivating blooms speak volumes, symbolizing enduring love and grace. ",
        "type": "Flower Boxes",
        "tags": [
            "Anniversary Flowers",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Elsa-1aa.jpg?v=1704335744",
            "/images/products/Elsa-2aa.jpg?v=1704335745"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "9 Preserved Roses"
            ]
        },
        "dimention": "Width*Height: 15cm*20cm"
    },
    {
        "id": 946,
        "name": "Debbie",
        "handle": "debbie",
        "description": "Elevate your flower gifting with our Everlasting Preserved White Roses, artfully arranged in a chic round box. Each bloom encapsulates eternal love, making this exquisite gift a timeless expression of your affection.",
        "type": "Flower Boxes",
        "tags": [
            "Anniversary Flowers",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Debbie-1aa-444907.jpg?v=1704463372",
            "/images/products/Debbie-2aa.jpg?v=1704336147"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "9 Preserved Roses"
            ]
        },
        "dimention": "Width*Height: 15cm*20cm"
    },
    {
        "id": 947,
        "name": "Aspyn",
        "handle": "aspyn",
        "description": "Celebrate love that lasts forever – Nine Everlasting Preserved Black Roses nestled in a round box. This enchanting arrangement radiates romance and sophistication, a perfect expression of enduring affection.",
        "type": "Flower Boxes",
        "tags": [
            "Anniversary Flowers",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Aspyn-1aa.jpg?v=1704337330",
            "/images/products/Aspyn-2aa.jpg?v=1704337330"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "12 Preserved Roses"
            ]
        },
        "dimention": "Width*Height: 18cm*20cm"
    },
    {
        "id": 948,
        "name": "Tina",
        "handle": "tina",
        "description": "Ignite the flame of passion with our bloom box exclusive – Nine Everlasting Preserved Red Roses elegantly arranged in a round box. Each bloom preserves the intensity of love, making this gift a timeless symbol of romance.",
        "type": "Flower Boxes",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Tina-1aa-540557.jpg?v=1704465103",
            "/images/products/Tina-2aa-109963.jpg?v=1704465103"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "12 Preserved Roses"
            ]
        },
        "dimention": "Width*Height: 18cm*20cm"
    },
    {
        "id": 949,
        "name": "Sweet Festivity HHP",
        "handle": "sweet-festivity-hhp",
        "description": "Sweet Festivity HHPSpread joy with this Sweet Festivity Hamper! Packed to the brim with traditional favourites and assorted sweet and savoury treats, this halal gourmet hamper will guarantee a snacklicious time for festive celebration!",
        "type": "Hampers",
        "tags": [
            "Halal Food Hampers"
        ],
        "price": "175.49",
        "inclTax": "175.49",
        "img": [
            "/images/products/Sweet-Festivity1A_7c55a536-dd67-4fdb-a107-2fc278fe9e9f-236170.jpg?v=1689295909",
            "/images/products/Sweet-Festivity2A_1ceac1dc-50aa-4a67-ab39-778f615319d7-940021.jpg?v=1689295909"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Haus Boom Manga Sparkling Juice 275ml",
                "2. Gretel Sprouted Cashews Truffle Salt 50g",
                "3. Beryl’s Classic Tiramisu Almond Milk Chocolate 65g",
                "4. Cowhead Crispy Butter Crackers 208g",
                "5. Mister Potato Crisps Hot & Spicy 100g",
                "6. Melvados Browney Brittle 100g",
                "7. Julie’s Love Letters Chocolate 100g",
                "8.  Strawberry Cookies with Soft Strawberry Filling 49g",
                "9. Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 950,
        "name": "Snack Delight HHP",
        "handle": "snack-delight-hhp",
        "description": "Snack Delight HHP",
        "type": "Hampers",
        "tags": [
            "Halal Food Hampers"
        ],
        "price": "157.23",
        "inclTax": "157.23",
        "img": [
            "/images/products/Snack-Delight1-645674.jpg?v=1675121148",
            "/images/products/Snack-Delight2.jpg?v=1675207837"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. St Michel Traditional Sponge Cake 150g",
                "2. Duncan’s of Deeside Traditional Shortbread Fingers 150g",
                "3. Hamlet Oublies Forum 125g",
                "4. Wernli Truffet 100g",
                "5. Hamlet Assorted Belgian Chocolates 125g",
                "6. Huntly & Palmers Seasmeal Rosemary & Garlic 200g",
                "7. Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 951,
        "name": "A Bit Of Everything HHP",
        "handle": "a-bit-of-everything-hhp",
        "description": "A Bit Of Everything HHP",
        "type": "Hampers",
        "tags": [
            "Halal Food Hampers"
        ],
        "price": "237.10",
        "inclTax": "237.10",
        "img": [
            "/images/products/A-Bit-Of-Everything1A_1b2b1bb9-6975-4a0d-9226-180608a036e5-918395.jpg?v=1701168956",
            "/images/products/A-Bit-Of-Everything2A-970813.jpg?v=1701168956"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Royal Select Sparkling Grape Juice 750ml",
                "2. Cavendish & Harvey Mixed Fruit Drops 200g",
                "3. Delfi Almond Milk Chocolate 180g",
                "4. Yamazaki Chocolate Chips Raisin+ Cookies 130g",
                "5. Tong Garden Nutrione Baked Cocktail Nuts 85g",
                "6. K.Harrodson Raspberry & Almond Butter Cookies 150g",
                "7. Bonz Crunchy Oat & Sesame Cookie Snack 100g",
                "8. Moore Cheese Cookies 50g",
                "9. Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 952,
        "name": "Darla BBS",
        "handle": "darla",
        "description": "This delightful floral arrangement in a stylish cream box showcases elegant white gerberas, delicate blue roses, and lush greenery. Perfect for any occasion, it exudes a serene and sophisticated charm, making it a lovely gift or a beautiful addition to any space.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Darla-BBS-2a.jpg?v=1721620484",
            "/images/products/Darla-BBS-1a.jpg?v=1721620484"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Gerbera . Eustoma . Baby Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 953,
        "name": "Scrumptious Assortment HHP",
        "handle": "scrumptious-assortment-hhp",
        "description": "Scrumptious Assortment HHPWe understand that everyone has different tastes, and this is why the Scrumptious Assortment Hari Raya Hamper features halal gourmet delights that will please everyone! From sweet to savoury, tender to crunchy, this Hari Raya gift hamper touches all the bases with its delectable treats, to make your special celebration a festival to be remembered.",
        "type": "Hampers",
        "tags": [
            "Halal Food Hampers"
        ],
        "price": "237.10",
        "inclTax": "237.10",
        "img": [
            "/images/products/Scrumptious-Assortment-HHP1A-258605.jpg?v=1700650616",
            "/images/products/Scrumptious-Assortment-HHP2A_6275f5ee-6b8f-4390-b0c0-7b1bd384749d-398047.jpg?v=1700650616"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.Farmland Thin Curv Chips 160g",
                "2. Matilde Vicenzi Puff Pastry Rolls Filled with Hazelnut Cream 125g",
                "3. Golden Bon Bon Soft Almond Nougat 70g",
                "4. Tchibo Golden Selection Coffee 50g",
                "5. Aalst- Almond Milk Chocolate Tin 150g",
                "6. Loackers Classic Cremkakao 225g",
                "7. Nature’s Wonder Fruits and Nuts Fusion 130g",
                "8. Orion Choco Pie Double Chocolate 12’s",
                "9. Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 954,
        "name": "Dorothy",
        "handle": "dorothy",
        "description": "Indulge in sophistication with our exquisite arrangement featuring 10 luxurious cappuccino roses elegantly displayed in a pristine white round box. The warm, coffee-toned petals create a captivating visual contrast against the clean white backdrop, offering a perfect blend of elegance and subtlety. Ideal for expressing admiration or as a refined gift, this arrangement radiates beauty and charm in every carefully curated bloom.",
        "type": "Flower Boxes",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Dorothy1-208959.jpg?v=1675473763",
            "/images/products/Dorothy2-385415.jpg?v=1675473763"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "10 Roses"
            ]
        },
        "dimention": "Width*Height: 15cm*20cm"
    },
    {
        "id": 955,
        "name": "Chocolate Delights HHP",
        "handle": "chocolate-delights-hhp",
        "description": "Chocolate Delights HHPDelight your special someone with the Chocolate Delights Hari Raya Hamper! Handpicked chocolate treats are loaded into an exquisite gift basket, making this halal gourmet hamper one that is truly memorable. Delight, impress and spark warmth with this delectable gift today! ",
        "type": "Hampers",
        "tags": [
            "Halal Food Hampers"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Chocolate-Delights-HHP1A-744583.jpg?v=1701169122",
            "/images/products/Chocolate-Delights-HHP2A-115150.jpg?v=1701169122"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Beryl’s Waffle Cookies Coated With Gianduja Milk Chocolate 80g",
                "2. Van Houten Cocoa Drink 100g",
                "3. Orion Choco Pie Double Chocolate 12’s",
                "4. Aalst Fruit and Nuts Chocolate Tin 150g",
                "5. Royal Select Sparkling Grape Juice 750ml",
                "6. Loacker Minis Napolitaner and Cremkakao 12pcs x 10g",
                "7. Melvados- Brownie Brittle 100g",
                "8. Beryl’s Double Chocolate Chip Cookies 100g",
                "9. Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 956,
        "name": "Flavour of Colors HHP",
        "handle": "flavour-of-colors-hhp",
        "description": "Flavour of Colors HHPThe Flavour of Colours halal gourmet hamper is an assortment of delightful treats packed into an exquisite gift box. Its delectable treats will certainly delight those with a sweet tooth, and put a smile on their faces! Make this Hari Raya a sweet one, by sending your special someone this gift of flavours.",
        "type": "Hampers",
        "tags": [
            "Halal Food Hampers"
        ],
        "price": "282.74",
        "inclTax": "282.74",
        "img": [
            "/images/products/Flavour-of-Colors-HHP1-619827.jpg?v=1700650135",
            "/images/products/Flavour-of-Colors2_e9580a36-c0e7-44c6-a75d-0d5c49ef7ff1.jpg?v=1700625495"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Golden Bon Bon Soft Almond Nougat Giftbox 130g",
                "2. Dahlsen Deloba Puff Pastry 100g",
                "3. K.Harrodson Blueberry Butter Cookies 180g",
                "4. Loackers Classic Fondente 225g",
                "5. Aalst- Fruits and Nuts Milk Chocolate Tin 150g",
                "6. Chips Ahoy! Original Chocolate Chip Cookies 228g",
                "7. Amazin’ Graze Anti-Oxidant Mixed Nuts Trail 130g",
                "8. Dilmah Premium Pure Ceylon Tea Bags 50g",
                "9. Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 957,
        "name": "Sparkling Surprise HHP",
        "handle": "sparkling-surprise-hhp",
        "description": "Sparkling Surprise HHPBe the reason for your loved ones to sparkle with the Sparkling Surprise Hari Raya Hamper! Surprise them with this gourmet halal gift that features sweet and savoury snacks that will keep the party going! Presented in an exquisite gift basket, it will surely be a showstopper for this festive celebration.",
        "type": "Hampers",
        "tags": [
            "Halal Food Hampers"
        ],
        "price": "330.66",
        "inclTax": "330.66",
        "img": [
            "/images/products/Sparkling-Surprise-HHP1-678458.jpg?v=1700651491",
            "/images/products/Sparkling-Surprise2.jpg?v=1700626295"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Golden Bon Bon- Crunchy Almond Nougats Gift Box 130g",
                "2. Amazin’Grace Pandan Coconut Nuts Mix 100g",
                "3. Royal Select Sparkling Grape Juice 750ml",
                "4. Bonz Chocolate Hazelnut Cookie Snack 100g",
                "5. Cavendish & Harvey- Tropical Candies Jar 300g",
                "6. Gardenia Snack’Em Lentil Chips 50g",
                "7. Elit Bohem Assorted Chocolate Selection 200g",
                "8. Swiss Miss Dark Chocolate Sensation Hot Cocoa 283g",
                "9. K.Harrodson Raspberry Butter Cookies 180g",
                "10. Delfi Almond Milk Chocolate Red Box 180g",
                "11. Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 958,
        "name": "Kris",
        "handle": "kris",
        "description": "Elegance takes center stage in this enchanting bouquet featuring nine delicate champagne roses embraced by the soft allure of caspia flowers. Each rose, a symbol of grace and admiration, is carefully surrounded by the ethereal beauty of caspia, creating a romantic and sophisticated arrangement perfect for any special occasion or heartfelt gesture.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Kris-1aa-886988.jpg?v=1705156011",
            "/images/products/Kris-2aa-242523.jpg?v=1705156011"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 959,
        "name": "Lia",
        "handle": "lia",
        "description": "Immerse yourself in the allure of our bouquet featuring 9 captivating purple roses, delicately accented with the timeless beauty of caspia and the aromatic touch of eucalyptus. The regal purple hues complement the subtle textures, creating a harmonious and sophisticated arrangement.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Lia-2a.jpg?v=1720709359",
            "/images/products/Lia-1a.jpg?v=1720709359"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 961,
        "name": "Tangerine Grace FNB",
        "handle": "tangerine-grace-fnb",
        "description": "Tangerine GraceYouthful, playful and energetic, roses, carnation sprays, matthiolas and preserved rice flowers in the Tangerine Grace flower and balloon box tampers the fierceness of red with the cheerfulness of yellow! The energy in this unique flower bouquet is combined with rustic colors from the balloons to convey hope, happiness and grace within each beautiful bloom. ",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Balloons Bouquet",
            "Get Well Soon Balloons Bouquet",
            "Happy Birthday Balloons Bouquet",
            "Congratulations Balloons & Flowers ",
            "Anniversary Balloons & Flowers",
            "Graduation Balloons Bouquet"
        ],
        "price": "383.15",
        "inclTax": "383.15",
        "img": [
            "/images/products/Tangerine-Grace-247340.jpg?v=1676885823",
            "/images/products/Tangerine-Grace1-512777.jpg?v=1676885823"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.  Roses",
                "2. Carnation Spray",
                "3. Matthiola",
                "4. Eustoma",
                "5. Veronica",
                "6. Preserved Rice Flower",
                "7. Pittosporum leaves",
                "8. Eucalyptus",
                "9. Personalised Text On Balloon with 4 Mini Balloons",
                "10. Wooden Crate"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 962,
        "name": "Bridal VIII",
        "handle": "bridal-viii",
        "description": "This beautiful bridal arrangement will come together with a Vase =)",
        "type": "Bouquets",
        "tags": [
            "Wedding Flowers",
            "Engagement Flowers & Bouquets"
        ],
        "price": "410.53",
        "inclTax": "410.53",
        "img": [
            "/images/products/Bridal-_VIII_7715f377-230f-489b-ac46-ed78de592afe-608946.jpg?v=1677236803",
            "/images/products/Bridal-_VIII_2-304356.jpg?v=1677236803"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Calla Lilies . Matthiolas . Rose"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 963,
        "name": "99 Pink Roses",
        "handle": "99-pink-roses",
        "description": "Experience the Vibrant Elegance of Our 99 Hot Pink Roses BouquetImmerse yourself in the vibrant elegance of our extraordinary bouquet featuring 99 dazzling hot pink roses, gracefully encircled by delicate white baby's breath. Ideal for grand celebrations, anniversaries, or as a luxurious expression of love, this bouquet radiates energy and timeless charm.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [],
        "price": "981.03",
        "inclTax": "981.03",
        "img": [
            "/images/products/99-Pink-Roses1-619668.jpg?v=1681183829"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "99 Hot Pink Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 50cm*50cm"
    },
    {
        "id": 964,
        "name": "Bountiful (II)",
        "handle": "bountiful-ii-1",
        "description": "Transform all grand openings into a burst of citrus joy with our Vibrant Citrus Celebration Tripod Stand. Featuring checker orange wrappers, balloons, and Orange Champagne flowers, this lively ensemble radiates positivity, setting the stage for a jubilant and memorable event.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Bountiful-_II_-1b.jpg?v=1720101193",
            "/images/products/Grand-Opening-Card-2024_e990338c-85ea-403d-9a50-50667846a486.jpg?v=1720101201",
            "/images/products/Bountiful-_II_-2b.jpg?v=1720101193"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Gerbera . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 965,
        "name": "Bountiful (III)",
        "handle": "bountiful-iii",
        "description": "Command attention at all grand business openings with our Radiant Red Bloom Tripod Stand. Vivid red balloons complement an array of bold red flowers, elegantly wrapped in matching skirtings. This arrangement is a powerful symbol of passion and the flourishing of new opportunities.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Bountiful-_III_-1b.jpg?v=1720100607",
            "/images/products/Grand-Opening-Card-2024_6c73d8b6-52c4-4ab3-9336-ef61005d7992.jpg?v=1720100614",
            "/images/products/Bountiful-_III_-2b.jpg?v=1720100607"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Orchids . Fillers & Foliage . Balloons"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 966,
        "name": "Ayva",
        "handle": "ayva",
        "description": "Embrace the classic charm with our bouquet featuring 6 passionate red roses adorned with the delicate and silvery elegance of dusty miller. Each velvety red rose is complemented by the soft, muted tones of the dusty miller leaves, creating a sophisticated and visually appealing arrangement.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Ayva-2a.jpg?v=1720691715",
            "/images/products/Ayva-1a.jpg?v=1720691715"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*36cm"
    },
    {
        "id": 967,
        "name": "Condolence (X)",
        "handle": "condolence-x",
        "description": "In a tender expression of sympathy, our condolence flower tripod stands feature pure white chrysanthemums and delicate lilies, creating a serene and elegant tribute. This arrangement offers solace and comfort during moments of deep grief, symbolizing compassion and remembrance with grace and dignity.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Condolence-_X_-1b.jpg?v=1720138330",
            "/images/products/Condolence-Card-2024_1_3d66cf11-d8de-4787-b002-513396eabfa6.jpg?v=1720138349",
            "/images/products/Condolence-_X_-2b.jpg?v=1720138330"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "White Lilies . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*170cm"
    },
    {
        "id": 968,
        "name": "Emma",
        "handle": "emma",
        "description": "Introducing our stunning Pink Hydrangea Bouquet, a delightful arrangement that embodies elegance and charm. This enchanting bouquet features a harmonious blend of delicate pink hydrangeas, vibrant pink tulips, and accents of graceful dusty miller foliage, creating a captivating visual display perfect for any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Emma-1b.jpg?v=1720627881",
            "/images/products/Emma-2b.jpg?v=1720627882"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea . 15 Tulips . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 969,
        "name": "Kiko",
        "handle": "kiko",
        "description": "Blossoming with life, this Flowers Malaysia creation showcases deep red roses and soft pink blooms, delicately nestled in a rustic basket, finished with an elegant ribbon. It's a vibrant celebration of natural beauty and affection.",
        "type": "Flower Baskets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Kiko-2b-797894.jpg?v=1727349960",
            "/images/products/Kiko-1b.jpg?v=1727312794"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Bright Pink Roses . Carnations . Spray Rose . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*30cm"
    },
    {
        "id": 970,
        "name": "Skylar",
        "handle": "skylar",
        "description": "Immerse yourself in vibrant elegance with our striking arrangement featuring 7 hot pink roses, meticulously presented in a pristine white round box. The bold and lively hues of the roses pop against the clean white backdrop, creating a visually stunning display. Ideal for expressing bold sentiments or as a chic and modern gift, this arrangement radiates beauty and sophistication in every captivating bloom.",
        "type": "Flower Boxes",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Skylar1_dbd52397-d0af-4be8-bbfe-90beb507c1c0-277020.jpg?v=1679436205",
            "/images/products/Skylar2_1-147629.jpg?v=1679436205"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Bright Pink Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*25cm"
    },
    {
        "id": 971,
        "name": "ILU",
        "handle": "ilu-ws",
        "description": "Presenting our exquisite bouquet featuring three vibrant red roses nestled within a cloud of delicate pink baby's breath. This elegant arrangement is beautifully wrapped in soft pink paper, making it a perfect gift for expressing love and admiration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/ILU-1a.jpg?v=1720528812",
            "/images/products/ILU-2a.jpg?v=1720528812"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 972,
        "name": "Jayla",
        "handle": "jayla",
        "description": "Delight in the subtle beauty of our bouquet featuring 5 charming pink-tipped roses, gracefully accented with the delicate blooms of caspia and the intricate texture of parvifolia. Perfect for expressing tender sentiments on various occasions, this bouquet is a delightful blend of grace and sophistication.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Jayla-2b.jpg?v=1720965667",
            "/images/products/Jayla-1b.jpg?v=1720965667"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Pink Tip Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 973,
        "name": "Emery",
        "handle": "emery-ws",
        "description": "This bouquet is a graceful composition of deep red roses, exuding classic romance, complemented by clusters of white wax flowers and green foliage. It's wrapped in a soft pink paper and tied with a delicate pink ribbon, creating a charming presentation perfect for expressing affection.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Emery-1b-182769.jpg?v=1720551868",
            "/images/products/Emery-2b-165050.jpg?v=1720551868"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Wax . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 974,
        "name": "Daebak Delights GHP",
        "handle": "daebak-delights-ghp",
        "description": "Daebak Delights GHPIntroducing the Daebak Delights gourmet hamper, the ultimate combination of Korean culture and gourmet snacking! This hamper features a thrilling drinking game and a variety of delicious gourmet snacks to thrill your tastebuds. Perfect to munch on while playing a drinking game with friends!",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Corporate Hampers"
        ],
        "price": "314.92",
        "inclTax": "314.92",
        "img": [
            "/images/products/Daebak-Delights_f94ca22f-ec9d-422f-8fbc-9c5b0ef10731-389294.jpg?v=1680572377",
            "/images/products/Daebak-Delights1_f8d63a84-7b4c-4ccd-a3dd-1f956b39aa6a-851232.jpg?v=1680572377"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tGB Roulette 16 Glass Lucky Shot Drinking Game",
                "2.\tJinro Soju Green Grape 360ml x 2",
                "3.\tJinro Soju Strawberry 360ml x 2",
                "4.\tWerther’s Original Classic Caramel Popcorn 140g",
                "5.\tNibbles Premium Mixed Nuts 45g",
                "6.\tHunter’s Gourmet Hand Cooked Black Truffle Potato Chips 40g",
                "7.\t Camel Premium Mixed Snacks 150g",
                "8.\t Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 975,
        "name": "Sofina",
        "handle": "sofina",
        "description": "“Sometimes it takes a heartbreak to shake us awake  help us see we are worth so much more than we’re settling for.” - Mandy Hale",
        "type": "Bouquets",
        "tags": [
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Sofina1A_7170b77a-c7ef-44b0-8b86-bc7b7e7a8d68-993300.jpg?v=1680573941",
            "/images/products/Sofina2A-459487.jpg?v=1680573941"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "9 White Roses . 9 Blue Roses . Baby Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 32cm*40cm"
    },
    {
        "id": 976,
        "name": "Truffle Shuffle GHP",
        "handle": "truffle-shuffle-ghp",
        "description": "Truffle Shuffle GHPPacked with premium treats that will delight even the most discerning of palates, this exquisite gift basket is sure to impress. Our Truffle Shuffle is filled with rich and bold flavours, along with savoury, sweet and refreshing treats and drinks that are absolutely perfect for sharing. ",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Corporate Hampers"
        ],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Truffle-Shuffle1-300768.jpg?v=1680573941",
            "/images/products/Truffle-Shuffle2-267601.jpg?v=1680573941"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tEsprit Passion Fruit Sparkling Water with Fruit Juice 300ml x 2",
                "2.\tTchibo Gold Selection Coffee 50g",
                "3.\tExcelcium – Pralines BonBons De Chocolat 200g",
                "4.\tDuncan’s of Deeside Traditional Shortbread Fingers 150g",
                "5.\tGodivine Nutty Mix 80g",
                "6.\tHamlet B&C Chocolate Wafers 110g",
                "7.\tQuickbury Mini Chocolate with Hazelnuts Brownies 125g",
                "8.\tHunter’s Gourmet Hand Cooked Black Truffle Potato Chip 40g",
                "9. Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 977,
        "name": "Office Oasis GHP",
        "handle": "office-oasis-ghp",
        "description": "Office Oasis GHPWork and relaxation meet in this stunning snack pack! The Office Oasis is designed to do exactly what it’s named after – to help you create a calming and comfortable environment on your work desk. Get your caffeine fix with the Starbucks Premium Mixes Coffè Mocha, or take a break with a soothing cup of Juan Tea Osmanthus Oolong Tea. We’ve included some essential tools to make your work experience more comfortable and productive, such as a desk mug, desktop fan, humidifier, laptop stand and more!",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Corporate Hampers"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Office-Oasis1-995175.jpg?v=1680572759",
            "/images/products/Office-Oasis2-533657.jpg?v=1680572759"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tStarbucks Premium Mixes Caffè Mocha 88g",
                "2.\tJuan Tea Osmanthus Oolong Tea Set 74g",
                "3.\tDesktop Fan",
                "4.\tAdjustable Laptop Stand",
                "5.\tHumidifer",
                "6.\tEssential Oil 10ml",
                "7.\tDesk Mug",
                "8.\t Huntley & Palmers Rosemary & Garlic Sesameal Crackers 200g",
                "9. Nibble Roasted Cashew 35g",
                "10. Exquisite Gift Box (L)"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 978,
        "name": "Jacinta",
        "handle": "jacinta",
        "description": "\"Love’s gift cannot be given, it waits to be accepted.\" – Rabindranath Tagore",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Jacinta1_6329c32d-b77d-4964-b133-a7dd2d0a9946-688089.jpg?v=1680747246",
            "/images/products/Jacinta2-384087.jpg?v=1680747246"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "25 Menta Roses . 25 White Roses"
            ]
        },
        "dimention": "Width*Height: 50cm*50cm"
    },
    {
        "id": 979,
        "name": "Greta",
        "handle": "greta",
        "description": "This bouquet is a symphony of soft elegance, featuring blushing pink and creamy white roses nestled amongst delicate baby's breath and verdant foliage. It's artfully wrapped in a pearlescent, holographic paper that catches the light, paired with a satin ribbon bearing a heartfelt message.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Greta-2b.jpg?v=1720883426",
            "/images/products/Greta-1b.jpg?v=1720883426"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Spray Roses. Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 980,
        "name": "Zelda",
        "handle": "zelda",
        "description": "A stunning floral arrangement with a delightful mix of blooms that create a harmonious balance of color and texture. The vibrant roses and carnations in shades of pink and orange exude warmth and joy, while the softer hues of the accompanying flowers add a touch of elegance.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Zelda-2b.jpg?v=1721058806",
            "/images/products/Zelda-1b.jpg?v=1721058806"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Spray Roses . Rose . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 981,
        "name": "99 White Roses",
        "handle": "99-white-roses",
        "description": "Immerse Yourself in the Dreamlike Ambiance of Our 99 White Roses BouquetA dreamlike ambiance with our opulent bouquet featuring 99 immaculate white roses, elegantly encircled by a delicate halo of baby's breath. Ideal for grand celebrations, weddings, or as an extravagant expression of love, this bouquet captures the essence of timeless beauty and enchantment.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "912.57",
        "inclTax": "912.57",
        "img": [
            "/images/products/Reia1A.jpg?v=1681093618"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "99 White Roses . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 50cm*50cm"
    },
    {
        "id": 982,
        "name": "Reia",
        "handle": "reia",
        "description": "This charming bouquet showcases a harmonious blend of vibrant magenta and soft pink roses, complemented by delicate mini pink roses and lush greenery. Wrapped in elegant mint-green paper, it exudes sophistication and elegance, making it the perfect gift for any special occasion or to brighten someone's day.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Reia-2a-209656.jpg?v=1720789881",
            "/images/products/Reia-1a.jpg?v=1720742899"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnation Spray . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 983,
        "name": "Abundance (X)",
        "handle": "abundance-x",
        "description": "Transform Grand Business Openings with Our Enchanting Lily Bloom Floral StandFaux pink lilies take center stage, accompanied by a vibrant display of colors with stunning pink gerberas clouding above violet mums, creating a spellbinding and visually captivating centerpiece.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Abundance-_X_-1e.jpg?v=1720523717",
            "/images/products/Grand-Opening-Card-2024_10562e10-3a38-49f6-aa64-3178feca65b9.jpg?v=1720523723",
            "/images/products/Abundance-_X_-2e.jpg?v=1720523717"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Gerbera . Roses . Mum . Carnations . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 984,
        "name": "Sophie",
        "handle": "sophie-1",
        "description": "An enchanting display of variegated pink carnations and creamy roses, this bouquet from Flowers Malaysia exudes charm. Tied with a delicate blush ribbon, it's an epitome of grace and a perfect gesture of affection.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Sophie-2b.jpg?v=1721174398",
            "/images/products/Sophie-1b.jpg?v=1721174398"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Spray Roses. Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 985,
        "name": "Alice",
        "handle": "alice",
        "description": "Express Passion with Our Red Carnation and Orange Spray Rose BouquetA dazzling arrangement featuring nine vibrant red carnations and the radiant charm of orange spray roses. This bouquet symbolizes love, passion, and enthusiasm. The rich red and warm orange hues create a stunning contrast, making it an ideal gift to express heartfelt emotions and celebrate special occasions with flair.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Alice-2b.jpg?v=1721174798",
            "/images/products/Alice-1b.jpg?v=1721174798"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Spray Roses. Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 987,
        "name": "Lips Like Sugar WGHP",
        "handle": "malesan-vin-de-france-rouge-with-chocolate-wghp",
        "description": "Lips Like SugarIrresistible and delightful, our Lips like Sugar chocolate and wine hamper features a charming pair of JP. Chenet Ice Edition Blanc 20cl and JP. Chenet Ice Edition Rose Sparkling Wine 20cl. This treasure-trove comes paired with creamy Belgian chocolate, packed with an elegant handcrafted hat box. Indulge today with a chocolate delivery in Malaysia!",
        "type": "Hampers",
        "tags": [
            "Corporate Hampers",
            "Wine Gift Hampers"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Lips-Like-Sugar1-274383.jpg?v=1685991578",
            "/images/products/Lips-Like-Sugar2-450183.jpg?v=1685991578"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tJP. Chenet Ice Edition Blanc 20cl",
                "2.\tJP. Chenet Ice Edition Rose Sparkling Wine 20cl",
                "3.\tGuylian Seashells Chocolate 250g",
                "4. Lindt Swiss Thin Dark 125g",
                "5. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 988,
        "name": "Taste & Colours WGHP",
        "handle": "taste-colours-wghp",
        "description": "Taste  ColoursSend this to a loved one to commemorate any occasion! Show them how much you care with a bottle of Chateau Fleurs Vignobles and an arrangement of beautiful flowers.",
        "type": "Hampers",
        "tags": [
            "Corporate Hampers",
            "Wine Gift Hampers"
        ],
        "price": "232.54",
        "inclTax": "232.54",
        "img": [
            "/images/products/Taste-_-Colours1-281802.jpg?v=1686079383",
            "/images/products/Taste-_-Colours2.jpg?v=1686008859"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tChateau Fleurs Vignobles 75cl",
                "2.\tExquisite Flower Arrangement",
                "3.\tWooden Crate"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 989,
        "name": "Fleurs Vignobles with Flowers WGHP",
        "handle": "travailleurs-with-flowers-wghp",
        "description": "Fleurs Vignobles with FlowersExperience the essence of sophistication and luxury with our Fleurs Vignobles with Flowers gift set. This elegant collection features the refined Vino Tinto Fleurs Vignobles from Spain, paired with an exquisite flower arrangement. Presented in a beautifully crafted wooden crate adorned with a ribbon, this gift set combines the rich flavors of fine Spanish wine with the delicate beauty of fresh blooms, making it the perfect choice for celebrating special moments or expressing heartfelt appreciation.",
        "type": "Hampers",
        "tags": [
            "Corporate Hampers",
            "Wine Gift Hampers"
        ],
        "price": "232.54",
        "inclTax": "232.54",
        "img": [
            "/images/products/Travailleurs-with-Flowers1.jpg?v=1686009436",
            "/images/products/Travailleurs-with-Flowers2.jpg?v=1686009435"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Vino Tinto Fleurs Vignobles, Espana, 75cl",
                "2.  Exquisite Flower Arrangement",
                "3.  Exquisite Wooden Crate"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 990,
        "name": "Enchanting Elegance WGHP",
        "handle": "enchanting-elegance-wghp",
        "description": "Enchanting EleganceIntroducing the perfect array of sophisticated indulgence. With a glass of Moët and taste of delicious Belgian chocolate, your recipient will surely savour every moment. Send the Enchanting Elegance hamper to a loved one today!",
        "type": "Hampers",
        "tags": [
            "Corporate Hampers",
            "Wine Gift Hampers"
        ],
        "price": "253.07",
        "inclTax": "253.07",
        "img": [
            "/images/products/Enchanting-Elegance1-420328.jpg?v=1686077639",
            "/images/products/Enchanting-Elegance2_dfdcea92-c60c-4289-9062-c529ba34dc10-241976.jpg?v=1686165800"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tMini Moët 20cl",
                "2.\tHamlet Assortiment Belgian Chocolates 125g",
                "3.\tDelaviuda Bombones Dark Chocolate 150g",
                "4. Wooden Crate"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 991,
        "name": "Crown Jewel WGHP",
        "handle": "crown-jewel-wghp-1",
        "description": "Crown JewelA simple, yet prestigious chocolate and wine hamper that’s made to shine like a coveted crown jewel. The hamper features a smooth Terre Forti Merlot, paired with smooth and creamy Guylian chocolate. A truly sophisticated and stylish gift that is sure to delight your senses.",
        "type": "Hampers",
        "tags": [
            "Corporate Hampers",
            "Wine Gift Hampers"
        ],
        "price": "253.07",
        "inclTax": "253.07",
        "img": [
            "/images/products/Crown-Jewel1-471209.jpg?v=1686077575",
            "/images/products/Crown-Jewel2-408349.jpg?v=1686077575"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tTerre Forti Merlot 75cl",
                "2.\tGuylian Seashells Chocolate 250g",
                "3.\tAkai Bohshi Elegant Assorted Cookies 71g",
                "4. Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 992,
        "name": "Castelforte Corvina Veronese with Chocolates WGHP",
        "handle": "castelforte-corvina-veronese-with-chocolates-wghp",
        "description": "Castelforte Corvina Veronese with Chocolates",
        "type": "Hampers",
        "tags": [
            "Corporate Hampers",
            "Wine Gift Hampers"
        ],
        "price": "257.64",
        "inclTax": "257.64",
        "img": [
            "/images/products/Castelforte-Corvina-Veronese-with-Chocolates1_7d6e202a-da02-4433-836f-010ec7e34acd-530004.jpg?v=1686165649",
            "/images/products/Castelforte-Corvina-Veronese-with-Chocolates2-984506.jpg?v=1686098580"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tCastelforte Corvina Veronese Indicazione Geografica Tipica, 75cl",
                "2.\tDelaviuda Bombones Dark Chocolate 150g",
                "3.\tHamlet Assorted Belgian Chocolates 125g",
                "4. Wooden Crate"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 993,
        "name": "Pastel Elegance WGHP",
        "handle": "pastel-elegance-wghp",
        "description": "Pastel EleganceThis a wonderful gift that’s filled with two bottles of Noblesse, a gorgeous flower arrangement, and a double wine crate.",
        "type": "Hampers",
        "tags": [
            "Corporate Hampers",
            "Wine Gift Hampers"
        ],
        "price": "294.15",
        "inclTax": "294.15",
        "img": [
            "/images/products/Pastel-Elegance1.jpg?v=1686012826",
            "/images/products/Pastel-Elegance2-409949.jpg?v=1686077997"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tNoblesse Merlot, Chile 75cl",
                "2.\tNoblesse Sauvignon Blanc, Chile 75cl",
                "3.\tExquisite Flower Arrangement",
                "4. Double Wine Crate"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 994,
        "name": "Radiant Bundle with Flower WHP",
        "handle": "radiant-bundle-with-flower-whp",
        "description": "Radiant Bundle with FlowerIntroducing Radiant Bundle with Flower, our luxurious wellness hamper that’s designed to nourish your loved one’s body and mind. Packed with premium health-enhancing goodies, this hamper is the perfect gift for anyone who cares about their wellbeing. From Bird’s Nest with Ginseng to Fomec’s Berry Essence, our wellness hamper contains a wide selection of health-boosting treats.",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "221.13",
        "inclTax": "221.13",
        "img": [
            "/images/products/Radiant-Bundle-with-Flower1-349216.jpg?v=1687302100",
            "/images/products/Radiant-Bundle-with-Flower2-313355.jpg?v=1687302100"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBird’s Nest with Ginseng, White Fungus & Rock Sugar 3 x 70ml",
                "2.\tYu Xiang Yan White Fungas Bird’s Nest Drink 3 x 75ml",
                "3.\tNewMoon Essence of Chicken 2 x 68ml",
                "4.     Fomec ‘s Berry Essence 2 x 42ml",
                "5.\tAssorted Flowers Arrangement",
                "6.\tExquisite Gift Basket",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 995,
        "name": "Recipe of Health With Flower WHP",
        "handle": "recipe-of-health-with-flower-whp",
        "description": "Recipe of Health With FlowerSay hello to Recipe of Health with Flower, a carefully curated wellness hamper with premium healthy goodies to promote overall health and wellbeing. This hamper includes Kinohimitsu Bird’s Nest Gift and Kinohimitsu Essence of Chicken Gift Box Set to help boost your loved one back to their best. Perfect for those looking to prioritise self-care and wellness, this hamper is the ultimate gift for a loved one.",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Recipe-of-Health-With-Flower1-768595.jpg?v=1687302100",
            "/images/products/Recipe-of-Health-With-Flower2-126497.jpg?v=1687302100"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tKinohimitsu Bird’s Nest Gift Set 8 x 75gl",
                "2.\tKinohimitsu Essence Of Chicken Gift Box Set 6 x 75g",
                "3.\tAssorted Flowers Arrangement",
                "4.     Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 996,
        "name": "Berry Blissful WHP",
        "handle": "nature-palate-with-flower-whp",
        "description": "Berry BlissfulThis delightful Berry Blissful wellness hamper is one of our favourites, and for good reason! It was designed to delight your senses while nourishing your body, with goodies such as the Juan Tea Indulgence Floral Blend Tea Set Pouch, Amazin’ Graze Antioxidant Berry Trail Mix, Godivine Cranberry Almond Cookie and Kinohimitsu Bird Nest With Longan  Wolfberry. This antioxidant-rich hamper is perfect for those seeking a balance of wellness and flavour.",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "175.49",
        "inclTax": "175.49",
        "img": [
            "/images/products/Berry-Blissful1-736475.jpg?v=1687301502",
            "/images/products/Berry-Blissful2-291253.jpg?v=1687301502"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\t Juan Tea Indulgence Floral Blend Tea Set Pouch 74g",
                "2.\tAmazin’ Graze Antioxidant Berry Trail Mix 130g",
                "3.\tGodivine Cranberry Almond Cookie 70g",
                "4.     Kinohimitsu Bird Nest With Longan & Wolfberry 2 x 150g",
                "5.     Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 997,
        "name": "Treasure of Wellness WHP",
        "handle": "treasure-of-wellness-whp",
        "description": "Treasure of Wellness",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Treasure-of-Wellness-NEW1.jpg?v=1711600130",
            "/images/products/Treasure-of-Wellness-NEW2.jpg?v=1711600130"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.   Kinohimitsu Birds Nest Gift Set 8 x 75g",
                "2.  New Moon Essence of Chicken with Cordyceps ",
                "6 x 68ml",
                "3.  Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 998,
        "name": "Theora",
        "handle": "theora",
        "description": "Brighten any space with this radiant sunflower and pastel floral arrangement. Sunflowers take center stage, surrounded by delicate white and soft pink roses, adding a touch of elegance. This bouquet is perfect for bringing a burst of sunshine and charm into any room. Presented in a chic glass vase with a decorative ribbon, it makes a thoughtful gift for any occasion.",
        "type": "Vase Arrangement",
        "tags": [
            "Sunflower Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Theora-2a-136836.jpg?v=1724160547",
            "/images/products/Theora-1a-802766.jpg?v=1724160547"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Spray Roses . Eustomas . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*32cm"
    },
    {
        "id": 999,
        "name": "Violet",
        "handle": "violet",
        "description": "Capture the essence of a charming countryside meadow with this delightful bouquet of daisies, baby’s breath, and lavender. The soft whites and cheerful yellows, complemented by sprigs of greenery, create a serene and refreshing display. Arranged in a rustic glass jar and adorned with a ribbon, this bouquet brings a touch of natural beauty and tranquility to any space, perfect for any occasion.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Violet-2a.jpg?v=1724124768",
            "/images/products/Violet-1a.jpg?v=1724124768"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Daisy . Baby Breath . Dried Lavender . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*32cm"
    },
    {
        "id": 1000,
        "name": "Clementine",
        "handle": "clementine",
        "description": "\"We do not remember days, we remember moments.\" - Cesare Pavese",
        "type": "Flower Baskets",
        "tags": [
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Clementine1A_a8b65bcf-cede-42f3-b2cb-a0103e2760ac-622539.jpg?v=1689987317",
            "/images/products/Clementine2A-517896.jpg?v=1689987317"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Eustomas . Gossypium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 25cm*25cm"
    },
    {
        "id": 1001,
        "name": "Eudora",
        "handle": "eudora",
        "description": "\"We do not remember days, we remember moments.\" - Cesare Pavese",
        "type": "Flower Baskets",
        "tags": [
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Eudora1_b7ce4e43-b8af-4f56-a41e-bc1a97b2f95b-506097.jpg?v=1689987372",
            "/images/products/Eudora2-813473.jpg?v=1689987372"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 25cm*25cm"
    },
    {
        "id": 1002,
        "name": "Akari FBS",
        "handle": "akari",
        "description": "This elegant arrangement from our signature bag series features a vibrant pink rose, delicate pink tipped spray roses, violet matthiola and lush greenery, all beautifully presented in a stylish pink bag. Perfect for any occasion, it adds a touch of elegance and joy to any setting.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Akari-FBS-1a-569030.jpg?v=1720116838",
            "/images/products/Akari-FBS-2a.jpg?v=1720056099"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Spray Rose . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 1003,
        "name": "Aksana FBS",
        "handle": "aksana",
        "description": "Embrace Elegance with Our Cappuccino Rose and White Floral BagThis elegant arrangement features cappuccino roses, delicate white blooms, and lush greenery, all beautifully presented in a stylish pink bag with black handles. Perfect for any occasion, it adds a touch of sophistication and joy to any setting.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bag Series",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Aksana1-233833.jpg?v=1690502774",
            "/images/products/Aksana2_625eb653-e696-48ba-8173-75248520709c-652913.jpg?v=1690591819"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Spray Rose . Matthiola . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 1004,
        "name": "Kids Wellness + Balloon HHP",
        "handle": "kids-wellness-balloon-hhp",
        "description": "Kids Wellness + Balloon HHPWe understand that parents all want the best for their children, which is why we have put together the Kids Wellness + Balloon Get Well Soon Hamper, which is an assortment of healthy treats, to make snack time both enjoyable and healthy! Make a kid smile today with this cheerful get well gift!",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Flowers & Bouquets",
            "Halal Food Hampers"
        ],
        "price": "180.05",
        "inclTax": "180.05",
        "img": [
            "/images/products/Kids-Wellness-_-Balloon1_e9d37113-74b1-4f93-9594-9b36dcff597b-137879.jpg?v=1690592655",
            "/images/products/Kids-Wellness-_-Balloon2_58e17000-33f0-4d11-b16b-c688ac439c32-192125.jpg?v=1690592655"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Oreo Cookie Sandwich Biscuit 266g",
                "2. Cowhead Chocolate Chip Oat Cookies 178g",
                "3. Kellogg’s Froot Loops 160g",
                "4. Farmhouse UHT Fresh Milk 1L",
                "5. Ribena Blackcurrent Packed Drink 2 x 200ml",
                "6. Milo Packed Drinks 2 x 200ml",
                "7. Fruity Crisps Strawberry & Apple 25g",
                "8. Assorted Non-Helium Balloon",
                "9. Exquisite Gift Basket (S)"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 1005,
        "name": "Janet",
        "handle": "janet",
        "description": "This soft and romantic bouquet features a delicate combination of pastel-hued roses, lisianthus, and other blooms, wrapped in elegant paper. The gentle mix of light purples, pinks, and whites creates a serene and graceful arrangement, perfect for expressing love, sympathy, or celebrating a special occasion. This bouquet brings a touch of tenderness and tranquility to any setting, making it an ideal choice for those cherished moments.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Janet-2a.jpg?v=1724818487",
            "/images/products/Janet-1a.jpg?v=1724818487"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Eustomas . Matthiolas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 18cm*28cm"
    },
    {
        "id": 1006,
        "name": "Alberta",
        "handle": "alberta",
        "description": "Celebrate Joy with Our Vibrant Cone-Shaped Mixed Flower BouquetThis vibrant cone-shaped bouquet features a delightful mix of red and peach spray roses, delicate daisies, and pastel-hued blooms, all wrapped in elegant ivory craft paper. Perfect for any celebration, it brings a burst of color and happiness to any occasion.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Alberta-2a-649818.jpg?v=1724863709",
            "/images/products/Alberta-1a.jpg?v=1724817637"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Spray Roses . Eustomas . Matthiolas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 18cm*28cm"
    },
    {
        "id": 1007,
        "name": "Ginger",
        "handle": "ginger",
        "description": "This charming bouquet showcases a delightful mix of cappuccino roses, dull orange spray roses, and dainty chamomile flowers, all beautifully arranged with lush greenery. Adorned with a rustic heart-shaped twig accent and wrapped in soft ivory paper, it's perfect for expressing love and appreciation.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Ginger-1a.jpg?v=1720889276",
            "/images/products/Ginger-2a.jpg?v=1720889276"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Matthiolas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1008,
        "name": "Tansy",
        "handle": "tansy-1",
        "description": "Brighten Your Day with Our Cheerful Sunflower BouquetThis bouquet exudes cheer with a large, radiant sunflower at its heart, surrounded by violet carnations, sunny yellow spray roses, and delicate white caspia and daisies. Set against a backdrop of rich green foliage and wrapped in tasteful light purple paper, it’s perfect for any occasion. Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Tansy-1e-403225.jpg?v=1720791509",
            "/images/products/Tansy-1d-478137.jpg?v=1720792169"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1009,
        "name": "Gisele",
        "handle": "gisele",
        "description": "This vibrant bouquet showcases a delightful mix of flowers in various shades of pink, purple, and yellow. It includes roses, carnations, daisies, and a striking yellow gerbera, complemented by delicate greenery. Wrapped in soft pastel papers with an iridescent ribbon, it exudes a cheerful and elegant vibe perfect for any celebration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Gisele-2a.jpg?v=1721175019",
            "/images/products/Gisele-1a.jpg?v=1721175019"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Matthiolas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1010,
        "name": "Bridal IX",
        "handle": "bridal-ix",
        "description": "This beautiful bridal arrangement will come together with a Vase =)",
        "type": "Bouquets",
        "tags": [
            "Wedding Flowers",
            "Engagement Flowers & Bouquets"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Bridal-IX1B-516136.jpg?v=1692585360",
            "/images/products/Bridal-IX2B-872518.jpg?v=1692585360"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Calla Lilies . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1011,
        "name": "Everly II",
        "handle": "everly-ii",
        "description": "Vivid pink carnations burst forth among delicate greenery, wrapped in soft pink and accented with a bold red ribbon—a Flowers Malaysia signature piece that’s both spirited and elegant.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Everly-II-2b-778355.jpg?v=1720788918",
            "/images/products/Everly-II-1b.jpg?v=1720690558"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1012,
        "name": "Roxanne",
        "handle": "roxanne",
        "description": "This graceful vase arrangement features pristine white tulips, complemented by cheerful daisies and delicate sprigs of greenery. The fresh and pure palette creates a bouquet that exudes simplicity and elegance, perfect for any occasion where a touch of natural beauty is desired. Whether as a thoughtful gift or a serene addition to your space, this bouquet brings a sense of calm and refinement to any setting.",
        "type": "Vase Arrangement",
        "tags": [
            "Tulip Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Roxanne-2a-359305.jpg?v=1724688263",
            "/images/products/Roxanne-1a.jpg?v=1724637577"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "13 Tulips . Daisy . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*32cm"
    },
    {
        "id": 1013,
        "name": "Cherished Cherubs BHP",
        "handle": "cherished-cherubs-bhp",
        "description": "Cherished Cherubs Our adorable Cherished Cherubs is packed with our Baby K Signature Diaper Bag and Baby K 4 Pcs Romper Gift Set, and we’ve added a nourishing set of Bird’s Nest with Ginseng  Rock Sugar for the parents too!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Cherished-Cherubs-368957.jpg?v=1698138070",
            "/images/products/Cherished-Cherubs1.jpg?v=1698110920"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tBird’s Nest with Ginseng, White Fungus & Rock Sugar 3 x70ml",
                "2.\tBaby K Signature Diaper Bag",
                "3.\tBaby K Romper Gift Set",
                "4.      Exquisite Wooden Crate",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 1014,
        "name": "Baby Gym Club BHP",
        "handle": "baby-gym-club-bhp",
        "description": "Baby Gym ClubThe Baby Gym Club gift hamper is the perfect gift for curious babies! We have included a piano play-gym mat to entertain even the most active babies. Other essentials in the basket include organic bamboo washcloths from BabyK, and Drypers diapers, for a fuss-free playtime session!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Baby-Gym-Club_8530bf6b-b81e-42d2-9bcc-1a9881454457-893725.jpg?v=1698137885",
            "/images/products/Baby-Gym-Club1_ca802f2d-7966-43c0-a2e0-12ffa66579aa-555200.jpg?v=1698137885"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.\tIMBABY Baby’s Piano Play-Gym Mat",
                "2.\tBaby K Romper Gift Set",
                "3.\tBaby K- Organic Bamboo 6 pcs pack Washcloth",
                "4.      KinderNurture Organic Baby Wipes 80’s",
                "5.      Drypers Wee Wee Dry Diapers M size (4pcs) x 2 packs",
                "6.      Exquisite Gift Box (L)",
                ""
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 1015,
        "name": "Toothsome GHP",
        "handle": "toothsome-ghp",
        "description": "Toothsome ",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Toothsome-GHP1.jpg?v=1707107383",
            "/images/products/Toothsome-GHP2.jpg?v=1707107383"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Casa de Campo Premium Cabernet Sauvignon 75cl",
                "2.  Wernli Chocofin 100g",
                "3.  Vicenzi Minisnack with Pastry Cream 75g",
                "4.  Nairns Gluten Free Salted Caramel Oat Biscuits 160g",
                "5.  Cupido Tiramisu Dessert Cups 125g",
                "6.  La Mere Poulard Vintage Les Sables French Butter Cookies 46.8g",
                "7.  Hunters Gourmet Hand Cooked Black Truffle Potato Chips 40g",
                "8.  Exquisite Gift Box"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1016,
        "name": "Holly",
        "handle": "holly",
        "description": "\"Gifts of time and love are surely the basic ingredients of a truly merry Christmas.” – Peg Bracken",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Holly-1a-347694.jpg?v=1700563338",
            "/images/products/Holly-2a-112121.jpg?v=1700563338"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Pine Cone . Pine Leaf . "
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1017,
        "name": "SnowFlake",
        "handle": "snowflake",
        "description": "\"Gifts of time and love are surely the basic ingredients of a truly merry Christmas.” – Peg Bracken",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Snowflake-1b-119344.jpg?v=1700564513",
            "/images/products/Snowflake-2b.jpg?v=1700551375"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Pine Cone . Pine Leaf . "
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1018,
        "name": "Xyla",
        "handle": "xyla",
        "description": "\"Gifts of time and love are surely the basic ingredients of a truly merry Christmas.” – Peg Bracken",
        "type": "Bouquets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Xyla-1a.jpg?v=1700551289",
            "/images/products/Xyla-2a.jpg?v=1700551289"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Pine Cone . Pine Leaf . "
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1019,
        "name": "Gwen",
        "handle": "gwen",
        "description": "Experience the timeless allure of our bouquet featuring 12 deep red roses, meticulously arranged in a pristine white round box. The rich red hues of the roses contrast beautifully with the clean, elegant backdrop, creating a visually striking display. Ideal for expressing love and passion, or as a sophisticated gift, this arrangement embodies both classic romance and modern aesthetics, presented in a stylish white round box.",
        "type": "Flower Boxes",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Gwen-2a-246230.jpg?v=1724158555",
            "/images/products/Gwen-1a-904286.jpg?v=1724158555"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "12 Roses"
            ]
        },
        "dimention": "Width*Height: 15cm*20cm"
    },
    {
        "id": 1020,
        "name": "Pink Poinsettia",
        "handle": "pink-poinsettia",
        "description": "“For it is in giving that we receive.” – Francis of Assisi",
        "type": "Bouquets",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Pink.P1-711671.jpg?v=1701688968"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Poinsettia"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1021,
        "name": "White Poinsettia",
        "handle": "white-poinsettia",
        "description": "“For it is in giving that we receive.” – Francis of Assisi",
        "type": "Bouquets",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/White.P1-180282.jpg?v=1701690040"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Poinsettia"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1022,
        "name": "Germaine",
        "handle": "germaine1",
        "description": "This elegant bouquet features pristine white tulips, exuding purity and grace. Wrapped in soft, neutral-toned paper, it creates a timeless and sophisticated look. Perfect for weddings, anniversaries, or any occasion where you want to convey elegance and simplicity. A beautiful choice for any floral gift.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Germaine-1a.jpg?v=1720777146",
            "/images/products/Germaine-2a.jpg?v=1720777146"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1023,
        "name": "Edith",
        "handle": "edith",
        "description": "This lovely bouquet features three delicate pink Gerbera daisies, accented with lush greenery and white baby's breath, wrapped in elegant brown paper. Perfect for any occasion, it brings a touch of freshness and charm to brighten someone's day.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Edith-1c.jpg?v=1720661652",
            "/images/products/Edith-2c-589226.jpg?v=1720788754"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Baby's Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1024,
        "name": "Giuliana",
        "handle": "giuliana",
        "description": "This vibrant bouquet features radiant sunflowers, striking blue hydrangeas, and lively orange roses, all complemented by fresh greenery. Wrapped in earthy brown paper, it captures the essence of joy and warmth, perfect for brightening any space or occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Giuliana-2a_b51b8952-de04-4323-9f0e-59b25425bb85.jpg?v=1721360947",
            "/images/products/Giuliana-1a.jpg?v=1721360947"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea . Sunflower . Spray Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1025,
        "name": "Red Poinsettia",
        "handle": "red-poinsettia",
        "description": "“For it is in giving that we receive.” – Francis of Assisi",
        "type": "Bouquets",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Red-Poinsettia-1a-309651.jpg?v=1702381902",
            "/images/products/Red-Poinsettia-2a-228335.jpg?v=1702381902"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Poinsettia"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1026,
        "name": "Cameron",
        "handle": "cameron",
        "description": "This elegant arrangement combines delicate pink carnations, soft meta roses, creamy white blooms, and lush greenery, accented with a fluffy chrysanthemum and sprigs of baby's breath. Nestled in a chic black hatbox adorned with a pink satin ribbon, this bouquet exudes sophistication and grace, making it a perfect choice for celebrating special moments or adding a touch of beauty to any space.",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Cameron-2a-212193.jpg?v=1726200286",
            "/images/products/Cameron-1a-520281.jpg?v=1726200287"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Carnations . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*25cm"
    },
    {
        "id": 1027,
        "name": "Bountiful (IV)",
        "handle": "bountiful-iv",
        "description": "Infuse charm into all grand business openings with our Cherry Blossom Charm Tripod Stand. Red Roses and Gerbera come together to create a delightful arrangement, symbolizing the charm and success of your venture.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Bountiful-_IV_-1a.jpg?v=1720104372",
            "/images/products/Grand-Opening-Card-2024_d709c93c-f9b0-4621-9c27-29ddf7f5bed1.jpg?v=1720104383",
            "/images/products/Bountiful-_IV_-2a.jpg?v=1720104372"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Gerbera . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*170cm"
    },
    {
        "id": 1028,
        "name": "Bountiful (V)",
        "handle": "bountiful-v",
        "description": "Embrace a sense of sophistication at all grand openings with our flower tripod stands, adorned in a captivating blue faux hydrangea theme and beautiful skirting. Radiating elegance, this arrangement signifies prosperity and new beginnings, enhancing the ambiance of your special occasion with a touch of timeless beauty and style.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Bountiful-_V_-1d.jpg?v=1720104522",
            "/images/products/Grand-Opening-Card-2024_35d45e90-468a-42cf-bb04-8f340732c6a6.jpg?v=1720104533",
            "/images/products/Bountiful-_V_-2d.jpg?v=1720104522"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Faux Hydrangeas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1029,
        "name": "Cross (I)",
        "handle": "cross-i",
        "description": "Express heartfelt sympathy with our Condolence Flower Tripod Stands. Impeccably shaped in a cross, pristine white chrysanthemums create a serene and dignified display. This elegant arrangement serves as a symbol of solace, honoring the departed with grace and offering comfort during times of sorrow.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "501.81",
        "inclTax": "501.81",
        "img": [
            "/images/products/Cross-_I_-1d.jpg?v=1720091298",
            "/images/products/Condolence-Card-2024_1_b532860e-7e5d-46a0-a692-0e3959ec0549.jpg?v=1720091304",
            "/images/products/Cross-_I_-2d.jpg?v=1720091298"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemum . Lilies . Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1030,
        "name": "Cross (II)",
        "handle": "cross-ii",
        "description": "In times of sorrow, our Condolence Flower Tripod Stands offer solace and grace. Shaped in a serene cross, pristine black chrysanthemums form a dignified and heartfelt tribute. This elegant arrangement symbolizes sympathy and pays a respectful homage to the departed, providing comfort during moments of grief.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "501.81",
        "inclTax": "501.81",
        "img": [
            "/images/products/Cross-_II_-1c.jpg?v=1720091650",
            "/images/products/Condolence-Card-2024_1_94e2360a-a2a1-4049-91d9-5964e9820533.jpg?v=1720091657",
            "/images/products/Cross-_II_-2c.jpg?v=1720091649"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemum . Lilies . Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1031,
        "name": "Cross (III)",
        "handle": "cross-iii",
        "description": "Extend heartfelt condolences with our Flower Tripod Stand featuring white chrysanthemums and delicate pink eustomas intricately shaped in a cross. This elegant arrangement offers solace, a tender expression of sympathy, and a comforting tribute to the departed during moments of grief and remembrance.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "501.81",
        "inclTax": "501.81",
        "img": [
            "/images/products/Cross-_III_-1f.jpg?v=1724292964",
            "/images/products/Condolence-Card-2024_1_6b3b98ae-8847-424c-a3e0-ee1fbd0cc74e.jpg?v=1724292970",
            "/images/products/Cross-_III_-2f.jpg?v=1724292964"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemum . Lilies . Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1032,
        "name": "Cross (IV)",
        "handle": "cross-iv",
        "description": "Express deepest sympathy with our Condolence Flower Tripod Stand, adorned in white chrysanthemums and champagne eustomas, delicately shaped in a cross. This elegant arrangement offers solace, a graceful tribute symbolizing compassion and remembrance during times of grief and loss.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "501.81",
        "inclTax": "501.81",
        "img": [
            "/images/products/Cross-_IV_-1c.jpg?v=1720091968",
            "/images/products/Condolence-Card-2024_1_af1a922b-5de7-40e9-8c30-aab249b962c6.jpg?v=1720091973",
            "/images/products/Cross-_IV_-2c.jpg?v=1720091969"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemum . Lilies . Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1033,
        "name": "Wealth (IX)",
        "handle": "wealth-ix",
        "description": "Embrace exotic elegance with our Exotic Elegance Tripod Stand. The combination of red Roses, Ginger Flowers, and Heliconia creates a visually stunning display that signifies sophistication and success for all grand business openings.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Wealth-_IX_-1d-235477.jpg?v=1720141035",
            "/images/products/Grand-Opening-Card-2024_50f0f236-6a14-48f3-ac47-f121fc4b04cc.jpg?v=1720141035",
            "/images/products/Wealth-_IX_-2d-853035.jpg?v=1720118593"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Eustoma . Matthiola . Anthurium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1034,
        "name": "心想事橙 Wishful Oranges CNY",
        "handle": "心想事橙-wishful-oranges",
        "description": "心想事橙 Wishful OrangesIn every orange, find the zest of hope and the sweetness of success. May these eight mandarins bring prosperity and fulfill your heart’s desires.",
        "type": "Hampers",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Wishful-Oranges1_c58aed85-17e9-4fb6-bb5b-144d60d511c7-739667.jpg?v=1704291411",
            "/images/products/Wishful-Oranges2_1d3621ea-0b5b-44fd-9cea-567303a6211a-727447.jpg?v=1704291411"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Mandarin Oranges x 8",
                "2. Round Cream Bloom Box – S"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 1035,
        "name": "大吉大利 Bountiful Oranges CNY",
        "handle": "大吉大利-bountiful-oranges",
        "description": "大吉大利 Bountiful OrangesEighteen oranges symbolizing abundant blessings. May this bountiful harvest bring immense joy and luck in all your endeavors. ",
        "type": "Hampers",
        "tags": [],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Bountiful-Oranges1_7d2c08de-f973-4c59-940b-99669230c2ec-835728.jpg?v=1704291411",
            "/images/products/Bountiful-Oranges2-855091.jpg?v=1704291412"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Mandarin Oranges x 18",
                "2. Red Carrier Box"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 1036,
        "name": "Harmony (III)",
        "handle": "harmony-iii",
        "description": "Celebrate triumph with our Majestic Fusion Floral Stand featuring Sunflowers, vibrant red  yellow gerberas. This captivating blend symbolizes prosperity and enthusiasm, creating a grand opening statement that resonates with elegance and success.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Harmony-_III_-1b.jpg?v=1720102599",
            "/images/products/Grand-Opening-Card-2024_a6d5d73c-8a97-4497-994c-55f4b7a44b59.jpg?v=1720102611",
            "/images/products/Harmony-_III_-2b.jpg?v=1720102599"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Gerberas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1037,
        "name": "Harmony (IV)",
        "handle": "harmony-iv",
        "description": "Celebrate triumph with our Majestic Fusion Floral Stand featuring vibrant red  Orange gerberas. This captivating blend symbolizes prosperity and enthusiasm, creating a grand opening statement that resonates with elegance and success.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Harmony-_IV_-1b.jpg?v=1720102758",
            "/images/products/Grand-Opening-Card-2024_0aa23260-7a2a-4408-8bd6-1a8acce23ee7.jpg?v=1720102763",
            "/images/products/Harmony-_IV_-2b.jpg?v=1720102758"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Red & Orange Gerberas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1038,
        "name": "Solace (V)",
        "handle": "solace-v",
        "description": "Expressing condolences with grace, our flower tripod stands feature serene white chrysanthemums  gerberra, delicate and elegant  champagne eustomas. This arrangement offers a gentle and comforting tribute, symbolizing compassion and remembrance, providing solace during moments of profound grief and reflection with tender beauty.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Solace-_V_-1b.jpg?v=1720143565",
            "/images/products/Condolence-Card-2024_1_98913f79-e933-4b33-93a5-4e7844fd2923.jpg?v=1720143569",
            "/images/products/Solace-_V_-2b.jpg?v=1720143565"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Eustoma . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 1039,
        "name": "吉祥如意 Auspicious Oranges CNY",
        "handle": "吉祥如意-auspicious-oranges-cny",
        "description": "吉祥如意 Auspicious OrangesEncased in a straw basket, these eighteen oranges carry the essence of auspiciousness. May fortune and happiness follow you like a shadow. ",
        "type": "Hampers",
        "tags": [],
        "price": "184.61",
        "inclTax": "184.61",
        "img": [
            "/images/products/Auspicious-Oranges1-299954.jpg?v=1704291412",
            "/images/products/Auspicious-Oranges2-371797.jpg?v=1704291412"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Mandarin Oranges x 18",
                "2. Straw Basket"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 1040,
        "name": "Solace (VI)",
        "handle": "solace-vi",
        "description": "Expressing condolences with grace, our flower tripod stands feature serene white  purple chrysanthemums. This arrangement offers a gentle and comforting tribute, symbolizing compassion and remembrance, providing solace during moments of profound grief and reflection with tender beauty.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Solace-_VI_-1e.jpg?v=1720147374",
            "/images/products/Condolence-Card-2024_1_6cd4226c-2f21-4223-81f3-30a61b111b9c.jpg?v=1720147399",
            "/images/products/Solace-_VI_-2e.jpg?v=1720147374"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemums . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 1041,
        "name": "橙心如意 Orange Treasures CNY",
        "handle": "橙心如意-orange-treasures",
        "description": "橙心如意 Orange TreasuresTreasure every moment, cherish every joy. May these eighteen mandarins in the carrier basket be a beacon of good fortune and heartfelt wishes.",
        "type": "Hampers",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Orange-Treasures1_74ced95a-8369-4fff-b1e3-97d93bfe3f75-225996.jpg?v=1704291412",
            "/images/products/Orange-Treasures2_ad2ffee0-dc9b-4d3d-9d53-fc2305df3699-540461.jpg?v=1704291412"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Mandarin Oranges x 18",
                "2. Carrier Basket"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 1042,
        "name": "Solace (VII)",
        "handle": "solace-vii",
        "description": "In solemn condolence, our flower stands feature vibrant orange orchids, pure white chrysanthemums, and exotic bird of paradise blooms. This elegant arrangement offers a dignified tribute, symbolizing compassion and remembrance, providing solace during moments of profound grief and reflection with tropical beauty.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Solace-_VII_-1d.jpg?v=1720147883",
            "/images/products/Condolence-Card-2024_1_0124311d-8c46-4d6e-9a54-0694f6ce24a1.jpg?v=1720147890",
            "/images/products/Solace-_VII_-2d.jpg?v=1720147883"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemums . Orchids . Bird of Paradise . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 1043,
        "name": "Solace (VIII)",
        "handle": "solace-viii",
        "description": "In heartfelt condolence, our flower tripod stands elegantly blend yellow and white hues, featuring tender gerberas, pure white chrysanthemums, delicate eustomas, and soft roses. Adorned in a tasteful white skirting, this arrangement provides a dignified tribute, symbolizing compassion and remembrance during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Solace-_VIII_-1b.jpg?v=1720142612",
            "/images/products/Condolence-Card-2024_1_ac512586-eb4b-45d8-9bd4-76f09a685ac8.jpg?v=1720142619",
            "/images/products/Solace-_VIII_-2b.jpg?v=1720142612"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemums . Gerbera . Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 1044,
        "name": "吉隆之喜 Prosperous Oranges CNY",
        "handle": "吉隆之喜-prosperous-oranges-cny",
        "description": "吉隆之喜 Prosperous OrangesIn this golden basket, eighteen oranges gleam like nuggets of prosperity. May they usher in a year filled with wealth, health, and happiness.",
        "type": "Hampers",
        "tags": [],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Prosperous-Oranges1-418008.jpg?v=1704291412",
            "/images/products/Prosperous-Oranges2-569836.jpg?v=1704291412"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Mandarin Oranges x 18",
                "2. Golden Basket"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 1045,
        "name": "橙心橙意 Blissful Oranges CNY",
        "handle": "橙心橙意-blissful-oranges-cny",
        "description": "橙心橙意 Blissful Oranges CNYBliss is found in simple pleasures, like these eighteen mandarins in a CNY leather basket. May they bring contentment and peace, filling your year with serenity.",
        "type": "Hampers",
        "tags": [],
        "price": "234.82",
        "inclTax": "234.82",
        "img": [
            "/images/products/Blissful-Oranges1-297357.jpg?v=1704291412",
            "/images/products/Blissful-Oranges2-245046.jpg?v=1704291412"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Mandarin Oranges x 18",
                "2. CNY Leather Basket (S)"
            ]
        },
        "dimention": "As Per Photo Shown."
    },
    {
        "id": 1046,
        "name": "Solace (IX)",
        "handle": "solace-ix",
        "description": "In sincere condolence, our flower tripod stands gracefully merge blue and white tones, adorned with gentle gerberas, pure white chrysanthemums, delicate eustomas, faux hydrangeas, and elegant gerberas. Dressed in a tasteful white skirting, this arrangement offers a dignified tribute, symbolizing compassion and remembrance during moments of profound grief and reflection.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "730.01",
        "inclTax": "730.01",
        "img": [
            "/images/products/Solace-_IX_-1b.jpg?v=1720143257",
            "/images/products/Condolence-Card-2024_1_8c993c87-cc26-428e-b766-462219197946.jpg?v=1720143262",
            "/images/products/Solace-_IX_-2b.jpg?v=1720143258"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Faux Hydrangeas . Chrysanthemums . Gerbera . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 1047,
        "name": "Bountiful (VI)",
        "handle": "bountiful-vi",
        "description": "Celebrate all grand openings with our flower tripod stands featuring a cheerful yellow and white theme. Adorned in a beautiful skirting, this vibrant arrangement symbolizes joy and success, creating a welcoming atmosphere for your special occasion. Elevate your event with the beauty of fresh flowers and the promise of new beginnings.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Bountiful-_VI_-1b.jpg?v=1720102900",
            "/images/products/Grand-Opening-Card-2024_609a8150-615b-4072-a7a0-68609124401a.jpg?v=1720102914",
            "/images/products/Bountiful-_VI_-2b.jpg?v=1720102900"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1048,
        "name": "Bountiful (VII)",
        "handle": "bountiful-vii",
        "description": "Transform all grand openings into a visually stunning event with our flower tripod stands in a pastel pink theme, accentuated by beautiful orange and pink skirting. Symbolizing prosperity and elegance, this arrangement sets the stage for a memorable and successful celebration of your new business endeavor.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Bountiful-_VII_-1b.jpg?v=1720103065",
            "/images/products/Grand-Opening-Card-2024_5c7071bd-58aa-40ad-a71c-3f849ddbe5fb.jpg?v=1720103084",
            "/images/products/Bountiful-_VII_-2b.jpg?v=1720103065"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*170cm"
    },
    {
        "id": 1049,
        "name": "Bountiful (VIII)",
        "handle": "bountiful-viii",
        "description": "Transform all grand openings into a visually stunning event with our flower tripod stands in a pastel pink theme, accentuated by beautiful orange and pink skirting. Symbolizing prosperity and elegance, this arrangement sets the stage for a memorable and successful celebration of your new business endeavor.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Bountiful-_VIII_-1b.jpg?v=1720103183",
            "/images/products/Grand-Opening-Card-2024_0919008f-de9b-4bbc-941e-baf042be7c5b.jpg?v=1720103204",
            "/images/products/Bountiful-_VIII_-2b.jpg?v=1720103183"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Roses . Sunflowers . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1050,
        "name": "Joy Abundance 丰衣足食 CNY",
        "handle": "joy-abundance-丰衣足食-cny",
        "description": "Joy Abundance 丰衣足食",
        "type": "Hampers",
        "tags": [],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Joy-Abundance-_-CNY1-934880.jpg?v=1704463727",
            "/images/products/Joy-Abundance-_-CNY2-617598.jpg?v=1704463727"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Bird Nest A.Ginseng With White Fungus＆ Rock Sugar 150ml",
                "2. Oolong Tea 375g",
                "3. Premium Pu’Er Tea 40g",
                "4. Souper Tang Herbal Soup- Vitality In A Bowl ",
                "45g x 2",
                "5. Packaging & Decorative",
                "\n– 冰糖泡参雪耳燕窝 150ml",
                "– 乌龙茶 375g",
                "– 普洱茶 40g",
                "– 汤师父养生靓汤 45g x 2",
                "– 精美包装与装饰",
                ""
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1051,
        "name": "Elegance Abundance 五谷丰登 CNY",
        "handle": "elegance-abundance-五谷丰登-cny",
        "description": "Elegance Abundance 五谷丰登",
        "type": "Hampers",
        "tags": [],
        "price": "472.15",
        "inclTax": "472.15",
        "img": [
            "/images/products/Elegance-Abundance-_-CNY1-601489.jpg?v=1704463373",
            "/images/products/Elegance-Abundance-_-CNY2-705463.jpg?v=1704463373"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "\n\t➧ 陈师傅绿茶发财鱼生\t➧ X.O 埃菲尔铁塔白兰地 20cl\t➧ 红酒 75cl\t➧ 人参银耳冰糖燕窝 6x70ml\t➧ 大家发卤水鲍鱼425g\t➧ 鲍今天卤水鲍鱼425g\t➧ 盒装铁观音 25包\t➧ 精美贺年礼篮\n\t– Chef Chen’s Green Tea Prosperity Yu Sheng\t– XO Effiel Tower French Brandy 20cl\t– Vino Fernandez Merlot 75cl\t– Bird’s Nest with Ginseng, White Fungus & Rock Sugar 6x70ml\t– 大家发 Abalone in Brine 425g\t– 鲍今天 Jade Abalone in Brine 425g\t– Tie Guan Yin Tea in Tin\t– Exquisite CNY Packaging"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1052,
        "name": "Sparkle Abundance 丰财聚宝 CNY",
        "handle": "sparkle-abundance-丰财聚宝-cny",
        "description": "Sparkle Abundance 丰财聚宝",
        "type": "Hampers",
        "tags": [],
        "price": "561.14",
        "inclTax": "561.14",
        "img": [
            "/images/products/Sparkle-Abundance-_-CNY1-457332.jpg?v=1704464823",
            "/images/products/Sparkle-Abundance-_-CNY2-367729.jpg?v=1704464823"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Souper Tang Bird Nest With Collagen 150ml x 3",
                "2. Bird Nest A.Ginseng With White Fungus＆Rock Sugar 150ml",
                "3. Souper Tang Premium Homemade Noodle 200g",
                "4. Korean Ginseng Candy 100gm",
                "5. Souper Tang American Ginseng Tea 3g x 6",
                "6. Premium Pu’Er Tea 40g",
                "7. Souper Tang Herbal Soup- Vitality In A Bowl ",
                "45g x 2",
                "8. Souper Tang Abalone 425g",
                "9. Red Wine – Montier 750ml",
                "10. Wild Ginseng 1pc",
                "11. Packaging & Decorative",
                "\n– 胶原蛋白燕窝 150ml x 3",
                "– 冰糖泡参雪耳燕窝 150ml",
                "– 膳面线 200g",
                "– 高丽人参糖 100g",
                "– 汤师父花旗泡参茶 3g x 6",
                "– 普洱茶 40g",
                "– 汤师父养生靓汤 45g x 2",
                "– 汤师父精选鲍鱼 425g",
                "– 高级红酒 750ml",
                "– 野山人参 1支",
                "– 精美包装与装饰",
                ""
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1053,
        "name": "一帆风顺 Auspicious Cellar CNY",
        "handle": "一帆风顺-auspicious-cellar-cny",
        "description": "一帆风顺 Auspicious Cellar",
        "type": "Hampers",
        "tags": [],
        "price": "278.18",
        "inclTax": "278.18",
        "img": [
            "/images/products/Auspicious-Cellar-CNY1-388537.jpg?v=1704465150",
            "/images/products/Auspicious-Cellar-CNY2-990018.jpg?v=1704465150"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Mandarin Oranges x 8",
                "2. Bellevie Pavillon Merlot 75cl",
                "3. Chateau Fleurs Vignobles 75cl",
                "4. Red Woven Basket",
                "\n– 柑橘 8粒",
                "– 红酒 75cl",
                "– 红酒 75cl",
                ""
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1054,
        "name": "新岁献瑞 Celebratory Cellar CNY",
        "handle": "新岁献瑞-celebratory-cellar-cny",
        "description": "新岁献瑞 Celebratory CellarLeap towards success with this hamper featuring eight mandarins, Chateau Les Martineaux Bordeaux, abalone, and bird’s nest in a carrier basket. May it mark the start of a year filled with extraordinary achievements.",
        "type": "Hampers",
        "tags": [],
        "price": "301.00",
        "inclTax": "301.00",
        "img": [
            "/images/products/Celebratory-Cellar-CNY1-109652.jpg?v=1704465151",
            "/images/products/Celebratory-Cellar-CNY2.jpg?v=1704338500"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Mandarin Oranges x 8",
                "2. Chateau Les Martineaux Bordeaux 75cl",
                "3. Kinohimitsu Imperial Braised Japanese Abalone 425g",
                "4. Kinohimitsu Longan & Wolfberry Bird’s Nest 150ml",
                "5. Carrier Basket",
                "\n– 柑橘 8粒",
                "– 红酒 75cl",
                "– Kinohimitsu 御皇红烧日本鲍鱼 425g",
                "– Kinohimitsu 龙眼枸杞燕窝 150ml",
                ""
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1055,
        "name": "龙腾虎跃 Prosperous Cellar CNY",
        "handle": "龙腾虎跃-prosperous-cellar-cny",
        "description": "龙腾虎跃 Prosperous CellarProsperity leaps like dragons and tigers with this selection of eight mandarins, De Valier Rosso, abalone, and bird’s nest in a carrier basket. Here’s to a year of robust health and wealth.",
        "type": "Hampers",
        "tags": [],
        "price": "344.58",
        "inclTax": "344.58",
        "img": [
            "/images/products/Prosperous-Cellar-CNY1-859246.jpg?v=1704465152",
            "/images/products/Prosperous-Cellar-CNY2.jpg?v=1704344769"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Mandarin Oranges x 8",
                "2. De Valier Rosso 75cl",
                "3. Skylight Braised Amidori Abalone 425g",
                "4. Kinohimitsu Longan & Wolfberry Bird’s Nest 150ml",
                "5. Carrier Basket",
                "\n– 柑橘 8粒",
                "– 红酒 75cl",
                "– 天亮牌高级红烧青深鲍鱼 425g",
                "– Kinohimitsu 龙眼枸杞燕窝 150ml",
                ""
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1056,
        "name": "Bountiful (IX)",
        "handle": "bountiful-ix",
        "description": "Elevate your grand opening with our exquisite flower tripod stand in a captivating blue theme. A harmonious blend of hydrangeas, roses, orchids, eustoma, and matthiolas creates a stunning arrangement, symbolizing prosperity and elegance. Make a bold statement and usher in success with this enchanting floral display.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Bountiful-_IX_-1c.jpg?v=1720103380",
            "/images/products/Grand-Opening-Card-2024_3d275fe7-9c56-4e16-bc94-ab2d743bb7b3.jpg?v=1720103390",
            "/images/products/Bountiful-_VIII_-2c.jpg?v=1720103380"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Faux Hydrangeas . Roses . Eustoma . Orchids . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1057,
        "name": "Bountiful (X)",
        "handle": "bountiful-x",
        "description": "Unveil the grandeur of all grand openings with our stunning flower tripod stand in a captivating hot pink theme. Lush roses and elegant eustoma are artfully arranged, accentuated by a delicate pink skirting. This vibrant display symbolizes prosperity and joy, setting the perfect tone for your auspicious beginning.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "844.11",
        "inclTax": "844.11",
        "img": [
            "/images/products/Bountiful-_X_-1b.jpg?v=1720103558",
            "/images/products/Grand-Opening-Card-2024_a3593e09-9479-4b38-9c58-dc007257773b.jpg?v=1720103571",
            "/images/products/Bountiful-_X_-2b.jpg?v=1720103558"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1058,
        "name": "祥云瑞气 Abundant Celler CNY",
        "handle": "祥云瑞气-abundant-celler-cny",
        "description": "祥云瑞气 Abundant CellerOverflowing with abundance, this hamper of eighteen mandarins, Vino Fernandez Merlot, and Chateau Fleurs Vignobles in a gold basket, signifies a year brimming with fortune and vitality.",
        "type": "Hampers",
        "tags": [],
        "price": "392.28",
        "inclTax": "392.28",
        "img": [
            "/images/products/Abundant-Celler-CNY1-749937.jpg?v=1704465154",
            "/images/products/Abundant-Celler-CNY2-935935.jpg?v=1704465154"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Mandarin Oranges x 18",
                "2. Vino Fernandez Merlot 75cl",
                "3. Chateau Fleurs Vignobles 75cl",
                "4. Gold Basket",
                "\n– 柑橘 18粒",
                "– 红酒 75cl",
                "– 红酒 75cl",
                ""
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1059,
        "name": "喜气腾腾 Golden Celler CNY",
        "handle": "喜气腾腾-golden-celler-cny",
        "description": "喜气腾腾 Golden CellerElevate celebrations with eight mandarins and Chivas Regal 12 Years in a leather basket, symbolizing a golden year filled with joy and prestigious achievements.",
        "type": "Hampers",
        "tags": [],
        "price": "485.84",
        "inclTax": "485.84",
        "img": [
            "/images/products/Golden-Celler-CNY1-484066.jpg?v=1704465150",
            "/images/products/Golden-Celler-CNY2-472585.jpg?v=1704465150"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Mandarin Oranges x 8",
                "2. Chivas Regal 12 Years 70cl",
                "3. CNY Leather Basket (L)",
                "\n– 柑橘 8粒",
                "– 12年芝华士 70cl",
                ""
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1060,
        "name": "龙凤呈祥 Wealthy Cellar CNY",
        "handle": "龙凤呈祥-wealthy-cellar-cny",
        "description": "喜气腾腾 Golden CellerElevate celebrations with eight mandarins and Chivas Regal 12 Years in a leather basket, symbolizing a golden year filled with joy and prestigious achievements.",
        "type": "Hampers",
        "tags": [],
        "price": "654.71",
        "inclTax": "654.71",
        "img": [
            "/images/products/Wealthy-Cellar-CNY1-550158.jpg?v=1704465153",
            "/images/products/Wealthy-Cellar-CNY2-937190.jpg?v=1704465153"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Mandarin Oranges x 8",
                "2. Martell Cognac VSOP 70cl",
                "3. CNY Leather Basket (L)",
                "\n– 柑橘 8粒",
                "– 馬爹利 VSOP干邑 70cl",
                ""
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1061,
        "name": "Harmony (V)",
        "handle": "harmony-v",
        "description": "Elevate all grand openings with the radiant warmth of our orange-themed flower tripod stand. Sunflowers take center stage, complemented by the elegance of roses and eustoma, all set against a backdrop of vibrant orange skirting. This exquisite arrangement symbolizes success, joy, and the blossoming of new beginnings.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Harmony-_V_-1d.jpg?v=1721525072",
            "/images/products/Grand-Opening-Card-2024_93b8f907-1616-40e7-af31-1a9c7da4f4c3.jpg?v=1721525081",
            "/images/products/Harmony-_V_-2d.jpg?v=1721525071"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Roses . Eustoma . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1062,
        "name": "Golden Lohei 皆大欢喜 CNY",
        "handle": "golden-lohei-皆大欢喜-cny",
        "description": "Golden Lohei 皆大欢喜Celebrate a symphony of flavors with Golden Lohei. Chef Chen’s Green Tea Prosperity Yu Sheng sets the stage for joyous gatherings, complemented by nourishing bird’s nest, succulent top shell in abalone sauce, and more. Toss high for a year filled with golden moments.",
        "type": "Hampers",
        "tags": [],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/Golden-Lohei-_-CNY1-722328.jpg?v=1704463536",
            "/images/products/Golden-Lohei-_-CNY2-752876.jpg?v=1704463536"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Chef Chen’s Green Tea Prosperity Yu Sheng",
                "2. Double Swallow Bird’s Nest with Ginseng, White Fungus & Rock Sugar 3x70ml",
                "3. New Moon Braised King Top Shell In Abalone Sauce 425g",
                "4. New Moon Buddha Jump Over The Wall 425g",
                "5. New Moon Fish Maw Soup 400g",
                "6. Camel Shandong Groundnuts 120g",
                "7. Exquisite CNY Packaging",
                "\n–陈师傅绿茶发财鱼生",
                "–双燕人参银耳冰糖燕窝 70ml x 3",
                "–人月红烧鲍鱼汁香鲍 425g",
                "–人月佛跳墙 425g",
                "–人月鱼鳔汤 400g",
                "–山东花生 120g",
                "–精美贺年礼篮",
                ""
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1063,
        "name": "Radiant Lohei 佳节团圆 CNY",
        "handle": "radiant-lohei-佳节团圆-cny",
        "description": "Radiant Lohei 佳节团圆Radiant Lohei brings the warmth of reunion. With Chef Chen’s unique Yu Sheng, sparkling juice, and a selection of gourmet treasures, it’s a perfect blend for festive radiance. Toss to the heights of happiness and familial harmony.",
        "type": "Hampers",
        "tags": [],
        "price": "351.20",
        "inclTax": "351.20",
        "img": [
            "/images/products/Radiant-Lohei-_-CNY1-789172.jpg?v=1704463901",
            "/images/products/Radiant-Lohei-_-CNY2-312322.jpg?v=1704463901"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Chef Chen’s Green Tea Prosperity Yu Sheng",
                "2. Royal Select Sparkling Juice 750ml",
                "3. Bird’s Nest with Ginseng, White Fungus & Rock Sugar 6x70ml",
                "4. 大家发 Abalone in Brine 425g",
                "5. 财神 Fortune Sea Asparagus in Brine 425g",
                "6. 财神 Fortune Razor Clam 425g",
                "7. Tie Guan Yin Tea Box 25s’",
                "8. Exquisite CNY Packaging",
                "\n–陈师傅绿茶发财鱼生",
                "–皇家精选气泡果汁 750ml",
                "–人参银耳冰糖燕窝 6x70ml",
                "–大家发卤水鲍鱼425g",
                "–财神清汤鲍筍 425g",
                "–财神贵妃贝 425g",
                "–盒装铁观音 25包",
                "–精美贺年礼篮",
                ""
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1064,
        "name": "Harmony (VI)",
        "handle": "harmony-vi",
        "description": "Celebrate triumph with our Majestic Fusion Floral Stand featuring Sunflowers  Orange Roses. This captivating blend symbolizes prosperity and enthusiasm, creating a grand opening statement that resonates with elegance and success.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Harmony-_VI_-1c.jpg?v=1720523881",
            "/images/products/Grand-Opening-Card-2024_e67ef4db-9cb4-4163-b49a-532599651fa9.jpg?v=1720523887",
            "/images/products/Harmony-_VI_-2c.jpg?v=1720523881"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Brassica . Roses . Anthurium . Mum . Faux Delphinium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 70cm*170cm"
    },
    {
        "id": 1065,
        "name": "Bountiful Lohei 多福多寿 CNY",
        "handle": "bountiful-lohei-多福多寿-cny",
        "description": "Bountiful Lohei 多福多寿Embrace abundance with Bountiful Lohei. The Green Tea Prosperity Yu Sheng, paired with fine wine, imperial abalone, and luxurious bird’s nest, symbolizes a cornucopia of blessings. Toss for a year of plentiful health and longevity.",
        "type": "Hampers",
        "tags": [],
        "price": "396.84",
        "inclTax": "396.84",
        "img": [
            "/images/products/Bountiful-Lohei-_-CNY1-578396.jpg?v=1704722871",
            "/images/products/Bountiful-Lohei-_-CNY2-629710.jpg?v=1704722871"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Chef Chens Green Tea Prosperity Yu Sheng",
                "2.  Bellevie Pavillon Merlot 75cl",
                "3.  鲍发 Abalone in Brine 425g",
                "4.  Double Swallow Birds Nest with Ginseng, White Fungus & Rock Sugar 2x70ml",
                "5.  Yu Xiang Yan White Fungus Series Birds Nest 3s 75ml",
                "6.  Yu Xiang Yan Abalone Buddha Jumps Over The Wall 425g",
                "7.  Exquisite CNY Packaging",
                "\n–陈师傅绿茶发财鱼生",
                "–红酒 75cl",
                "–鲍发 Abalone in Brine 425g",
                "–双燕人参银耳冰糖燕窝 70ml x 2",
                "–宇鄕燕银耳燕窝系列 75ml x 3",
                "–宇鄕燕鲍鱼佛跳墙 425g",
                "–精美贺年礼篮"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1066,
        "name": "Elegant Lohei 富贵有余 CNY",
        "handle": "elegant-lohei-富贵有余-cny",
        "description": "Elegant Lohei 富贵有余Indulge in the elegance of festive celebrations with Elegant Lohei. Featuring Chef Chen’s Yu Sheng, exquisite X.O Brandy, and a range of premium delicacies, it’s a toast to prosperity and grace. Elevate your celebrations with each toss.",
        "type": "Hampers",
        "tags": [],
        "price": "501.81",
        "inclTax": "501.81",
        "img": [
            "/images/products/Elegant-Lohei-_-CNY1-581116.jpg?v=1704723039",
            "/images/products/Elegant-Lohei-_-CNY2-990047.jpg?v=1704723039"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Chef Chen’s Green Tea Prosperity Yu Sheng",
                "2. X.O Brandy Marquis 35cl",
                "3. Bird’s Nest with Ginseng, White Fungus & Rock Sugar 6x70ml",
                "4. 大家发 Abalone in Brine 425g",
                "5. 财神 Fortune Razor Clam 425g",
                "6. Tie Guan Yin Tea Box 25s’",
                "7. Exquisite CNY Packaging",
                "\n–陈师傅绿茶发财鱼生",
                "–X.O 白兰地侯爵 35cl",
                "–人参银耳冰糖燕窝 6x70ml",
                "–大家发卤水鲍鱼425g",
                "–财神贵妃贝 425g",
                "–盒装铁观音 25包",
                "–精美贺年礼篮",
                ""
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1067,
        "name": "Gorgeous Lohei 鱼跃龙门 CNY",
        "handle": "gorgeous-lohei-鱼跃龙门-cny",
        "description": "Gorgeous Lohei 鱼跃龙门Gorgeous Lohei epitomizes the pinnacle of celebration. With a luxurious mix of Chef Chen’s Yu Sheng, French Brandy, Merlot, and select abalones, it’s a grand gesture for a prosperous year ahead. Toss towards a year of soaring success and happiness.",
        "type": "Hampers",
        "tags": [],
        "price": "572.55",
        "inclTax": "572.55",
        "img": [
            "/images/products/Grand-Lohei-_-CNY1-222658.jpg?v=1704723162",
            "/images/products/Grand-Lohei-_-CNY2-933827.jpg?v=1704723162"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Chef Chen’s Green Tea Prosperity Yu Sheng",
                "2. XO Effiel Tower French Brandy 20cl",
                "3. Vino Fernandez Merlot 75cl",
                "4. Bird’s Nest with Ginseng, White Fungus & Rock Sugar 6x70ml",
                "5. 大家发 Abalone in Brine 425g",
                "6. 鲍今天 Jade Abalone in Brine 425g",
                "7. Tie Guan Yin Tea in Tin",
                "8. Exquisite CNY Packaging",
                "\n–陈师傅绿茶发财鱼生",
                "–X.O 埃菲尔铁塔白兰地 20cl",
                "–红酒 75cl",
                "–人参银耳冰糖燕窝 6x70ml",
                "–大家发卤水鲍鱼425g",
                "–鲍今天卤水鲍鱼425g",
                "–盒装铁观音 25包",
                "–精美贺年礼篮",
                ""
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1068,
        "name": "Joyful Reunion 合家团圆 CNY",
        "handle": "joyful-reunion-合家团圆-cny",
        "description": "Joyful Reunion 合家团圆Spread warmth and joy with your loved one during the upcoming auspicious holiday with a beautiful feative theme box consisting rejuvenating birdnest, mouth watering cookies and other sweet treats.",
        "type": "Hampers",
        "tags": [],
        "price": "200.59",
        "inclTax": "200.59",
        "img": [
            "/images/products/Joyful-Reunion-_-CNY1-402202.jpg?v=1704983089",
            "/images/products/Joyful-Reunion-_-CNY2-907714.jpg?v=1704983089"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Bird’s Nest with Ginseng, White Fungus & Rock Sugar 3x70ml",
                "2. Yam Cookies 80g",
                "3. New Peng Hiang Shrimp Roll 80g",
                "4. Hokkaido Love Pudding 50g",
                "5. Camel Shandong Groundnuts 120g",
                "6. Kueh Bangkit Pandan Flavour 60g",
                "7. Beryl’s Tiramisu Almond Milk Chocolate 65g",
                "8. Royal Family Pineapple Cake 138g",
                "9. Exquisite CNY Packaging",
                "\n–人参银耳冰糖燕窝 3x70ml",
                "–香芋曲奇 80g",
                "–新品香香酥虾米卷 80g",
                "–北海道爱心布丁 50g",
                "–山东花生 120g",
                "–班兰味薯粉饼 60g",
                "–提拉米苏杏仁牛奶巧克力 65g",
                "–凤梨酥 138g",
                "–精美贺年包装",
                ""
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1069,
        "name": "Blissful Reunion 圆满丰收 CNY",
        "handle": "blissful-reunion-圆满丰收-cny",
        "description": "Blissful Reunion 圆满丰收This exquisite food hamper is perfect for celebrating the Lunar New Year. Consisting of revitalising bird’s nest, tasty pineapple tarts and other savoury treats, our basket of love is made to be shared among family members and friends.",
        "type": "Hampers",
        "tags": [],
        "price": "246.23",
        "inclTax": "246.23",
        "img": [
            "/images/products/Blissful-Reunion-_-CNY1-468979.jpg?v=1705501798",
            "/images/products/Blissful-Reunion-_-CNY2-742816.jpg?v=1705459290"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Royal Select Sparkling Juice 750ml",
                "2. Bird’s Nest with Ginseng, White Fungus & Rock Sugar 3x70ml",
                "3. Kinohimitsu Longan & Wolfberry Bird’s Nest 150ml",
                "4. Nanyang Aromatic Black Grain Milk Tea 6s’ x 65g",
                "5. Premium Lava Custard Cookies 96g",
                "6. Delfi Almond Chocolate 180g",
                "7. CNY Pineapple Tarts 102g",
                "8. Crispy Fresh Milk Cakes 36g",
                "9. Mahjong Juice Candy 100g",
                "10. Kueh Bangkit Pandan Flavour 60g",
                "11. Exquisite CNY Packaging",
                "\n–皇家精选气泡果汁 750ml",
                "–人参银耳冰糖燕窝 3x70ml",
                "–Kinohimitsu 龙眼枸杞燕窝 150ml",
                "–南洋 芳香黑五谷代餐奶茶 6 x 65g",
                "–熔岩奶油曲奇 96g",
                "–德尔菲巧克力 180g",
                "–凤梨酥 102g",
                "–香脆鲜奶糕 36g",
                "–趣味麻将软糖 100g",
                "–班兰味薯粉饼 60g",
                "–精美贺年包装",
                ""
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1070,
        "name": "Peace (VII)",
        "handle": "peace-vii",
        "description": "Express sympathy and solace with our Condolence Flower Stand featuring a serene blend of purple chrysanthemums and white gerbera daisies. Symbolizing peace and purity, the arrangement offers comfort during difficult times. The gentle hues convey heartfelt condolences, creating a dignified tribute to honor and remember a cherished life with grace and beauty.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Peace-_VII_-1c.jpg?v=1720188598",
            "/images/products/Condolence-Card-2024_1_2ef9422a-9e24-46dd-bd70-44eacf11206a.jpg?v=1720188614",
            "/images/products/Peace-_VII_-2c.jpg?v=1720188597"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Mum . Artificial Lilies . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 120cm*180cm"
    },
    {
        "id": 1071,
        "name": "Season of Giving Hamper #1 爱心献礼 #1 CNY",
        "handle": "season-of-giving-hamper-1-爱心献礼-1-cny",
        "description": "Season of Giving Hamper #1 爱心献礼 #1",
        "type": "Hampers",
        "tags": [],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Season-of-Giving-Hamper-_1-_-_1-CNY1-654576.jpg?v=1705415829",
            "/images/products/Season-of-Giving-Hamper-_1-_-_1-CNY2-187763.jpg?v=1705415829"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Flour Power Sea Salt black Sesame Cookies Pouch 100g",
                "2. Flour Power Pineapple Ball in a Jar 15pcs",
                "3. Ang Baos",
                "4. Kinohimitsu Longan & Wolfberry Bird’s Nest 150ml",
                "5. CNY Round Gift Box (S)"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1072,
        "name": "Season of Giving Hamper #2 爱心献礼 #2 CNY",
        "handle": "season-of-giving-hamper-2-爱心献礼-2-cny",
        "description": "Season of Giving Hamper #2 爱心献礼 #2",
        "type": "Hampers",
        "tags": [],
        "price": "246.23",
        "inclTax": "246.23",
        "img": [
            "/images/products/Season-of-Giving-Hamper-_2-_-_2-CNY1-770498.jpg?v=1705415829",
            "/images/products/Season-of-Giving-Hamper-_2-_-_2-CNY2-553479.jpg?v=1705415829"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Flour Power Oatmeal Cranberry Cookies Pouch 100g",
                "2. Flour Power Sea Salt black Sesame Cookies Pouch 100g",
                "3. Flour Power Pineapple Ball in a Jar 15pcs",
                "4. Tie Guan Yin Tea In Pink Tin",
                "5.  Kinohimitsu Longan & Wolfberry Bird’s Nest 150ml x 2",
                "6. Ang Baos",
                "7. Brown Basket"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1073,
        "name": "Peace (VIII)",
        "handle": "peace-viii",
        "description": "Express sympathy and solace with our Condolence Flower Stand, adorned with serene pink gerbera daisies and deep purple chrysanthemums, offers a heartfelt expression of sympathy and comfort during difficult times. The gentle, soothing colors convey a sense of peace and remembrance, creating a dignified tribute to honor and cherish the memory of a loved one.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Peace-_VIII_-1c.jpg?v=1720155204",
            "/images/products/Condolence-Card-2024_1_ee423321-7da7-4d64-8e1d-e7dcc5da664d.jpg?v=1720155211",
            "/images/products/Peace-_VIII_-2c.jpg?v=1720155204"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Mum . Artificial Lilies . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 120cm*180cm"
    },
    {
        "id": 1074,
        "name": "Peace (IX)",
        "handle": "peace-ix",
        "description": "Introducing our Sympathy Serenity Flower Stand, a graceful tribute in a black and white theme. Majestic white chrysanthemums symbolize purity, while the elegant black gerbera daisies convey solemnity and respect. This arrangement, thoughtfully designed for condolence, offers solace and a dignified farewell, expressing sympathy with timeless grace and understated beauty.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Peace-_IX_-1d.jpg?v=1720188281",
            "/images/products/Condolence-Card-2024_1_ecf417b3-3b96-4d2f-8867-5361941aa16a.jpg?v=1720188292",
            "/images/products/Peace-_IX_-2d.jpg?v=1720188281"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Gerbera . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 1075,
        "name": "Season of Giving Hamper #3 爱心献礼 #3 CNY",
        "handle": "season-of-giving-hamper-3-爱心献礼-3-cny",
        "description": "Season of Giving Hamper #3 爱心献礼 #3 ",
        "type": "Hampers",
        "tags": [],
        "price": "269.05",
        "inclTax": "269.05",
        "img": [
            "/images/products/Season-of-Giving-Hamper-_3-_-_3-CNY1-219716.jpg?v=1705503087",
            "/images/products/Season-of-Giving-Hamper-_3-_-_3-CNY2-660024.jpg?v=1705503087"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Flour Power Oatmeal Cranberry Cookies Pouch 100g",
                "2. Flour Power Sea Salt black Sesame Cookies Pouch 100g",
                "3. Flour Power Pineapple Ball in a Jar 15pcs",
                "4. Kinohimitsu Longan & Wolfberry Bird’s Nest 150ml x 2",
                "5. Tie Guan Yin Tea in Red Tin",
                "6. Fortune White Cat",
                "7. Golden Chest Box",
                "8. Ang Baos"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1076,
        "name": "Sympathy (IX)",
        "handle": "sympathy-ix",
        "description": "In moments of sorrow, our Sympathy Serenity Flower Stand offers solace. Crisp white orchids, delicate anthuriums, and serene chrysanthemums create a tranquil tribute. This elegant arrangement conveys heartfelt condolences, expressing comfort and compassion during difficult times with a timeless palette of pure and soothing white blooms.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Sympathy-_IX_-1d.jpg?v=1720188438",
            "/images/products/Condolence-Card-2024_1_a3c791db-6334-4a58-b325-be8f3481fd16.jpg?v=1720188469",
            "/images/products/Sympathy-_IX_-2d.jpg?v=1720188437"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemums . Orchids . Anthurium . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 1077,
        "name": "Season of Giving Hamper #4 爱心献礼 #4 CNY",
        "handle": "season-of-giving-hamper-4-爱心献礼-4-cny",
        "description": "Season of Giving Hamper #4 爱心献礼 #4",
        "type": "Hampers",
        "tags": [],
        "price": "314.69",
        "inclTax": "314.69",
        "img": [
            "/images/products/Season-of-Giving-Hamper-_4-_-_4-CNY1-925903.jpg?v=1705589045",
            "/images/products/Season-of-Giving-Hamper-_4-_-_4-CNY2-928496.jpg?v=1705589045"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1. Flour Power Oatmeal Cranberry Cookies Pouch 100g",
                "2. Flour Power Sea Salt black Sesame Cookies Pouch 100g",
                "3. Flour Power Pineapple Ball in a Jar 15pcs",
                "4. Flour Power Pineapple Ball in a Jar 15pcs",
                "5. Fortune Gold Cat",
                "6. Tie Guan Yin in Red Tin",
                "7. Red Handle Basket",
                "8. Ang Baos"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1078,
        "name": "Solace (X)",
        "handle": "solace-x",
        "description": "Introducing our elegant Condolence Flower Stand, a thoughtful and heartfelt expression of sympathy during times of loss. Crafted with utmost care and compassion, this arrangement features a harmonious blend of delicate Brassica, serene Lily, and graceful Eustoma, accented with the serene beauty of faux white Hydrangeas.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Solace-_X_-1c.jpg?v=1720189341",
            "/images/products/Condolence-Card-2024_1_1929a7c3-0a57-4c79-ba21-4c6294711583.jpg?v=1720189348",
            "/images/products/Solace-_X_-2c.jpg?v=1720189341"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Brassica . Lilies . Eustoma . Faux White Hydrangeas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 1079,
        "name": "Sympathy (X)",
        "handle": "sympathy-x",
        "description": "Elegance meets compassion in our Condolence Jumbo Flower Stand. Gracefully adorned with deep purple Brassica, serene Lily, and tender pink Gerbera, alongside delicate Orchids, this arrangement conveys heartfelt sympathy. Thoughtfully curated, it offers a comforting tribute, honoring the departed with a majestic display of beauty and solace during difficult times.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "684.37",
        "inclTax": "684.37",
        "img": [
            "/images/products/Sympathy-_X_-1c.jpg?v=1720189944",
            "/images/products/Condolence-Card-2024_1_e92f32ec-6d10-4a56-8283-6808ba8a180f.jpg?v=1720189950",
            "/images/products/Sympathy-_X_-2c.jpg?v=1720189943"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Brassica . Chrysanthemums . Orchids . Gerbera . Lilies . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 120cm*180cm"
    },
    {
        "id": 1080,
        "name": "Charming Reunion 其乐融融 CNY",
        "handle": "charming-reunion-其乐融融-cny",
        "description": "Charming Reunion 其乐融融What better way to say “Happy New Year” than with a basket of delectable goodies? This gift hamper comes with high-quality bird’s nest, BBQ Bak Hwa, and desserts suitable for the whole family.",
        "type": "Hampers",
        "tags": [],
        "price": "280.46",
        "inclTax": "280.46",
        "img": [
            "/images/products/Charming-Reunion-_-CNY1-419182.jpg?v=1706283641",
            "/images/products/Charming-Reunion-_-CNY2-412723.jpg?v=1706283641"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Royal Select Sparkling Juice 750ml",
                "2.  Birds Nest with Ginseng, White Fungus & Rock Sugar 3x70ml",
                "3.  Delfi Almond Chocolate 180g",
                "4.  New Peng Hiang BBQ Bak Hwa 100g",
                "5.  Hong Kong Sweetheart Butterfly Cookies 66g",
                "6.  Royal Family Pineapple Cake 138g",
                "7.  Salted Egg Crispy Fish Skin Crackers 50g",
                "8.  Hokkaido Love Pudding 50g",
                "9.  柿柿如意 Milk Candy 52g",
                "10.  Mala Hotpot Mushroom 30g",
                "11.  Hong Kong Handmade Egg Roll 38g",
                "12.  Yam Cookies 80g",
                "13.  Camel Mixed Snacks 80g",
                "14.  Exquisite CNY Packaging",
                "\n–皇家精选气泡果汁 750ml",
                "–人参银耳冰糖燕窝 3x70ml",
                "–德尔菲巧克力 180g",
                "–新品香烤肉干 100g",
                "–味与森甜心蝴蝶酥 66g",
                "–凤梨酥 138g",
                "–香脆咸蛋黄鱼皮 50g",
                "–北海道爱心布丁 50g",
                "–柿柿如意牛奶糖 52g",
                "–麻辣香锅香菇脆片 30g",
                "–雅佳港式古法手工蛋卷 38g",
                "–香芋曲奇 80g",
                "–混合零食 80g",
                "–精美贺年包装"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1081,
        "name": "Delights Reunion 福满人间 CNY",
        "handle": "delights-reunion-福满人间-cny",
        "description": "Delights Reunion 福满人间Delights Reunion brings together a harmonious blend of Bellevie Pavillon Merlot, luxurious abalone, and a cornucopia of snacks from almond cookies to cheese egg rolls. Each item is carefully selected to enhance the joy of sharing and celebrating together.",
        "type": "Hampers",
        "tags": [],
        "price": "385.43",
        "inclTax": "385.43",
        "img": [
            "/images/products/Delights-Reunion-_-CNY1-936409.jpg?v=1706283699",
            "/images/products/Delights-Reunion-_-CNY2-194446.jpg?v=1706283699"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                "1.   Bellevie Pavillon Merlot 75cl",
                "2.  大家发 Abalone in Brine 425g",
                "3.  Kinohimitsu Longan & Wolfberry Birds Nest 150ml",
                "4.  Birds Nest with Ginseng, White Fungus & Rock Sugar 3x70ml",
                "5.  Essence of Chicken with Ginseng & Cordyceps 3x70ml",
                "6.  Yamazaki Almond Cookies 160g",
                "7.  CNY Pineapple Tarts 102g",
                "8.  Premium Roasted Ringent Chestnut 75g",
                "9.  Yam Cookies 80g",
                "10.  Tokyo Banana Cake 52g",
                "11.  Kueh Bangkit Pandan Flavour 60g",
                "12.  Camel Mixed Snacks 80g",
                "13.  Macimimi Lava Cheese Egg Roll 100g",
                "14.  Exquisite CNY Packaging",
                "\n–红酒 75cl",
                "–大家发卤水鲍鱼425g",
                "–Kinohimitsu 龙眼枸杞燕窝 150ml",
                "–人参银耳冰糖燕窝 3x70ml",
                "–人参虫草鸡精 3x70ml",
                "–Yamazaki 杏仁饼 160g",
                "–凤梨酥 102g",
                "–特级烤板栗75g",
                "–香芋曲奇 80g",
                "–东京香蕉牛奶味蛋糕 52g",
                "–班兰味薯粉饼 60g",
                "–混合零食 80g",
                "–麦米米芝士味流心蛋酥 100g",
                "–精美贺年包装"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1082,
        "name": "Warmth Reunion 瑞雪迎春 CNY",
        "handle": "warmth-reunion-瑞雪迎春-cny",
        "description": "Warmth Reunion 瑞雪迎春Warmth Reunion is a toast to cozy gatherings. With fine Bordeaux and French Brandy, complemented by bird’s nest drinks and a variety of snacks from handmade cookies to shrimp rolls, it’s a perfect way to share warmth and joy this festive season.",
        "type": "Hampers",
        "tags": [],
        "price": "536.04",
        "inclTax": "536.04",
        "img": [
            "/images/products/Warmth-Reunion-_-CNY1-146608.jpg?v=1706458224",
            "/images/products/Warmth-Reunion-_-CNY2-852483.jpg?v=1706458224"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Vieux Chateau Lamothe Bordeaux 75cl",
                "2.  XO Effiel Tower French Brandy 20cl",
                "3.  Birds Nest with Ginseng, White Fungus & Rock Sugar 3x70ml",
                "4.  Yu Xiang Yan White Fungas Birds Nest Drink 3 x 75ml",
                "5.  Hokkaido Love Pudding 50g",
                "6.  Tokyo Handmade Butterfly Cookies 30g",
                "7.  Yamazaki Almond Cookies 160g",
                "8.  Salted Egg Crispy Fish Skin Crackers 50g",
                "9.  Premium Lava Custard Cookies 96g",
                "10.  New Peng Hiang Shrimp Roll 80g",
                "11.  Premium Roasted Ringent Chestnut 75g",
                "12.  Vikoi Happy Peanuts 60g",
                "13.  Yam Cookies 80g",
                "14.  Exquisite CNY Packaging",
                "\n–红酒 75cl",
                "–X.O 埃菲尔铁塔白兰地 20cl",
                "–人参银耳冰糖燕窝 3x70ml",
                "–宇鄕燕银耳燕窝系列 75ml x 3",
                "–北海道爱心布丁 50g",
                "–Martha 东京手制蝴蝶酥 30g",
                "–Yamazaki 杏仁饼 160g",
                "–香脆咸蛋黄鱼皮 50g",
                "–熔岩奶油曲奇 96g",
                "–新品香香酥虾米卷 80g",
                "–特级烤板栗75g",
                "–快乐花生 60g",
                "–香芋曲奇 80g",
                "–精美贺年包装"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1083,
        "name": "Botanica De Dragon Gift Set #1 CNY",
        "handle": "botanica-de-dragon-gift-set-1-cny",
        "description": "Botanica De Dragon Gift Set #1",
        "type": "Hampers",
        "tags": [],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Botanica-De-Dragon-Gift-Set-_1-CNY1.jpg?v=1706241942",
            "/images/products/Botanica-De-Dragon-Gift-Set-_1-CNY2.jpg?v=1706241941"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Limited Edition Dragon Year 2024 Botanica Chinoiserie Home Fragrance Reed Diffuser 100ml",
                "2.  Mandarin Oranges x 8",
                "3.  CNY Red Box Carrier"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1084,
        "name": "Botanica De Dragon Gift Set #2 CNY",
        "handle": "botanica-de-dragon-gift-set-2-cny",
        "description": "Botanica De Dragon Gift Set #2",
        "type": "Hampers",
        "tags": [],
        "price": "321.53",
        "inclTax": "321.53",
        "img": [
            "/images/products/Botanica-De-Dragon-Gift-Set-_2-CNY1-829305.jpg?v=1706283399",
            "/images/products/Botanica-De-Dragon-Gift-Set-_2-CNY2.jpg?v=1706242299"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Limited Edition Dragon Year 2024 Botanica Chinoiserie Home Fragrance Reed Diffuser 100ml",
                "2.  Flour Power Pineapple Ball in a Jar 15pcs",
                "3.  JP. Chenet Cabernet Syrah 250ml",
                "4.  Birds Nest with Ginseng, White Fungus & Rock Sugar 2x70ml",
                "5.  CNY Red Box Carrier"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1085,
        "name": "Botanica De Dragon Gift Set #3 CNY",
        "handle": "botanica-de-dragon-gift-set-3-cny",
        "description": "Botanica De Dragon Gift Set #3",
        "type": "Hampers",
        "tags": [],
        "price": "360.33",
        "inclTax": "360.33",
        "img": [
            "/images/products/Botanica-De-Dragon-Gift-Set-_3-CNY1-159049.jpg?v=1706283399",
            "/images/products/Botanica-De-Dragon-Gift-Set-_3-CNY2.jpg?v=1706242735"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Limited Edition Dragon Year 2024 Botanica Chinoiserie Home Fragrance Reed Diffuser 100ml",
                "2.  Skylight Braised Amidori Abalone 425g",
                "3.  Tie Guan Yin Tea In Tin",
                "4.  Birds Nest with Ginseng, White Fungus & Rock Sugar 2x70ml",
                "5.  CNY Red Box Carrier"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1086,
        "name": "Peace (X)",
        "handle": "peace-x",
        "description": "Elegance unfolds in our condolence flower stand, a serene tribute in a soothing palette of purple, green, and white. Embracing the delicate beauty of brassica, chrysanthemum, orchids, and lilies, this arrangement symbolizes heartfelt sympathy. Expressing solace and remembrance, it provides a graceful gesture of comfort during moments of grief and loss.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "456.17",
        "inclTax": "456.17",
        "img": [
            "/images/products/Peace-_X_-1b.jpg?v=1720188108",
            "/images/products/Condolence-Card-2024_1_d7f80257-d058-42da-9f69-bac63311b8fb.jpg?v=1720188141",
            "/images/products/Peace-_X_-2b.jpg?v=1720188109"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Brassica . Mum . Lily . Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 120cm*180cm"
    },
    {
        "id": 1087,
        "name": "Benevolence (VI)",
        "handle": "benevolence-vi",
        "description": "In a gentle tribute, our condolence flower stand features a serene blend of green and white hues. Graceful chrysanthemums, delicate orchids, and serene lilies intertwine to create a tranquil arrangement. Symbolizing condolences and sympathy, this stand offers solace and comfort during times of sorrow, expressing heartfelt support and remembrance.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Benevolence-_VI_-1b.jpg?v=1720157133",
            "/images/products/Condolence-Card-2024_1_3feb5cea-8df3-4f73-b203-604dcb8124d7.jpg?v=1720157148",
            "/images/products/Benevolence-_VI_-2b.jpg?v=1720157133"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemum . Orchids . Lilies . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 1088,
        "name": "Huat VI",
        "handle": "huat-vi",
        "description": "Usher in prosperity with our floral masterpiece. Scarlet tulips and golden chrysanthemums elegantly signify good luck and wealth, making it a perfect centerpiece for your New Year celebrations.",
        "type": "Bouquets",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Huat-_VI_-1b-626064.jpg?v=1706716479",
            "/images/products/Huat-_VI_-2b-986229.jpg?v=1706716479"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Pussy Willow . Preserved Hydrangea"
            ]
        },
        "dimention": "Width*Height: 15cm*40cm"
    },
    {
        "id": 1089,
        "name": "Fruit Frenzy FHP",
        "handle": "fruit-frenzy-fhp",
        "description": "Fruit Frenzy",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers",
            "Corporate Hampers"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fruit-Frenzy-FHP1_4727cf8a-2a6f-4152-8197-538bcf1df50b-746394.jpg?v=1707148895",
            "/images/products/Fruit-Frenzy-FHP2_4340f9d4-0ebe-45e5-9785-62516617cb58-736790.jpg?v=1707148895"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Orange x 2",
                "2.  Forelle Pear x 2",
                "3.  Fuji Apple x 2",
                "4.  Assorted Flower Decoration",
                "5.  Exquisite Wooden Crate (M)"
            ]
        },
        "dimention": "Size of Hamper : 30cm x 23cm x 25cm"
    },
    {
        "id": 1090,
        "name": "Harmony Reunion 花好月圆 CNY",
        "handle": "harmony-reunion-花好月圆-cny",
        "description": "Harmony Reunion 花好月圆Spread warmth and joy with your loved one during the upcoming auspicious holiday with a beautiful feative theme box consisting rejuvenating birdnest, mouth watering cookies and other sweet treats.",
        "type": "Hampers",
        "tags": [],
        "price": "394.56",
        "inclTax": "394.56",
        "img": [
            "/images/products/Harmony-Reunion-_-CNY1-796246.jpg?v=1707148989",
            "/images/products/Harmony-Reunion-_-CNY2-381455.jpg?v=1707148989"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Birds Nest with Ginseng, White Fungus & Rock Sugar 3x70ml",
                "2.  Excelcium  Pralines BonBons De Chocolat 200g",
                "3.  New Peng Hiang BBQ Bak Hwa 100g",
                "4.  Hong Kong Sweetheart Butterfly Cookies 66g",
                "5.  Royal Family Pineapple Cake 138g",
                "6.  Almond Cookies 80g",
                "7.  Hokkaido Love Pudding 50g",
                "8.  柿柿如意 Milk Candy 52g",
                "9.  Mala Hotpot Mushroom 30g",
                "10.  Hong Kong Handmade Egg Roll 38g",
                "11.  Yam Cookies 80g",
                "12.  Camel Mixed Snacks 80g",
                "13.  Exquisite CNY Packaging",
                "\n–人参银耳冰糖燕窝 3x70ml",
                "–果仁糖巧克力 200g",
                "–新品香烤肉干 100g",
                "–味与森甜心蝴蝶酥 66g",
                "–凤梨酥 138g",
                "–杏仁饼 80g",
                "–北海道爱心布丁 50g",
                "–柿柿如意牛奶糖 52g",
                "–麻辣香锅香菇脆片 30g",
                "–雅佳港式古法手工蛋卷 38g",
                "–香芋曲奇 80g",
                "–混合零食 80g",
                "–精美贺年包装"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1091,
        "name": "Prosperous Joy 福运财生 CNY",
        "handle": "prosperous-joy-福运财生-cny",
        "description": "Prosperous Joy 福运财生Unveil a world of prosperity with this hamper. Containing South Japan Sea Jade Abalone, nourishing essence of chicken, and other premium selections, it symbolizes the blossoming of wealth and happiness in abundance.",
        "type": "Hampers",
        "tags": [],
        "price": "301.00",
        "inclTax": "301.00",
        "img": [
            "/images/products/Prosperous-Joy-_-CNY1-116024.jpg?v=1707149230",
            "/images/products/Prosperous-Joy-_-CNY2-363152.jpg?v=1707149230"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   鲍今天 Jade Abalone in Brine 425g",
                "2.  New Moon Essence of Chicken with Cordyceps 68ml x 3",
                "3.  Premium White Fungus 100g",
                "4.  Yu Xiang Yan Premium Scallop Fish Maw Soup 425g",
                "5.  Superior Tea Flower Mushrooms 100g",
                "6.  Premium Red Dates 100g",
                "7.  Kinohimitsu Birds Nest Beverages 3s 75ml in Gift Box",
                "8.  Exquisite CNY Gift Basket",
                "\n–鲍今天卤水鲍鱼425g",
                "–人月牌虫草鸡精 68ml x 6",
                "–特级银耳 100g",
                "–宇鄉燕特级带子花胶汤 425g",
                "–上等茶花菇 100g",
                "–特级红枣 100g",
                "–Kinohimitsu燕窝饮品礼盒 75ml x 3",
                "–精美贺年礼篮"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1092,
        "name": "Harmony (VII)",
        "handle": "harmony-vii",
        "description": "Introducing our stunning Grand Opening Flower Stand, a vibrant and luxurious arrangement designed to captivate and celebrate the joyous occasion of a new beginning. Crafted with meticulous attention to detail, this magnificent display features a harmonious blend of sunflowers, hot pink gerbera daisies, red anthuriums, and three exquisite ginger flowers, exuding elegance and charm.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Harmony-_VII_-1b.jpg?v=1720495161",
            "/images/products/Grand-Opening-Card-2024_fe5c9384-2581-40ea-a0ea-409b8c45b0d0.jpg?v=1720596783",
            "/images/products/Harmony-_VII_-2b-992071.jpg?v=1720552234"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Gerbera . Anthurium . Ginger Flower . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 60cm*160cm"
    },
    {
        "id": 1093,
        "name": "Simple Charm GHP",
        "handle": "simple-charm-ghp",
        "description": "Simple Charm",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Corporate Hampers"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Simple-Charm-GHP1_e9926aaf-13c3-492b-8d33-f9deb0d3f2cd.jpg?v=1718172658",
            "/images/products/Simple-Charm-GHP2_69c6ccec-75e3-46b7-81f6-bd01381522d7.jpg?v=1718172658"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Jacobs Creek Shiraz Cabernet 187ml",
                "2.  Jacobs Creek Chardonnay 187ml",
                "3.  Hamlet with Bow Assorted Belgian Chocolate 125g",
                "4.  San Andres Marzipan with Candied Fruit 200g",
                "5.  Gavottes Crepes Dentelle 125g",
                "6.  Round Gift Box"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1094,
        "name": "Gourmet Bites GHP",
        "handle": "gourmet-bites-ghp",
        "description": "Gourmet Bites",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Corporate Hampers"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Gourmet-Bites-GHP1_d1a2cad6-5485-40a2-8817-4b113847e2c1.jpg?v=1718172184",
            "/images/products/Gourmet-Bites-GHP2.jpg?v=1718172184"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   La Costena Salsa Dip in glass jar 453g",
                "2.  Mission Tortilla Chips 65g",
                "3.  Re-Cashew With Honey Sesame 100g",
                "4.  Taylors of Harrogate Earl Grey Tea 125g",
                "5.  Ore Liete Cantuccini with Dark Chocolate Chips 180g",
                "6.  Nibbles Premium Natural Mixed Nuts 45g",
                "7.  Primo Forno Traditional Breadsticks with Parmesan Cheese 125g",
                "8.  Exquisite Wooden Crate (L)"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1096,
        "name": "Blushing Springtime FNB",
        "handle": "blushing-springtime-fnb",
        "description": "Blushing SpringtimeLooking to send chocolates to someone? This pretty pink chocolate hamper is sure to make them blush! Enjoy a bottle of JP. Chenet Colombard Sauvignon 250ml along with assorted Belgian chocolates, delivered in an exquisite handcrafted box.",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet",
            "Happy Birthday Balloons Bouquet",
            "Congratulations Balloons & Flowers ",
            "Anniversary Balloons & Flowers"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Blushing-Springtime-FNB1_11d4fdba-83f9-4b04-8606-c4be463708f9.jpg?v=1709004160",
            "/images/products/Blushing-Springtime-FNB2_e0a49a91-d9d2-44c4-9901-ccaba82d452c.jpg?v=1709004159"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.   JP. Chenet Colombard Sauvignon 250ml",
                "2.  Hamlet Assortiment Belgian Chocolates 125g",
                "3.  Excelcium Tradition Pralines Chocolates 180g",
                "4.  Exquisite Handcrafted Hat Box",
                "5.  Personalised Text On Balloon with 4 Mini Balloons"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 1097,
        "name": "Luminous Joy FNB",
        "handle": "luminous-joy-fnb",
        "description": "Luminous JoySpread joy with the Luminous Joy balloon hamper! Perfect for occasions that demand a little extravagance, this delectable food hamper with JP. Chenet Colombard Sauvignon 250ml, assorted chocolates and bon bons will impress the most discerning tasters! Personalize your occasion with a message on our display balloon! ",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet",
            "Happy Birthday Balloons Bouquet",
            "Congratulations Balloons & Flowers ",
            "Anniversary Balloons & Flowers"
        ],
        "price": "346.64",
        "inclTax": "346.64",
        "img": [
            "/images/products/Luminous-Joy-FNB1-391031.jpg?v=1709056689",
            "/images/products/Luminous-Joy-FNB2-102134.jpg?v=1709056689"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.   Excelcium  Pralines BonBons De Chocolat 200g",
                "2.  Hamlet Assortiment Chocolate 125g",
                "3.  Tiptree  Orange & Tangerine Fine Cut Marmalade 340g",
                "4.  Cavendish & Harvey Tropical fruit drops 200g",
                "5.  Lindt Lindor Assorted Swiss Chocolate 200g",
                "6.  JP. Chenet Colombard Sauvignon 250ml",
                "7.  Beryls Waffle Cookies 80g",
                "8.  ST Dalfour Fruit Spread ( 3 x 28g )",
                "9.  Excelcium Pralines Assorted Chocolate 180g",
                "10.  Personalised Text On Balloon with 6 Mini Balloons",
                "11.  Exquisite Wicker Basket"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 1098,
        "name": "Macchiato Delight FNB",
        "handle": "macchiato-delight-fnb",
        "description": "Macchiato DelightOur Macchiato Delight was made to share, but also entirely enjoyable on its own. Savour two of Jacob’s Creek’s classic Carbernet and Chardonnay, paired with treats such as Cupido Latte Macchiato Dessert Cups and Walters Hazelnut Macadamia Almond Nougat. A perfect food hamper for a delightful evening!",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet",
            "Happy Birthday Balloons Bouquet",
            "Congratulations Balloons & Flowers ",
            "Anniversary Balloons & Flowers"
        ],
        "price": "221.13",
        "inclTax": "221.13",
        "img": [
            "/images/products/Macchiato-Delight-FNB1_4896a40e-cead-45b6-b2f5-5a5fc395abda.jpg?v=1709008282",
            "/images/products/Macchiato-Delight-FNB2A.jpg?v=1709008282"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.   Jacobs Creek Shiraz Cabernet 187ml",
                "2.  Jacobs Creek Classic Chardonnay 187ml",
                "3.  Cupido Latte Macchiato Dessert Cups 125g",
                "4.  Walters Hazelnut Macadamia Almond Nougat 120g",
                "5.  Exquisite Handcrafted Hat Box",
                "6.  Personalized Text On Balloon with 4 Mini Balloons"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 1099,
        "name": "Delicious Delights FNB",
        "handle": "delicious-delights-fnb",
        "description": "Delicious Delights Celebrate a loved one’s achievements with an array of tasty treats! Packed with some of the most delicious snacks, the Delicious Delights hamper will leave your recipient’s cravings satisfied and hungry for more. Add on a personalized note and it’ll be the perfect gift for any occasion that requires a true celebration!",
        "type": "Balloons & Flowers",
        "tags": [
            "Get Well Soon Balloons Bouquet",
            "Happy Birthday Balloons Bouquet",
            "Congratulations Balloons & Flowers ",
            "Anniversary Balloons & Flowers"
        ],
        "price": "198.31",
        "inclTax": "198.31",
        "img": [
            "/images/products/Delicious-Delights-FNB1-627427.jpg?v=1709056409",
            "/images/products/Delicious-Delights-FNB2.jpg?v=1709009892"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " 1.   Foxs Fabulous Cookie 180g",
                "2.  Oreo Dutch Cocoa Wafter 140g",
                "3.  Oreo Wafer Roll 54g",
                "4.  Bickfords Traditional Soda 275ml",
                "5.  Ritter Sport Chocolate 100g",
                "6.  Personalised Text On Balloon with 4 Mini Balloons",
                "7.  Exquisite Handcrafted Hat Box"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 1100,
        "name": "Pinky Paradise FNB",
        "handle": "pinky-paradise-fnb",
        "description": "Pinky ParadiseWelcome to the Pinky Paradise, where little ones can play and relax in style! This adorable baby hamper is the perfect gift for any new parent who wants to spoil their little princess. With a bundle of pink balloons to add a pop of color to any room, this hamper is sure to be a hit.",
        "type": "Balloons & Flowers",
        "tags": [
            "Newborn Flowers & Bouquets",
            "Baby Hampers",
            "Newborn Balloons Bouquet"
        ],
        "price": "303.28",
        "inclTax": "303.28",
        "img": [
            "/images/products/Pinky-Paradise-FNB1.jpg?v=1709010673",
            "/images/products/Pinky-Paradise-FNB2-164079.jpg?v=1709056797"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   BabyK Organic Head Shaping Pillow",
                "2.  Kayen & Kayes Bamboo Swaddling Cloth",
                "3.  Kayen & Kayes 6 pcs Bamboo Wash cloth",
                "4.  BabyK Keepsake Mini book",
                "5.  BabyK Baby Bath Scalp Massager",
                "6.  BabyK Silicone Giraffe Toy",
                "7.  Fleece Blanket",
                "8.  Personalised Text On Balloon with 6 Mini Balloons",
                "9.  Hand crafted Wicker Basket"
            ]
        },
        "dimention": "Width*Height: 35cm*50cm"
    },
    {
        "id": 1101,
        "name": "Gloriana",
        "handle": "gloriana",
        "description": "Brighten someone's day with this charming bouquet, a vibrant mix of sun-kissed sunflower, delicate roses, and lush eustomas. Wrapped elegantly in soft beige paper, this floral arrangement is a stunning gift, perfect for birthdays, anniversaries, or just because. \"Flowers Malaysia\" promises fresh, exquisite blooms that symbolize joy and affection.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Gloriana-1c.jpg?v=1720881017",
            "/images/products/Gloriana-2c.jpg?v=1720881017"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Carnation  .  Sunflower  .  Tulip  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1102,
        "name": "Gavrila",
        "handle": "gavrila",
        "description": "Capture the essence of a serene garden with this bouquet, featuring a bold sunflower surrounded by tranquil violet caspia and complemented by delicate white caspia. Tied with a soft pink ribbon, this arrangement from \"Flowers Malaysia\" makes a serene statement, ideal for expressing affection, gratitude, or simply celebrating the beauty of nature.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "45.41",
        "inclTax": "45.41",
        "img": [
            "/images/products/Gavrila-1d.jpg?v=1720531324",
            "/images/products/Gavrila-2d.jpg?v=1720531324"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower  .  Caspia"
            ]
        },
        "dimention": "Width*Height: 20cm*28cm"
    },
    {
        "id": 1103,
        "name": "Gavina",
        "handle": "gavina",
        "description": "This \"Flowers Malaysia\" bouquet exudes romance, with plush pink  vivid red carnations nestled among a cloud of baby's breath. Wrapped in chic white and soft beige, and finished with a delicate pink ribbon, it's a classic gesture of love and a timeless symbol of elegance.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Gavina-2b-546805.jpg?v=1721049268",
            "/images/products/Gavina-1b.jpg?v=1721010611"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Baby's Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1104,
        "name": "Graelyn",
        "handle": "graelyn",
        "description": "This exquisite bouquet radiates with a sunny sunflower, surrounded by blush carnations and frothy baby's breath, creating a perfect harmony of color and texture. Adorned with a pink satin bow, it's a celebration of grace and beauty, ideal for special moments and everyday elegance. ",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Baby's Breath Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Graelyn-2c.jpg?v=1721059408",
            "/images/products/Graelyn-1c.jpg?v=1721059408"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower  .  Carnation  .  Baby's Breath .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1105,
        "name": "Geraldine",
        "handle": "geraldine",
        "description": "This enchanting bouquet of pink carnations interlaced with eucalyptus leaves presents a classic and romantic charm. Wrapped in a delicate blush paper and tied with a soft ribbon, it's a timeless expression of affection and elegance. Perfect for any occasion that calls for a touch of floral sophistication.",
        "type": "Bouquets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Geraldine-2b-140734.jpg?v=1721222758",
            "/images/products/Geraldine-1b.jpg?v=1721182129"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnation  . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1106,
        "name": "Fresh Blossoms THP",
        "handle": "fresh-blossoms-thp",
        "description": "Fresh Blossoms",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Tea Hampers",
            "Corporate Hampers"
        ],
        "price": "225.69",
        "inclTax": "225.69",
        "img": [
            "/images/products/Fresh-Blossoms-THP1.jpg?v=1710396434",
            "/images/products/Fresh-Blossoms-THP2.jpg?v=1710396435"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Indulgence Gift Set 3 in 1",
                "2.  AmazinGrace Pandan Coconut Nuts Mix 100g",
                "3.  Flower  Roses China",
                "4.  Flower  Cappucino Holland Carnation",
                "5.  Preserved Rice Flower",
                "6.  Gift Box"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1107,
        "name": "Simple Sonata THP",
        "handle": "simple-sonata-thp",
        "description": "Simple Sonata Treat someone to teatime delights with the Simple Sonata tea gift set, which features the Amazin’Grace Sweet Chili Nuts Mix and the delicious Juan Tea 3 in 1 floral tea blends. Our delicious sampler tea set gift ensures that you get to experience our range of indulgent floral green and red tea blends, that have been designed to freshen your mood.",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Tea Hampers",
            "Corporate Hampers"
        ],
        "price": "225.69",
        "inclTax": "225.69",
        "img": [
            "/images/products/Simple-Sonata-THP1_29afd350-53ce-4282-a0ba-146945b12887.jpg?v=1710734050",
            "/images/products/Fresh-Blossoms-THP2_11b34faa-f52f-4f82-a07b-e8402a14fec5.jpg?v=1710734050"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Indulgence Gift Set 3 in 1",
                "2.  AmazinGrace Sweet Chili Nuts Mix 100g",
                "3.  Flower  Holland Carnation",
                "4.  Flower  Eustoma (Lisanthum)",
                "5.  Preserved Rice Flower",
                "6.  Gift Box"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1108,
        "name": "Morning Mood THP",
        "handle": "morning-mood-thp",
        "description": "Morning Mood Nothing puts you in a better mood than waking up to an invigorating cup of tea! The Morning Mood tea hamper set features the Juan Tea 6 in 1 Gift Set. This is a collection of robust red teas and green teas, blended with premium buds to offer the best floral tea experience. Get ready to wake up with a smile! ",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Tea Hampers",
            "Corporate Hampers"
        ],
        "price": "237.10",
        "inclTax": "237.10",
        "img": [
            "/images/products/Morning-Mood-THP1_4341a278-9398-41fd-b993-53bdab7b22ca-833317.jpg?v=1710797542",
            "/images/products/Evening-Enchantment-THP2_2fd445de-6f90-4313-bcad-fe021e644749.jpg?v=1710735016"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Indulgence Gift Set 6 in 1",
                "2.  Flower  Roses China",
                "3.  Flower  Holland Carnation",
                "4.  Flower  Gerbera",
                "5.  Flower  Rose Spray",
                "6.  Flower  Mini Pom pom",
                "7.  Gift Box"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1109,
        "name": "Lovely Sonnet THP",
        "handle": "lovely-sonnet-thp",
        "description": "Lovely Sonnet Indulgence has a name and it is Lovely Sonnet. Featuring the Juan Tea 3 in 1 Gift Set, this is a collection of robust red teas and green teas, blended with premium buds to offer the best floral tea experience. Indulged with the Gavottes Crepe Dentelle, this is one truly unforgettable tea gift set. ",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Tea Hampers",
            "Corporate Hampers"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Lovely-Sonnet-THP1.jpg?v=1710735073",
            "/images/products/Lovely-Sonnet-THP2.jpg?v=1710735072"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Indulgence Gift Set 3 in 1",
                "2.  Gavottes Crepe Dentelle 125g",
                "3.  Flower Eustoma (Lisanthum)",
                "4.  Flower Roses China",
                "5.  Flower Holland Carnation",
                "6.  Preserved Rice Flower",
                "7.  Flower Clematis",
                "8.  Flower Eucalyptus",
                "9.  Wooden Crate"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1110,
        "name": "Bunny Happy THP",
        "handle": "bunny-happy-thp",
        "description": "Bunny Happy Wish someone a Bunny Happy day with this darling hamper! Featuring the Juan Tea 3 in 1 Gift Set, this beautiful curation of blends is the perfect gift for tea connoisseurs. Reap the health benefits of green and red teas that have been beautifully blended with premium floral buds. ",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Tea Hampers",
            "Corporate Hampers"
        ],
        "price": "330.66",
        "inclTax": "330.66",
        "img": [
            "/images/products/Bunny-Happy-THP1.jpg?v=1710738320",
            "/images/products/Bunny-Happy-THP2.jpg?v=1710738321"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Indulgence Gift Set 3 in 1",
                "2.  Bunny Plush Toy",
                "3.  Amazin Graze Nut Mix Variety Box ( 8 x 30g )",
                "4.  The Fine Cheese Co Rose Mary and Extra Virgin Oil Cracker 125g",
                "5.  Flower Roses China",
                "6.  Flower Holland Carnation",
                "7.  Flower Eustoma (Lisanthum)",
                "8.  Preserved Rice Flower",
                "9.  Gift Box"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1111,
        "name": "Bunny Sweet THP",
        "handle": "bunny-sweet-thp",
        "description": "Bunny Sweet Wish someone a Bunny Sweet day with this darling hamper! Featuring the Juan Tea 3 in 1 Gift Set, this beautiful curation of blends is the perfect gift for tea connoisseurs. Reap the health benefits of green and red teas that have been beautifully blended with premium floral buds. ",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Tea Hampers",
            "Corporate Hampers"
        ],
        "price": "330.66",
        "inclTax": "330.66",
        "img": [
            "/images/products/Bunny-Sweet-THP1_f7269095-7a3e-4c58-b448-74af9b126669-307059.jpg?v=1710796873",
            "/images/products/Bunny-Sweet-THP2.jpg?v=1710738999"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Indulgence Gift Set 3 in 1",
                "2.  Bunny Plush Toy",
                "3.  Amazin Graze Nut Mix Variety Box ( 8 x 30g )",
                "4.  The Fine Cheese Co Rose Mary and Extra Virgin Oil Cracker 125g",
                "5.  Flower Roses China",
                "6.  Flower Holland Carnation",
                "7.  Flower Eustoma ( Lisanthum)",
                "8.  Preserved Rice Flower"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1112,
        "name": "Autumn Ballad THP",
        "handle": "autumn-ballad-thp",
        "description": "Autumn Ballad Send good tastes through delightful teatime treats with the Autumn Ballad tea gift set. Featuring the Juan Tea 3 in 1 Gift Set, this is a collection of robust red teas and green teas, blended with premium buds to offer the best floral tea experience. Indulged with freshly baked cookies by Temptations and assorted chocolates from Hamlet, this tea set gift hamper will excite even the most discerning palates!",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Tea Hampers",
            "Corporate Hampers"
        ],
        "price": "330.66",
        "inclTax": "330.66",
        "img": [
            "/images/products/Autumn-Ballad-THP1-340178.jpg?v=1710796680",
            "/images/products/Autumn-Ballad-THP2-960485.jpg?v=1710796681"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Indulgence Gift Set 3 in 1",
                "2.  Temptations Freshly Baked Cookies  Butter",
                "3.  Hamlet Assortiment Chocolate 125g",
                "4.  Flower Gerbera",
                "5.  Flower Holland Carnation",
                "6.  Flower Roses",
                "7.  Flower Eustoma (Lisanthum)",
                "8.  Flower Eucalyptus",
                "9.  Wooden Crate"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1113,
        "name": "Happy Sunshine THP",
        "handle": "happy-sunshine-thp",
        "description": "Happy SunshineHappy Sunshine is what we want your recipient to be when they receive this deluxe tea gift set. Featuring the Juan Tea 6 in 1 Gift Set, this tea set collection ensures that you get to experience our range of indulgent floral tea blends, which have been designed to calm and lift your spirit. Your recipient will be treated with the Amazin‘ Graze Nut Mix Variety Box, and Hamlet’s assortment of chocolates. They can also brew our premium tea leaves in the Juan Tea glass bottle for a truly indulgent experience.",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Tea Hampers",
            "Corporate Hampers"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Happy-Sunshine-THP1-147841.jpg?v=1710797234",
            "/images/products/Happy-Sunshine-THP2-294014.jpg?v=1710797234"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Indulgence Gift Set 6 in 1",
                "2.  Amazin Graze Nut Mix Variety Box ( 8 x 30g )",
                "3.  Juan Tea Glass Bottle",
                "4.  Hamlet Assortiment Chocolate 125g",
                "5.  Flower Gerbera",
                "6.  Flower Roses China",
                "7.  Flower Eustoma (Lisanthum)",
                "8.  Flower Eucalyptus",
                "9.  Flower Waxflower",
                "10.  Wooden Crate"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1114,
        "name": "Blushing Ballad THP",
        "handle": "blushing-ballad-thp",
        "description": "Blushing Ballad There are tea gift sets and there are delectable tea time gift hampers. The highlight of the Blushing Ballad is the Juan Tea 3 in 1 Gift Set. This is a collection of robust red teas and green teas, blended with premium buds to offer the best floral tea experience. Indulged with freshly baked chocolate and double chocolate cookies by Temptations, this tea set gift hamper is truly an all-in-one teatime basket! ",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Tea Hampers",
            "Corporate Hampers"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Blushing-Ballad-THP1.jpg?v=1710740362",
            "/images/products/Blushing-Ballad-THP2.jpg?v=1710740363"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Indulgence Gift Set 3 in 1",
                "2.  Temptations Freshly Baked Cookies Butter",
                "3.  Temptations Freshly Baked Cookies Double Chocolate",
                "4.  Flower Gerbera",
                "5.  Flower Eustoma (Lisanthum)",
                "6.  Flower Rose Spray",
                "7.  Flower Mini Pom pom",
                "8.  Wooden Crate"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1115,
        "name": "Sunset Ballad THP",
        "handle": "sunset-ballad-thp",
        "description": "Sunset BalladSend good tastes through delightful teatime treats with the Sunset Ballad tea gift set. Featuring the Juan Tea 3 in 1 Gift Set, this is a collection of robust red teas and green teas, blended with premium buds to offer the best floral tea experience. Indulged with freshly baked chocolate and double chocolate cookies by Temptations, this tea set gift hamper will kick teatime up a notch!",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Tea Hampers",
            "Corporate Hampers"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Sunset-Ballad-THP1.jpg?v=1710740882",
            "/images/products/Sunset-Ballad-THP2-266660.jpg?v=1710798585"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Indulgence Gift Set 3 in 1",
                "2.  Temptations Freshly Baked Cookies Butter",
                "3.  Temptations Freshly Baked Cookies Double Chocolate",
                "4.  Flower Gerbera",
                "5.  Flower Holland Carnation",
                "6.  Flower Roses",
                "7.  Flower Eustoma (Lisanthum)",
                "8.  Flower Eucalyptus",
                "9.  Wooden Crate"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1116,
        "name": "Zorah",
        "handle": "zorah",
        "description": "Elegant and sophisticated, this bouquet features luscious red tulips paired with a cloud of gypsophila, also known as baby's breath. It's wrapped in a gradient of pink paper, creating a lovely contrast, and is tied together with a long, pink satin ribbon. Perfect for a charming gift or a special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Zorah-2c.jpg?v=1720662306",
            "/images/products/Zorah-1b.jpg?v=1720453943"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1117,
        "name": "Wealth (X)",
        "handle": "wealth-x",
        "description": "Celebrate a grand opening with this majestic standing arrangement, featuring a lively medley of sunflowers, orange roses, yellow blooms, and distinctive green foliage. Accented with playful curls and a grand golden bow, it's a radiant display that conveys best wishes for success and prosperity.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Wealth-_X_-1b.jpg?v=1720103922",
            "/images/products/Grand-Opening-Card-2024_bbbee8a1-ee73-4480-9390-2dbb0aef8c9d.jpg?v=1720103928",
            "/images/products/Wealth-_X_-2b.jpg?v=1720103922"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Roses . Spray Roses . Eustoma . Orchids . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1118,
        "name": "Cheers To Health WHP",
        "handle": "cheers-to-health-whp",
        "description": "Cheers To Health",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Cheers-To-Health-WHP1.jpg?v=1711600781",
            "/images/products/Cheers-To-Health-WHP2.jpg?v=1711600782"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Birds Nest with Ginseng, White Fungus & Rock Sugar 6s x 70ml",
                "2.  New Moon Essence of Chicken with Cordyceps ",
                "6s x 68ml",
                "3.  Essence of Chicken with Ginseng & Cordyceps ",
                "6s x 70ml",
                "4.  Exquisite Gift Box"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1119,
        "name": "Essential Tonic Wellness Basket with Fruits WHP",
        "handle": "essential-tonic-wellness-basket-with-fruits-whp",
        "description": "Essential Tonic Wellness Basket with Fruits",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "200.59",
        "inclTax": "200.59",
        "img": [
            "/images/products/Essential-Tonic-Wellness-Basket-with-Fruits-WHP1-787603.jpg?v=1711667545",
            "/images/products/Essential-Tonic-Wellness-Basket-with-Fruits-WHP2-675199.jpg?v=1711667545"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Yu Xiang Yan Premium Birdnest 3s x 70ml",
                "2.  Essence of Chicken with Ginseng & Cordyceps ",
                "3s x 70ml",
                "3.  Formecs Berry Essence 2s x 41ml",
                "4.  Assorted Fruits Packaging",
                "5.  Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1120,
        "name": "Essence Extraordinary WHP",
        "handle": "essence-extraordinary-whp",
        "description": "Essence Extraordinary",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "234.82",
        "inclTax": "234.82",
        "img": [
            "/images/products/Essence-Extraordinary-WHP1.jpg?v=1711601755",
            "/images/products/Essence-Extraordinary-WHP2.jpg?v=1711601755"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Bao Jin Tian Single Abalone in bottle 3s in Gift Box",
                "2.  Yu Xiang Yan Premium Birds Nest 3 x 70ml",
                "3.  New Moon Essence of Chicken with Cordyceps ",
                "3 x 68ml",
                "4.  Assorted Flower Packaging",
                "5.  Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1121,
        "name": "Wellness Wish WHP",
        "handle": "wellness-wish-whp-1",
        "description": "Wellness Wish",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers"
        ],
        "price": "243.95",
        "inclTax": "243.95",
        "img": [
            "/images/products/Wellness-Wish-WHP1.jpg?v=1711602130",
            "/images/products/Wellness-Wish-WHP2.jpg?v=1711602130"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Bao Jin Tian Single Abalone in bottle 3s in Gift Box",
                "2.  Yu Xiang Yan Premium Birds Nest 6 x 70ml",
                "3.  Tie Guan Yin Loose Tea in Tin",
                "4.  Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1122,
        "name": "VinoVerde Delights GHP",
        "handle": "chocolate-dreams-ghp",
        "description": "VinoVerde Delights",
        "type": "Hampers",
        "tags": [
            "Gourmet Food Hampers",
            "Chocolate Hampers",
            "Corporate Hampers"
        ],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/VinoVerde-Delights-GHP1-681963.jpg?v=1712016337",
            "/images/products/VinoVerde-Delights-GHP2.jpg?v=1711949085"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Chateau Les Martineaux Bordeaux 75cl",
                "2.  Heidi Mountains Hazel Nuts Premium Chocolate 150g",
                "3.  La Mere Poulard Les Cookies tout Chocolat- All Chocolate Chips Cookies in Tin 200g",
                "4.  Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 1123,
        "name": "Tessa",
        "handle": "tessa",
        "description": "Evoke the splendor of spring with Tessa, a charming basket brimming with the softest pink carnations. Each blossom is a delicate whisper of love, nestled within a rustic, hand-woven basket, accented with a silky ribbon, embodying timeless elegance for any cherished occasion or serene moment.",
        "type": "Flower Baskets",
        "tags": [
            "Carnation Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Tessa-1b-559761.jpg?v=1713318478",
            "/images/products/Tessa-2b.jpg?v=1713262484"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Carnations . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*30cm"
    },
    {
        "id": 1124,
        "name": "Cerulean",
        "handle": "cerulean",
        "description": "Introducing Cerulean, a symphony of sky-blue carnations cradled in the embrace of azure wrap, adorned with iridescent ribbons. This bouquet captures the serene beauty of a tranquil sea, offering a peaceful whisper of nature’s elegance for moments of reflection or celebration.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Cerulean-2c-342854.jpg?v=1721222468",
            "/images/products/Cerulean-1c.jpg?v=1721181252"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1125,
        "name": "Aimee",
        "handle": "aimee",
        "description": "Introducing \"Aimee\" - A Vision of Spring’s First Blush\"Aimee\" is an exquisite bouquet of pink-tipped carnations set against soft white blossoms. Wrapped in delicate shades of pink, this arrangement whispers of innocence and celebrates the gentle joy of nature’s beauty in bloom.Place your orders now and enjoy free flower delivery across Malaysia.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Aimee-2a.jpg?v=1721181466",
            "/images/products/Aimee-1a.jpg?v=1721181465"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1126,
        "name": "Delia",
        "handle": "delia",
        "description": "Pink carnations exude affection with their delicate petals, while white eustoma symbolizes purity and serenity. Together, they create a bouquet of elegance and innocence. Get them today to show your appreciation.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Delia-2e.jpg?v=1721181982",
            "/images/products/Delia-1e.jpg?v=1721181982"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations . Eustoma"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1127,
        "name": "Wisteria",
        "handle": "wisteria",
        "description": "\"Wisteria\" is a bouquet that captures the tranquility of a serene garden with its delicate array of white and subtle lavender carnations. The soft whisper of color, entwined with silken lilac ribbons, makes it a graceful tribute to quiet elegance and timeless sophistication.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Carnation Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Wisteria-2b.jpg?v=1720952378",
            "/images/products/Wisteria-1b.jpg?v=1720952378"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1128,
        "name": "Emily",
        "handle": "emily",
        "description": "This charming floral arrangement features a selection of rich, velvety red roses paired with lush carnations in a similar hue, artfully placed in a rustic, woven basket. A delicate bow with hints of silver and transparent accents adds a touch of elegance to the bouquet. The natural basketry contrasts beautifully with the opulent red petals, making this arrangement a perfect gift for someone who appreciates classic beauty with a homey twist.",
        "type": "Flower Baskets",
        "tags": [
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Emily-1a-327561.jpg?v=1713490110",
            "/images/products/Emily-2a-229272.jpg?v=1713490110"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations"
            ]
        },
        "dimention": "Width*Height: 25cm*25cm"
    },
    {
        "id": 1129,
        "name": "Miriam",
        "handle": "miriam",
        "description": "Celebrate Mother's Day with the timeless beauty of vibrant red carnations, delicately wrapped in a crystal clear cellophane with a heartwarming red ribbon. Symbolizing love, admiration, and affection, this exquisite bouquet nestled against a charming, heart-shaped backdrop is a perfect expression of gratitude for the special woman in your life. Presented with elegance, it's more than just flowers—it's your heart wrapped in petals.",
        "type": "Bouquets",
        "tags": [],
        "price": "45.41",
        "inclTax": "45.41",
        "img": [
            "/images/products/Miriam-1a-709246.jpg?v=1713924938",
            "/images/products/Miriam-2a-801827.jpg?v=1713924938"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations"
            ]
        },
        "dimention": "Width*Height: 10cm*28cm"
    },
    {
        "id": 1130,
        "name": "Mariah",
        "handle": "mariah",
        "description": "This Mother's Day, honor her with an enchanting bouquet of pink-tinged carnations, each petal kissed with variegated shades of grace. Wrapped in a translucent pink sleeve and tied with a soft, blush ribbon, these blooms are a whisper of appreciation, a gentle reminder of the beauty and strength she embodies. Perfectly presented, they're not just a gift, but a delicate embrace in floral form.",
        "type": "Bouquets",
        "tags": [],
        "price": "45.41",
        "inclTax": "45.41",
        "img": [
            "/images/products/Mariah-1c-997986.jpg?v=1714098712",
            "/images/products/Mariah-2c-543013.jpg?v=1714098712"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Carnations"
            ]
        },
        "dimention": "Width*Height: 10cm*28cm"
    },
    {
        "id": 1133,
        "name": "Colourful Care Package HHP",
        "handle": "colourful-care-package-hhp",
        "description": "Colourful Care PackageIf your loved one is under the weather, this is the hamper for them! The Colourful Care Package is packed with a set of essence of chicken, and a flower arrangement to lift their spirits!",
        "type": "Hampers",
        "tags": [
            "Get Well Soon Hampers",
            "Halal Food Hampers"
        ],
        "price": "152.67",
        "inclTax": "152.67",
        "img": [
            "/images/products/Colourful-Care-Package-HHP1.jpg?v=1719280308",
            "/images/products/Colourful-Care-Package-HHP2.jpg?v=1719280308"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Kinohimitsu Esssence Of Chicken 6 x 75g",
                "2.  Assorted Flowers Arrangement",
                "3.  Exquisite Round Hat Gift Box (M)"
            ]
        },
        "dimention": "Width*Height: 35cm*40cm"
    },
    {
        "id": 1134,
        "name": "Bardolino Classico with Chocolates WGHP",
        "handle": "bardolino-classico-with-chocolates-wghp",
        "description": "Bardolino Classico with Chocolates  ",
        "type": "Hampers",
        "tags": [
            "Corporate Hampers",
            "Wine Gift Hampers"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Bardolino-Classico-with-Chocolates-WGHP1.jpg?v=1719979716",
            "/images/products/Bardolino-Classico-with-Chocolates-WGHP2.jpg?v=1719979717"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Domini Veneti Bardolino Classico 75cl",
                "2.  Hamlet with Bow Assorted Belgian Chocolate 125g",
                "3.  Cupido Chocolate Sticks 125g",
                "4.  Wooden Crate"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1135,
        "name": "Champagne Charms & Chocolate WGHP",
        "handle": "champagne-charms-chocolate-wghp",
        "description": "Champagne Charms  Chocolate ",
        "type": "Hampers",
        "tags": [
            "Corporate Hampers",
            "Wine Gift Hampers"
        ],
        "price": "312.41",
        "inclTax": "312.41",
        "img": [
            "/images/products/Champagne-Charms-_-Chocolate-WGHP1-195544.jpg?v=1720030233",
            "/images/products/Champagne-Charms-_-Chocolate-WGHP2-867645.jpg?v=1720030233"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Adrien Chopin Champagne Brut NV, France 75cl",
                "2.  Hamlet with Bow Assorted Belgian Chocolate 125g",
                "3.  Beryls Tiramisu Almond Milk Chocolate 65g",
                "4.  Round Gift Box"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1136,
        "name": "Fruity Surprise FHP",
        "handle": "fruity-surprise-fhp",
        "description": "Fruity Surprise",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers"
        ],
        "price": "125.28",
        "inclTax": "125.28",
        "img": [
            "/images/products/Fruity-Surprise-FHP1-362387.jpg?v=1720030522",
            "/images/products/Fruity-Surprise-FHP2_013fd575-65a0-4be8-80cf-553c54cf298d.jpg?v=1719986341"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Orange",
                "2.  Forelle Pear",
                "3.  Fuji Apple",
                "4.  Grapes",
                "5.  Packham Pear",
                "6.  Kiwi",
                "7.  Green Apple",
                "8.  Avacado",
                "9.  DragonFruit",
                "10.  Exquisite Wooden Crate (M)"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1137,
        "name": "Blush",
        "handle": "blush",
        "description": "Featuring a vibrant mix of sunflower, roses, daisies, and eucalyptus, this arrangement brings together an array of colors and textures. Perfect for celebrations or as a thoughtful gift, it's wrapped in elegant paper to add a touch of sophistication.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Blush-2b-391330.jpg?v=1720962058",
            "/images/products/Blush-1b_2d601b04-a825-4ab9-8c50-24be33c4b236.jpg?v=1720948619"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Rose . Eustomas . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1138,
        "name": "Success (VII)",
        "handle": "success-vii",
        "description": "This grand arrangement is a floral masterpiece featuring a mix of pink and purple blooms, including roses, gerberas, and chrysanthemums. Accented with lush greenery and elegantly wrapped in lavender and blush pink paper, it stands proudly on a black stand. This arrangement is perfect for significant celebrations, expressing congratulations, or enhancing any elegant event with its stunning presence.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Success-_VII_-1b.jpg?v=1721541477",
            "/images/products/Success-_VII_-2b.jpg?v=1721541477"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Brassica . Roses . Gerbera . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*170cm"
    },
    {
        "id": 1139,
        "name": "Success (VIII)",
        "handle": "success-viii",
        "description": "This striking arrangement features an abundance of vibrant red roses, beautifully complemented by lush greenery. The roses are elegantly wrapped in luxurious black paper and displayed on a black stand, creating a dramatic and sophisticated presentation. Perfect for grand occasions, expressions of deep affection, or as a bold statement piece in any setting.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "661.55",
        "inclTax": "661.55",
        "img": [
            "/images/products/Success-_VIII_-1b.jpg?v=1721492166",
            "/images/products/Success-_VIII_-2b.jpg?v=1721492166"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*170cm"
    },
    {
        "id": 1140,
        "name": "Success (IX)",
        "handle": "success-ix",
        "description": "This vibrant and cheerful standing bouquet features a mix of purple, pink, and blue flowers, accented with pops of yellow. The arrangement is beautifully wrapped in soft pastel yellow and lavender paper, tied with a large, elegant bow. Perfect for making a grand statement at any event or celebration.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "844.11",
        "inclTax": "844.11",
        "img": [
            "/images/products/Success-_IX_-1b.jpg?v=1721539150",
            "/images/products/Success-_IX_-2a.jpg?v=1721534259"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Carnations . Eustoma . Hydrangea . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*170cm"
    },
    {
        "id": 1141,
        "name": "Success (X)",
        "handle": "success-x",
        "description": "This striking stand arrangement features a stunning mix of vibrant red roses, cheerful yellow and pink gerberas, and lush greenery. Perfect for grand celebrations or corporate events, it adds a touch of elegance and sophistication to any venue. The floral-patterned stand enhances its charm, making it an eye-catching display.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Success-_X_-1a.jpg?v=1721624977",
            "/images/products/Success-_IX_-2a_dc5eacec-dc10-4df5-b03d-480a2916cb10.jpg?v=1721624976"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Gerbera .Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 1142,
        "name": "Swan",
        "handle": "swan-1",
        "description": "Introducing our Elegant White Bouquet, a harmonious blend of pristine white roses, calla lilies, and lush greenery. Artfully arranged and wrapped in delicate white paper, this bouquet exudes timeless elegance and sophistication, making it perfect for weddings, anniversaries, or any special occasion. Let this exquisite arrangement bring a touch of grace to your loved one's day.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Sorry Flowers & Bouquets",
            "Calla Lilies & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Swan-2a.jpg?v=1721954106",
            "/images/products/Swan-1a.jpg?v=1721954107"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Calla Lilies . Roses  .  Alstroemeria  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1143,
        "name": "Tinker Tale BHP",
        "handle": "tinker-tale-bhp",
        "description": "Tinker TaleThe Tinker Tale baby gift hamper celebrates the beautiful story of a new arrival. Pamper the precious newborn baby with quality grooming and bathtime essentials in this exquisite caddy organiser basket, and record the memories in the Hello Baby Milestone Book.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Tinker-Tale-BHP1-704288.jpg?v=1722463950",
            "/images/products/Tinker-Tale-BHP2.jpg?v=1722233154"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Baby K Diaper Caddy Organizer Basket",
                "2.  KinderNurture Organic Baby Wipes 40s",
                "3.  Drypers Wee Wee Dry Diapers M size (2pcs) x 3",
                "4.  Fiffy Organic Moisturizing Head To Toe Wash 750ml",
                "5.  Fiffy Organic Moisturizing Lotion 450ml",
                "6.  Fiffy Mini Cotton Buds 400 Buds",
                "7.  Fiffy Cotton Balls 100 Cotton Balls",
                "8.  Hello Baby Milestone Book"
            ]
        },
        "dimention": "Width*Height: 35cm x 30cm"
    },
    {
        "id": 1144,
        "name": "Double Dare BHP",
        "handle": "double-dare-bhp",
        "description": "Double DarePerfectly balanced in every way, this hamper has all the basic needs for two mischievous little ones. For a family with a baby girl and a baby boy, there’s twice as much fun to have!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "472.15",
        "inclTax": "472.15",
        "img": [
            "/images/products/Double-Dare-BHP1_98a77e3d-af25-4497-9b51-9a8a85b3b919.jpg?v=1722234550",
            "/images/products/Double-Dare-BHP.jpg?v=1722233783"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Baby K Romper Gift Set x 2",
                "2.  Bunny Plush Toys x 2 sets",
                "3.  Drypers Wee Wee Dry Diapers M size (2pcs) x 4 packs",
                "4.  Fiffy Organic Moisturizing Head To Toe Wash 750ml",
                "5.  Fiffy Organic Moisturizing Lotion 450ml",
                "6.  Baby K Dino Food Grade Teething Toy x 2",
                "7.  KinderNurture Organic Baby Wipes 80s x 2",
                "8.  Baby K Sippy Cup x 2",
                "9.  Baby Boy Non-Helium Balloons x 2",
                "10.  Exquisite Straw Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 30cm"
    },
    {
        "id": 1145,
        "name": "Double Trouble BHP",
        "handle": "double-trouble-bhp",
        "description": "Double TroubleTwo boys means double the trouble, but it also means double the excitement! This exquisite hamper covers all bases from bathtime to playtime and from mealtime to car time.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "472.15",
        "inclTax": "472.15",
        "img": [
            "/images/products/Double-Trouble-BHP1.jpg?v=1722234205",
            "/images/products/Double-Trouble-BHP2.jpg?v=1722234205"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Baby K Romper Gift Set x 2",
                "2.  Bunny Plush Toys x 2 sets",
                "3.  Drypers Wee Wee Dry Diapers M size (2pcs) x 4 packs",
                "4.  Fiffy Organic Moisturizing Head To Toe Wash 750ml",
                "5.  Fiffy Organic Moisturizing Lotion 450ml",
                "6.  Baby K Dino Food Grade Teething Toy x 2",
                "7.  KinderNurture Organic Baby Wipes 80s x 2",
                "8.  Baby K Sippy Cup x 2",
                "9.  Baby Boy Non-Helium Balloons x 2",
                "10.  Exquisite Straw Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 30cm"
    },
    {
        "id": 1146,
        "name": "Double Delight BHP",
        "handle": "double-delight-bhp",
        "description": "Double DelightTwo girls means twice the work but also twice the giggles! Love and care for the new lovely little ladies with this exceptional hamper that covers everything from meals to travel and from baths to play!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "472.15",
        "inclTax": "472.15",
        "img": [
            "/images/products/Double-Delight-BHP1.jpg?v=1722234446",
            "/images/products/Double-Delight-BHP2.jpg?v=1722234446"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Baby K Romper Gift Set x 2",
                "2.  Bunny Plush Toys x 2 sets",
                "3.  Drypers Wee Wee Dry Diapers M size (2pcs) x 4 packs",
                "4.  Fiffy Organic Moisturizing Head To Toe Wash 750ml",
                "5.  Fiffy Organic Moisturizing Lotion 450ml",
                "6.  Baby K Dino Food Grade Teething Toy x 2",
                "7.  KinderNurture Organic Baby Wipes 80s x 2",
                "8.  Baby K Sippy Cup x 2",
                "9.  Baby Boy Non-Helium Balloons x 2",
                "10.  Exquisite Straw Basket"
            ]
        },
        "dimention": "Width*Height: 35cm x 30cm"
    },
    {
        "id": 1147,
        "name": "Denice",
        "handle": "denice-5",
        "description": "This elegant bouquet showcases a stunning cluster of purple hydrangeas complemented by white blooms and greenery. Wrapped in soft, white paper with a lavender ribbon, it exudes a serene and sophisticated charm, perfect for expressing your sentiments on any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Denice-2a-117888.jpg?v=1722357534",
            "/images/products/Denice-1a-717087.jpg?v=1722357534"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1148,
        "name": "Eiffel",
        "handle": "eiffel",
        "description": "Indulge in the allure of romance with our captivating bouquet featuring 12 radiant red roses elegantly encased in a chic black wrapper. This sophisticated arrangement exudes passion and mystery, making it a perfect gift for anniversaries, declarations of love, or any special occasion. Elevate your gesture with this timeless expression of affection.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Eiffel-2a_282c00e5-5bd0-4916-8038-3f32cbdbac0a.jpg?v=1722298228",
            "/images/products/Emani-2b.jpg?v=1722298228"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1149,
        "name": "Elvira",
        "handle": "elvira-1",
        "description": "Brighten any room with our cheerful mixed rose bouquet. Featuring an enchanting blend of orange, yellow, peach, pink, and blue roses, this vibrant arrangement is complemented by delicate daisies and lush eucalyptus leaves. Wrapped in soft blue paper, it's perfect for bringing a splash of color and joy to any celebration or special moment.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Elvira-1a-254605.jpg?v=1722357537",
            "/images/products/Elvira-2a-731628.jpg?v=1722357537"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Spray Roses . Tulip . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 1150,
        "name": "Emberlyn",
        "handle": "emberlyn",
        "description": "This bouquet showcases a vibrant mix of tulips in a variety of colors, including red, pink, orange, and white. The tulips are elegantly arranged and wrapped in a translucent, iridescent paper, secured with a white ribbon. The combination of colors and the iridescent wrapping create a cheerful and fresh presentation, perfect for brightening any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Emberlyn-2a-641427.jpg?v=1722357537",
            "/images/products/Emberlyn-1a-117452.jpg?v=1722357537"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 1151,
        "name": "Pipsqueak Pals BHP",
        "handle": "pipsqueak-pals-bhp",
        "description": "Pipsqueak PalsOur Pipsqueak Pals is perfect for baby showers, or just to celebrate your little one’s arrival! Featuring the Baby K Signature Diaper Bag, Drypers DryPantz Diapers, and a cute Bunny Plush Toy, all presented in an exquisite tray box. This diaper bag is the perfect companion for parents on the go, with ample space to carry all the essentials!",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Pipsqueak-Pals-BHP1_1a59ee80-be48-4f1d-ac61-ca5a1b4cd250.jpg?v=1722993877",
            "/images/products/Pipsqueak-Pals-BHP2_3c73f16a-2bd0-4a85-a257-42ae788339a2.jpg?v=1722993878"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Baby K Signature Diaper Bag",
                "2.  Drypers DryPantz Diapers M size (4 pcs) x2",
                "3.  Bunny Plush Toy",
                "4.  Exquisite Tray Box"
            ]
        },
        "dimention": "Width*Height: 35cm x 40cm"
    },
    {
        "id": 1152,
        "name": "Joe",
        "handle": "joe",
        "description": "Brighten any space with our charming spring tulip bouquet. Featuring a delightful mix of orange and white tulips, accented with blue thistle and lush greenery, this arrangement is wrapped in soft peach paper. Perfect for celebrating new beginnings, expressing gratitude, or simply bringing a burst of joy and color to any occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Joe-2a-352159.jpg?v=1723167082",
            "/images/products/Joe-1a-507534.jpg?v=1723167082"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1153,
        "name": "Koala",
        "handle": "koala",
        "description": "Introducing our luxurious bouquet featuring a captivating trio of meta roses and three cappuccino roses, their deep red and warm coffee tones weaving a tale of passion and refinement. White eustoma adds an ethereal touch, radiating purity and grace. Nestled among these blooms, caspia imparts delicate texture, while eucalyptus leaves provide a refreshing contrast.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Eustoma Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Koala-2c.jpg?v=1723329541",
            "/images/products/Koala-1c.jpg?v=1723329541"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses .  Matthiola  .  Eustomas  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1154,
        "name": "Champagne with Flowers & Chocolates WGHP",
        "handle": "champagne-with-flowers-chocolates",
        "description": "Champagne with Flowers  Chocolates ",
        "type": "Hampers",
        "tags": [
            "Corporate Hampers",
            "Wine Gift Hampers"
        ],
        "price": "355.76",
        "inclTax": "355.76",
        "img": [
            "/images/products/Champagne-with-Flowers-_-Chocolates-WGHP1.jpg?v=1723444211",
            "/images/products/Champagne-with-Flowers-_-Chocolates-WGHP2_2b81c25d-a827-499a-8a68-1b723aa6bf35-971439.jpg?v=1723541630"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Aubert et Fils Brut Champagne N.V. 75cl",
                "2.  Hamlet Assorted Belgian Chocolates 125g",
                "3.  Cupido Chocolate Sticks 125g",
                "4.  Exquisite Flower Arrangement",
                "5.  Wooden Crate (L)"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1155,
        "name": "Domini Veneti Bardolino Classico With Flowers & Chocolates WGHP",
        "handle": "domini-veneti-bardolino-classico-with-flowers-chocolates-wghp",
        "description": "Domini Veneti Bardolino Classico With Flowers  Chocolates",
        "type": "Hampers",
        "tags": [
            "Corporate Hampers",
            "Wine Gift Hampers"
        ],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/Domini-Veneti-Bardolino-Classico-With-Flowers-_-Chocolates-WGHP1.jpg?v=1723523451",
            "/images/products/Domini-Veneti-Bardolino-Classico-With-Flowers-_-Chocolates-WGHP2.jpg?v=1723523451"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Domini Veneti Bardolino Classico 75cl",
                "2.  Hamlet Assorted Belgian Chocolates 125g",
                "3.  Cupido Chocolate Sticks 125g",
                "4.  Exquisite Flower Arrangement",
                "5.  Wooden Crate (L)"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1156,
        "name": "Natural Nurture BHP",
        "handle": "natural-nurture-bhp",
        "description": "Natural Nurture This beautiful, classically styled hamper is an essential addition to any organic fanatic’s life! It contains all-natural, pure, and healthy products that baby will love.",
        "type": "Hampers",
        "tags": [
            "Baby Hampers"
        ],
        "price": "618.19",
        "inclTax": "618.19",
        "img": [
            "/images/products/Natural-Nurture-BHP1-633415.jpg?v=1723542032",
            "/images/products/Natural-Nurture-BHP2.jpg?v=1723524708"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.   Gaia Baby  Natural Baby Powder 100g",
                "2.  Gaia Baby -Baby Massage Oil 125ml",
                "3.  Gaia Baby  Soothing Cream 100ml",
                "4.  Gaia Baby  Hair & Body Wash 200ml",
                "5.  Gaia Baby  Moisturiser 250ml",
                "6.  Gaia Baby  Bamboo Baby Wipes 80s",
                "7.  Shears Organic  2 Pcs Gift Set",
                "8.  Exquisite Gift Basket"
            ]
        },
        "dimention": "Width*Height: As Per Photo Shown"
    },
    {
        "id": 1157,
        "name": "Organic Goodness OHP",
        "handle": "organic-goodness-ohp",
        "description": "Organic GoodnessWith the most delectable fruits and refreshing fruity drinks, the Organic Goodness is definitely one of a kind. Apart from the freshest fruits, the interesting and artisanal drinks will surely impress anyone!",
        "type": "Hampers",
        "tags": [
            "Fruit Hampers",
            "Organic Hampers"
        ],
        "price": "221.13",
        "inclTax": "221.13",
        "img": [
            "/images/products/Organic-Goodness-FHP1-461650.jpg?v=1723542033",
            "/images/products/Organic-Goodness-FHP2-641744.jpg?v=1723542034"
        ],
        "detail_child": {
            "title": "Items",
            "description": [
                " 1.  English Tea Shop Organic  Super Berries 40g",
                "2. Basilur  Assorted black fruit teas 25s",
                "3. Firefly Peach & Green Tea Drink 330ml",
                "4. Firefly Kiwi Lime & Mint Drink 330ml",
                "5. Classic Juice  Apple with blackcurrant 300ml",
                "6. Orange",
                "7. Kiwi",
                "8. Green Apple",
                "9. Fuji Apple",
                "10. Nam Shui Pear",
                "11. Green Grape"
            ]
        },
        "dimention": "As Per Photo Shown"
    },
    {
        "id": 1158,
        "name": "Livia",
        "handle": "livia",
        "description": "Introducing our \"Pastel Elegance\" floral arrangement, a delicate blend of soft pinks, purples, and creams, featuring roses, lisianthus, and chrysanthemums. Set in a stylish glass vase, this arrangement exudes grace and charm, making it the perfect gift for any occasion or a stunning centerpiece to elevate your space. Celebrate beauty with timeless elegance.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Livia-2a-131153.jpg?v=1724158918",
            "/images/products/Livia-1a-955513.jpg?v=1724158918"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Rose . Spray Roses . Eustomas . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 16cm*28cm"
    },
    {
        "id": 1159,
        "name": "Leanne",
        "handle": "leanne-1",
        "description": "A bold, single deep red rose emerges from an artistic wrap of black and cream, creating a dramatic contrast. Tied with a cream ribbon, this striking bouquet symbolizes a deep and passionate love, perfectly encapsulated in one exquisite bloom.",
        "type": "Bouquets",
        "tags": [],
        "price": "45.41",
        "inclTax": "45.41",
        "img": [
            "/images/products/Leanne-1a-802268.jpg?v=1724158818",
            "/images/products/Leanne-2a-557929.jpg?v=1724158818"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Red Rose"
            ]
        },
        "dimention": "Width*Height: 20cm*30cm"
    },
    {
        "id": 1160,
        "name": "Candy",
        "handle": "candy",
        "description": "Experience the charm of our elegant mixed rose bouquet. This exquisite arrangement combines soft pink, creamy yellow, and pristine white roses with delicate accents of greenery and baby's breath. Wrapped in luxurious white paper and adorned with a satin ribbon, it is perfect for expressing love, gratitude, or celebrating any special occasion with timeless beauty.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Candy-2a.jpg?v=1724251568",
            "/images/products/Candy-1a.jpg?v=1724251568"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Eustoma . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1161,
        "name": "Luvyna",
        "handle": "luvyna",
        "description": "Experience the epitome of romance with our lavish bouquet showcasing 40 stunning red roses, elegantly encased in a soft pink wrapper. This grand arrangement is a breathtaking symphony of passion and elegance, perfect for expressing deep love on special occasions. Unveil the beauty of each bloom in this captivating ensemble, a timeless gesture that speaks volumes.",
        "type": "Bouquets",
        "tags": [],
        "price": "342.07",
        "inclTax": "342.07",
        "img": [
            "/images/products/Luvyna-2b-910442.jpg?v=1724512320",
            "/images/products/Luvyna-1b.jpg?v=1724507218"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "40 Roses"
            ]
        },
        "dimention": "Width*Height: 32cm*38cm"
    },
    {
        "id": 1162,
        "name": "Mikayla",
        "handle": "mikayla",
        "description": "This vibrant vase arrangement features a radiant mix of sunflowers, peach roses, and cheerful orange blooms, all beautifully complemented by lush greenery. The warm and lively color palette creates a bouquet that exudes joy and positivity, making it perfect for brightening up any room or celebrating special moments. Ideal as a thoughtful gift or a stunning centerpiece, this bouquet brings a burst of sunshine to any setting.",
        "type": "Vase Arrangement",
        "tags": [
            "Sunflower Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Mikayla-2a-759164.jpg?v=1724688023",
            "/images/products/Mikayla-1a-670388.jpg?v=1724688023"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Spray Roses . Eustomas . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*32cm"
    },
    {
        "id": 1163,
        "name": "Ananya",
        "handle": "ananya-1",
        "description": "Introducing our Vibrant Tulip Bouquet, a stunning arrangement featuring a symphony of colorful tulips in shades of pink, purple, yellow, red, and white. Each tulip is carefully selected and artfully arranged to create a bouquet that radiates joy and elegance. Wrapped in premium paper, this bouquet is perfect for any occasion, bringing a touch of springtime freshness to your special moments.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Ananya-2a.jpg?v=1724890585",
            "/images/products/Ananya-1a.jpg?v=1724890585"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Tulips"
            ]
        },
        "dimention": "Width*Height: 28cm*34cm"
    },
    {
        "id": 1164,
        "name": "Harmony (VIII)",
        "handle": "harmony-viii",
        "description": "Introducing our stunning Grand Opening Flower Stand, a vibrant and luxurious arrangement designed to captivate and celebrate the joyous occasion of a new beginning. Crafted with meticulous attention to detail, this magnificent display features a harmonious blend of sunflowers, orange gerbera daisies, exuding elegance and charm.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Harmony-_VIII_-1a.jpg?v=1724894823",
            "/images/products/Harmony-_VIII_-2a.jpg?v=1724894823"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Gerbera . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 60cm*160cm"
    },
    {
        "id": 1165,
        "name": "Miltonia",
        "handle": "miltonia",
        "description": "This stunning bouquet features a harmonious blend of soft pink hydrangeas, delicate roses, and elegant lisianthus blooms, accented with sprigs of baby's breath. Perfect for any occasion, this arrangement is presented in a clear glass vase, showcasing the fresh and vibrant colors. Ideal for brightening up any space, it makes a thoughtful gift or a beautiful centerpiece for your home.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Miltonia-2a.jpg?v=1724943070",
            "/images/products/Miltonia-1a-191886.jpg?v=1725054490"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea . Roses . Spray Roses . Eustoma . Matthiola . Baby's Breath . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 1166,
        "name": "Wallis BBS",
        "handle": "wallis",
        "description": "This charming arrangement features a radiant sunflower paired with classic red roses and delicate white blooms. Accented with soft greenery and nestled in an elegant signature bag, it brings a touch of warmth and sophistication, perfect for any occasion, adding a burst of cheerful elegance to any setting.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Wallis-2d-807036.jpg?v=1726288792",
            "/images/products/Wallis-1d.jpg?v=1726187689"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Roses . Spray Roses . Eustomas . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 22cm*30cm"
    },
    {
        "id": 1167,
        "name": "Benevolence (VII)",
        "handle": "benevolence-vii",
        "description": "This serene condolence stand features an elegant arrangement of white lilies, roses, and chrysanthemums, gracefully accented by lush greenery. Set in a tall, floral-patterned base, it conveys deep sympathy and heartfelt condolences, offering a gentle tribute during times of loss. A perfect gesture to express your support and care.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Benevolence-_VII_-1c.jpg?v=1727225487",
            "/images/products/Condolence-Card-2024_1_cb8946dd-42d6-49ed-a36d-db508e683d25.jpg?v=1727225545",
            "/images/products/Benevolence-_VII_-2b.jpg?v=1727225487"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemum . Orchids . Lilies . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 1168,
        "name": "Phyllis",
        "handle": "phyllis",
        "description": "This delightful bouquet features vibrant sunflowers surrounded by soft pink roses, delicate carnations, and lush greenery. Wrapped in elegant white and pink paper, it exudes warmth and cheerfulness, making it the perfect gift to brighten anyone's day. Ideal for birthdays, celebrations, or just because.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Sunflower Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Phyllis-2a.jpg?v=1726064042",
            "/images/products/Phyllis-1a-541575.jpg?v=1726113057"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers .  Roses  .  Eustomas  . Matthiola . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1169,
        "name": "Fairy",
        "handle": "fairy",
        "description": "This exquisite bouquet features a harmonious blend of delicate pink roses and vibrant red tulips, accented with sprigs of baby's breath. The arrangement is wrapped in soft pink and white paper, tied with a matching ribbon, creating an elegant and romantic presentation. Perfect for expressing love and appreciation on any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Roses Flowers & Bouquets",
            "Tulip Flowers & Bouquets"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Fairy-2a.jpg?v=1726064405",
            "/images/products/Fairy-1a.jpg?v=1726064405"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Tulips . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1170,
        "name": "Ruby",
        "handle": "ruby",
        "description": "Celebrate elegance with our stunning mixed bouquet. Featuring a lush combination of pink hydrangeas, vibrant red roses, and delicate blush blooms, this arrangement is accented with fresh greenery. Wrapped in bold red paper and tied with a satin ribbon, it's perfect for expressing love, admiration, or adding a touch of sophistication to any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Sorry Flowers & Bouquets",
            "Hydrangea Flowers & Bouquets"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Ruby-2a.jpg?v=1726064541",
            "/images/products/Ruby-1a.jpg?v=1726064541"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea  .  Roses  .  Spray Roses . Eustomas  .  Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 26cm*32cm"
    },
    {
        "id": 1171,
        "name": "Katherine",
        "handle": "katherine",
        "description": "Celebrate in style with our vibrant mixed flower bouquet. Featuring elegant white lilies, soft pink roses, and an array of colorful blooms, this arrangement is accented with lush greenery and wrapped in bold red paper. Perfect for expressing love, joy, and appreciation, it adds a burst of color and elegance to any special occasion.",
        "type": "Bouquets",
        "tags": [
            "Anniversary Flowers",
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Romantic Flowers & Bouquets",
            "Sorry Flowers & Bouquets",
            "Lilies Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Katerine-2a.jpg?v=1726064626",
            "/images/products/Katerine-1a-438887.jpg?v=1726112864"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Lilies . Roses . Spray Roses . Matthiola . Fillers & Foliage "
            ]
        },
        "dimention": "Width*Height: 32cm*46cm"
    },
    {
        "id": 1172,
        "name": "Elora",
        "handle": "elora",
        "description": "This charming floral arrangement showcases a stunning sunflower at its heart, surrounded by delicate white roses, cheerful yellow gerberas, and vibrant red blooms. Accented with lush eucalyptus and sprigs of greenery, it sits gracefully in a golden hatbox. Perfect for brightening any space, this bouquet brings a sense of warmth, joy, and elegance to any occasion.",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Elora-2a-567647.jpg?v=1726200404",
            "/images/products/Elora-1a-642832.jpg?v=1726200404"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Roses . Eustoma . Gerbera . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*25cm"
    },
    {
        "id": 1173,
        "name": "Emanuela",
        "handle": "emanuela",
        "description": "This delightful arrangement features a radiant sunflower, pastel pink carnations, and lavender-hued chrysanthemums, accented with sprigs of statice and lush greenery. Presented in a chic white hatbox adorned with a delicate ribbon, this bouquet embodies elegance and charm, making it a perfect gift for brightening any room with a touch of sunshine and floral beauty.",
        "type": "Flower Boxes",
        "tags": [],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Emanuela-2a-452508.jpg?v=1726200403",
            "/images/products/Emanuela-1a.jpg?v=1726196415"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflower . Eustoma . Mum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 15cm*20cm"
    },
    {
        "id": 1174,
        "name": "Trixie",
        "handle": "trixie",
        "description": "This vase arrangement features a radiant mix of sunflowers and soft pastel blooms, including pale peach roses and delicate carnations, complemented by cheerful chamomile flowers and fresh greenery. The bright sunflowers bring a burst of sunshine, while the gentle hues of peach and cream add a touch of elegance and serenity. Perfect for brightening up any room or occasion, this bouquet is thoughtfully presented in a charming glass vase, tied with a satin ribbon for a refined finishing touch.",
        "type": "Vase Arrangement",
        "tags": [
            "Sunflower Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Trixie-2c.jpg?v=1727402454",
            "/images/products/Trixie-1c.jpg?v=1727402454"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Roses . Carnation . Eustomas . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*32cm"
    },
    {
        "id": 1175,
        "name": "Benevolence (VIII)",
        "handle": "benevolence-viii",
        "description": "This stunning arrangement features a burst of white pom-pom chrysanthemums, soft lavender carnations, and striking birds of paradise, all set against lush green foliage. Arranged in a charming floral-patterned stand, this display adds elegance and vibrancy to any setting, making it perfect for grand openings, celebrations, or as a heartfelt gesture of appreciation. A beautiful blend of nature’s finest blooms to create a lasting impression.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Benevolence-_VIII_1a.jpg?v=1726578123",
            "/images/products/Benevolence-_VIII_2a.jpg?v=1726578123"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemum . Birds of Paradise . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*170cm"
    },
    {
        "id": 1176,
        "name": "Tindra",
        "handle": "tindra",
        "description": "This charming vase arrangement bursts with color and joy, featuring a radiant sunflower paired with cheerful yellow lilies, orange tulips, and soft pink and peach roses. Accents of white ranunculus and sprigs of eucalyptus add a fresh, natural touch. Perfect for brightening any space, it brings warmth, elegance, and a sense of happiness to every occasion.",
        "type": "Vase Arrangement",
        "tags": [
            "Sunflower Bouquets"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Tindra-2b-405333.jpg?v=1727037585",
            "/images/products/Tindra-1b-677525.jpg?v=1727037585"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Roses . Carnation . Eustomas . Alstroemeria . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 20cm*32cm"
    },
    {
        "id": 1177,
        "name": "Acacia",
        "handle": "acacia",
        "description": "This floral arrangement features a vibrant mix of blooms, including large pink lilies, deep red roses, soft pink carnations, and delicate purple orchids. Complemented by lush greenery and placed in a clear glass vase, the bouquet radiates elegance and freshness. Perfect for special occasions or simply to brighten up a room, the soft ribbon accent adds a touch of sophistication.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Acacia-2b-582143.jpg?v=1727153767",
            "/images/products/Acacia-1b-371564.jpg?v=1727153767"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Lilies . Eustoma . Carnations . Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 1178,
        "name": "Arabella",
        "handle": "arabella",
        "description": "This beautiful bouquet features a delightful mix of pastel pink roses and lavender carnations, complemented by delicate greenery. The arrangement, set in a clear glass vase, exudes elegance and charm, perfect for special occasions or as a thoughtful gift. The flowers are tied with a ribbon, adding a touch of grace and sophistication to the presentation.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Arabella-2b-990223.jpg?v=1727261337",
            "/images/products/Arabella-1b-580954.jpg?v=1727261337"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Lilies . Eustoma . Carnations . Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 30cm*40cm"
    },
    {
        "id": 1179,
        "name": "Timothea",
        "handle": "timothea",
        "description": "This charming bouquet showcases a lush gathering of white and yellow chamomile flowers, arranged in a rounded, natural style. The tiny daisy-like blooms, with their bright yellow centers, create a fresh and cheerful look. The arrangement is placed in a clear glass vase, tied with a ribbon, making it a perfect choice for adding a touch of rustic beauty and simplicity to any setting.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Timothea-2a-207479.jpg?v=1727156018",
            "/images/products/Timothea-1a-657029.jpg?v=1727156018"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Daisies . Baby's Breath"
            ]
        },
        "dimention": "Width*Height: 25cm*30cm"
    },
    {
        "id": 1180,
        "name": "Harmony (IX)",
        "handle": "harmony-ix",
        "description": "This stunning floral arrangement features an abundant display of vibrant orange and yellow blooms, accented with lush greenery and delicate white flowers. Set on a black tripod stand, the bouquet radiates energy and warmth, making it perfect for celebratory events or grand occasions. The mix of roses, orchids, and wildflowers creates a striking balance of elegance and natural beauty, brightening up any space with its bold color palette.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "684.37",
        "inclTax": "684.37",
        "img": [
            "/images/products/Harmony-_IX_-1a.jpg?v=1727060132",
            "/images/products/Harmony-_IX_-2a.jpg?v=1727060132"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Roses . Orchids . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 60cm*160cm"
    },
    {
        "id": 1181,
        "name": "Harmony (X)",
        "handle": "harmony-x",
        "description": "This captivating arrangement bursts with a vibrant mix of champagne roses, striking sunflowers, and orange spray roses, all accented with lush eucalyptus foliage. Wrapped in elegant pastel papers and tied with a red satin ribbon, it exudes charm and warmth. Perfect for brightening any room or special occasion, this bouquet offers a stunning display of color and grace.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "752.83",
        "inclTax": "752.83",
        "img": [
            "/images/products/Harmony-_X_-1a.jpg?v=1727135714",
            "/images/products/Harmony-_X_-2a.jpg?v=1727135714"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Roses . Spray Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 60cm*160cm"
    },
    {
        "id": 1182,
        "name": "Benevolence (IX)",
        "handle": "benevolence-ix",
        "description": "This elegant condolence arrangement features a serene mix of white and peach roses, delicate chrysanthemums, and soft pink fillers, symbolizing peace and comfort. Accented with lush greenery and thoughtfully wrapped in muted tones, it offers a gentle expression of sympathy. A heartfelt tribute, bringing warmth and solace during times of grief and remembrance.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "684.37",
        "inclTax": "684.37",
        "img": [
            "/images/products/Benevolence-_IX_-1a.jpg?v=1727138331",
            "/images/products/Benevolence-_IX_-2a.jpg?v=1727138332"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Chrysanthemum . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1183,
        "name": "Luxury (VI)",
        "handle": "luxury-vi",
        "description": "This vibrant grand opening stand features a stunning array of bright sunflowers, radiant orange gerberas, and cheerful yellow oncidium orchids. Accented with lush green foliage, this elegant arrangement exudes positivity and success, making it the perfect centerpiece for celebrating new beginnings. The floral stand’s graceful design adds a touch of sophistication, setting the stage for a prosperous venture.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "456.17",
        "inclTax": "456.17",
        "img": [
            "/images/products/Luxury-_VI_-2aa-149051.jpg?v=1727262258",
            "/images/products/Grand-Opening-Card-2024_484de493-4f19-4133-9457-32a0987bdfe4.jpg?v=1721487966",
            "/images/products/Luxury-_VI_-1a-592627.jpg?v=1727262258"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Sunflowers . Gerbera . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 1184,
        "name": "Remembrance (I)",
        "handle": "remembrance-i",
        "description": "This grand opening stand showcases a captivating blend of blue hydrangeas, soft pink roses, and lush green foliage. Enhanced with delicate white blooms and a floral-themed base, the arrangement exudes elegance and charm. Perfect for marking a momentous occasion, it brings an air of grace and celebration, symbolizing growth and success in any new venture.",
        "type": "Flower Stands",
        "tags": [
            "Condolence Flower Stands"
        ],
        "price": "547.45",
        "inclTax": "547.45",
        "img": [
            "/images/products/Remembrance-_I_-2a.jpg?v=1727224343",
            "/images/products/Condolence-Card-2024_1.jpg?v=1720187690",
            "/images/products/Remembrance-_I_-1a.jpg?v=1727224343"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangeas . Roses . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 80cm*160cm"
    },
    {
        "id": 1185,
        "name": "Luxury (VII)",
        "handle": "luxury-vii",
        "description": "This striking grand opening stand bursts with vibrant red gerberas, fiery ginger flowers, and luxurious orchids. Complemented by soft pink and white accents, along with bold tropical greens, the arrangement radiates energy and passion. Set in a floral-printed base, it embodies strength and celebration, making it the perfect centerpiece to herald success and prosperity for any grand occasion.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Luxury-_VII_-2a.jpg?v=1727227925",
            "/images/products/Grand-Opening-Card-2024_484de493-4f19-4133-9457-32a0987bdfe4.jpg?v=1721487966",
            "/images/products/Luxury-_VII_-1a.jpg?v=1727227926"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea . Roses . Gerbera . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 1186,
        "name": "Luxury (VIII)",
        "handle": "luxury-viii",
        "description": "This stunning floral stand features a vibrant mix of peach gerberas, delicate pink roses, and lush greenery, accented with pops of purple blooms. Presented in an elegant floral-patterned stand adorned with soft pink ribbons, this arrangement exudes grace and charm, making it the perfect gesture for grand openings, celebratory events, or heartfelt congratulations.",
        "type": "Flower Stands",
        "tags": [
            "Grand Opening Flower Stands"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Luxury-_VIII_-2a-556610.jpg?v=1727262258",
            "/images/products/Grand-Opening-Card-2024_484de493-4f19-4133-9457-32a0987bdfe4.jpg?v=1721487966",
            "/images/products/Luxury-_VIII_-1a-648914.jpg?v=1727262258"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                "Hydrangea . Roses . Gerbera . Eustoma . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 90cm*180cm"
    },
    {
        "id": 1187,
        "name": "Nyomi",
        "handle": "nyomi",
        "description": "This charming basket arrangement combines soft blue hydrangeas, delicate white roses, and eustomas, enhanced by sprigs of lush greenery. Set in a woven basket, this floral piece brings a fresh and serene atmosphere, making it a perfect centerpiece for special occasions or a thoughtful gift. Its gentle color palette adds an elegant touch to any space.",
        "type": "Flower Baskets",
        "tags": [
            "Birthday Flowers & Bouquets",
            "Congratulations Flowers",
            "Get Well Soon Flowers & Bouquets",
            "Romantic Flowers & Bouquets",
            "Carnation Flowers & Bouquets",
            "Roses Flowers & Bouquets"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Nyomi-2b.jpg?v=1727312997",
            "/images/products/Nyomi-1b.jpg?v=1727312997"
        ],
        "detail_child": {
            "title": "Flowers Used",
            "description": [
                " Bright Pink Roses . Carnations . Spray Rose . Fillers & Foliage"
            ]
        },
        "dimention": "Width*Height: 28cm*30cm"
    }
]