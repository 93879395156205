import React from 'react'
import { useState } from 'react'
import './NavMenuBlock.css'
import roundModal from '../../assets/icon/round-modal.svg'
import { useNavigate } from 'react-router-dom';

export default function NavMenuBlock() {
    const [modalShow, setModalShow] = useState(false)

    const occasionsElements = [{"title": 'Grand Opening', "link": "/grand-opening-flowers"}, {"title": 'Condolence', "link": "/condolence"}, {"title": 'Wedding', "link": "/wedding-flower-bouquets"} , {"title": 'Graduation', "link": "/graduation-flower-bouquets"} , {"title": 'Anniversary', "link": "/anniversary-flower-bouquets"} , {"title": 'Birthday', "link": "/birthday-flower-bouquets"}, {"title": 'Congratulation', "link": "/congratulation-flower-bouquets"}, {"title": 'Get Well Soon', "link": "/get-well-soon-flower-bouquets"} , {"title": 'Newborn', "link": "/newborn-flower-bouquets"}, {"title": 'Proposal/Romantic', "link": "/romantic"}, {"title": 'Sorry', "link": "/sorry-flower-bouquets"} , {"title": 'Engagement/Bridal', "link": "/engagement-flower-bouquets"}]
    const hampersElements = [{"title": 'Baby Hampers', "link": "/baby-hampers"}, {"title": 'Fruit Hampers', "link": "/fruit-hampers"}, {"title": 'Gourmet Hampers', "link": "/gourmet-hampers"}, {"title": 'Tea Set Hampers', "link": "/tea-set-hampers"}, {"title": 'Wellness Hampers', "link": "/wellness-hampers"}, {"title": 'Organic Hampers', "link": "/organic-hampers"}, {"title": 'Chocolate Hampers', "link": "/chocolate-hampers"} , {"title": 'Corporate Hampers', "link": "/corporate-hampers"} , {"title": 'Halal Food Hampers', "link": "/halal-food-hampers"}, {"title": 'Wine Gifts', "link": "/wine-gifts"}]
    const shopFlowersElements = [{"title": "Baby's Breath", "link": "/baby-breath-bouquet-malaysia"}, {"title": 'Calla Lily', "link": "/calla-lily-flower-bouquets"}, {"title": 'Carnations', "link": "/carnation-bouquet-flowers"} , {"title": 'Eustoma', "link": "/eustoma-flower-bouquets"} , {"title": 'Hydrangea', "link": "/hydrangea-bouquet-flowers"}, {"title": 'Lilies', "link": "/lilies"}, {"title": 'Roses', "link": "/rose-bouquets-malaysia"}, {"title": 'Sunflower', "link": "/sunflower-bouquets-malaysia"}, {"title": 'Tulip', "link": "/tulip-flower-bouquets"}]
    const flowersBalloonElements = [{"title": 'Newborn/Baby FNB', "link": "/newborn-fnb"}, {"title": 'Well Wishes/Occasions FNB', "link": "/get-well-soon-fnb"}, {"title": 'Happy Birthday FNB', "link": "/happy-birthday-fnb"}, {"title": 'Congratulations FNB', "link": "/congratulations"}, {"title": 'Anniversary FNB', "link": "/anniversary-fnb"}, {"title": 'Graduation FNB', "link": "/graduation-fnb"}]

    function showElement(elements) {
        return(
            <div className='header__modal-block'><img className='modal__round right' src={roundModal} /><img className='modal__round' src={roundModal} draggable='false' />{elements.map((el) => <button onClick={() => {navigate(el.link)}} className='header__modal-button'>{el.title}</button>)}</div>
        )
    }

    const navigate = useNavigate();


    return (
        <ul className='header__menu-nav-block'>
            <li onClick={() => {navigate('/')}} className='header__menu-button'>Home</li>
            <li onClick={() => {navigate('/graduation-flower-bouquets')}} className='header__menu-button'>Graduation</li>
            <li onMouseEnter={() => {setModalShow('occasions')}} onMouseLeave={() => {setModalShow(null)}} className='header__menu-button'>Occasions{modalShow === "occasions" && (showElement(occasionsElements))}</li>
            <li onMouseEnter={() => {setModalShow('hampers')}} onMouseLeave={() => {setModalShow(null)}} className='header__menu-button'>Hampers{modalShow === "hampers" && (showElement(hampersElements))}</li>
            <li onMouseEnter={() => {setModalShow('shopFlowers')}} onMouseLeave={() => {setModalShow(null)}} className='header__menu-button'>Shop Flowers{modalShow === "shopFlowers" && (showElement(shopFlowersElements))}</li>
            <li onMouseEnter={() => {setModalShow('flowersBalloon')}} onMouseLeave={() => {setModalShow(null)}} className='header__menu-button'>Flowers & Balloons{modalShow === "flowersBalloon" && (showElement(flowersBalloonElements))}</li>
        </ul>
    )

    
}
