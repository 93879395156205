import React from 'react'

import Header from '../components/Header/Header'
import FaqAccordion from '../components/FaqAccordion/FaqAccordion'
import Footer from '../components/Footer/Footer'

import banerMain from '../assets/images/bg-header.png'
import freeShipin from '../assets/icon/free-shipping.svg'
import priceDown from '../assets/icon/price-down.svg'
import moneyBack from '../assets/icon/money-back.svg'
import flowers from '../assets/icon/flowers.svg'
import ShopifySecion from '../components/ShopifySection/ShopifySection'
import illustration from '../assets/images/bouquet-roses.png'

import ScrollToTop from '../global/ScrollToTop'

export default function Home() {
  const blocksProducts = [
    ['Flower Bouquets', 'Give your loved one a stunning flower bouquet to celebrate their achievements or show how much you appreciate them.', 'Bouquets'],
    ['Flower Bags', 'Perfect for gifting or enhancing your space, these flowers in a bag combine beauty and functionality, providing a delightful touch to any setting.', 'Bag Series'],
    ['Flower Stands', 'Elevate the atmosphere at weddings, grand openings, or other significant gatherings with these magnificent flower stands.', 'Flower Stands'],
    ['Flowers & Balloons', 'Have your loved one experience the joy of combining traditional beauty with playful elements in our flowers and balloon collection.', 'Balloons & Flowers'],
    ['Fruit Hampers', 'Our thoughtfully curated fruit hampers combine the freshness of seasonal fruits with the artistry of our skilled florists.', 'Hampers']
  ]

  const FaqAccordionData = [
    {
      'title': 'What is special about Flowers Malaysia?',
      'body': 'What makes Flowers Malaysia special is our quick delivery service and our extensive range of fresh flowers. Whether you’re looking for delicate peonies as a gift for a first date or wedding carnations for your big day, Flowers Malaysia will prepare the best floral arrangement for any occasion and have it delivered to you anywhere in Malaysia on the same day.'
    },
    {
      'title': 'How fast can flowers be delivered?',
      'body': "At Flowers Malaysia, we understand the importance of timely deliveries. That's why we offer same-day flower delivery services, ensuring your beautiful blooms reach their destination promptly."
    },
    {
      'title': 'How do I choose the perfect bouquet for different occasions?',
      'body': 'Here’s what you can consider when you choose to order flowers online for a special occasion:Graduation: Sunflowers and Carnations are great celebratory bouquets due to their gorgeous blooms and colours. Add a balloon, plushie, or their favourite chocolate bars into the bouquet for a great touch!Anniversary: Make your anniversary memorable with a beautifully curated bouquet to show your love. Roses, hydrangeas or peonies are great selections for grand romantic gestures as they come with dainty yet passionate colours that symbolise strong passion.Get well soon: Buying a bouquet for a loved one or a friend who has fallen ill? Enliven their day with bright-coloured blooms! Sunflowers, Tulips and Carnations are excellent choices due to their variety of cheerful shades and hues bound to brighten someone’s day.Birthday: Celebrate your loved one’s special day with unique blooms! Hydrangea, multi-coloured Baby Breaths or even dried bouquets such as our Everlast, which is specially curated with a mix of Gossypium and dried Lavender, are impressive flowers bound to impress. Looking for more options? Check out our range of bouquets available for any occasion!'
    },
    {
      'title': 'What should I do if the flowers are damaged or incorrect?',
      'body': "Before dispatch, our team meticulously checks each bouquet for accuracy and condition. We provide you with updates on your order's status before forwarding your flower bouquet for delivery."
    },
    {
      'title': 'Where do you deliver flowers?',
      'body': "Flowers Malaysia delivers flowers to the whole of Malaysia. This includes Kuala Lumpur, Georgetown, Malacca, Johor Bahru, Kota Kinabalu, Kuching, Ipoh, Shah Alam, Putrajaya, Alom Setar, and more."
    }
  ]

  return (
    <>
      <ScrollToTop />
      <Header />
      <img className='baner__main-image' src={banerMain} draggable='false'/>
      <div className='header__footer-block'>
        <h1 className='h1__main'>Flowers Malaysia: Your Trusted Florist Malaysia Providing Same-Day Flower Delivery</h1>
        <div className='header__footer-mini'>
          <div className='header__footer-mini-block'>
            <img src={freeShipin} draggable='false'/>
            <h5 className='header__footer-title'>Free Delivery</h5>
            <h4 className='header__footer-description'>Expect FREE flower delivery across Malaysia.</h4>
          </div>
          <div className='header__footer-mini-block'>
            <img src={priceDown} draggable='false'/>
            <h5 className='header__footer-title'>Affordability</h5>
            <h4 className='header__footer-description'>Order premium flowers online starting from $19.90</h4>
          </div>
          <div className='header__footer-mini-block'>
            <img src={moneyBack} draggable='false'/>
            <h5 className='header__footer-title'>Customer Care</h5>
            <h4 className='header__footer-description'>Get reliable support from our staff if you have inquiries.</h4>
          </div>
          <div className='header__footer-mini-block'>
            <img src={flowers} draggable='false'/>
            <h5 className='header__footer-title'>Our Promise</h5>
            <h4 className='header__footer-description'>If you’re not satisfied with the order, we offer re-deliveries.</h4>
          </div>
        </div>
      </div>
      <section>
        {blocksProducts.map((el) => <ShopifySecion title={el[0]} description={el[1]} type={el[2]} />)}
        <div className='section__block flex'>
          <img src={illustration} className='illustration__image' />
          <div className='section__info-text-block'>
            <h3 className='section__info-text-title'>Same Day Flower and Gift Delivery in Malaysia</h3>
            <p className='section__info-text-description'>Experience the convenience of same-day islandwide flower delivery with Flowers Malaysia. Whether it's a weekday surprise or a weekend celebration, we've got you covered with our seamless delivery service.</p>
            <h4 className='section__info-text-title-mini'>Delivery Time Slots: ROUND THE CLOCK!!!</h4>
            <p className='section__info-text-description'><span>Free Delivery:</span> Enjoy free islandwide delivery on all orders. No hidden fees, no surprises.</p>
            <p className='section__info-text-description'><span>Extra Charges for Specific Timing:</span> While delivery is free, opting for specific delivery timings may incur additional charges.</p>
            <p className='section__info-text-description'><span>Recipient Availability:</span> After placing your order online, ensure that the recipient will be present at the designated venue during the time of delivery to avoid extra charges for re-delivery.</p>
          </div>
        </div>
        <div className='section__block'>
          <h1 className='section__title'>FAQs on Flower Delivery</h1>
          <div className='faq-accordion__blocks'>
            <FaqAccordion data={FaqAccordionData[0]} />
            <FaqAccordion data={FaqAccordionData[1]} />
            <FaqAccordion data={FaqAccordionData[2]} />
            <FaqAccordion data={FaqAccordionData[3]} />
            <FaqAccordion data={FaqAccordionData[4]} />
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
