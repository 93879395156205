import React from 'react'
import { useParams } from 'react-router-dom'
import { products, catigories } from '../global/cartUtils'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import CardElement from '../components/CardElement/CardElement'
import FaqAccordion from '../components/FaqAccordion/FaqAccordion'

export default function MenuPage() {
    const { catigory } = useParams()

    return (
        <>
        <Header />
        <section>
          <div className='container__catigory'>
              <h5 className='product__path'>Flowers Malaysia / {catigories[catigory].title}</h5>
              <h1 className='catigory__name'>{catigories[catigory].title}</h1>
              <p className='catigory__description'>{catigories[catigory].description}</p>
              <div className='catigory__products-block'>
                  {products.map((el) => {
                    if (el.tags.includes(catigories[catigory].title)) {
                      return(<CardElement key={el.id}>{el}</CardElement>)
                    }
                  })}
              </div>
              <div style={{marginBottom: '100px'}}>
              </div>
          </div>
        </section>
        <Footer />
        </>
    )
}