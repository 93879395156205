import React, {useContext} from 'react'
import './Search.css'
import { products } from '../../global/cartUtils'
import { useRef, useState, useEffect } from 'react'
import CartContext from '../../global/CartContext'

export default function Search() {
  const ref = useRef(null)
  const blockSearch = useRef(null)

  const [showSearch, setShowSearch] = useState(false)
  const [items, setItem] = useState([])

  const { cart, addToCart } = useContext(CartContext);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'MYR',
  });

  const handleClickOutside = (event) => {
    if (blockSearch.current && !blockSearch.current.contains(event.target)) {
      setShowSearch(false);
      ref.current.value = ''
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function searchItems() {
    
    setItem([])
    if (ref.current.value) {
      setShowSearch(true)
      products.forEach((el) => {
        if (el.name.toLocaleLowerCase().startsWith(ref.current.value.toLocaleLowerCase())) {
          setItem((prevItems) => [...prevItems, el]);
        }
      });
    }
  }

  function showSearchItems() {
    if (items.length > 0){
      return(
        <div ref={blockSearch} className='cart__items-block search'>
          <h1 className='cart__total-price'>Products: {items.length}</h1>
          <div className='cart__elements-block search'>
            {
              items.map((el) => {
                return(
                  <div key={el.id} className='cart__item-block search'>
                    <div className='cart__item-main'>
                      <img className='cart__img-item' src={el.img[0]}/>
                      <div className='cart__item-details'>
                        <h3 className="cart__item-name">{el.name}</h3>
                        <h4 className="cart__item-price">{formatter.format(el.price)}</h4>
                        <h5 className="cart__item-incltax">{formatter.format(el.inclTax)} (Incl. Tax)</h5>
                      </div>
                    </div>
                    <button onClick={() => addToCart(el)} className='search-button__add-to-cart'>ADD TO CART</button>
                  </div>
                )
              })
            }
          </div>
        </div>
      )
    } else {
      return(
        <div ref={blockSearch} className='cart__items-block search'>
          <h4 className='cart__is-empty-text'>Nothing was found for your request...</h4>
        </div>
      )
    }
  }
  
  return (
    <div className='search__main-block'>
      <input ref={ref} onInput={() => searchItems()} className='input__search' placeholder='Search here...' />
      <button className='button__search'></button>
      {showSearch && showSearchItems()}
    </div>
  )
}
