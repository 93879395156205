import React from 'react'
import './MobileMenu.css'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function MobileMenu() {
    const [isMobileMenu, setMobile] = useState(false)

    const navigate = useNavigate();

    const [isOccasion, setOccasion] = useState(false)
    const [isHampers, setHampers] = useState(false)
    const [isFlowers, setFlowers] = useState(false)
    const [isBallons, setBallons] = useState(false)

    const occasionsElements = [{"title": 'Grand Opening', "link": "/grand-opening-flowers"}, {"title": 'Condolence', "link": "/condolence"}, {"title": 'Wedding', "link": "/wedding-flower-bouquets"} , {"title": 'Graduation', "link": "/graduation-flower-bouquets"} , {"title": 'Anniversary', "link": "/anniversary-flower-bouquets"} , {"title": 'Birthday', "link": "/birthday-flower-bouquets"}, {"title": 'Congratulation', "link": "/congratulation-flower-bouquets"}, {"title": 'Get Well Soon', "link": "/get-well-soon-flower-bouquets"} , {"title": 'Newborn', "link": "/newborn-flower-bouquets"}, {"title": 'Proposal/Romantic', "link": "/romantic"}, {"title": 'Sorry', "link": "/sorry-flower-bouquets"} , {"title": 'Engagement/Bridal', "link": "/engagement-flower-bouquets"}]
    const hampersElements = [{"title": 'Baby Hampers', "link": "/baby-hampers"}, {"title": 'Fruit Hampers', "link": "/fruit-hampers"}, {"title": 'Gourmet Hampers', "link": "/gourmet-hampers"}, {"title": 'Tea Set Hampers', "link": "/tea-set-hampers"}, {"title": 'Wellness Hampers', "link": "/wellness-hampers"}, {"title": 'Organic Hampers', "link": "/organic-hampers"}, {"title": 'Chocolate Hampers', "link": "/chocolate-hampers"} , {"title": 'Corporate Hampers', "link": "/corporate-hampers"} , {"title": 'Halal Food Hampers', "link": "/halal-food-hampers"}, {"title": 'Wine Gifts', "link": "/wine-gifts"}]
    const shopFlowersElements = [{"title": "Baby's Breath", "link": "/baby-breath-bouquet-malaysia"}, {"title": 'Calla Lily', "link": "/calla-lily-flower-bouquets"}, {"title": 'Carnations', "link": "/carnation-bouquet-flowers"} , {"title": 'Eustoma', "link": "/eustoma-flower-bouquets"} , {"title": 'Hydrangea', "link": "/hydrangea-bouquet-flowers"}, {"title": 'Lilies', "link": "/lilies"}, {"title": 'Roses', "link": "/rose-bouquets-malaysia"}, {"title": 'Sunflower', "link": "/sunflower-bouquets-malaysia"}, {"title": 'Tulip', "link": "/tulip-flower-bouquets"}]
    const flowersBalloonElements = [{"title": 'Newborn/Baby FNB', "link": "/newborn-fnb"}, {"title": 'Well Wishes/Occasions FNB', "link": "/get-well-soon-fnb"}, {"title": 'Happy Birthday FNB', "link": "/happy-birthday-fnb"}, {"title": 'Congratulations FNB', "link": "/congratulations"}, {"title": 'Anniversary FNB', "link": "/anniversary-fnb"}, {"title": 'Graduation FNB', "link": "/graduation-fnb"}]

    return (
        <div className='mobile__menu-main-block'>
            <button onClick={() => {setMobile(!isMobileMenu)}} className='mobile__menu-button'></button>
            {isMobileMenu && (
                <div className='mobile__menu-item-block'>
                    <button onClick={() => {setMobile(!isMobileMenu)}} className='mobile__menu-button-close'></button>
                    <div className='mobile__menu-buttons-block'>
                        <button onClick={() => {navigate('/'); setMobile(!isMobileMenu)}} className='mobile__menu-element-button'>Home</button>
                        <button onClick={() => {navigate('/graduation-flower-bouquets'); setMobile(!isMobileMenu)}} className='mobile__menu-element-button'>Graduation</button>
                        <div>
                            <button onClick={() => {setOccasion(!isOccasion)}} className={isOccasion ? 'mobile__menu-element-button active' : 'mobile__menu-element-button'}>Occasions</button>
                            { isOccasion &&
                                occasionsElements.map((el) => {
                                    return(
                                        <button onClick={() => {navigate(el.link); setMobile(!isMobileMenu)}} className='mobile__menu-element-button sub'>{el.title}</button>
                                    )
                                })
                            }
                        </div>
                        <div>
                            <button onClick={() => {setHampers(!isHampers)}} className={isHampers ? 'mobile__menu-element-button active' : 'mobile__menu-element-button'}>Hampers</button>
                            { isHampers &&
                                hampersElements.map((el) => {
                                    return(
                                        <button onClick={() => {navigate(el.link); setMobile(!isMobileMenu)}} className='mobile__menu-element-button sub'>{el.title}</button>
                                    )
                                })
                            }
                        </div>
                        <div>
                            <button onClick={() => {setFlowers(!isFlowers)}} className={isFlowers ? 'mobile__menu-element-button active' : 'mobile__menu-element-button'}>Shop Flowers</button>
                            { isFlowers &&
                                shopFlowersElements.map((el) => {
                                    return(
                                        <button onClick={() => {navigate(el.link); setMobile(!isMobileMenu)}} className='mobile__menu-element-button sub'>{el.title}</button>
                                    )
                                })
                            }
                        </div>
                        <div>
                            <button onClick={() => {setBallons(!isBallons)}} className={isBallons ? 'mobile__menu-element-button active' : 'mobile__menu-element-button'}>Flowers & Balloons</button>
                            { isBallons &&
                                flowersBalloonElements.map((el) => {
                                    return(
                                        <button onClick={() => {navigate(el.link); setMobile(!isMobileMenu)}} className='mobile__menu-element-button sub'>{el.title}</button>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
